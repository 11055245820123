import React, {useMemo, useRef} from 'react';
import {Capacitor} from '@capacitor/core';
import {Swiper, SwiperSlide} from 'swiper/react';

import {BrandImageWrapper} from './brandImageCarousel.styled';

interface Props {
  images: string[];
  height: number;
  isScrolled: boolean;
  children: React.ReactNode;
  onChangeIndex: (el: any) => void;
}

export default function BrandImageCarousel({
  images,
  children,
  height,
  onChangeIndex,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const wrapperHeight = useMemo(() => {
    console.log(ref.current);
    if (ref.current) {
      console.log(ref.current.offsetHeight);
      return ref.current.offsetHeight;
    }
    return height;
  }, [ref]);
  return (
    <>
      <BrandImageWrapper
        android={Capacitor.getPlatform() === 'android'}
        style={{height: `${height}px`}}>
        <Swiper
          initialSlide={0}
          centeredSlides={true}
          spaceBetween={0}
          className="homeSwiper"
          loop={true}
          slideToClickedSlide={true}
          onRealIndexChange={(el: any) => onChangeIndex(el)}>
          {images.map((image, index) => {
            return (
              <SwiperSlide
                key={`brand_detail_images_${index}`}
                data-order={`${index + 1}`}
                data-ordering={`${index + 1}`}
                style={{height: `${height}px`}}>
                <img src={image} alt={''} height={wrapperHeight} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {children}
      </BrandImageWrapper>
    </>
  );
}
