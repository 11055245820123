import React, {useEffect, useState} from 'react';

import FollowBtn from '../../common/follows/FollowBtn';
import {userProfile} from '../../../services/user';

interface Props {
  author: Author | undefined;
}

export default function FoodLogProfile({author}: Props) {
  const [profile, setProfile] = useState<UserInfo>();
  const init = async () => {
    const id = `${author?.id || 0}`;
    const res = await userProfile(id);
    setProfile(res.data as UserInfo);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="food-log-profile-wrapper">
      <div className="profile-info">
        <div className="profile-image">
          <img
            src={author?.profileImage}
            alt={author?.nickname}
            width={'40px'}
            height={'40px'}
          />
        </div>
        <div className="names-follwers-cnt">
          <div className="name">{author?.nickname}</div>
          <div className="followers">
            팔로워
            <span className="follow-cnt">
              {profile ? profile.followerCount : 0}
            </span>
          </div>
        </div>
      </div>
      <div className="follow-btn-wrapper">
        <FollowBtn />
      </div>
    </div>
  );
}
