import React from 'react';
interface Props {
  imgPath: string;
  alt: string;
}
export default function FeedItem({imgPath, alt}: Props) {
  return (
    <div className="item-wrapper">
      <div className="item-image">
        <img src={imgPath} alt={alt} width="260px" height="260px" />
      </div>
    </div>
  );
}
