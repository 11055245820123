import React from 'react';
import {useRecoilValue} from 'recoil';
import {useTranslation} from 'react-i18next';

import {UserInfo} from '@/store';
import Layout from '@/layouts';

import MyCommunities from '../myKnewnew/MyCommunities';

export default function MyArticles() {
  const userInfo = useRecoilValue(UserInfo);
  const {t} = useTranslation(['views'], {keyPrefix: 'myPage.MyArticles'});
  return (
    <Layout.Main>
      <div>
        <Layout.ContentsHeader txt={t('header')} isOption="none" />
        <MyCommunities isOther={false} id={userInfo.id} />
      </div>
    </Layout.Main>
  );
}
