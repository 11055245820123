import styled, {css} from 'styled-components';

const AndroidStyle = css`
  padding-top: var(--status-bar-height);
`;

export const ImageSlideWrapper = styled.div<ImageSliderPropsI>`
  z-index: 20;
  width: ${({mobile}) => (mobile ? '100%' : '375px')};
  position: fixed;
  top: 0px;
  bottom: 0;
  background-color: #000;
  overflow: auto;
  padding-top: env(safe-area-inset-top);
  ${({android}) => android && AndroidStyle};
  .squareSwiper {
    width: 100%;
    height: 100%;
  }
`;

export const SliderItemsWrapper = styled.div<{mobile: boolean}>`
  z-index: 20;
  width: ${({mobile}) => (mobile ? '100%' : '375px')};
  position: fixed;
  top: ${({mobile}) => (mobile ? '100px' : '80px')};
  bottom: 0;
  background-color: #000;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ImgWrapper = styled.div<{mobile: boolean}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${({mobile}) => (mobile ? '100%' : '375px')};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  }
`;
