import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';

import ZipContentCard from '@/components/contents/ZipContentCard';
import {getFamousNewzip} from '@/services/magazine';
import {MagazineItem} from '@/store';
import {UserInfo} from '@/store/user';
import {switchNewLineTxt} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';
import ContentsFooter from '@/components/contents/ContentsFooter';
import FreeComment from '@/assets/banner/its-free.svg';

import styles from './styles.module.scss';

export default function Zip() {
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [famousContentsRows, setFamousContentsRows] = useState<
    MagazineItem[][]
  >([]);
  const [id, setId] = useState(0);
  const magazineItem = useRecoilValue(
    MagazineItem({
      id,
      type: 'zip',
    }),
  );
  const [brandImages, setBrandImages] = useState<
    {title: string; img: string; link: string; id: number; parentId: number}[]
  >([]);
  const [hasLocked, setHasLocked] = useState(false);
  const [brandImageRows, setBrandImageRows] = useState<
    {title: string; img: string; link: string; id: number; parentId: number}[][]
  >([]);

  const _getFamousNewzip = async (info: MagazineItem) => {
    const res = await getFamousNewzip();
    const famous: MagazineItem[] = (res.data as MagazineItem[])
      .filter(item => item.id !== info.id)
      .splice(0, 4);
    const latestLength = Math.ceil(famous.length / 2);
    const rows: MagazineItem[][] = [];
    for (let i = 0; i < latestLength; i++) {
      rows.push([...famous].splice(i * 2, 2));
    }
    setFamousContentsRows(rows);
  };
  const makeBrandRow = (imgs: typeof brandImages) => {
    const rows: typeof brandImageRows = [];
    const rowLength = Math.ceil(imgs.length / 3);
    for (let i = 0; i < rowLength; i++) {
      rows.push([...imgs].splice(i * 3, 3));
    }
    setBrandImageRows(rows);
  };
  const onScroll = () => {
    if (window.scrollY > 1600 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
    }
  };
  const _getContentsDetail = async () => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    setId(id);
  };

  useEffect(() => {
    if (magazineItem.info) {
      _getFamousNewzip(magazineItem.info);
      if (magazineItem.info.zipList && magazineItem.info.zipList.length > 0) {
        const brands: {
          title: string;
          img: string;
          link: string;
          id: number;
          parentId: number;
        }[] = [];
        magazineItem.info.zipList.map((zip: ZipItem) => {
          brands.push({
            title: zip.title,
            img: zip.logoImage ?? zip.thumbnailUrl,
            link: zip.brandLink ?? undefined,
            id: zip.id,
            parentId: zip.parentId,
          });
        });
        setBrandImages(brands);
        makeBrandRow(brands);
      }
    }
  }, [magazineItem]);

  const sender = useLogger();

  useEffect(() => {
    _getContentsDetail();
    window.scrollTo(0, 0);
    document.addEventListener('scroll', onScroll);
    sender({
      _msg: 'ToZip',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Dark,
      });
    }
    return () => document.removeEventListener('scroll', onScroll);
  }, [window.location.pathname]);

  return magazineItem.info ? (
    <div>
      <div className="content-zip-banner">
        <div
          className="banner-contents"
          style={{
            background: `center / cover no-repeat url(${magazineItem.info.imageUrl})`,
          }}>
          <div className="txt-content">
            {/**
            <div className="type-info">
              {magazineItem.info.target?.replace(/^[a-z]/, (char: string) =>
                char.toUpperCase(),
              )}
            </div>
            */}
            <div className="title-contents">
              <div className="titles">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: switchNewLineTxt(magazineItem.info.title || ''),
                  }}></div>
                <div className="sub-title">{magazineItem.info.subtitle}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`main-content`}>
        <div
          className={`zip-contents-wrapper ${hasLocked ? 'show-login' : ''}`}>
          <div
            className="logins"
            style={{display: userInfo.id === -1 ? 'flex' : 'none', zIndex: 3}}>
            <div className="login-txt">
              <p>다음 내용이 궁금하다면?</p>
              <h1>
                딱 3초만에 가입하고 <br /> 계속 볼 수 있어요!
              </h1>
              <div
                className="main-cont btn black"
                onClick={() => {
                  sender({
                    _msg: 'joinmodal_click',
                    _screen: window.location.pathname,
                    _action: Action.CLICK,
                  });
                  navigator('/login', {
                    state: {
                      prevPath: `${location.pathname}${location.search}`,
                    },
                  });
                }}
                style={{cursor: 'pointer', background: '#000', color: '#fff'}}>
                <div
                  className="message-box"
                  style={{
                    background: `url("${FreeComment}") no-repeat center / cover`,
                  }}></div>
                <div>로그인하러 가기</div>
              </div>
            </div>
          </div>
          <div className="contents-info">
            <div className="dates">
              {magazineItem.info.created.split('T')[0]}
            </div>
            <div className="see-count">
              <span className="icon see-count-icon">&nbsp;</span>
              <span className="cnt">{magazineItem.info.viewCount}</span>
            </div>
          </div>
          <div
            className="pick-reason"
            dangerouslySetInnerHTML={{__html: magazineItem.file}}></div>
          <div className="zip-content-card-list">
            {magazineItem.info.zipList?.map((zip: ZipItem, i: number) => {
              return (
                <ZipContentCard key={`zip_item_${zip.id}`} item={zip} idx={i} />
              );
            })}
          </div>
        </div>
        <div
          className="more-brand"
          style={{display: userInfo ? 'block' : 'none'}}>
          <h1>브랜드 더 알아보기</h1>
          <div className="brand-contents">
            {brandImageRows.map((imgs, i) => {
              return (
                <div className="brand-row" key={`img_brand_items_row_${i}`}>
                  {imgs.map((m, i) => {
                    return (
                      <div
                        className="brand-item"
                        key={`img_brand_items_${i}`}
                        onClick={
                          m.parentId
                            ? () => {
                                return navigator(
                                  `/brand/${m.parentId}/${m.id}`,
                                  {
                                    state: {
                                      prev: window.location.pathname.replace(
                                        '/',
                                        '',
                                      ),
                                    },
                                  },
                                );
                              }
                            : undefined
                        }
                        style={{
                          cursor: m.parentId ? 'pointer' : 'default',
                        }}>
                        <div
                          className="brand-img"
                          style={{
                            background: `center / cover no-repeat url('${m.img}')`,
                            cursor: m.parentId ? 'pointer' : 'default',
                          }}></div>
                        <div
                          className={`brand-txt ${styles.brandLink}`}
                          style={{
                            cursor: m.parentId ? 'pointer' : 'default',
                          }}>
                          <div className={m.parentId ? styles.hasLink : ''}>
                            <span className={styles.title}>{m.title}</span>
                            <span className={styles.float}>
                              <a className={styles.linkIcon}></a>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="curator-info">
          <div
            className="curator-img"
            style={{
              background: `center / cover no-repeat url(${magazineItem.info.author.profileImage})`,
            }}></div>
          <div className="curator-txt-info">
            <div className="nick-name">
              <span>{magazineItem.info.author.nickname}</span>
              <span
                className="curator-profile"
                onClick={() =>
                  navigator(`/curators/${magazineItem.info.author.id}`, {
                    state: {
                      history: 'contents',
                      prev: `${location.pathname.slice(1, undefined)}`,
                    },
                  })
                }>
                <span className="curator-txt">Curator Profile</span>
                <span className="arrow-right-at-brand">&nbsp;</span>
              </span>
            </div>
            <div className="curator-message">
              {magazineItem.info.author.headline}
            </div>
          </div>
        </div>
        <div className="content-divider"></div>
        <div className="curator-other-contents">
          <h1 className="hangul">지금 많이 보는 컨텐츠</h1>
          {famousContentsRows.map((c, idx) => {
            return (
              <div
                className="contents-info"
                key={`famous_contents_info_${idx}`}>
                {c.map(item => {
                  return (
                    <div
                      className="item"
                      key={`famous_contents_item_${item.id}`}
                      onClick={() => navigator(`/zip/${item.id}`)}
                      style={{cursor: 'pointer'}}>
                      <div
                        className="item-img"
                        style={{
                          background: `center / cover no-repeat  url(${item.imageUrl})`,
                        }}></div>
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: switchNewLineTxt(item.title || ''),
                        }}></div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <ContentsFooter
        detailInfo={magazineItem.info as MagazineItem}
        type="zip"
        getContentsDetail={_getContentsDetail}
      />
    </div>
  ) : (
    <div>{/* <ContentsHelmet></ContentsHelmet> */}</div>
  );
}
