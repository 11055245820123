import React, {useState} from 'react';
import {useSetRecoilState} from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';

import CuratorCard from '@/components/curator/CuratorCard';
import {getCurators} from '@/services/magazine';
import {Curators} from '@/store/user';
import ContentsHeader from '@/layouts/ContentsHeader';
import Layout from '@/layouts';

import styles from './styles.module.scss';

export default function Curator() {
  const setCurators = useSetRecoilState(Curators);
  const [curatorRows, setCuratorRows] = useState<Curator[][]>([]);
  const [maxCount, setMaxCount] = useState(200);
  const [curators, setCuratorList] = useState<Curator[]>([]);

  const makeRows = (c: Curator[]) => {
    const rowCnt = Math.ceil(c.length / 2);
    const rows: Curator[][] = [];
    for (let i = 0; i < rowCnt; i++) {
      rows.push([...c].splice(i * 2, 2));
    }
    setCuratorRows(rows);
  };

  const _getCurators = async (limit: number) => {
    const res = await getCurators(limit);
    console.log(res);
    setMaxCount(res.count);
    setCuratorList(res.results);
    setCurators(res);
    makeRows(res);
  };

  return (
    <Layout.Main>
      <div className={`${styles.curators}`}>
        <ContentsHeader
          txt={'Curator'}
          isOption={'none'}
          isBlack={true}
          isBottomLine={'none'}
        />
        <div className={styles.banner}>
          <div className={styles.txtWrapper}>
            <img src="/assets/icons/curator-logo.svg"></img>
            <div className={styles.subTitle}>
              맛과 멋이 있는 F&B브랜드를 발굴하는
            </div>
            <div className={styles.subTitle}>Knewnew 큐레이터</div>
          </div>
        </div>
        <div className={styles.listsWrapper}>
          <InfiniteScroll
            pageStart={0}
            loadMore={(page: number) => {
              const limit = page * 20;
              _getCurators(limit);
            }}
            hasMore={curators.length < maxCount}>
            <div className={styles.lists}>
              {curatorRows.map((curators: Curator[], idx) => {
                return (
                  <div className={styles.curatorRow} key={`curator_row_${idx}`}>
                    {curators.map((c, idx) => {
                      return (
                        <CuratorCard
                          isLeft={true}
                          key={`curator_item_${idx}`}
                          item={c}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.apply_wrapper}>
          <span>뉴뉴와 함께 F&B의 새로운 이야기를 써보고 싶으시다면?</span>
          <button>큐레이터 신청하기</button>
        </div>
      </div>
    </Layout.Main>
  );
}
