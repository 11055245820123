import React from 'react';

interface Props {
  path: string;
}
export default function FeedProfile({path}: Props) {
  return (
    <div className="feed-user-img">
      <img
        src={path}
        alt=""
        width="30px"
        height="30px"
        style={{borderRadius: '30px', height: '30px'}}
      />
    </div>
  );
}
