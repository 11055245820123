import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {timeForToday} from '@/utils';

import {UserInfo} from '../../store/user';
import emptyKnewnew from '../../products/user_default.svg';
import useLogger, {Action} from '../../hooks/useLogger';
import CommunityLoader from '../common/Skeleton/CommunityLoader';

interface Props {
  community: Community;
  wrapperType: 'community' | 'my-contents';
}

export default function UserContent({community, wrapperType}: Props) {
  const location = useLocation();
  const imageRef = useRef(null);
  const navigator = useNavigate();
  const [author, setAuthor] = useState<Author>();
  const userInfo = useRecoilValue(UserInfo);
  const sender = useLogger();

  // const setOpenLoginModal = useSetRecoilState(isOpenCommunityLoginModal);

  const makeCapital = (title: string) => {
    const words = title.split('');
    words[0] = words[0].toUpperCase();
    return words.join('');
  };

  const moveToContents = (id: number) => {
    navigator(`/community/${id}`, {
      state: {
        wrapperType,
        id: location.state?.id,
        postPrev: location.state?.prev,
      },
    });
  };

  const moveToProfile = ($event: React.MouseEvent<HTMLDivElement>) => {
    if (community.author.id !== userInfo.id) {
      sender({
        _msg: 'viewWriter',
        _screen: `/community/writer/${community.author.id}`,
        _action: Action.CLICK,
      });
    }
    $event.stopPropagation();

    navigator(
      userInfo
        ? community.author.id === userInfo.id
          ? '/user'
          : `/community/writer/${community.author.id}`
        : `/community/writer/${community.author.id}`,
      {
        state: {
          prev: 'community',
          id: location.state?.id,
        },
      },
    );
  };

  useEffect(() => {
    if (community) {
      setAuthor(community.author);
    }
  }, [community]);

  return (
    <div
      className="user-content-wrapper"
      onClick={() => moveToContents(community.id)}>
      {community.id > -1 ? (
        <div>
          <div className="content-top">
            <div
              className="writer"
              onClick={($event: React.MouseEvent<HTMLDivElement>) => {
                if (author) {
                  moveToProfile($event);
                }
              }}>
              <div
                className="profile"
                style={{
                  backgroundImage: `url(${
                    author?.profileImage ? author?.profileImage : emptyKnewnew
                  })`,
                }}></div>
              {author ? (
                <div className="name">{author?.nickname}</div>
              ) : (
                <div className="delete-name"> {'탈퇴한 유저입니다'}</div>
              )}
            </div>
            <div className="content-category">
              {makeCapital(community.category)}
            </div>
          </div>
          <div className="content-image-wrapper" ref={imageRef}>
            {[...community.images].splice(0, 4).map((img, idx) => {
              return (
                <div
                  key={`user_contents_${img.image}_${idx}`}
                  className="card-item"
                  style={{
                    background: `url(${img.image}) no-repeat center / cover`,
                    margin:
                      idx === 0
                        ? '0 2px 0 20px'
                        : idx === [...community.images].splice(0, 4).length - 1
                        ? '0 20px 0 0'
                        : '0 2px 0 0',
                  }}>
                  <div
                    className="blur-item"
                    style={{
                      display:
                        idx > 0 &&
                        idx === [...community.images].splice(0, 4).length - 1
                          ? 'flex'
                          : 'none',
                    }}>
                    본문 보기
                  </div>
                </div>
              );
            })}
            {/* <span
              className="arrow arrow-left-wrapper"
              onClick={($event: React.MouseEvent<HTMLSpanElement>) =>
                moveLeft($event)
              }
              style={{
                display: community.images.length > 2 ? 'inline-flex' : 'none',
              }}>
              <span className="icon arrow-left-white">&nbsp;</span>
            </span>
            <span
              className="arrow arrow-right-wrapper"
              onClick={($event: React.MouseEvent<HTMLSpanElement>) =>
                moveRight($event)
              }
              style={{
                display: community.images.length > 2 ? 'inline-flex' : 'none',
              }}>
              <span className="icon arrow-right-white">&nbsp;</span>
            </span> */}
          </div>
          <div className="content-bottom">
            <div className="title">{community.title}</div>
            <div className="bottom-info">
              <div className="additional-content-info">
                <span className="times">
                  {timeForToday(community.created.split('.')[0])}
                </span>
                &nbsp;•&nbsp;
                <span className="region">
                  {community.address1
                    ? community.address1 + ' ' + community.address2
                    : '온라인 스토어'}
                </span>
              </div>
              <div className="feedback-info">
                <div className="comment-wrapper">
                  <span className="community-comment-icon">&nbsp;</span>
                  <div className="comment-cnt">{community.commentCount}</div>
                </div>
                <div className="like-wrapper">
                  <span className="community-like-icon">&nbsp;</span>
                  <div className="like-cnt">{community.likeCount}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CommunityLoader />
      )}
    </div>
  );
}
