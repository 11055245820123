import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
  linkText: string;
  linkCallback: () => void;
}

export default function NewBrandsLink({
  title,
  description,
  linkText,
  linkCallback,
}: Props) {
  return (
    <div className={styles.wrapper} onClick={() => linkCallback()}>
      <div className={styles.txtWrapper}>
        <h3>{title}</h3>
        <span>{description}</span>
      </div>
      <div className={styles.linkWrapper}>
        <span className={styles.linkTxt}>{linkText}</span>
        <span className={styles.arrow}></span>
      </div>
    </div>
  );
}
