import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {lock, unlock} from 'tua-body-scroll-lock';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';

import {userDetail} from '@/services/user';
import {isOpenMenu} from '@/store';
import {user, UserInfo} from '@/store/user';
import useStorage from '@/hooks/useStorage';

import styles from './styles.module.scss';

export default function Menu() {
  const setIsOpenMenu = useSetRecoilState(isOpenMenu);
  const {getToken} = useStorage();
  const isOpenMenuState = useRecoilValue(isOpenMenu);
  const setUserInfo = useSetRecoilState(UserInfo);
  const [userToken, setAccessToken] = useRecoilState(user);
  const [settedUserInfo, setSettedUserInfo] = useState(false);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const navigator = useNavigate();
  const move = (path: string) => {
    navigator(path);
    setIsOpenMenu(false);
  };
  const checkActive = (path: string) => {
    return window.location.pathname.indexOf(path) > -1;
  };
  const init = async () => {
    try {
      const accessToken = await getToken();
      if (accessToken && accessToken.length > 0) {
        const res = await userDetail(accessToken);
        setUserInfo(res.data);
        setAccessToken(accessToken);
        setSettedUserInfo(true);
      } else {
        setSettedUserInfo(false);
      }
    } catch (e) {
      console.log(e);
      setSettedUserInfo(false);
    }
  };
  const openSubMenu = () => {
    setIsOpenSubMenu(!isOpenSubMenu);
  };
  const checkOpenSubMenu = () => {
    return (
      checkActive('brands') ||
      checkActive('zip') ||
      checkActive('news') ||
      checkActive('curators')
    );
  };
  useEffect(() => {
    if (isOpenMenuState) {
      setIsOpenSubMenu(checkOpenSubMenu);
      lock();
    } else {
      unlock();
    }
    if (!userToken) {
      init();
    } else {
      setSettedUserInfo(true);
    }
  }, [isOpenMenuState, window.location.pathname]);
  return (
    <div
      className={styles.mainMenuWrapper}
      style={{display: isOpenMenuState ? 'block' : 'none'}}>
      <div
        className={`${styles.closeBtnWrapper} ${
          Capacitor.getPlatform() === 'android' ? 'android' : ''
        }`}>
        <span className={styles.menuLogo} onClick={() => move('/home')}></span>
        <span
          className={`${styles.closeIcon} close-white-icon`}
          style={{cursor: 'pointer'}}
          onClick={() => setIsOpenMenu(false)}></span>
      </div>
      <div className={styles.menuBtnWrapper}>
        <div className={styles.loginWrapper}>
          <span
            className={`${styles.loginBtn} ${styles.loginBtnPreLogin}`}
            onClick={() => move('/user')}
            style={{display: settedUserInfo ? 'none' : 'inline'}}>
            Login
          </span>
          <span
            className={`${styles.loginBtn} ${styles.loginBtnMypage}`}
            onClick={() => move('/user')}
            style={{display: settedUserInfo ? 'inline-flex' : 'none'}}>
            <span className="icon white-person-filled-icon">&nbsp;</span>
            My page
          </span>
        </div>
        <span
          className={`${styles.menuButton} home ${
            checkActive('brands') ||
            checkActive('zip') ||
            checkActive('news') ||
            checkActive('curators')
              ? `${styles.menuButtonActive}`
              : ''
          }`}
          onClick={() => openSubMenu()}>
          Magazine
        </span>
        <ul
          className={styles.magazineSubMenu}
          style={{display: isOpenSubMenu ? 'block' : 'none'}}>
          <li
            className={checkActive('brands') ? 'active' : ''}
            onClick={() => move('/brands')}>
            Knew Brands
          </li>
          <li
            className={checkActive('zip') ? 'active' : ''}
            onClick={() => move('/zip')}>
            Knew Zip
          </li>
          <li
            className={checkActive('news') ? 'active' : ''}
            onClick={() => move('/news')}>
            Knew News
          </li>
          <li
            className={checkActive('curators') ? 'active' : ''}
            onClick={() => move('/curators')}>
            Curator
          </li>
        </ul>
        <span
          className={`${styles.menuButton} knew-brands ${
            checkActive('community') ? `${styles.menuButtonActive}` : ''
          }`}
          onClick={() => move('/community')}>
          Community
        </span>
        <span
          className={`${styles.menuButton} knew-news ${
            checkActive('about') ? `${styles.menuButtonActive}` : ''
          }`}
          onClick={() => move('/about')}>
          About
        </span>
      </div>
    </div>
  );
}
