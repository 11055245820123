import React from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

import {
  ToastMessage,
  isOpenNotificationAlert,
  isOpenToast,
} from '../../../store';
import useStorage from '../../../hooks/useStorage';
import usePushNotification from '../../../hooks/usePushNotification';
import {UserInfo, user} from '../../../store/user';
import {updateProfile} from '../../../services/user';

export default function NotificationAlert() {
  const navigator = useNavigate();
  const storage = useStorage();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const accessToken = useRecoilValue(user);
  const {checkPermissions} = usePushNotification();
  const openModal = useRecoilValue(isOpenNotificationAlert);
  const setOpenModal = useSetRecoilState(isOpenNotificationAlert);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);

  const agree = async () => {
    const isAgreed = await checkPermissions();
    const date = `${dayjs().format('YYYY.MM.DD')}`;
    const _userInfo = {
      ...userInfo,
      isMarketing: true,
      isNotifiable: true,
    };
    await updateProfile(userInfo.id, accessToken, _userInfo);
    setUserInfo(_userInfo);
    setOpenModal(false);
    if (isAgreed !== 'granted') {
      setOpenModal(false);
      navigator('/push-config', {
        state: {
          isMarketing: true,
        },
      });
    }
    setToastMessage(`${date}에 마케팅 정보 수신을 동의하였습니다.`);
    setIsOpenToast(true);
  };
  const disagree = async () => {
    await storage.setItem(
      'knewnew_not_agree_marketing',
      `${dayjs().format('YYYY-MM-DD HH:mm:SS')}`,
    );
    setOpenModal(false);
  };
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="login-modal-header"
          style={{
            marginTop: '30px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          스페셜 혜택을 놓치지 마세요
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          마케팅 정보 수신에 동의하시면 <br />
          새로운 혜택을 가장 빠르게 확인하실 수 있습니다.
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn black-filled"
            style={{fontWeight: 600, fontSize: '14px'}}
            onClick={() => agree()}>
            알림 수신에 동의합니다.
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => disagree()}>
            동의하지 않습니다.
          </button>
        </div>
      </div>
    </div>
  );
}
