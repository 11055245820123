import React from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  action: () => void;
}

export default function BrandLoginModal({isOpen, setIsOpen, action}: Props) {
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
      }}>
      <div
        className="modal-contents  community-auth-alert"
        style={{
          width: '295px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '40px',
        }}>
        <div>
          <strong>
            더 많은 브랜드 정보부터
            <br />
            할인 혜택까지 받아보세요.
          </strong>
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '30px'}}>
          <>
            <button
              className="modal btn black-filled"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                border: '1px solid #E9E7EC',
                background: '#000',
                borderRadius: '5px',
                color: '#fff',
              }}
              onClick={() => action()}>
              로그인
            </button>
            <button
              className="modal btn black-filled"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                background: '#fff',
                color: '#9F9CA3',
              }}
              onClick={() => setIsOpen(false)}>
              닫기
            </button>
          </>
        </div>
      </div>
    </div>
  );
}
