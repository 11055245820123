import React, {useRef, useState} from 'react';
import {Capacitor} from '@capacitor/core';

import FilterModalHeader from '@/components/common/FilterModal/FilterModalHeader';

import styles from '../styles.module.scss';

interface Props {
  item: OrderListItem;
  isOpen: boolean;
  toggleOpen: (is: boolean) => void;
  openCancelConfirmModal?: (is: boolean) => void;
  changeOptions?: (txt: string) => void;
}

interface CancelOptionItem {
  txt: CancelOption;
  param: CancelOptionParam;
}

export default function OrderCancelModal({
  isOpen,
  toggleOpen,
  openCancelConfirmModal,
  changeOptions,
}: Props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedReason, setSelectedReason] = useState<number>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const options: CancelOptionItem[] = [
    {txt: '옵션 (수량, 시간대 등)을 잘못 입력해서', param: 'typo'},
    {txt: '일정이 맞지 않아 참석이 어려워서', param: 'schedule'},
    {txt: '가격이 합리적이지 않아서', param: 'price'},
    {txt: '상품이 매력적이지 않아서', param: 'appeal'},
    {txt: '기타', param: 'else'},
  ];
  const onChange = (idx: number) => {
    if (idx === selectedReason) {
      setSelectedReason(undefined);
      setIsDisabled(true);
      changeOptions && changeOptions('');
    } else {
      setSelectedReason(idx);
      setIsDisabled(false);
      changeOptions && changeOptions(options[idx].param);
    }
  };
  return (
    <div className="modal-wrapper" style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : 'none'}`}
        ref={wrapperRef}>
        <FilterModalHeader
          txt={'주문 취소'}
          close={() => toggleOpen(false)}
          fnTxt=""
          isHeaderPadding
        />
        <div className={styles.cancel_modal_body}>
          <h2>
            취소 사유 <span>*</span>
          </h2>
          <div className={styles.option_list}>
            {options.map((option, idx) => {
              return (
                <div
                  className={styles.select_options}
                  key={`option_list_item_${idx}`}
                  style={{cursor: 'pointer'}}>
                  <input
                    type="radio"
                    checked={selectedReason === idx}
                    onChange={() => onChange(idx)}
                  />
                  <span
                    style={{marginLeft: '10px'}}
                    onClick={() => onChange(idx)}>
                    {option.txt}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={styles.notice}>
            <div>
              <div style={{float: 'left', width: '15px'}}>•</div>
              <div>
                {' '}
                환불 금액 반환은 결제수단에 따라 최대 7일 소요될 수 있습니다.
              </div>
            </div>
            <div>
              <div style={{float: 'left', width: '15px'}}>•</div>
              <div>
                일부 수량만 취소를 원하는 경우, 전체 취소 후 재구매 해주세요.
              </div>
            </div>
            <div>
              <div style={{float: 'left', width: '15px'}}>•</div>
              <div>
                결제 시 사용하신 할인쿠폰은 취소 완료 후 즉시 반환됩니다.
              </div>
            </div>
            <div>
              <div style={{float: 'left', width: '15px'}}>•</div>
              <div>
                미성년자가 법정 대리인의 동의 없이 계약을 체결한 경우, 미성년자
                또는 법정 대리인이 구매를 취소할 수 있습니다.
              </div>
            </div>
          </div>
        </div>
        <div className="filter-modal-footer">
          <button
            className="modal btn black-filled address-btn"
            onClick={() => {
              toggleOpen(false);
              openCancelConfirmModal && openCancelConfirmModal(true);
            }}
            disabled={isDisabled}
            style={{
              background: isDisabled ? '#E9E7EC' : '#000',
              marginBottom: Capacitor.getPlatform() === 'ios' ? '7px' : '0',
            }}>
            주문 취소하기
          </button>
        </div>
      </div>
    </div>
  );
}
