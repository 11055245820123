import {AxiosResponse} from 'axios';
import {atom, selector, selectorFamily} from 'recoil';

import {fetchAddress, getCommunityContents} from '../services/community';
import {user} from './user';
import {ListArgs} from '../views/community/List';

export const CommunityPage = atom<number>({
  key: 'community/page',
  default: 0,
});

export const CommunityCategory = atom<CategoryItem | undefined>({
  key: 'community/category',
  default: undefined,
});

export const CommunityListArgs = atom<ListArgs>({
  key: 'community/list-args',
  default: {
    page: 0,
    address1: undefined,
    address2: undefined,
  },
});

export const Communities = atom<Community[]>({
  key: 'community/list',
  default: [],
});

export const CommunityTotalCnt = atom<number>({
  key: 'community/totalCnt',
  default: 0,
});

export const CommunityAddress1 = atom<string>({
  key: 'community/address-1',
  default: '',
});

export const CommunityAddress2 = atom<string>({
  key: 'community/address-2',
  default: '',
});

export const CommunityAddressTxt = atom<string>({
  key: 'community/address-text',
  default: '지역 전체',
});

export const EditableCommunity = atom<CommunityContentReq>({
  key: 'community/temp-contents',
  default: undefined,
});

export const ModalContentsID = atom<number>({
  key: 'community/modal-contents-id',
  default: -1,
});

export const CommunityContentId = atom<number>({
  key: 'community/content-id',
  default: 0,
});

export const CommunityCommentCount = atom<number>({
  key: 'community/comment-count',
  default: 0,
});

export const CommunityLikeCount = atom<number>({
  key: 'community/like-count',
  default: 0,
});

export const CommunityIsLike = atom<boolean>({
  key: 'community/is-like',
  default: false,
});

export const CommunityContent = selectorFamily({
  key: 'community/contents',
  get:
    (param: {id: number}) =>
    async ({get}) => {
      if (param.id > 0) {
        const res: AxiosResponse<Community> = await getCommunityContents(
          param.id,
          get(user),
        );
        return res.data;
      }
      return undefined;
    },
  set:
    () =>
    ({set}, newVal) => {
      set(CommunityCommentCount, (newVal as Community).commentCount);
      set(CommunityLikeCount, (newVal as Community).likeCount);
      set(CommunityIsLike, (newVal as Community).isLike);
    },
});

export const AddressList = selector<Address[]>({
  key: 'community/address-list',
  get: async () => {
    const res: AxiosResponse<Address[]> = await fetchAddress();
    return res.data;
  },
});

export const CommunityDummyItem = atom<Community>({
  key: 'community/dummy-item',
  default: {
    address1: '',
    address2: '',
    author: {
      badge: null,
      id: -1,
      nickname: '',
      profileImage: '',
    },
    bookmarkContent: -1,
    brands: {
      address: '',
      brandLink: '',
      brandImages: [''],
      categoryList: [''],
      hashtags: [''],
      id: -1,
      info: null,
      openingHours: '',
      points: [''],
      productName: '',
      state: '',
      thumbnailUrl: '',
      title: '',
      type: '',
      whereToBuy: '',
    },
    category: '',
    commentCount: -1,
    content: '',
    created: '',
    id: -1,
    images: [
      {
        id: -1,
        image: '',
        priority: -1,
      },
    ],
    isActive: false,
    isBookmark: false,
    isEdit: false,
    isLike: false,
    likeCount: -1,
    market: null,
    product: null,
    satisfaction: '',
    tags: [],
    title: '',
    viewCount: -1,
  },
});

export const AddressSelectorList = atom<AddressSelectorInfo[]>({
  key: 'community/address-selector-info',
  default: [
    {
      groupName: '인기 지역',
      addressType: 1,
      address1: [1],
      addressList: [
        {
          txt: '성수 · 서울숲',
          address2: [16],
        },
        {
          txt: '압구정 · 청담',
          address2: [2],
        },
        {
          txt: '잠실 · 송파',
          address2: [18],
        },
        {
          txt: '신당 · 명동 · 을지로',
          address2: [24],
        },
        {
          txt: '한남 · 이태원',
          address2: [21],
        },
        {
          txt: '합정 · 망원',
          address2: [13],
        },
        {
          txt: '연남 · 연희',
          address2: [14],
        },
        {
          txt: '서촌 · 북촌',
          address2: [23],
        },
        {
          txt: '용산 · 삼각지',
          address2: [21],
        },
      ],
    },
  ],
});
