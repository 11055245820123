import React, {useMemo} from 'react';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

import styles from '../styles.module.scss';

interface Props {
  title: string;
  date: string;
  img: string;
  id: number;
  item: OrderListItem;
  prev?: 'my' | 'benefits';
}

export default function BenefitItem({title, date, img, item}: Props) {
  const navigator = useNavigate();
  const remainDate = useMemo(() => {
    const _remain = dayjs().diff(dayjs(`${date} 23:59:00`), 'd');
    return _remain;
  }, [date]);
  const statusTxt = useMemo(() => {
    if (remainDate > -8 && remainDate < 0) {
      return `D${remainDate}`;
    }
    if (remainDate === 0) {
      return '오늘 만료';
    }

    if (item.usedStatus === '취소 불가') {
      return '구매 완료';
    }
    if (remainDate <= -8) {
      return dayjs(date).format('MM월 DD일 까지');
    }
    if (remainDate > 0 && item.usedStatus === '구매 완료') {
      return '기간 만료';
    }
    if (item.usedStatus === '사용 완료') {
      return item.usedStatus;
    }
    return item.usedStatus;
  }, [date, item]);
  return (
    <div
      className={styles.benefitItem}
      onClick={() =>
        navigator(`/order/gift/use/${item.id}?step=1`, {
          state: {
            status: item.usedStatus,
            item,
            fromOrderList: false,
            prev: '/user',
          },
        })
      }>
      <div
        className={styles.benefitImg}
        style={{background: `url('${img}') no-repeat center / cover`}}></div>
      <div className={styles.txtArea}>
        <div className={styles.txts}>
          <div className={styles.date}>{statusTxt}</div>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.txtArrow}></div>
      </div>
    </div>
  );
}
