import React from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {isOpenFilterModal} from '../../../store';
import {MarketPageFilters} from '../../../store/recommend';

interface Props {
  useRetry: boolean;
  type: string;
}

export default function FilterModalFooter({useRetry, type}: Props) {
  const [filterInfo, setFilterInfo] = useRecoilState(MarketPageFilters);
  const setOpenModal = useSetRecoilState(isOpenFilterModal);
  const retry = () => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    _filterInfo.forEach(f => {
      if (f.type === type) {
        f.selection = [];
      }
    });
    setFilterInfo(_filterInfo);
  };
  return (
    <div className="filter-modal-footer">
      <div
        className="retry-wrapper"
        style={{display: useRetry ? 'flex' : 'none'}}
        onClick={() => retry()}>
        <span className="retry-icon"></span>
        <span className="txt">다시 선택</span>
      </div>
      <button
        className="modal btn main-pink-filled"
        onClick={() => setOpenModal(false)}>
        선택 완료
      </button>
    </div>
  );
}
