import styled from 'styled-components';

export const BrandDetailWrapper = styled.div`
  padding: 24px 20px;
  background: #000;
`;

export const BrandInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
