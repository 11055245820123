import React from 'react';
import {useRecoilState} from 'recoil';

import {IsOpenBlackBtnConfirmModal} from '@/store';

import Modal from '../../layouts/Modal';
import styles from './styles.module.scss';
import Button from '../Button';

interface Props {
  callback?: () => void;
  isBtnDisable?: boolean;
}

export default function BlackButtonConfirmModal({
  callback,
  isBtnDisable,
}: Props) {
  const [isOpen, setIsOpen] = useRecoilState(IsOpenBlackBtnConfirmModal);
  return (
    <Modal.Alert
      width={'315px'}
      height={'246px'}
      isOpen={isOpen}
      style={{borderRadius: '10px'}}>
      <div className={styles.contents} style={{padding: '30px 16px 10px 16px'}}>
        <div className={styles.txtArea}>주문을 취소하시겠습니까?</div>
        <div className={styles.descriptions}>
          취소 후에는 변경이 불가합니다.
        </div>
        <div className={styles.buttonArea}>
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'주문 취소'}
            background={'#000'}
            color={'#fff'}
            disabled={isBtnDisable}
            onClick={() => (callback ? callback() : '')}
          />
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'이전으로'}
            background={'#fff'}
            color={'#9F9CA3'}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </Modal.Alert>
  );
}
