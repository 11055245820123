/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useRef, useState} from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {Keyboard} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';

import {editComment, makeComment} from '../../services/comment';
import {
  answerNickName,
  answerTargetId,
  commentEditContents,
  isOpenLoginModal,
  ModalCommentID,
  ModalCommentStatus,
  openAnswer,
  CommunityCommentCount,
} from '../../store';
import {user, UserInfo} from '../../store/user';

interface Props {
  fetch?: () => void;
  refetchContents?: () => void;
  list: ReviewComment[];
}

export default function EnterComments({fetch, refetchContents, list}: Props) {
  const userInfo = useRecoilValue(UserInfo);
  const userAccessToken = useRecoilValue(user);
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const openAnswerWrapper = useRecoilValue(openAnswer);
  const nickname = useRecoilValue(answerNickName);
  const parentId = useRecoilValue(answerTargetId);
  const resetOpenAnswer = useResetRecoilState(openAnswer);
  const commentId = useRecoilValue(ModalCommentID);
  const commentStatus = useRecoilValue(ModalCommentStatus);
  const resetAnswerNickName = useResetRecoilState(answerNickName);
  const resetAnswerTargetId = useResetRecoilState(answerTargetId);
  const [_commentEditContents] = useRecoilState(commentEditContents);
  const resetCommentEditContents = useResetRecoilState(commentEditContents);
  const resetCommentStatus = useResetRecoilState(ModalCommentStatus);
  const resetCommentId = useResetRecoilState(ModalCommentID);
  const [commentCnt, setCommunityCommentCount] = useRecoilState(
    CommunityCommentCount,
  );
  const [content, setContent] = useState<string>('');
  const offsetTop = useRef(0);

  const change = ($event: React.KeyboardEvent<HTMLDivElement>) => {
    if (($event.currentTarget as any).textContent.length > 500) {
      $event.preventDefault();
    }
    const message = `${$event.currentTarget.textContent}`.slice(0, 500);
    setContent(message);
  };

  const doAfterSave = (type?: string) => {
    if (fetch) {
      fetch();
    }
    if (refetchContents) {
      refetchContents();
    }
    if (parentId > -1) {
      cancelAnswer();
    }
    if (commentStatus === 'edit') {
      cancelEdit();
    }
    if (type && (type === 'add' || type === 'answer')) {
      setCommunityCommentCount(commentCnt + 1);
    }
  };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  const saveComment = async () => {
    try {
      const paths = window.location.pathname.split('/');
      const id = Number(paths[paths.length - 1]);
      const type =
        commentStatus === 'edit' && commentId > -1
          ? 'edit'
          : parentId === -1
          ? 'add'
          : 'answer';
      if (userInfo) {
        type === 'add'
          ? await makeComment(id, content, userAccessToken)
          : type === 'answer'
          ? await makeComment(id, content, userAccessToken, parentId)
          : await editComment(
              commentId,
              id,
              content,
              userAccessToken,
              undefined,
            );
        setContent('');
        (
          document.querySelector('.enter-comments.main-comments') as any
        ).innerHTML = '';
      } else {
        setLoginModal(true);
      }
      doAfterSave(type);
    } catch (e) {
      setContent('');
    }
  };
  const cancelAnswer = () => {
    resetOpenAnswer();
    resetAnswerNickName();
    resetAnswerTargetId();
  };
  const cancelEdit = () => {
    resetCommentStatus();
    resetCommentEditContents();
    resetCommentId();
  };
  const keydown = ($event: React.KeyboardEvent<HTMLDivElement>) => {
    if ($event.keyCode === 13) {
      $event.preventDefault();
      return false;
    }
    if (($event.currentTarget as any).textContent.length > 500) {
      $event.preventDefault();
      return false;
    }
  };

  const focusInput = async ($event: React.FocusEvent<HTMLDivElement>) => {
    const ot =
      $event.currentTarget.getBoundingClientRect().y +
      $event.currentTarget.getBoundingClientRect().height;

    offsetTop.current = ot;
    if (Capacitor.getPlatform() === 'web') {
      window.scrollTo(0, ot);
    }
  };

  const moveToBottom = () => {
    const paths = window.location.pathname.split('/');
    const target =
      paths[paths.length - 2] === 'comments' ? 'comments' : 'community';
    const wrapper =
      target === 'community'
        ? document.querySelector(
            '.main.community-detail-contents .community-detail-wrapper',
          )
        : document.querySelector('.comments-wrapper');
    if (target === 'comments') {
      window.scrollTo(0, (wrapper as any).clientHeight + 300);
    }
  };
  useEffect(() => {
    if (_commentEditContents.length > 0) {
      setContent(_commentEditContents);
    }
  }, [_commentEditContents]);
  useEffect(() => {
    if (list.length > 0) {
      moveToBottom();
    }
  }, [list]);
  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      initKeyboard();
    }
    if (openAnswerWrapper) {
      cancelAnswer();
    }
    return () => {
      if (Capacitor.getPlatform() !== 'web') {
        Keyboard.removeAllListeners();
      }
    };
  }, [window.location.pathname]);
  return (
    <div className="enter-something">
      <div
        className={`enter-answer-wrapper`}
        style={{display: openAnswerWrapper ? 'flex' : 'none'}}>
        <input
          className="enter-answer-comments"
          type="text"
          placeholder={`${nickname ?? ''}님에게 답글 남기는 중...`}
          disabled={true}
        />
        <button className="submit" onClick={() => cancelAnswer()}>
          취소
        </button>
      </div>
      <div
        className={`enter-comments-wrapper ${
          content.length > 0 ? 'active' : ''
        }`}>
        <div
          className={`enter-comments main-comments`}
          contentEditable
          // @ts-ignore
          placeholder="댓글을 남겨보세요."
          suppressContentEditableWarning={true}
          onInput={($event: React.KeyboardEvent<HTMLDivElement>) =>
            change($event)
          }
          onKeyDown={($event: React.KeyboardEvent<HTMLDivElement>) =>
            keydown($event)
          }
          onFocus={focusInput}
          style={{maxHeight: '76px', overflowY: 'auto'}}>
          {_commentEditContents.length > 0 ? _commentEditContents : ''}
        </div>
        <button
          className={`submit ${content.length > 0 ? 'active' : ''}`}
          onClick={() => saveComment()}>
          {commentStatus === 'edit' ? '등록' : '작성'}
        </button>
      </div>
    </div>
  );
}
