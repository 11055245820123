import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';

import {isAndroid, isIos, isMobile} from '@/utils';
import ContentsHeader from '@/layouts/ContentsHeader';
import CouponItem from '@/components/myKnewnew/Coupon';
import AuthModal from '@/components/common/AuthModal';
import BlackButtonModal from '@/components/BlackButtonModal';
import {applyCouponCode, getCouponList} from '@/services/coupon';
import Fetcher from '@/components/common/Fetcher';
import useAuth from '@/hooks/useAuth';
import {
  IsOpenQRCodePopup,
  ToastMessage,
  isOpenToast,
  UserInfo,
  user,
} from '@/store';
import Caution from '@/components/common/CouponSelector/Caution';
import {getCollections} from '@/services/magazine';
import Layout from '@/layouts';
import Modal from '@/layouts/Modal';
import Button from '@/components/Button';
import QRCodePopup from '@/components/QRCodePopup';

import styles from './styles.module.scss';

export default function Coupon() {
  const navigator = useNavigate();
  const location = useLocation();
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const [isOpenAuthCheckModal, setIsOpenAuthCheckModal] = useState(false);
  const [isOpenCautionModal, setIsOpenCautionModal] = useState(false);
  const [isOpenCouponErrorModal, setIsOpenCouponErrorModal] = useState(false);
  const [modalType] = useState<'error' | 'auth'>('error');
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [couponCode, setCouponCode] = useState('');
  const [activeBtn, setActiveBtn] = useState(true);
  const [args, setArgs] = useState({
    count: 0,
  });
  const accessToken = useRecoilValue(user);
  const userInfo = useRecoilValue(UserInfo);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const [isShowQRCode, setIsShowQRCode] = useRecoilState(IsOpenQRCodePopup);
  const ADMIN_COUPON_LIST_STORAGE_KEY = 'checked_coupon_list';
  const {openAuth} = useAuth();
  const messages = [
    <>
      본인인증에 실패했습니다
      <br />
      다시 시도해 주세요
    </>,
    <>
      아직 오픈되지 않은 <br />
      컬렉션 입니다.
    </>,
    <>
      이미 다른 계정으로
      <br />
      본인 인증이 완료되었습니다.
    </>,
  ];
  const [couponMessage, setCouponMessage] = useState(
    <>
      유효하지 않은 쿠폰입니다.
      <br />
      쿠폰 코드를 다시 확인해 주세요
    </>,
  );
  const [errorCnt, setErrorCnt] = useState(0);

  const openBeforeTypePopup = () => {
    setErrorCnt(1);
    setIsOpenAuthModal(true);
  };

  const openToastMessage = () => {
    setToastMessage('쿠폰이 발급되었습니다.');
    setIsOpenToast(true);
  };

  const changeTxt = ($event: React.ChangeEvent<HTMLInputElement>) => {
    if ($event.currentTarget.value.length === 0) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
    setCouponCode($event.currentTarget.value);
  };

  const displayType = useMemo(() => {
    if (!userInfo.isIdentified) {
      return 'noAuth';
    }
    if (coupons && coupons.length > 0) {
      return 'list';
    } else {
      return 'noCoupon';
    }
  }, [userInfo, coupons]);

  const applyCoupon = async () => {
    try {
      if (displayType === 'noAuth') {
        return setIsOpenAuthCheckModal(true);
      }
      const _args = {...args};
      await applyCouponCode(accessToken, couponCode);
      _args.count = _args.count + 1;
      setCouponCode('');
      setArgs(_args);
      openToastMessage();
    } catch (e: any) {
      if (e?.response?.status === 555) {
        setCouponMessage(<>이미 발급된 쿠폰입니다.</>);
      } else if (e?.response?.status === 556) {
        setCouponMessage(
          <>
            유효하지 않은 쿠폰입니다.
            <br />
            쿠폰 코드를 다시 확인해 주세요
          </>,
        );
      } else if (e?.response?.status === 500) {
        setCouponMessage(<>서버에러 입니다</>);
      } else if (e?.response?.status === 400) {
        setCouponMessage(<>쿠폰 코드를 확인해 주세요</>);
      }
      setIsOpenCouponErrorModal(true);
    }
  };

  const registAdminCoupons = (coupons: Coupon[]) => {
    const existedAdminCoupons = JSON.parse(
      localStorage.getItem(ADMIN_COUPON_LIST_STORAGE_KEY) || '[]',
    );
    const adminCoupons = coupons.filter(c => c.target !== 'code');
    adminCoupons.forEach(c => {
      if (existedAdminCoupons.indexOf(c.id) === -1) {
        existedAdminCoupons.push(c.id);
      }
    });
    localStorage.setItem(
      ADMIN_COUPON_LIST_STORAGE_KEY,
      JSON.stringify(existedAdminCoupons),
    );
  };

  const getData = async () => {
    const res = await getCouponList(accessToken, 0);
    const collectionRes = await getCollections(accessToken, {
      limit: 10000,
      status: '2_before_open',
    });
    const _beforeOpenCollections = collectionRes.data.results;
    registAdminCoupons(res.data);
    const _coupons = res.data
      .map(d => {
        return {
          ...d,
          onlyType: d.brandId
            ? '브랜드 전용'
            : d.magazineId
            ? '컬렉션 전용'
            : '',
          link: d.brandId
            ? `/brand/${d.brandGroupId}/${d.brandId}`
            : d.magazineId
            ? `/popup/${d.magazineId}`
            : '/collection/list',
          isBeforeOpen: d.magazineId
            ? _beforeOpenCollections.filter(
                (o: CollectionListItem) => o.id === d.magazineId,
              ).length > 0
              ? true
              : false
            : false,
        };
      })
      .filter(c => {
        return dayjs().diff(c.expireDate) < 0;
      });
    return _coupons;
  };

  const moveToAppScheme = () => {
    setTimeout(() => {
      if (isAndroid()) {
        window.location.href = 'market://launch?id=com.knewnew.app';
      } else if (isIos()) {
        window.open(
          'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
          '_blank',
        );
      }
    }, 1000);
    setTimeout(() => {
      window.location.href = 'knewnew://home';
    }, 0);
  };

  useEffect(() => {
    const result = location.state?.certResult;
    if (result) {
      switch (result) {
        case 'duplicated':
          setErrorCnt(2);
          setIsOpenAuthModal(true);
          break;
        case 'failed':
          setErrorCnt(0);
          setIsOpenAuthModal(true);
          break;
        default:
          break;
      }
    }
  }, [location.state]);

  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          isOption="none"
          txt={'쿠폰'}
          back={() => {
            navigator('/user');
          }}
        />

        <Fetcher fetcher={getData} setter={setCoupons} args={args}>
          <div className={styles.enterCouponNumberWrapper}>
            <div className={styles.enterArea}>
              <input
                type={'text'}
                placeholder="쿠폰 코드를 입력하세요"
                value={couponCode}
                onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                  changeTxt($event)
                }
                className={activeBtn ? styles.disabled : ''}
              />
              <button
                className={styles.couponEnterBtn}
                disabled={activeBtn}
                onClick={() => {
                  if (
                    Capacitor.getPlatform() === 'web' &&
                    !userInfo.isIdentified
                  ) {
                    setIsShowQRCode(true);
                  } else {
                    applyCoupon();
                  }
                }}>
                쿠폰 등록
              </button>
            </div>
            <div className={styles.couponWarning}>
              <p>∙ 유효기간이 지난 쿠폰은 코드 등록이 불가합니다.</p>
              <p>∙ 등록한 쿠폰은 재발급이 불가합니다.</p>
            </div>
          </div>
          <div className={styles.couponList}>
            <div className={styles.listHeader}>
              <div>
                <span className={styles.ownCoupon}>보유 쿠폰</span>
                <span className={styles.ownCouponCnt}>
                  {userInfo.isIdentified ? coupons && coupons.length : 0}
                </span>
              </div>
              <div>
                <span
                  onClick={() => setIsOpenCautionModal(true)}
                  style={{cursor: 'pointer'}}>
                  유의사항
                </span>
                <span className={styles.rightArrowIcon}>&nbsp;</span>
              </div>
            </div>
            <div
              className={styles.couponNoAuth}
              style={{display: displayType === 'noAuth' ? 'block' : 'none'}}>
              <div className={styles.couponIntroArea}></div>
              <div className={styles.authButtonArea}>
                <button
                  className={styles.authButton}
                  onClick={() => {
                    if (Capacitor.getPlatform() === 'web') {
                      setIsShowQRCode(true);
                    } else {
                      openAuth(location.pathname);
                    }
                  }}>
                  본인인증하고 쿠폰 받기
                </button>
                <p style={{fontSize: '12px', paddingTop: '10px'}}>
                  *쿠폰 중복 사용 방지를 위해 본인인증 절차가 최초 1회
                  필요합니다.
                </p>
              </div>
            </div>
            <div
              className={styles.couponNoneArea}
              style={{display: displayType === 'noCoupon' ? 'flex' : 'none'}}>
              <div>
                보유중인 쿠폰이 없습니다. <br /> 알림을 통해 새로운 쿠폰을
                놓치지 마세요.
              </div>
              <p>{`*마이 > 설정 > 알림 설정에서 '혜택 및 마케팅 알림'을 켜주세요.`}</p>
              <button className={styles.move_to_push}>
                <span className={`icon bell-icon ${styles.bell}`}>&nbsp;</span>
                <span>알림 설정</span>
              </button>
            </div>
            <div
              className={styles.coupons}
              style={{display: displayType === 'list' ? 'block' : 'none'}}>
              {coupons &&
                _.sortBy(coupons, 'expireDate').map(coupon => {
                  return (
                    <CouponItem
                      key={coupon.id}
                      coupon={coupon}
                      openAuthModal={() => openBeforeTypePopup()}
                    />
                  );
                })}
            </div>
          </div>
          <AuthModal
            txt={messages[errorCnt]}
            isOpen={isOpenAuthModal}
            setIsOpen={setIsOpenAuthModal}
            btnType={modalType}
            action={() => openAuth(location.pathname)}
          />
          <AuthModal
            txt={couponMessage}
            isOpen={isOpenCouponErrorModal}
            setIsOpen={setIsOpenCouponErrorModal}
            btnType={'error'}
            action={() => setIsOpenCouponErrorModal(false)}
          />
          <AuthModal
            txt={
              <>
                쿠폰 등록을 위해
                <br />
                본인인증을 진행해 주세요
              </>
            }
            isOpen={isOpenAuthCheckModal}
            setIsOpen={setIsOpenAuthCheckModal}
            btnType={'auth'}
            action={() => openAuth(location.pathname)}
          />
          <BlackButtonModal />
          <Caution
            isOpen={isOpenCautionModal}
            setIsOpen={setIsOpenCautionModal}
          />
        </Fetcher>
        {isMobile() ? (
          <Modal.Alert width={'315px'} height={'224px'} isOpen={isShowQRCode}>
            <div
              className={styles.contents}
              style={{padding: '30px 16px 10px 16px'}}>
              <div className={styles.iconArea}>
                <span className={styles.bookmarkIcon}></span>
              </div>
              <div className={styles.txtArea}>
                본인인증은 APP에서만
                <br />
                이용가능한 서비스입니다.
              </div>
              <div className={styles.buttonArea} style={{marginTop: '30px'}}>
                <Button.ModalButton
                  width={'100%'}
                  height={'50px'}
                  txt={'앱 다운로드'}
                  background={'#000'}
                  color={'#fff'}
                  onClick={() => {
                    moveToAppScheme();
                    setIsShowQRCode(false);
                  }}
                />
                <Button.ModalButton
                  width={'100%'}
                  height={'50px'}
                  txt={'닫기'}
                  background={'#fff'}
                  color={'#9F9CA3'}
                  onClick={() => setIsShowQRCode(false)}
                />
              </div>
            </div>
          </Modal.Alert>
        ) : (
          <QRCodePopup />
        )}
      </div>
    </Layout.Main>
  );
}
