import React from 'react';

import styles from './styles.module.scss';
import Header from '../Header';
import BottomNavigation from '../BottomNavigation';
import Layout from '..';

interface Props {
  children: React.ReactElement;
}

export default function MagazineLayout({children}: Props) {
  return (
    <Layout.Main>
      <div className={`${styles.wrapper}`}>
        <Header title={'매거진'} isNoRing={true} />
        {children}
        <BottomNavigation />
      </div>
    </Layout.Main>
  );
}
