import React, {Suspense, useEffect} from 'react';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';

import Header from '@/layouts/Header';
import Profile from '@/components/my/Profile';
import Benefits from '@/components/my/Benefits';
import Menus from '@/components/my/Menus';
import MenuItem from '@/components/my/Menus/MenuItem';
import BottomNavigation from '@/layouts/BottomNavigation';
import EmptyInfo from '@/components/myKnewnew/EmptyInfo';
import PopupModal from '@/components/common/PopupModal';
import {isOpenLogoutModal, UserInfo} from '@/store';
import Layout from '@/layouts';
import {useScroll} from '@/hooks/useScroll';

import styles from './styles.module.scss';

export default function MyPage() {
  const {syncScroll} = useScroll();
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const setIsOpenLoginModal = useSetRecoilState(isOpenLogoutModal);
  const {t} = useTranslation(['views'], {keyPrefix: 'myPage.index'});
  const openLogout = () => {
    setIsOpenLoginModal(true);
  };
  const doLogout = async () => {
    openLogout();
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
    if (userInfo.id === -1) {
      console.log('move to login');
      navigator('/login', {
        state: {
          prevPath: `home`,
        },
      });
    }
    syncScroll();
  }, []);
  return (
    <Layout.Main>
      <div>
        <Header
          title={t('header')}
          isCommunity={false}
          isWhite={true}
          isNoRing={false}></Header>
        {userInfo.id > -1 && (
          <>
            <Suspense>
              <Profile />
            </Suspense>
            <Suspense>
              <Benefits />
            </Suspense>
          </>
        )}
        {userInfo.id === -1 && <EmptyInfo />}
        <Menus />
        <div className={styles.divider}></div>
        <div style={{paddingBottom: '100px'}}>
          <MenuItem
            title={userInfo.id === -1 ? t('login') : t('logout')}
            onClick={() =>
              userInfo.id === -1 ? navigator('/login') : doLogout()
            }>
            <div></div>
          </MenuItem>
        </div>
        <Layout.Footer type={'home'} />
        <BottomNavigation
          style={{
            position: userInfo.id === -1 ? 'absolute' : 'sticky',
            right: userInfo.id === -1 ? '0' : 'inherit',
          }}
        />
        <PopupModal type={'logout'} />
      </div>
    </Layout.Main>
  );
}
