import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="drop down" clipPath="url(#clip0_22_2133)">
        <path
          id="Polygon 2"
          d="M6 11L0.803847 3.5L11.1962 3.5L6 11Z"
          fill="#D2D0D5"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_2133">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
