import React from 'react';

import Text from '../../atoms/Text/Text';

interface Props {
  icon: React.ReactElement;
  txt: string;
  onClick?: () => void;
}

export default function ModalMapItem({icon, txt, onClick}: Props) {
  return (
    <div
      className="flex justify-start items-center"
      onClick={() => {
        console.log('123');
        onClick && onClick();
      }}
      style={{
        padding: '12px 0',
        borderBottom: '1px solid #F7F7FC',
        cursor: 'pointer',
      }}>
      {icon}
      <Text
        txt={txt}
        fontSize={16}
        color={'#000'}
        style={{
          fontWeight: 500,
          lineHeight: '100%',
          letterSpacing: '-0.32px',
          marginLeft: '10px',
        }}
      />
    </div>
  );
}
