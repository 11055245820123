import {StatusBar, Style} from '@capacitor/status-bar';
import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Device} from '@capacitor/device';
import {Capacitor} from '@capacitor/core';
import cc from 'classcat';
import {useNavigate} from 'react-router-dom';

import BannerScroller from '@/components/home/BannerScroller';
import QuickMenu from '@/components/home/QuickMenu';
import usePushNotification from '@/hooks/usePushNotification';
import Fetcher from '@/components/common/Fetcher';
import {getHomeNewZip} from '@/services/magazine';
import {
  HomeOpacity,
  deviceId,
  user,
  HomeKnewZipCard,
  HomeKnewZipIdx,
  AddressList,
  BrandAddr3,
  BrandAddr2,
  IsAreaSelected,
  HomeKnewBrandCard,
  BrandAreaListPage,
} from '@/store';
import {useScroll} from '@/hooks/useScroll';
import Container from '@/container';
import KnewBrand from '@/components/home/KnewBrand';
import Layout from '@/layouts';
import {registDevice} from '@/services/user';
import AddressSelector from '@/components/community/AddressSelector';

import styles from './styles.module.scss';

export default function Home() {
  const navigator = useNavigate();
  const userAccessToken = useRecoilValue(user);
  const {initNotificationList, checkMarketingAllow} = usePushNotification();
  const setDeviceId = useSetRecoilState(deviceId);
  const setZipIdx = useSetRecoilState(HomeKnewZipIdx);
  const [homeOpacity, setHomeOpacity] = useRecoilState(HomeOpacity);
  const [homeZipCards, setHomeZipCards] = useRecoilState(HomeKnewZipCard);
  const addressList = useRecoilValue(AddressList);
  const setAddr2 = useSetRecoilState(BrandAddr2);
  const setAddr3 = useSetRecoilState(BrandAddr3);
  const setIsAreaSelected = useSetRecoilState(IsAreaSelected);
  const setBrandPage = useSetRecoilState(BrandAreaListPage);
  const homeBrand = useRecoilValue(HomeKnewBrandCard);
  const {syncScroll} = useScroll();
  const {getToken} = usePushNotification();

  const saveDeviceInfo = async () => {
    const {identifier} = await Device.getId();
    const platform = (await Device.getInfo()).platform;
    const token = await getToken();
    setDeviceId(identifier);
    await registDevice(
      {
        deviceId: identifier,
        registrationId: token,
        type: platform as 'ios' | 'android',
        isActive: true,
      },
      userAccessToken,
    );
  };

  const getData = async () => {
    const res = await getHomeNewZip();
    const zips = (res.data.newzip as ZipCollectionItem[]).map(m => {
      let cardList = m.zipList;
      cardList = cardList.splice(0, 3);
      cardList.unshift(m as unknown as NewZipCard);
      return cardList;
    }) as NewZipCard[][];
    return zips;
  };

  const updateAddress = (address2: string, address3: string) => {
    const addr2Code = addressList.find(o => o.address === address2)?.id;
    const addr3Code = addressList
      .find(o => o.address === address2)
      ?.child?.find(o => o.address === address3)?.id;
    setAddr2(addr2Code);
    setAddr3(addr3Code);
    setBrandPage(0);
    setIsAreaSelected(true);
    navigator('/brands');
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Dark,
      });
      if (userAccessToken) {
        saveDeviceInfo();
      }
      initNotificationList();
      checkMarketingAllow();
    }
    // Set Home knewzip card set index randomly
    setZipIdx(Math.floor(Math.random() * homeZipCards.length));
    syncScroll();
  }, [homeZipCards]);

  return (
    <Container.Home>
      <Fetcher
        fetcher={getData}
        setter={setHomeZipCards}
        animationFn={() => setHomeOpacity(1)}
        fixedHeader>
        <div className={cc(['home', {[styles.contents]: homeOpacity === 1}])}>
          <BannerScroller />
          <QuickMenu />
          <div
            style={{height: '0px', width: '100%', background: '#F7F7FC'}}></div>
          <div className={styles.popularArea}>
            <p
              className={styles.popularAreaSubTit}
              style={{
                paddingLeft: '20px',
              }}>
              {homeBrand.subtitle}
            </p>
            <h3
              className={styles.popularAreaTitle}
              style={{
                paddingLeft: '20px',
              }}>
              {homeBrand.title.replace(/\\n/g, '\n')}
            </h3>
          </div>
          {homeBrand.brandList.map(item => (
            <KnewBrand key={item.id} item={item} />
          ))}
          {/* <KnewRegionBrand /> */}
          <Layout.Footer type="home" />
        </div>
        <AddressSelector
          addressList={addressList}
          updateAddress={updateAddress}
          callback={() => {
            navigator('/brands');
          }}
        />
      </Fetcher>
    </Container.Home>
  );
}
