import React from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './styles.module.scss';
interface Props {
  item: ZipItem;
  idx: number;
}

export default function ZipContentCard({item, idx}: Props) {
  const navigator = useNavigate();
  return (
    <div className="zip-content-card">
      <div className={styles.titleWrapper}>
        <h1>
          {idx + 1}. {item.title}
        </h1>
        {item.parentId ? (
          <button
            onClick={
              item.parentId
                ? () =>
                    navigator(`/brand/${item.parentId}/${item.id}`, {
                      state: {
                        prev: location.pathname.replace('/', ''),
                      },
                    })
                : undefined
            }>
            브랜드 홈<span></span>
          </button>
        ) : undefined}
      </div>
      <div className="points">
        {item.points.map((p, i) => {
          return (
            <div className="zip-point" key={`zip_content_point_${i}`}>
              <span className="title" style={{minWidth: '60px'}}>
                POINT {i + 1}
              </span>
              <span className="txt-contents" style={{textAlign: 'left'}}>
                {p}
              </span>
            </div>
          );
        })}
      </div>
      <div className="zip-card-img-slider">
        {item.brandsImages.map((img, idx) => {
          return (
            <div
              className="zip-card-img"
              key={`zip_card_image_slider_${idx}`}
              style={{
                background: `url('${img}') center / cover no-repeat`,
                margin:
                  idx === 0
                    ? '0 0 0 20px'
                    : idx === item.brandsImages.length - 1
                    ? '0 20px 0 0'
                    : 0,
              }}></div>
          );
        })}
      </div>
      {item.type === 'place' ? (
        <div className="market-info">
          <div className="info-item">
            <span className="title">Place</span>
            <span
              className="contents"
              dangerouslySetInnerHTML={{
                __html: `${item.address.replace(/(\\n)/g, '<br/>')}`,
              }}></span>
          </div>
          <div className="info-item">
            <span className="title">Opening Hours</span>
            <span
              className="contents"
              dangerouslySetInnerHTML={{
                __html: `${item.openingHours.replace(/\\n/g, '<br/>')}`,
              }}></span>
          </div>
        </div>
      ) : (
        <div className="market-info">
          <div className="info-item">
            <span className="title">Product</span>
            <span
              className="contents"
              dangerouslySetInnerHTML={{
                __html: `${item.productName.replace(/\\n/g, '<br/>')}`,
              }}></span>
          </div>
          <div className="info-item">
            <span className="title">Online Shop</span>
            <span
              className="contents"
              dangerouslySetInnerHTML={{
                __html: `${item.whereToBuy.replace(/\\n/g, '<br/>')}`,
              }}></span>
          </div>
        </div>
      )}
    </div>
  );
}
