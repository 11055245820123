import React from 'react';

import MenuListTitle from './MenuListTitle';
import MenuItem from '../../molecules/MenuItem/MenuItem';

interface Props {
  menus: MenuItemI[];
  menuImgLength: number;
  openMenuImg: () => void;
}

export default function MenuList({menus, menuImgLength, openMenuImg}: Props) {
  return menus?.length > 0 ? (
    <div className="pt-7 pr-5 pb-15 pl-5 bg-white">
      <MenuListTitle openMenuImg={openMenuImg} menuImgLength={menuImgLength} />
      <div>
        {menus.map((menu, idx) => {
          return (
            <MenuItem
              key={`present_menu_${idx}`}
              menu={menu}
              style={{marginTop: '16px'}}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
}
