import React from 'react';

interface Props {
  text: string;
}

export default function HashTag({text}: Props) {
  return (
    <div className="hash-tag">
      <span>#</span>
      <span>{text}</span>
    </div>
  );
}
