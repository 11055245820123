import React from 'react';

import {ReactComponent as CloseIcon} from '@/assets/icons/close-white-icon.svg';
import {isMobile} from '@/utils';

import {
  ImageIndex,
  ImageSliderHeaderWrapper,
} from './brandImageSliderHeader.styled';

interface Props<T> {
  close: () => void;
  selectedImg?: number;
  images: T[];
}

export default function BrandImageSliderHeader<T>({
  close,
  selectedImg,
  images,
}: Props<T>) {
  return (
    <ImageSliderHeaderWrapper mobile={isMobile()}>
      <>
        <button
          type="button"
          onClick={() => close()}
          style={{display: 'inline-block', width: '20px', height: '20px'}}>
          <CloseIcon />
        </button>
        <ImageIndex>{`${(selectedImg || 0) + 1}/${images?.length}`}</ImageIndex>
      </>
    </ImageSliderHeaderWrapper>
  );
}
