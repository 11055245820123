import {atom} from 'recoil';
export const modalType = atom<'tastTag' | 'logout' | 'signout' | 'propose'>({
  key: 'modal/tast-tag',
  default: undefined,
});
export const isOpenCenterModal = atom<boolean>({
  key: 'modal/isOpen',
  default: false,
});
export const isOpenLoginModal = atom<boolean>({
  key: 'modal/login-modal',
  default: false,
});
export const isOpenLogoutModal = atom<boolean>({
  key: 'modal/logout-modal',
  default: false,
});
export const isOpenToast = atom<boolean>({
  key: 'modal/toast',
  default: false,
});
export const isOpenMagazineModal = atom<boolean>({
  key: 'modal/magazine-modal',
  default: false,
});
export const isOpenTempAlert = atom<boolean>({
  key: 'modal/temp-alert',
  default: false,
});
export const tempAlertType = atom<'save' | 'delete'>({
  key: 'modal/temp-alert-type',
  default: 'save',
});
export const isOpenTempModal = atom<boolean>({
  key: 'modal/temp-modal',
  default: false,
});
export const isOpenContentsModal = atom<boolean>({
  key: 'modal/is-open-contents-modal',
  default: false,
});

export const ToastMessage = atom<string>({
  key: 'modal/toast-message',
  default: '아직 오픈되지 않은 기능이예요. 열심히 만들게요!',
});

export const isOpenContentsDeleteModal = atom<boolean>({
  key: 'modal/is-open-contents-delete-modal',
  default: false,
});

export const isOpenCommunityLoginModal = atom<boolean>({
  key: 'modal/is-open-community-login-modal',
  default: false,
});

export const isOpenCommunityWriteAlert = atom<boolean>({
  key: 'modal/is-open-community-write-alert',
  default: false,
});

export const isOpenCommunityEditCancelAlert = atom<boolean>({
  key: 'modal/is-open-community-edit-cancel-alert',
  default: false,
});

export const isOpenNickNameAlert = atom<boolean>({
  key: 'modal/is-open-nick-name-alert',
  default: false,
});

export const isOpenInstallModal = atom<boolean>({
  key: 'modal/is-open-install-modal',
  default: false,
});

export const isOpenAddressSelector = atom<boolean>({
  key: 'modal/is-open-address-selector',
  default: false,
});

export const isOpenNotificationAlert = atom<boolean>({
  key: 'modal/is-open-notification-alert',
  default: false,
});

export const isOpenMyPageModal = atom<boolean>({
  key: 'modal/is-open-my-page-modal',
  default: false,
});

export const isOpenDuplicatedEmailModal = atom<boolean>({
  key: 'modal/duplicated-email-modal',
  default: false,
});

export const isOpenSoldOutModal = atom<boolean>({
  key: 'modal/order-confirm-modal',
  default: false,
});

export const SoldOutModalInfo = atom<
  'option' | 'product' | 'popup_option' | 'popup_max'
>({
  key: 'modal/sold-out-modal-info',
  default: 'option',
});

export const IsOpenCertTryError = atom<boolean>({
  key: 'modal/is-open-cert-try-error',
  default: false,
});

export const IsOpenDuplicatedNotice = atom<boolean>({
  key: 'modal/is-open-duplicated-notice',
  default: false,
});

export const IsOpenQRCodePopup = atom<boolean>({
  key: 'modal/is-open-qr-code-popup',
  default: false,
});

export const IsBrandingMessageOnInstall = atom<boolean>({
  key: 'modal/is-branding-message',
  default: false,
});

export const IsOpenBlackBtnConfirmModal = atom<boolean>({
  key: 'modal/is-open-blackbtn-confirm',
  default: false,
});
