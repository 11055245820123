import Axios from 'axios';

import {errorInterceptor} from '../utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

export const getComment = (id: number, token?: string) => {
  return token
    ? axios.get(`/comment/review/?rid=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: 99999,
        },
      })
    : axios.get(`/comment/review/?rid=${id}`);
};

export const getMagazineComment = (id: number, token?: string) => {
  return token
    ? axios.get(`/comment/magazine/?rid=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.get(`/comment/magazine/?rid=${id}`);
};

export const makeComment = (
  id: number,
  content: string,
  token: string,
  parent?: number,
) => {
  const param = parent
    ? {
        review: id,
        content,
        parent,
      }
    : {
        review: id,
        content,
      };
  return axios.post(`/comment/review/`, param, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
};

export const likeComment = (id: number, isLike: boolean, token: string) => {
  return axios.post(
    `/comment/review/${id}/like/`,
    {
      isLike,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    },
  );
};

export const deleteComment = (id: number, token: string) => {
  return axios.delete(`/comment/review/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reportComment = (
  commentObj: {
    reviewComment: number;
  },
  detail: string,
  token: string,
  qnaType: string,
) => {
  return axios.post(
    '/qna/',
    {
      ...commentObj,
      objectType: 'review_comment',
      content: detail,
      qnaType,
      status: 'unresolved',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const editComment = (
  commentId: number,
  reviewId: number,
  content: string,
  token: string,
  parent?: number,
) => {
  return axios.patch(
    `/comment/review/${commentId}/`,
    {
      review: reviewId,
      content,
      parent,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
