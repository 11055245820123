import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import InfiniteScroll from 'react-infinite-scroll-component';

import {user} from '@/store';
import {useScroll} from '@/hooks/useScroll';
import {getPayments} from '@/services/magazine';

import styles from '../styles.module.scss';
import OrderItem from './OrderItem';

export default function OrderItems() {
  const {syncScroll} = useScroll();
  const userToken = useRecoilValue(user);
  // const orderList = useRecoilValue(OrderListItems);

  const [orderList, setOrderList] = useState<OrderListItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [page, setPage] = useState(0);
  const getData = async () => {
    const res: any = await getPayments(
      {
        limit: 20,
        offset: page * 20,
        type: 'default',
        pagination: 'true',
      },
      userToken,
    );
    setMaxCount(res.data.count);
    const _list = [...orderList];
    setOrderList([..._list, ...res.data.results]);
    setPage(page + 1);
  };
  useEffect(() => {
    if (maxCount > 0 && orderList.length > 0) {
      setHasMore(orderList.length < maxCount);
    }
  }, [orderList, maxCount]);
  useEffect(() => {
    // refetchOrderList();
    getData();
    syncScroll();
  }, []);

  return (
    orderList && (
      <div className={styles.order_list}>
        <div
          className={styles.no_order_items}
          style={{
            display: orderList.length === 0 ? 'flex' : 'none',
            marginTop: '-78px',
          }}>
          구매내역이 없습니다.
        </div>
        <div style={{display: orderList.length > 0 ? 'block' : 'none'}}>
          <InfiniteScroll
            dataLength={orderList.length}
            next={getData}
            hasMore={hasMore}
            loader={<div></div>}>
            {orderList.map((r, idx) => {
              return (
                <OrderItem
                  key={`order_item_idx_${idx}`}
                  status={r.usedStatus || '사용 완료'}
                  item={r}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    )
  );
}
