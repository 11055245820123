import React from 'react';

import ContentsFeed from './contents/Feed';

interface Props {
  reviews: RecommendContentReview[];
}

export default function FeedList({reviews}: Props) {
  return (
    <div className="feed-list-wrapper main-content">
      {reviews &&
        reviews.map(review => {
          return <ContentsFeed key={review.id} review={review} />;
        })}
    </div>
  );
}
