import React from 'react';
import {ChevronDown, X} from 'react-feather';
import {Capacitor} from '@capacitor/core';

import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  setIsOpen?: (is: boolean) => void;
  height?: string;
  title?: string;
  children: React.ReactElement;
}

export default function BottomSheet({
  isOpen,
  setIsOpen,
  height,
  children,
  title,
}: Props) {
  return (
    <div
      className={styles.modalWrapper}
      style={{display: isOpen ? 'flex' : 'none'}}
      onClick={() => (setIsOpen ? setIsOpen(false) : '')}>
      <div
        className={`${styles.modalContents} ${
          isOpen ? styles.active : styles.close
        } ${Capacitor.getPlatform() === 'ios' ? styles.ios : ''}`}
        style={{
          height: height ?? 'auto',
          maxHeight: 'min-content',
          overflow: 'auto',
        }}>
        <div className={styles.header}>
          {title ? (
            <div className={styles.modalCloseHeader}>
              <X
                size={'20px'}
                color={'#9F9CA3'}
                style={{cursor: 'pointer'}}
                onClick={() => (setIsOpen ? setIsOpen(false) : '')}
              />
              <div className={styles.headerTitle}>{title}</div>
            </div>
          ) : (
            <ChevronDown
              size={'30px'}
              color={'#D2D0D5'}
              style={{cursor: 'pointer'}}
              onClick={() => (setIsOpen ? setIsOpen(false) : '')}
            />
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
