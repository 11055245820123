import React from 'react';
import {useParams} from 'react-router-dom';

import {useDoBookmark} from '@/hooks/mutations/brands';
import {ReactComponent as InstagramIcon} from '@/assets/icons/brandInstagram.svg';

import {BrandDetailWrapper, BrandInfoWrapper} from './branddetail.styled';
import Text from '../../atoms/Text/Text';
import Bookmark from '../../atoms/Bookmark/Bookmark';
import Paragraph from '../../atoms/Paragraph/Paragraph';

interface Props {
  title: string;
  address: string;
  description: string;
  hasBookmark: boolean;
  instagram?: string;
  refetch?: () => void;
}

export default function BrandDetailInfo({
  title,
  address,
  description,
  hasBookmark,
  instagram,
  refetch,
}: Props) {
  const {id} = useParams();
  const {mutateAsync} = useDoBookmark();
  const doBookmark = async (isBookmark: boolean) => {
    try {
      await mutateAsync({
        id: Number(id || 0),
        bookmark: isBookmark,
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <BrandDetailWrapper>
      <BrandInfoWrapper>
        <div className="flex justify-start items-center">
          <Text
            fontSize={22}
            color={'#fff'}
            txt={title}
            style={{fontWeight: '700'}}
          />
          <Text
            fontSize={14}
            color={'#9F9CA3'}
            txt={address}
            style={{marginLeft: '8px'}}
          />
        </div>
        <Bookmark
          hasBookmark={hasBookmark}
          onClick={(is: boolean) => doBookmark(is)}
        />
      </BrandInfoWrapper>
      <Paragraph
        txt={description}
        fontSize={14}
        color={'#fff'}
        style={{marginTop: '13px'}}
      />
      {instagram && (
        <a
          className="flex justify-start items-center"
          href={instagram || '#'}
          style={{marginTop: '14px'}}
          target="_blank"
          rel="noreferrer">
          <InstagramIcon />
          <Text
            txt={'인스타그램'}
            fontSize={14}
            color={'#9F9CA3'}
            style={{fontWeight: '500', marginLeft: '4px'}}></Text>
        </a>
      )}
    </BrandDetailWrapper>
  );
}
