import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';

import {tastTags} from '@/store';
import ContentsHeader from '@/layouts/ContentsHeader';

export default function TastTagSelector() {
  const tags = useRecoilValue(tastTags);
  const [selectedTags] = useState<string[]>([
    'bread',
    'healthy',
    'seafood',
    'gm',
    'sweet',
    'grocery',
  ]);
  return (
    <div className="main my-tast-tag">
      <ContentsHeader txt={'입맛태그 선택'} isOption={'home'} />
      <div className="tast-tag-selector">
        <div className="tast-tag-title">
          <div className="tast-tag-icon-wrapper">
            <span className="tast-tag-icon">&nbsp;</span>
          </div>
          <div className="tast-tag-txt">
            김하영님을 나타내는
            <br />
            입맛 태그를 마음껏 선택해주세요.
          </div>
        </div>
        <div className="tast-tag-body">
          <div className="tast-tag-line tast-tag-line-1">
            {tags.slice(0, 5).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.type) > -1 ? 'active' : ''
                  }`}
                  key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-2">
            {tags.slice(5, 9).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.type) > -1 ? 'active' : ''
                  }`}
                  key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-3">
            {tags.slice(9, 14).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.type) > -1 ? 'active' : ''
                  }`}
                  key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-4">
            {tags.slice(14, 18).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.type) > -1 ? 'active' : ''
                  }`}
                  key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-5">
            {tags.slice(18, 23).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.type) > -1 ? 'active' : ''
                  }`}
                  key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
        </div>
        <div className="tast-tag-footer">
          <button className="main-cont btn main-black-border">선택 완료</button>
        </div>
      </div>
    </div>
  );
}
