import React, {useMemo} from 'react';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router';

import {addComma} from '@/utils';

import styles from './styles.module.scss';

interface Props {
  coupon: Coupon;
  openAuthModal: () => void;
}

export default function CouponItem({coupon, openAuthModal}: Props) {
  const navigator = useNavigate();
  const {
    discountAmount,
    discountRatio,
    expireDate,
    maxDiscount,
    minAmount,
    title,
    onlyType,
    link,
    isBeforeOpen,
  } = coupon;
  const remainDate = useMemo(() => {
    const _remain = dayjs().diff(expireDate, 'd');
    return _remain;
  }, [expireDate]);

  const goToLink = () => {
    if (isBeforeOpen) {
      openAuthModal();
    } else {
      navigator(link as string);
    }
  };

  return (
    <div className={styles.couponWrapper}>
      <div className={styles.couponTxtArea}>
        <div className={styles.discountTxt}>
          <span className={styles.discountAmt}>
            {Number(discountAmount) > 0
              ? `${addComma(Number(discountAmount))}원`
              : discountRatio + '%'}{' '}
            할인
          </span>
          <span
            className={styles.only}
            style={{display: onlyType ? 'inline-block' : 'none'}}>
            {onlyType}
          </span>
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.couponDateArea}>
        <div>
          <div className={styles.expireDate}>
            <span className={styles.dateTxt}>
              {dayjs(expireDate).format('YYYY-MM-DD HH:mm')}까지
            </span>
            {remainDate > -8 && (
              <span className={styles.ddayCnt}>
                D{remainDate === 0 ? '-0' : remainDate}
              </span>
            )}
          </div>
          <div className={styles.minAmount}>
            {addComma(Number(minAmount))}원 이상 구매 시
          </div>
          <div
            className={styles.maxDiscount}
            style={{
              display:
                Number(discountAmount) > 0 || Number(maxDiscount) === 0
                  ? 'none'
                  : 'block',
            }}>
            최대{' '}
            {addComma(
              Number(maxDiscount) > 0
                ? Number(maxDiscount)
                : Number(discountAmount),
            )}
            원까지 할인
          </div>
        </div>
        <div className={styles.useButtonArea}>
          <button className={styles.useButton}>
            <span className={styles.useButtonTxt} onClick={() => goToLink()}>
              사용하러 가기
            </span>
            <span className={styles.useButtonIcon}>&nbsp;</span>
          </button>
        </div>
      </div>
    </div>
  );
}
