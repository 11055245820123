import React from 'react';
import {VscChevronRight} from 'react-icons/vsc';
import {useNavigate} from 'react-router-dom';

import styles from '../styles.module.scss';

interface Props {
  keyword: string;
  searchedList: any[];
}

export default function SearchResult({keyword, searchedList}: Props) {
  const router = useNavigate();
  const seperator = (groupCount: number, sep: string) => {
    if (groupCount == 1 && sep === '본점') {
      return '';
    }
    return `${sep} ∙`;
  };
  const highlightKeyword = (text: string, keyword: string) => {
    if (!keyword) return text;
    const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase() ? (
        <span key={index} className={styles.highlight}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };
  const moveTo = (id: number, parentId: number) => {
    router(`/brand/${parentId}/${id}`);
  };
  return (
    <div style={{padding: '20px 20px 0 20px', overflow: 'auto'}}>
      {searchedList.map(o => {
        return (
          <div
            className={styles.searchItem}
            key={`searched_items_${o.id}`}
            style={{cursor: 'pointer'}}
            onClick={() => moveTo(o.id, o.parentId)}>
            <div className={styles.leftArea}>
              <div
                className={styles.itemImg}
                style={{
                  background: `url('${o.thumbnailUrl}') no-repeat center/cover`,
                }}></div>
              <div className={styles.itemTxtArea}>
                <div className={styles.itemTit}>
                  {highlightKeyword(o.title, keyword)}
                </div>
                <div className={styles.itemRegion}>
                  {seperator(o.groupCount, o.separator)}
                  {`${o.address1 || ''} ${o.address2 || ''}`}
                </div>
              </div>
            </div>
            <div className={styles.rightArea}>
              <button>
                <VscChevronRight color="#000" size={'20px'} />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
