import React from 'react';

import AboutBanner from '../../components/about/Banner';
import AboutMagazine from '../../components/about/Magazine';
import ContentsHeader from '../../layouts/ContentsHeader';
import AboutCommunity from '../../components/about/Community';
import Layout from '../../layouts';

export default function About() {
  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt="About"
          isOption="none"
          isBlack={true}
          isBottomLine={'none'}
        />
        <AboutBanner />
        <AboutMagazine />
        <AboutCommunity />
      </div>
    </Layout.Main>
  );
}
