import {AxiosResponse} from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useRecoilValue} from 'recoil';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import MyCommunityCard from '@/components/knewContents/MyCommunityCard';
import {getUsersReview} from '@/services/user';
import {user, UserInfo} from '@/store';
import {useScroll} from '@/hooks/useScroll';

import styles from './styles.module.scss';

interface Props {
  isOther: boolean;
  id?: number;
}

export default function MyCommunities({isOther, id}: Props) {
  const {syncScroll} = useScroll();
  const navigator = useNavigate();
  const [maxCount, setMaxCount] = useState(0);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [isMyPage, setIsMyPage] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const {t} = useTranslation(['views'], {keyPrefix: 'myKnewnew.MyCommunities'});
  const apply = () => {
    window.open(
      'https://docs.google.com/forms/d/1B1L3oVuGj8Kn9MnpcPPyeg_-xiwMx6pu0t98OUDnImM/viewform?edit_requested=true',
      '_blank',
    );
  };
  const moveToAddCommunity = () => {
    navigator('/community/add', {
      state: {
        history: 'my-page',
      },
    });
  };
  const getCommunityList = async () => {
    let userId = -1;

    if (id && id > -1) {
      userId = id;
    } else {
      if (userInfo && userInfo.id > -1) {
        userId = userInfo.id;
      }
    }
    const res: AxiosResponse<CommunityRes> = await getUsersReview(
      userId,
      accessToken,
      {
        offset: page * 20,
      },
    );
    const _communities = [...communities, ...res.data.results];
    setCommunities(_communities);
    setMaxCount(res.data.count);
    setPage(page + 1);
  };
  const init = async () => {
    getCommunityList();
    if (location.pathname.indexOf('my-articles') > -1) {
      setIsMyPage(true);
    } else {
      setIsMyPage(false);
    }
  };
  useEffect(() => {
    if (maxCount > 0 && communities.length > 0) {
      setHasMore(communities.length < maxCount);
    }
  }, [communities, maxCount]);
  useEffect(() => {
    syncScroll();
    if (id) {
      init();
    }
  }, [id]);
  return (
    <div className={`${styles.my_communities} ${isOther ? styles.other : ''}`}>
      <div
        className={`${styles.header} ${
          isMyPage ? styles.my : styles.community
        }`}>
        <div className={styles.title}>COMMUNITY</div>
        <div className={styles.description}>
          {communities.length > 0
            ? `${t('descCount1') + communities.length + t('descCount2')}`
            : id == -1
            ? t('descLogin')
            : t('descNoContents')}
        </div>
      </div>
      <div className={styles.communities}>
        <InfiniteScroll
          dataLength={communities.length}
          next={getCommunityList}
          hasMore={hasMore}
          loader={<div></div>}>
          {communities.map((r, idx) => {
            return <MyCommunityCard key={`r_item_key_${idx}`} item={r} />;
          })}
        </InfiniteScroll>
      </div>
      {
        <div>
          <div
            className={`${styles.no_communities} ${styles.normal}`}
            style={{
              display:
                communities.length === 0 && userInfo?.userType === 'normal'
                  ? 'flex'
                  : 'none',
            }}>
            <span className="txt">
              {t('applyTxt1')}
              <br /> {t('applyTxt2')}
            </span>
            <button className="apply-creator" onClick={() => apply()}>
              {t('applyBtn')}
            </button>
          </div>
          <div
            className={`${styles.no_communities} ${styles.creator}`}
            style={{
              display:
                communities.length === 0 && userInfo?.userType !== 'normal'
                  ? 'flex'
                  : 'none',
            }}>
            <span className="txt">
              {t('applyTxt1')}
              <br /> {t('applyTxt2')}
            </span>
            <button
              className="write-community"
              onClick={() => moveToAddCommunity()}>
              {t('writeBtn')}
            </button>
          </div>
        </div>
      }
      <div
        className={`${styles.no_communities} ${styles.other_show}`}
        style={{
          display:
            communities.length === 0 && isOther
              ? id == -1
                ? 'none'
                : 'flex'
              : 'none',
        }}>
        <span className="txt">{t('nonUserTxt')}</span>
        <button
          className="write-community"
          onClick={() => navigator('/community')}>
          {t('more')}
        </button>
      </div>
    </div>
  );
}
