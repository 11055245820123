import React from 'react';

import {MenuTitleWrapper} from './menulist.styled';
import Text from '../../atoms/Text/Text';

interface Props {
  openMenuImg: () => void;
  menuImgLength: number;
}

export default function MenuListTitle({openMenuImg, menuImgLength}: Props) {
  return (
    <MenuTitleWrapper>
      <Text
        txt={'대표 메뉴'}
        fontSize={18}
        color={'#000'}
        style={{
          fontWeight: '700',
          lineHeight: '150%',
          letterSpacing: '-0.32px',
        }}
      />
      {menuImgLength > 0 && (
        <button onClick={() => openMenuImg()}>
          <Text
            txt={'메뉴판 이미지로 보기'}
            fontSize={14}
            color={'#9F9CA3'}
            style={{
              fontWeight: '500',
              lineHeight: '150%',
              letterSpacing: '-0.32px',
            }}
          />
        </button>
      )}
    </MenuTitleWrapper>
  );
}
