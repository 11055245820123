import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import {MarketPageProductInfo} from '../../../store/recommend';
import MoveToShopBtn from '../../common/buttons/MoveToShopBtn';
import HashTag from '../../common/HashTag';
import FeedItem from '../items/FeedItem';
import FeedFooter from './FeedFooter';
import FeedHeader from './FeedHeader';
import ShortContents from './TextsContents';

interface Props {
  review: RecommendContentReview;
}

export default function ({review}: Props) {
  const navigator = useNavigate();
  const setMarketProductInfo = useSetRecoilState(MarketPageProductInfo);
  const moveToMarketDetail = (id: number, product: RecommendProduct) => {
    setMarketProductInfo(product);
    navigator(`/recommend/market/${id}`);
  };
  return (
    <div className="feed-wrapper">
      <FeedHeader
        imgPath={review.author.profileImage}
        name={review.author.nickname}
      />
      <div className="hashtag-wrapper">
        {review.tags.interest &&
          review.tags.interest.length > 0 &&
          review.tags.interest.map(o => {
            return <HashTag key={o} text={o} />;
          })}
      </div>
      <div className="feed-contents">
        {/* slider */}
        <div className="feed-items slide-wrapper">
          {review.images &&
            review.images.length > 0 &&
            review.images.map((img, idx) => {
              return (
                <FeedItem
                  key={`feed_item_${idx}`}
                  alt={`${review.id}`}
                  imgPath={img.image}
                />
              );
            })}
        </div>
        {/* 최고에요 */}
        <div
          className="is-best-wrapper"
          style={{display: review.isLike ? 'flex' : 'none'}}>
          <span className="is-best-icon"></span>
          <span className="txt">최고에요</span>
        </div>
        {/* 마켓컬리 - 구매처 */}
        <div
          className="buy-info"
          style={{cursor: 'pointer'}}
          onClick={() => moveToMarketDetail(review.product.id, review.product)}>
          <div className="shop-name">{review.market}</div>
          <div className="product-info">
            {review.product.name}
            <span className="right-direction-icon"></span>
          </div>
        </div>
        <MoveToShopBtn link={review.product.link} />
        {/* short-contents */}
        <ShortContents contents={review.content} />
      </div>
      <FeedFooter
        likeCount={review.likeCount}
        commentCount={review.commentCount}
        isLike={review.isLike}
        reviewId={review.id}
      />
      <div className="feed-divider"></div>
    </div>
  );
}
