import React, {useEffect, useRef} from 'react';
import {useRecoilState} from 'recoil';
import {lock, unlock} from 'tua-body-scroll-lock';

import {isOpenCommunityWriteAlert} from '../../../store';
import useLogger, {Action} from '../../../hooks/useLogger';

export default function CommunityWriteAlert() {
  const [openModal, setOpenModal] = useRecoilState(isOpenCommunityWriteAlert);
  const sender = useLogger();
  const apply = () => {
    sender({
      _msg: 'applyCreator',
      _screen: '/apply/',
      _action: Action.CLICK,
    });

    window.open(
      'https://docs.google.com/forms/d/1B1L3oVuGj8Kn9MnpcPPyeg_-xiwMx6pu0t98OUDnImM/viewform?edit_requested=true',
      '_blank',
    );
    setOpenModal(false);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openModal) {
      lock(document.querySelector('.modal-wrapper.alert') as HTMLElement);
    } else {
      unlock(document.querySelector('.modal-wrapper.alert') as HTMLElement);
    }
  }, [openModal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  };

  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        ref={wrapperRef}
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="close-btns"
          style={{display: 'flex', justifyContent: 'flex-start'}}>
          <span
            className="icon close-gray-icon"
            style={{
              width: '14px',
              height: '14px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(false)}>
            &nbsp;
          </span>
        </div>
        <div
          className="login-modal-header"
          style={{
            marginTop: '25px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          뉴뉴 크리에이터만 작성할 수 있습니다.
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          지금 뉴뉴 크리에이터에 지원하세요!
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn"
            style={{
              fontWeight: 600,
              fontSize: '14px',
              background: '#000',
              color: '#fff',
            }}
            onClick={() => apply()}>
            크리에이터 지원하기
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => setOpenModal(false)}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
}
