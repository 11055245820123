import React from 'react';
import {useNavigate} from 'react-router-dom';

import NoResult from '@/assets/imgs/no-result-search.svg';

import styles from '../styles.module.scss';

export default function NoSearchList() {
  const navigator = useNavigate();
  return (
    <div>
      {/* 이미지 */}
      <div className={styles.no_result_img}>
        <img src={NoResult} />
      </div>
      {/* 타이틀 */}
      <h3 className={styles.no_result_tit}>검색 결과가 없습니다</h3>
      {/* 상세 */}
      <ul className={styles.search_notice}>
        <li>브랜드명으로 검색해 주세요</li>
        <li>브랜드명은 공백없이 입력해 주세요</li>
        <li>찾으시는 브랜드가 없다면 아래 버튼을 눌러 추천해 보세요</li>
      </ul>
      {/* 브랜드 추천하기 */}
      <div className={styles.recommend_brands}>
        <button onClick={() => navigator('/recommend-brand')}>
          브랜드 추천하기
        </button>
      </div>
    </div>
  );
}
