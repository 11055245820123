import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {newArgsState, newsItems} from '@/store';
import {useScroll} from '@/hooks/useScroll';

import BrandCard from '../BrandCard';

export default function NewsGroup() {
  const {syncScroll} = useScroll();
  const _newsItems = useRecoilValue(newsItems);
  const [lastElement, setLastElement] = useState<Element | null>(null);
  const setArgs = useSetRecoilState(newArgsState);
  // const {scrollPosList} = useScroll();
  const [isUpdateArgs, setIsUpdateArgs] = useState(false);

  const observer = useRef(
    new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setIsUpdateArgs(true);
      }
    }),
  );

  useEffect(() => {
    if (isUpdateArgs) {
      setArgs(prev => {
        return {
          ...prev,
          limit: prev.limit + 20,
        };
      });
    }
  }, [isUpdateArgs]);
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    syncScroll();
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <div>
      {_newsItems.map(item => {
        return item.target === 'archive' ? (
          <></>
        ) : (
          <div key={item.id} ref={setLastElement}>
            <BrandCard info={item} type={'news'} />
          </div>
        );
      })}
    </div>
  );
}
