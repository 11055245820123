import React from 'react';
import {useTranslation} from 'react-i18next';

import MagazineItem from '../MagazineItem';
import styles from './styles.module.scss';

export default function AboutMagazine() {
  const {t} = useTranslation(['components'], {keyPrefix: 'about.Magazine'});
  return (
    <div className="about-magazine-wrapper">
      <div className={styles.aboutItemsWrapper}>
        <div className={`${styles.magazineInfo} about-items`}>
          <div className={styles.number_wrapper}>
            <span className={styles.number}>01</span>
          </div>
          <h1>Knewnew Magazine</h1>
          <div className="subtitle">
            {t('subtitle1')}
            <br />
            {t('subtitle2')}
            <br />
            {t('subtitle3')}
            <br />
            {t('subtitle4')}
          </div>
        </div>
        <div className={styles.aboutMagazineItems}>
          <MagazineItem
            title={'Knew<br />Brands'}
            subtitle={t('item-brands')}
            type={'brands'}
          />
          <MagazineItem title={'Pick'} subtitle={t('item-pick')} type={'zip'} />
          <MagazineItem
            title={'Knew<br />News'}
            subtitle={t('item-news')}
            type={'news'}
          />
        </div>
      </div>
      <div className={styles.divider}></div>
    </div>
  );
}
