import React from 'react';

export default function UserAgree() {
  return (
    <div className="main">
      <div className="user-agree">
        <div className="agree-title">
          🖐 잠깐,
          <br /> 뉴저가 되기 전 마지막 관문인
          <br /> <strong>협찬과 광고 금지</strong>에 동의해주세요.
        </div>
        <div className="agree-txt-1">
          광고없는 공간을 위해
          <br />
          뉴뉴가 실시간으로 광고를 추적하고 있습니다.
          <br />
          <strong>
            광고글로 판정될 경우{' '}
            <span className="emphasize">계정이 영구 정지</span> 됩니다.
          </strong>
        </div>
        <div className="warning-illegal">
          ※ 광고임을 밝히지 않는 이른바 {"'뒷광고'"}는
          <br />
          표시광고법 제3조 제1항에 의거하여{' '}
          <span className="emphasize">엄연한 불법행위</span>입니다.
        </div>
        <div className="user-agree-check-box">
          <input type="checkbox" name="user-agree" checked={true} />
          <label htmlFor="user-agree">
            뉴뉴의 광고 금지 정책을 확인하였으며,
            <br /> 서비스 이용시 협찬 및 광고 글을 게시하지 않겠습니다.
          </label>
        </div>
        <div className="btn-wrapper">
          <button className="main-cont btn main-pink-filled">
            후다닥 입장하기
          </button>
        </div>
      </div>
    </div>
  );
}
