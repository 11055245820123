import React, {useEffect, useMemo, useRef, useState} from 'react';
import {VscChevronLeft} from 'react-icons/vsc';
import {IoIosSearch} from 'react-icons/io';
import {IoCloseCircleSharp} from 'react-icons/io5';
import {useRecoilValue} from 'recoil';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';

import Layout from '@/layouts';
import {getBrandSearch} from '@/services/magazine';
import {user} from '@/store';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';
import PopularBrands from './components/PopularBrands';
import SearchResult from './components/SearchResult';
import NoSearchList from './components/NoSearchList';

export default function Search() {
  const navigator = useNavigate();
  const offsetTop = useRef(0);
  const [searchParams] = useSearchParams();
  const token = useRecoilValue(user);
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchedBrandList, setSearchedBrandList] = useState([]);
  const isDoingSearch = useMemo(() => {
    return search.length > 0;
  }, [search]);
  const sender = useLogger();

  const doSearch = () => {
    setIsSearching(true);
    getSearchedBrands();
    Keyboard.hide();
  };

  const initSearch = () => {
    setSearch('');
    setIsSearching(false);
  };

  const getSearchedBrands = async () => {
    const res = await getBrandSearch(token, search);
    sender({
      _msg: 'search_click',
      _screen: window.location.pathname,
      _action: Action.CLICK,
      _keyword: search,
    });
    setSearchedBrandList(res.data);
  };

  const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch($event.target.value);
  };

  const activeEnter = ($event: React.KeyboardEvent<HTMLInputElement>) => {
    if ($event.key === 'Enter') {
      doSearch();
    }
  };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
      const mode: KeyboardResize = KeyboardResize.Body; // 타입 정의에 맞는 값 설정
      Keyboard.setResizeMode({
        mode: mode,
      });
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
      initKeyboard();
    }
  }, []);

  return (
    <Layout.Main>
      <div>
        <div
          className={`${styles.searchArea} ${
            Capacitor.getPlatform() === 'android' ? styles.android : ''
          }`}>
          <button
            onClick={() =>
              navigator(
                searchParams.get('from')
                  ? `/${searchParams.get('from')}`
                  : '/home',
              )
            }>
            <VscChevronLeft size={'30px'} />
          </button>
          <div className={styles.inputWrapper}>
            <input
              className={styles.searchBar}
              type="text"
              placeholder="찾고 계신 할인권의 브랜드명을 입력해 주세요"
              value={search}
              onChange={onChange}
              onKeyDown={activeEnter}
            />
            <div className={styles.subBtns}>
              {isDoingSearch && (
                <button
                  style={{marginRight: '12px'}}
                  onClick={() => initSearch()}>
                  <IoCloseCircleSharp
                    color={'#fff'}
                    size={'20px'}
                    fill={'#D2D0D5'}
                  />
                </button>
              )}
              <button onClick={() => doSearch()}>
                <IoIosSearch
                  color={isDoingSearch ? '#000' : '#9F9CA3'}
                  size={'20px'}
                />
              </button>
            </div>
          </div>
        </div>
        <div
          className={styles.container}
          style={{maxHeight: 'calc(100vh - 85px)', overflow: 'auto'}}>
          {/* 지금 많이 찾는 브랜드 */}
          {!isSearching && <PopularBrands />}
          {isSearching && searchedBrandList.length > 0 && (
            <SearchResult keyword={search} searchedList={searchedBrandList} />
          )}
          {isSearching && searchedBrandList.length === 0 && <NoSearchList />}
        </div>
        <Layout.BottomNavigation />
      </div>
    </Layout.Main>
  );
}
