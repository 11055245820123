import {Capacitor} from '@capacitor/core';
import React from 'react';

import Modal from '@/layouts/Modal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function FrequencyQuestion({close, isOpen}: Props) {
  return (
    <Modal.BottomSheet
      isOpen={isOpen}
      setIsOpen={close}
      height={Capacitor.getPlatform() === 'ios' ? 'auto' : '415px'}>
      <div>
        <div className={styles.wrapper}>
          <div className={styles.tit}>이용 시간이 정해져 있나요?</div>
          <div className={styles.desc}>
            매장 영업시간 내 이용 가능합니다. 매장마다 영업시간이 상이하므로
            휴무일 및 영업시간을 확인 후 방문해 주세요.
            <br /> *할인권 이용 가능 시간이 별도로 안내되어 있는 경우 해당
            시간에만 할인권 사용이 가능합니다.
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.tit}>몇 명까지 방문 가능한가요?</div>
          <div className={styles.desc}>
            할인권의 경우 인원수 제한은 없으나 매장 상황에 따라 인원 제한 및
            현장 웨이팅이 발생할 수 있습니다.
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.tit}>
            뉴뉴의 다른 상품이나 타 사 쿠폰을 같이 사용할 수 있나요?
          </div>
          <div className={styles.desc}>
            10% 할인권은 단독 사용만 가능합니다.
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.tit}>
            할인권을 발급하고 기한 내에 사용하지 못했는데 어떻게 되나요?
          </div>
          <div className={styles.desc}>
            기한 내 방문하지 않을 경우 해당 할인권은 자동 만료됩니다.
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.tit}>
            동일한 브랜드의 할인권을 여러번 발급 받을 수 있나요?
          </div>
          <div className={styles.desc}>
            동일 유효기간 내에 중복 발급은 불가하나, 할인권 추가 오픈 시<br />
            에는 추가 발급 가능합니다.
          </div>
        </div>
      </div>
    </Modal.BottomSheet>
  );
}
