import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';
import BenefitItem from './BenefitItem';
import {user} from '../../../store/user';
import Fetcher from '../../common/Fetcher';
import {getPayments} from '../../../services/magazine';

export default function Benefits() {
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const [params] = useState<PaymentListParam>({
    limit: 99999,
    offset: 0,
    type: 'benefits',
    pagination: 'true',
    valid: 'true',
  });
  const [benefits, setBenefits] = useState<OrderListItem[]>([]);
  const {t} = useTranslation(['components'], {keyPrefix: 'my.Benefits'});

  const setAvailableGiftcard = (items: OrderListItem[]) => {
    setBenefits(items);
  };

  const getData = async () => {
    const res = await getPayments(params, accessToken);
    const orderedResponse = res.data.results;

    return orderedResponse;
  };

  return (
    <div className={styles.benefits}>
      <Fetcher fetcher={getData} setter={setAvailableGiftcard} noLoader>
        <div className={styles.benefitInfo}>
          <div className={styles.cnt}>
            <strong>{'받은 할인권'}</strong>
          </div>

          <div
            className={styles.moveTo}
            onClick={() =>
              navigator('/order/benefits', {
                state: {
                  prev: 'user',
                },
              })
            }>
            <span className={styles.txt}>{t('to_list')}</span>
            <span className={styles.move_to_link}></span>
          </div>
        </div>
        {benefits && benefits.length === 0 ? (
          <div className={styles.noList}>
            <span className={styles.txt}>
              {'사용가능한 할인권이 없습니다.'}
            </span>
          </div>
        ) : (
          <div className={styles.list}>
            {benefits &&
              benefits.map((d, idx) => {
                return (
                  <BenefitItem
                    key={`benefit_items_${idx}`}
                    title={d.popup.title}
                    date={dayjs(d.expired).format('YYYY-MM-DD')}
                    img={d.popup.imageUrl}
                    id={d.popup.id}
                    prev={'my'}
                    item={d}
                  />
                );
              })}
          </div>
        )}
      </Fetcher>
    </div>
  );
}
