/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {Keyboard} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';
import {useTranslation} from 'react-i18next';

import ContentsHeader from '@/layouts/ContentsHeader';
import NickNameAlert from '@/components/common/NickNameAlert';
import PopupModal from '@/components/common/PopupModal';
import {updateProfile, uploadImage, userDetail} from '@/services/user';
import {
  isOpenLogoutModal,
  isOpenNickNameAlert,
  isOpenToast,
  ToastMessage,
  user,
  UserInfo,
} from '@/store';
import useStorage from '@/hooks/useStorage';
import Layout from '@/layouts';
import useS3 from '@/hooks/useS3';
import useCanvas from '@/hooks/useCanvas';

import styles from './styles.module.scss';

export default function EditProfile() {
  const uploaderRef = useRef(null);
  const {getToken} = useStorage();
  const navigator = useNavigate();
  const s3 = useS3();
  const {translateImg} = useCanvas();
  const [globalUserInfo, setGlobalUserInfo] = useRecoilState(UserInfo);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenNickNameOverFlow = useSetRecoilState(isOpenNickNameAlert);
  const accessToken = useRecoilValue(user);
  const setAccessToken = useSetRecoilState(user);
  const [userInfo, setUserInfo] = useState<UserInfoObj>(globalUserInfo);
  const [modalType, setModalType] = useState<'logout' | 'signout'>('logout');
  const [isOpenKeyboard, setIsOpenKeyboard] = useState(false);
  const setIsOpenLoginModal = useSetRecoilState(isOpenLogoutModal);
  const {t} = useTranslation(['views'], {keyPrefix: 'myPage.EditProfile'});

  const offsetTop = useRef(0);
  const keyboardDummyHeight = useRef(0);
  const update = (key: keyof typeof userInfo, value: any) => {
    const _userInfo = JSON.parse(JSON.stringify(userInfo));
    _userInfo[key] = value;
    setUserInfo(_userInfo);
  };

  const isExistUserProfile = useMemo(() => {
    return (
      userInfo && userInfo.profileImage && userInfo.profileImage.length > 0
    );
  }, [userInfo]);

  const bgStyle = useMemo(() => {
    return userInfo && userInfo.profileImage
      ? `url(${userInfo.profileImage})`
      : '#E9E7EC';
  }, [userInfo]);

  const changeName = ($event: React.ChangeEvent<HTMLInputElement>) => {
    update('nickname', $event.currentTarget.value);
  };

  const changeHeadline = ($event: React.ChangeEvent<HTMLTextAreaElement>) => {
    update('headline', $event.currentTarget.value);
  };

  const changeSNS = (
    $event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    const _snsInfo = {
      youtube: '',
      instagram: '',
      naver: '',
    };
    const keys = Object.keys(_snsInfo);
    keys.forEach(k => {
      _snsInfo[k as keyof typeof _snsInfo] =
        userInfo.snsInfo[k as keyof typeof _snsInfo];
      if (k === type) {
        _snsInfo[k as keyof typeof _snsInfo] = $event.currentTarget.value;
      }
    });
    update('snsInfo', _snsInfo);
  };

  const submit = async () => {
    try {
      await updateProfile(userInfo.id, accessToken, userInfo);
      await setToastMessage(t('toast_done'));
      await setIsOpenToast(true);
      await setGlobalUserInfo(userInfo);
      navigator('/user');
    } catch (e) {
      console.log(e);
      await setToastMessage(t('toast_err'));
      await setIsOpenToast(true);
    }
  };

  const callFileUploader = () => {
    (uploaderRef.current as any).click();
  };

  const removeImg = () => {
    update('profileImage', undefined);
  };

  const upload = async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      translateImg(file, 'profile', async (makedFile: File) => {
        try {
          const presignedRes = await uploadImage(file.name, 'user');
          await s3.uploadImage(
            makedFile,
            makedFile.name,
            `${presignedRes.data[0].url}`,
            presignedRes.data[0].fields,
          );
          resolve(
            `${presignedRes.data[0].url}${presignedRes.data[0].fields.key}`,
          );
        } catch (e) {
          reject(e);
        }
      });
    });
  };

  const changeImage = async ($event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const imgs = $event.currentTarget.files as FileList;
      if ((imgs as FileList).length > 0) {
        const presignedPath = await upload(imgs[0]);
        update('profileImage', `${presignedPath}`);
      }
    } catch (e) {
      console.log(e);
      setToastMessage(t('toast_imgErr'));
      setIsOpenToast(true);
    }
  };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', info => {
      console.log('keyboard will show with height:', info.keyboardHeight);
      keyboardDummyHeight.current = info.keyboardHeight;
      setIsOpenKeyboard(true);
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current - info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
      setIsOpenKeyboard(false);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  const focusInput = async (
    $event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    offsetTop.current = $event.currentTarget.offsetTop;
  };

  const openSignout = () => {
    setModalType('signout');
    setIsOpenLoginModal(true);
  };

  const init = async () => {
    try {
      const accessToken = await getToken();
      if (accessToken) {
        const res: AxiosResponse<UserInfoObj> = await userDetail(accessToken);
        setUserInfo(res.data);
        setAccessToken(accessToken);
        if (res.data.nickname.length > 15) {
          setIsOpenNickNameOverFlow(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeListener = async () => {
    if (Capacitor.getPlatform() !== 'web') {
      await Keyboard.removeAllListeners();
    }
  };

  useEffect(() => {
    if (!userInfo) {
      init();
    } else {
      if (userInfo.nickname.length > 15) {
        setIsOpenNickNameOverFlow(true);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      initKeyboard();
    }
    return () => {
      removeListener();
    };
  }, []);

  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt={t('header')}
          isOption="none"
          back={() => navigator('/user')}
        />
        <div className={styles.myEditProfile}>
          <div className={styles.profileImageEdit}>
            <div className={styles.userProfile}>
              <div
                className={`${styles.profileImage} ${
                  isExistUserProfile ? styles.noImage : ''
                }`}
                style={{
                  background: `${bgStyle} center no-repeat`,
                  backgroundSize: '100% 100%',
                }}>
                <span
                  className={styles.noImageIcon}
                  style={{
                    display: isExistUserProfile ? 'none' : 'inline-block',
                  }}></span>
                <span
                  className={styles.circleWhitePlusIcon}
                  onClick={() => callFileUploader()}
                  style={{cursor: 'pointer'}}></span>
              </div>
              <span
                className={styles.removeImageTxt}
                onClick={() => removeImg()}
                style={{cursor: 'pointer'}}>
                {t('removeImgBtn')}
              </span>
            </div>
          </div>
          <div className={styles.infoForm}>
            <div className={`${styles.inputForm} ${styles.name}`}>
              <div className={`${styles.label}`}>
                {t('input_nickname')}
                <span className={`${styles.asterisk}`}>*</span>{' '}
                <span className={styles.option}>
                  (
                  {userInfo && userInfo.nickname ? userInfo.nickname.length : 0}
                  /15){t('input_length')}
                </span>
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  value={userInfo ? userInfo.nickname : ''}
                  maxLength={15}
                  onFocus={focusInput}
                  onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                    changeName($event)
                  }
                  placeholder={t('input_nickname_placeholder')}
                />
              </div>
              {/* <button className={styles.deleteUser} onClick={() => openSignout()}>
              회원탈퇴
            </button> */}
            </div>
            {userInfo.userType !== 'normal' && (
              <div className={styles.additionalInfo}>
                <div className={`${styles.inputForm} ${styles.introduction}`}>
                  <div className={styles.label}>
                    {t('input_introduce')}{' '}
                    <span className={styles.option}>
                      (
                      {userInfo && userInfo.headline
                        ? userInfo.headline.length
                        : 0}
                      /140){t('input_length')}
                    </span>
                  </div>
                  <textarea
                    className={styles.introductionContents}
                    maxLength={140}
                    value={
                      userInfo && userInfo.headline ? userInfo.headline : ''
                    }
                    onChange={(
                      $event: React.ChangeEvent<HTMLTextAreaElement>,
                    ) => changeHeadline($event)}
                    onFocus={focusInput}
                    placeholder={t('input_introduce_placeholder')}
                    style={{
                      borderTop: '1px solid #F7F7FC',
                      borderBottom: '1px solid #F7F7FC',
                      padding: '15px 20px',
                    }}></textarea>
                </div>
                <div className={`${styles.inputForm} ${styles.linkSns}`}>
                  <div className={`${styles.label}`} style={{fontSize: '16px'}}>
                    {t('input_sns_title')}
                  </div>
                  <div
                    className={`${styles.input} ${styles.snsInputBox} ${styles.youtube}`}>
                    <div className={`${styles.iconWrapper}`}>
                      <span
                        className={`sns-icon youtube ${
                          userInfo &&
                          userInfo.snsInfo &&
                          userInfo.snsInfo.youtube
                            ? 'active'
                            : 'inactive'
                        }`}>
                        &nbsp;
                      </span>
                    </div>
                    <div className={`${styles.snsInput}`}>
                      <input
                        type="text"
                        placeholder={t('input_sns_placeholder')}
                        onChange={(
                          $event: React.ChangeEvent<HTMLInputElement>,
                        ) => changeSNS($event, 'youtube')}
                        onFocus={focusInput}
                        value={
                          (userInfo &&
                            userInfo.snsInfo &&
                            userInfo.snsInfo.youtube) ??
                          ''
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.input} ${styles.snsInputBox} instagram`}>
                    <div className="icon-wrapper">
                      <span
                        className={`sns-icon instagram ${
                          userInfo &&
                          userInfo.snsInfo &&
                          userInfo.snsInfo.instagram
                            ? 'active'
                            : 'inactive'
                        }`}>
                        &nbsp;
                      </span>
                    </div>
                    <div className={`${styles.snsInput}`}>
                      <input
                        type="text"
                        placeholder={t('input_sns_placeholder')}
                        onChange={(
                          $event: React.ChangeEvent<HTMLInputElement>,
                        ) => changeSNS($event, 'instagram')}
                        onFocus={focusInput}
                        value={
                          (userInfo &&
                            userInfo.snsInfo &&
                            userInfo.snsInfo.instagram) ??
                          ''
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`${styles.input} ${styles.snsInputBox} naver-blog`}>
                    <div className="icon-wrapper">
                      <span
                        className={`sns-icon naver-blog ${
                          userInfo && userInfo.snsInfo && userInfo.snsInfo.naver
                            ? 'active'
                            : 'inactive'
                        }`}>
                        &nbsp;
                      </span>
                    </div>
                    <div className={`${styles.snsInput}`}>
                      <input
                        type="text"
                        placeholder={t('input_sns_placeholder')}
                        onChange={(
                          $event: React.ChangeEvent<HTMLInputElement>,
                        ) => changeSNS($event, 'naver')}
                        onFocus={focusInput}
                        value={
                          (userInfo &&
                            userInfo.snsInfo &&
                            userInfo.snsInfo.naver) ??
                          ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.deleteWrapper}>
              <button
                className={styles.deleteUser}
                onClick={() => openSignout()}>
                {t('signoutBtn')}
              </button>
            </div>
          </div>
          <div
            className={styles.btnWrapper}
            style={{
              position: userInfo.userType === 'normal' ? 'absolute' : 'sticky',
            }}>
            <button
              className={`main-cont btn main-black-border ${
                userInfo.nickname && userInfo.nickname.length > 0
                  ? ''
                  : 'disabled'
              } ${styles.editBtn}`}
              disabled={!(!!userInfo.nickname && userInfo.nickname.length > 0)}
              onClick={() => submit()}>
              {t('doneBtn')}
            </button>
          </div>
        </div>
        <input
          ref={uploaderRef}
          type="file"
          accept="image/*,camera=capture"
          style={{visibility: 'hidden'}}
          onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
            changeImage($event)
          }
        />
        <NickNameAlert />
        <div
          className="keyboard_dummy"
          style={{
            width: '100%',
            height: `${keyboardDummyHeight.current}px`,
            display: isOpenKeyboard ? 'block' : 'none',
          }}></div>
        <PopupModal type={modalType} />
      </div>
    </Layout.Main>
  );
}
