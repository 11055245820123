import styled, {css} from 'styled-components';

const iosStyle = css`
  height: calc(56px + env(safe-area-inset-top)) !important;
`;
const androidStyle = css`
  height: calc(56px + var(--status-bar-height)) !important;
`;
export const PickHeaderWrapper = styled.div<{
  android: boolean;
  ios: boolean;
}>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  display: flex;
  padding: 0 23px;
  align-items: center;
  padding-top: ${({android}) =>
    android ? 'var(--status-bar-height)' : 'env(safe-area-inset-top)'};
  justify-content: space-between;
  background: #ebf3fa;
  ${({ios}) => ios && iosStyle}
  ${({android}) => android && androidStyle}
`;

export const PickBottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-bottom: 40px;
`;
