import React from 'react';

import {ParagraphWrapper} from './paragraph.styled';

interface Props {
  txt: string;
  color: string;
  fontSize: number;
  style?: React.CSSProperties;
}

export default function Paragraph({txt, color, fontSize, style}: Props) {
  return (
    <ParagraphWrapper color={color} fontSize={fontSize} style={style}>
      {txt}
    </ParagraphWrapper>
  );
}
