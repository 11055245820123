import React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import LinkToShopBtn from '../../common/buttons/LinkToShopBtn';
import ShopFilter from '../../common/filters/ShopFilter';
import {filterType, isOpenFilterModal} from '../../../store';
import {
  FoodLogFeeds,
  MarketPageFilters,
  MarketPageProductInfo,
} from '../../../store/recommend';

export default function MarketTitle() {
  const setFilter = useSetRecoilState(filterType);
  const setOpenFilterModal = useSetRecoilState(isOpenFilterModal);
  const foodLogReviews = useRecoilValue(FoodLogFeeds);
  const openModal = (type: 'shop' | 'reaction' | 'sort', isOpen: boolean) => {
    setFilter(type);
    setOpenFilterModal(isOpen);
  };
  const marketPageProduct = useRecoilValue(MarketPageProductInfo);
  const marketPageFilter = useRecoilValue(MarketPageFilters);
  const isFilter = (type: string) => {
    const _filter = marketPageFilter.filter(f => f.type === type);
    return _filter[0].selection.length > 0;
  };
  return (
    <div className="main-content market-title-wrapper">
      <div className="contents-wrapper">
        <div className="product-title">
          <div className="product">{marketPageProduct.name}에 대한</div>
          <div className="food-log-cnt">푸드로그 {foodLogReviews.length}건</div>
        </div>
        <div className="price">예상 최저가격 3,500원</div>
        <LinkToShopBtn link={marketPageProduct.link} />
        <div className="filter-group">
          <ShopFilter
            title={'반응별'}
            isSelected={isFilter('reaction')}
            click={() => openModal('reaction', true)}
            filterType={'reaction'}
          />
          <ShopFilter
            title={'구매처별'}
            isSelected={isFilter('store')}
            click={() => openModal('shop', true)}
            filterType={'store'}
          />
          <ShopFilter
            title={'정렬'}
            isSelected={isFilter('sort')}
            click={() => openModal('sort', true)}
            filterType={'sort'}
          />
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
}
