import React from 'react';

import {ReactComponent as DefaultBookmark} from '@/assets/icons/NewBookmark.svg';
import {ReactComponent as FilledBookmark} from '@/assets/icons/filled_bookmark.svg';

interface Props {
  hasBookmark: boolean;
  onClick: (isBookmark: boolean) => void;
}

export default function Bookmark({hasBookmark, onClick}: Props) {
  return (
    <button onClick={() => onClick(!hasBookmark)}>
      {hasBookmark ? <FilledBookmark /> : <DefaultBookmark />}
    </button>
  );
}
