import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {
  BookMarkCount,
  BookMarkPage,
  BookMarkTotalPage,
  CollectionCount,
  CollectionTotalPage,
} from '../../../store/bookmark';

interface Props {
  type: 'bookmark' | 'collection';
}

export default function Pagination({type}: Props) {
  const [page, setPage] = useRecoilState(BookMarkPage);
  const totalPage = useRecoilValue(
    type === 'bookmark' ? BookMarkTotalPage : CollectionTotalPage,
  );
  const totalCnt = useRecoilValue(
    type === 'bookmark' ? BookMarkCount : CollectionCount,
  );
  const [pages, setPages] = useState<number[]>([]);
  const [max, setMax] = useState(0);
  const makePages = (min: number, max: number) => {
    const ps = [];
    for (let i = min; i <= max; i++) {
      ps.push(i);
    }
    setMax(max);
    return ps;
  };
  const next = () => {
    if (page + 1 >= max && pages[pages.length - 1] < page + 1) {
      setPages(makePages(page + 1 - 4, page + 1));
    }
    setPage(page + 1);
  };
  const goToLast = () => {
    setPages(makePages(totalPage < 5 ? 1 : totalPage - 4, totalPage));
    setPage(totalPage);
  };
  const goToFirst = () => {
    setPages(makePages(1, totalPage < 5 ? totalPage : 5));
    setPage(1);
  };
  const prev = () => {
    if (pages[0] > page - 1) {
      setPages(makePages(page - 1, page - 1 + 4));
    }
    setPage(page - 1);
  };
  const clickPage = (page: number) => {
    setPage(page);
  };
  useEffect(() => {
    const ps = [];
    const _max = totalPage > 5 ? 5 : totalPage;
    setMax(_max);
    for (let i = 0; i < _max; i++) {
      ps.push(i + 1);
    }
    console.log(ps);
    setPages(ps);
  }, [totalPage]);
  return (
    <div
      className="pagination"
      style={{display: totalCnt > 8 ? 'flex' : 'none'}}>
      <div className="previews">
        <span
          className={`icon pre-preview ${page === 1 ? '' : 'active'}`}
          style={{cursor: 'pointer'}}
          onClick={() => page > 1 && goToFirst()}>
          &nbsp;
        </span>
        <span
          className={`icon preview ${page === 1 ? '' : 'active'}`}
          style={{cursor: 'pointer'}}
          onClick={() => page > 1 && prev()}>
          &nbsp;
        </span>
      </div>
      <div className="pages">
        {pages.map(p => {
          return (
            <span
              className={`page-item ${page === p ? 'active' : ''}`}
              key={`page_item_in_${p}`}
              onClick={() => clickPage(p)}
              style={{cursor: 'pointer'}}>
              {p}
            </span>
          );
        })}
      </div>
      <div className="nexts">
        <span
          className={`icon next ${page === totalPage ? '' : 'active'}`}
          onClick={() => page < totalPage && next()}
          style={{cursor: 'pointer'}}>
          &nbsp;
        </span>
        <span
          className={`icon next-next ${page === totalPage ? '' : 'active'}`}
          style={{cursor: 'pointer'}}
          onClick={() => page < max && goToLast()}>
          &nbsp;
        </span>
      </div>
    </div>
  );
}
