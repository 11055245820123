import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import ListContent from '../../components/recommend/ListContent';
import NewContent from '../../components/recommend/NewContent';
import PickContent from '../../components/recommend/PickContent';
import Header from '../../layouts/Header';
import {recommendList} from '../../services/recommend';
import {RecommendContents} from '../../store/recommend';
import {user} from '../../store/user';

export default function RecommendMain() {
  const userInfo = useRecoilValue(user);
  const [_recommendList, _setRecommendList] = useRecoilState(RecommendContents);
  const [pickContent, setPickContent] = useState<RecommendData>();
  const init = async () => {
    const res = await recommendList({
      limit: 20,
      offset: 0,
    });
    setPickContent(res.data[0]);
    _setRecommendList(res.data.slice(1, res.data.length));
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div className="main">
      <Header isNoRing={true} />
      <div className="main-content recommend-main-wrapper">
        {pickContent && <PickContent content={pickContent} />}
        {userInfo && <NewContent />}
        <div className="divider-recommend-main"></div>
        {_recommendList.length > 0 &&
          _recommendList.map(recommend => {
            return (
              <ListContent
                key={recommend.id}
                isBest={true}
                content={recommend}
              />
            );
          })}
      </div>
    </div>
  );
}
