import React, {useEffect, useMemo} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {isOpenSoldOutModal, SoldOutModalInfo} from '../../../store';

export default function OrderSoldOutModal() {
  const [openModal, setOpenModal] = useRecoilState(isOpenSoldOutModal);
  const soldoutType = useRecoilValue(SoldOutModalInfo);

  const soldOutMessage = useMemo(() => {
    return (
      <div
        className="login-modal-info"
        style={{
          marginTop: '10px',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '140%',
          letterSpacing: '-0.02em',
          textAlign: 'center',
        }}>
        {soldoutType === 'option' && (
          <>
            선택한 옵션이 매진되었습니다.
            <br />
            옵션을 다시 선택해 주세요.
          </>
        )}
        {soldoutType === 'product' && '상품이 품절되었습니다.'}
        {soldoutType === 'popup_option' && (
          <>
            선택한 옵션이 매진되었습니다.
            <br />
            옵션을 다시 선택해주세요.
          </>
        )}
        {soldoutType === 'popup_max' && '한 ID당 1회만 주문 가능합니다.'}
      </div>
    );
  }, [soldoutType]);

  useEffect(() => {
    console.log(openModal);
  }, []);

  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '40px',
        }}>
        {soldOutMessage}
        <div className="login-btn-wrapper" style={{marginTop: '30px'}}>
          <button
            className="modal btn black-filled"
            style={{
              fontWeight: 600,
              fontSize: '14px',
              border: '1px solid #E9E7EC',
              background: '#fff',
              borderRadius: '5px',
              color: '#9F9CA3',
            }}
            onClick={() => setOpenModal(false)}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
