import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {CommonHistories} from '../store';

export default function useNativeNavigator() {
  const navigator = useNavigate();
  const [histories, setHistories] = useRecoilState(CommonHistories);
  const defaultTab = ['/home', '/magazine', '/community', '/user'];
  const goBack = () => {
    const list = [...histories];
    let path = '/home';
    if (list.length > 0) {
      list.pop();
      path = list.pop() || '/home';
    }
    setHistories(list);
    if (path) {
      navigator(path, {
        replace: true,
      });
    } else {
      navigator('/home', {
        replace: true,
      });
    }
  };
  useEffect(() => {
    const list = [...histories];
    const lastPath = list[list.length - 1];
    if (defaultTab.find(t => t === window.location.pathname)) {
      setHistories([window.location.pathname]);
    } else {
      if (
        lastPath !== window.location.pathname &&
        window.location.pathname !== '/'
      ) {
        list.push(window.location.pathname);
        setHistories(list);
      }
    }
  }, [window.location.pathname]);
  return {
    goBack,
  };
}
