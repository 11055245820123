import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import emptyKnewnew from '../../products/user_default.svg';

export default function EmptyInfo() {
  const navigator = useNavigate();
  const {t} = useTranslation(['components'], {
    keyPrefix: 'myKnewnew.EmptyInfo',
  });
  const goLogin = () => {
    navigator('/login', {
      state: {
        prevPath: `${location.pathname}${location.search}`,
      },
    });
  };

  return (
    <div className="my-info-wrapper">
      <div>
        <div className="profile" style={{justifyContent: 'flex-start'}}>
          <div
            className="profile-image-wrapper"
            style={{
              background: `url(${emptyKnewnew}) center no-repeat`,
              width: '60px',
              height: '60px',
              marginRight: '12px',
            }}></div>
          <div>
            <div className="profile-name-sns-link">
              <div className="profile-name">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={goLogin}>
                  <span className="name" style={{fontSize: '20px'}}>
                    {t('login_signin')}
                  </span>
                  <span
                    className="arrow-right-for-mypage"
                    style={{width: '16px', height: '16px'}}>
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
            <div
              className="introduce"
              style={{marginTop: '8px', marginBottom: 0}}>
              {t('signin_info')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
