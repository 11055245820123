import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useLocation, useNavigate} from 'react-router-dom';

import Layout from '../../../layouts';
import ImageRail from '../../imageRail';
import {getBrandInfo} from '../../../services/brands';
import {user} from '../../../store/user';
import {
  GetNearPlaceParams,
  NearPlaceList,
  NearPlaceMyId,
} from '../../../store/brand';

interface Props {
  brandId: number;
  item: BrandListItemType;
  reload: (_brandInfo: BrandListItemType) => void;
}

export default function RecommendBrand({item, reload}: Props) {
  const navigator = useNavigate();
  const location = useLocation();
  const setNearPlaceParams = useSetRecoilState(GetNearPlaceParams);
  const setNearPlaceMyId = useSetRecoilState(NearPlaceMyId);
  const brands = useRecoilValue(NearPlaceList);
  const accessToken = useRecoilValue(user);
  const changeRoute = async (parentId: number, id: number) => {
    const res = await getBrandInfo(accessToken, parentId);
    const child = res.data.brands.find((o: BrandListItemType) => o.id === id);
    const _info: BrandListItemType = {
      ...res.data,
      ...child,
    };
    reload(_info);
    navigator(`/brand/${parentId}/${id}`, {
      state: {
        prev: location.pathname.replace('/', ''),
      },
      replace: true,
    });
  };
  useEffect(() => {
    setNearPlaceParams({
      limit: 20,
      offset: 0,
      sort: 'newest',
      address_1: Number(item.address1 || 0),
      address_2: Number(item.address2 || 0),
    });
    setNearPlaceMyId(item.id);
  }, [item]);
  return (
    <div>
      {brands.length > 0 && (
        <div>
          <Layout.BrandCardView style={{paddingBottom: '60px'}}>
            <ImageRail
              title={'근처 주목할만한 브랜드'}
              titleStyle={{
                marginTop: '0px',
                marginBottom: '0px',
                marginLeft: '20px',
              }}
              images={[...brands].splice(0, 5)}
              callbackItemClick={(item: any) =>
                changeRoute(item.parentId, item.id)
              }
            />
          </Layout.BrandCardView>
        </div>
      )}
    </div>
  );
}
