import React from 'react';

import {ReactComponent as GoToMapIcon} from '@/assets/icons/goToMapArrow.svg';
import {ReactComponent as MapIcons} from '@/assets/icons/map_open_icon.svg';
import {isMobile} from '@/utils';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';

import {FooterWrapper} from './brandDetailFooter.styled';
import Text from '../../../atoms/Text/Text';

interface Props {
  setClosed: (is: boolean) => void;
}

export default function BrandDetailFooter({setClosed}: Props) {
  const {brandInfo} = useBrandContext();
  return (
    <FooterWrapper
      mobile={isMobile()}
      style={{
        cursor: 'pointer',
        display:
          brandInfo?.locationNaver && brandInfo?.locationKakao
            ? 'flex'
            : 'none',
      }}>
      <div
        className="flex justify-between items-center w-full"
        onClick={() => setClosed(false)}>
        <div className="flex justify-start items-center">
          <MapIcons style={{marginRight: '8px'}} />
          <Text
            txt={'지도앱으로 보기'}
            fontSize={16}
            color="#fff"
            style={{
              letterSpacing: '-0.32px',
            }}
          />
        </div>
        <div>
          <GoToMapIcon />
        </div>
      </div>
    </FooterWrapper>
  );
}
