import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import cc from 'classcat';

import {UserInfo, user} from '@/store/user';
import {getBrandInfo, likeBrand} from '@/services/brands';

import styles from '../styles.module.scss';

interface Props {
  info: BrandParentInfo;
  toggleLike: (idx: number, isLike: boolean) => void;
  idx: number;
}

export default function MyBrandItem({info, toggleLike, idx}: Props) {
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const _doBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.preventDefault();
    $event.stopPropagation();
    try {
      if (userInfo.id == -1) {
        // setOpenLoginModal(true);
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const id = info.id;
        const next = !info.isBookmark;
        await likeBrand(accessToken, id, next);
        toggleLike(idx, next);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const moveTo = async () => {
    const prevPath = `${location.pathname.replace('/', '')}`;
    const brandRes = await getBrandInfo(accessToken, info.id);
    const item = brandRes.data.brands.find(
      (o: BrandListItemType) => o.separator === '본점',
    );
    if (item) {
      navigator(`/brand/${info.id}/${item.id}`, {
        state: {
          prev: `${prevPath}`,
          searchParam: location.search,
        },
      });
    } else {
      const separatorId = brandRes.data.brands[0].id;
      navigator(`/brand/${info.id}/${separatorId}`, {
        state: {
          prev: `${prevPath}`,
          searchParam: location.search,
        },
      });
    }
  };
  return (
    <div className="brand-card" onClick={() => moveTo()}>
      <div className={cc([styles.picture_info_wrapper, styles.brand])}>
        <div
          className={styles.picture_wrapper}
          style={{
            background: `center / cover no-repeat url('${info.thumbnail}')`,
          }}></div>
        <div className={styles.info_wrapper}>
          <div className={`title ${styles.title}`}>{info.title}</div>
          {/* <div className={styles.sub_txt}></div> */}
        </div>
        <span
          className={cc([styles.bookmark, info.isBookmark && styles.active])}
          onClick={_doBookmark}></span>
      </div>
    </div>
  );
}
