import React, {ReactNode, useEffect, useState} from 'react';

import Loader from '../Loader';

type Props<T, U> = {
  children?: ReactNode;
  fetcher: (args?: T) => Promise<U>;
  args?: T;
  setter: (value: U) => void;
  fallbackData?: U;
  manual?: boolean;
  noLoader?: boolean;
  CustomLoader?: JSX.Element;
  animationFn?: () => void;
  syncScroll?: () => void;
  fixedHeader?: boolean;
};

type State = 'pending' | 'fulfilled' | 'rejected';

const Fetcher = <T, U>({
  children,
  fetcher,
  args,
  setter,
  fallbackData,
  manual,
  noLoader,
  CustomLoader,
  syncScroll,
  animationFn,
  fixedHeader,
}: Props<T, U>) => {
  const [state, setState] = useState<State>('pending');
  const fetchData = async () => {
    setState('pending');
    try {
      const result = await fetcher(args);
      setter(result);
      setState('fulfilled');
      animationFn && animationFn();
      if (syncScroll) {
        syncScroll();
      }
    } catch (e) {
      setter(fallbackData as U);
      setState('rejected');
    }
  };
  useEffect(() => {
    if (manual && !args) {
      setState('fulfilled');
      if (syncScroll) {
        syncScroll();
      }
      return;
    }
    if (!manual) {
      fetchData();
    }
  }, [args]);
  if (state === 'pending') {
    return noLoader ? (
      <>{children}</>
    ) : CustomLoader ? (
      CustomLoader
    ) : (
      <Loader fixedHeader={fixedHeader} />
    );
  }
  if (state === 'fulfilled') {
    return <>{children}</>;
  }
  return <div>Something Wrong Happend</div>;
};

export default Fetcher;
