import React from 'react';
import {useRecoilState} from 'recoil';

import {MarketPageFilters} from '../../../../store/recommend';

export default function ReactionModalBody() {
  const [filterInfo, setFilterInfo] = useRecoilState(MarketPageFilters);
  const clickIcon = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'reaction')[0];
    const fIdx = f.selection.indexOf(selection);
    if (fIdx > -1) {
      f.selection.splice(fIdx, 1);
    } else {
      f.selection.push(selection);
    }
    _filterInfo.forEach(filter => {
      if (filter.type === f.type) {
        filter.selection = f.selection;
      }
    });
    setFilterInfo(_filterInfo);
  };
  const isActive = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'reaction')[0];
    return f.selection.indexOf(selection) > -1;
  };
  return (
    <div className="filter-modal-body">
      <span
        className={`reaction-icon best ${isActive('best') ? 'active' : ''}`}
        onClick={() => clickIcon('best')}
        style={{cursor: 'pointer'}}>
        <span
          className={`reaction-best-icon icon ${
            isActive('best') ? 'active' : ''
          }`}>
          &nbsp;
        </span>
        <span className="reaction-txt">최고에요</span>
      </span>
      <span
        className={`reaction-icon like  ${isActive('like') ? 'active' : ''}`}
        onClick={() => clickIcon('like')}
        style={{cursor: 'pointer'}}>
        <span
          className={`reaction-like-icon icon ${
            isActive('like') ? 'active' : ''
          }`}>
          &nbsp;
        </span>
        <span className="reaction-txt">좋아요</span>
      </span>
      <span
        className={`reaction-icon normal  ${
          isActive('normal') ? 'active' : ''
        }`}
        onClick={() => clickIcon('normal')}
        style={{cursor: 'pointer'}}>
        <span
          className={`reaction-normal-icon icon ${
            isActive('normal') ? 'active' : ''
          }`}>
          &nbsp;
        </span>
        <span className="reaction-txt">애매해요</span>
      </span>
      <span
        className={`reaction-icon bad  ${isActive('bad') ? 'active' : ''}`}
        onClick={() => clickIcon('bad')}
        style={{cursor: 'pointer'}}>
        <span
          className={`reaction-bad-icon icon ${
            isActive('bad') ? 'active' : ''
          }`}>
          &nbsp;
        </span>
        <span className="reaction-txt">별로에요</span>
      </span>
    </div>
  );
}
