import React from 'react';
import {useRecoilState} from 'recoil';

import {isOpenFilterModal} from '../../../store';

interface Props {
  txt: string;
  close?: () => void;
  hasInit?: boolean;
  withPadding?: boolean;
  initFn?: () => void;
  fnTxt: string;
  isHeaderPadding?: boolean;
}
export default function FilterModalHeader({
  txt,
  close,
  hasInit,
  initFn,
  withPadding,
  fnTxt,
  isHeaderPadding,
}: Props) {
  const [openFilterModal, setOpenFilterModal] =
    useRecoilState(isOpenFilterModal);

  return (
    <div
      className={`filter-modal-header ${withPadding ? 'with-padding' : ''}`}
      style={{height: isHeaderPadding ? '46px' : ''}}>
      <span
        className="gray-close-icon"
        onClick={() => (close ? close() : setOpenFilterModal(!openFilterModal))}
        style={{cursor: 'pointer'}}>
        &nbsp;
      </span>
      <span className="title">{txt}</span>
      <span
        className="init"
        onClick={() => (initFn ? initFn() : '')}
        style={{display: hasInit ? 'inline' : 'none', cursor: 'pointer'}}>
        {fnTxt}
      </span>
    </div>
  );
}
