import React from 'react';

import ModalButton from './ModalButton';

function Button() {
  return <div>index</div>;
}
Button.ModalButton = ModalButton;

export default Button;
