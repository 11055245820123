import React from 'react';

type store = 'kurly' | 'coupang' | 'bmart' | 'ssg' | 'naver' | 'etc';

interface Props {
  type: store;
  isActive: boolean;
  _click: (selection: string) => void;
}
interface StoreTxt {
  type: store;
  txt: string;
}

export default function FilterIconBtn({type, isActive, _click}: Props) {
  const stores: StoreTxt[] = [
    {
      type: 'kurly',
      txt: '마켓컬리',
    },
    {
      type: 'coupang',
      txt: '쿠팡',
    },
    {
      type: 'bmart',
      txt: 'B마트',
    },
    {
      type: 'ssg',
      txt: 'SSG',
    },
    {
      type: 'naver',
      txt: '네이버 쇼핑',
    },
    {
      type: 'etc',
      txt: '기타',
    },
  ];
  return (
    <span
      className={`filter-icon ${type} ${isActive ? 'active' : ''}`}
      style={{cursor: 'pointer'}}
      onClick={() => _click(type)}>
      {stores.filter(o => o.type === type)[0].txt}
    </span>
  );
}
