import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import {reportComment} from '@/services/comment';
import {reportContents} from '@/services/community';
import {isOpenToast, ToastMessage} from '@/store';
import {user} from '@/store/user';

interface Props {
  type: 'comment' | 'contents';
}

export default function ReportComment({type}: Props) {
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const [reasonContents, setReasonContents] = useState('');
  const [reportType, setReportType] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const changeDetailReason = (
    $event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setReasonContents($event.currentTarget.value);
  };
  const check = (type: string) => {
    if (type !== reportType) {
      setReportType(type);
      setIsEnabled(true);
    } else {
      setReportType('');
      setIsEnabled(false);
    }
  };
  const contentsSubmit = async (id: string) => {
    await reportContents(
      {
        review: Number(id),
      },
      reasonContents.length === 0 ? '-' : reasonContents,
      accessToken,
      reportType,
    );
    setToastMessage('게시글 신고가 완료되었습니다.');
    setIsOpenToast(true);
    navigator(`/community/${id}`);
  };
  const commentSubmit = async (id: string) => {
    await reportComment(
      {
        reviewComment: Number(id),
      },
      reasonContents.length === 0 ? '-' : reasonContents,
      accessToken,
      reportType,
    );
    setToastMessage('댓글 신고가 완료되었습니다.');
    setIsOpenToast(true);
    navigator(-1);
  };
  const submit = async () => {
    const paths = window.location.pathname.split('/');
    if (reportType.length === 0) {
      setToastMessage('신고하는 이유를 선택해 주세요');
      setIsOpenToast(true);
      return;
    }
    /*
    if (reasonContents.length === 0) {
      setToastMessage('상세내용을 입력해 주세요');
      setIsOpenToast(true);
      return;
    }
    */
    const id = paths[paths.length - 1];
    type === 'comment' ? commentSubmit(id) : contentsSubmit(id);
  };
  useEffect(() => {
    if (type === 'comment') {
      // init();
    }
  }, []);
  return (
    <div className="main report-page" style={{minHeight: '100vh'}}>
      <ContentsHeader
        txt={type === 'comment' ? '댓글 신고' : '게시글 신고'}
        isOption={'none'}
      />
      <div className="report-reason contents-body">
        <div className="report-title">
          신고사유 선택
          <span className="mendetory" style={{color: '#FF3E48'}}>
            &nbsp;*
          </span>
        </div>
        <div className="report-reason-checker">
          <div
            className="report-reason-item"
            onClick={() => check('report_advertise')}>
            <input
              type="checkbox"
              name="reason1"
              checked={reportType === 'report_advertise'}
            />
            <label htmlFor="reason1" style={{cursor: 'pointer'}}>
              신뢰하기 어려운 홍보 게시물
            </label>
          </div>
          <div
            className="report-reason-item"
            onClick={() => check('report_inappropriate')}>
            <input
              type="checkbox"
              name="reason2"
              checked={reportType === 'report_inappropriate'}
            />
            <label htmlFor="reason2" style={{cursor: 'pointer'}}>
              음란성 또는 부적절한 내용
            </label>
          </div>
          <div
            className="report-reason-item"
            onClick={() => check('report_copyright')}>
            <input
              type="checkbox"
              name="reason"
              checked={reportType === 'report_copyright'}
            />
            <label htmlFor="reason3" style={{cursor: 'pointer'}}>
              명예훼손 및 저작권 침해
            </label>
          </div>
          <div
            className="report-reason-item"
            onClick={() => check('report_privacy')}>
            <input
              type="checkbox"
              name="reason"
              checked={reportType === 'report_privacy'}
            />
            <label htmlFor="reason4" style={{cursor: 'pointer'}}>
              개인정보 유출
            </label>
          </div>
        </div>
        <div className="detail-reason-wrapper">
          <textarea
            className="detail-reason"
            placeholder="상세 내용&#13;&#10; &bull; 위 신고 항목에 없거나 추가로 신고하시려는 내용을 작성해 주세요."
            maxLength={500}
            value={reasonContents}
            onChange={($event: React.ChangeEvent<HTMLTextAreaElement>) =>
              changeDetailReason($event)
            }></textarea>
          <span>
            신고 내용은 관리자 검토 후 운영정책에 의거 조치되며, 별도의
            처리결과는 안내해드리지 않습니다.
          </span>
          <span className="text-length-counter">
            ({reasonContents.length}/500자)
          </span>
        </div>
      </div>
      <div className="report-btn-wrapper">
        <button
          className={`main-cont btn main-black-border ${
            isEnabled ? '' : 'disabled'
          }`}
          onClick={() => submit()}
          disabled={!isEnabled}>
          신고하기
        </button>
      </div>
    </div>
  );
}
