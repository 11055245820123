import React, {useState} from 'react';

import {makeShortContent, switchNewLineTxt} from '../../../utils';
import ExpendContentsBtn from '../../common/buttons/ExpendContentsBtn';

interface Props {
  contents: string;
}

export default function TextsContents({contents}: Props) {
  const [isShort, setIsShort] = useState<boolean>(true);
  return (
    <div className="feed-texts-wrapper">
      <div
        className="text-content short-contents"
        style={{display: isShort ? 'block' : 'none'}}>
        <div className="blur-wrapper">&nbsp;</div>
        <div
          dangerouslySetInnerHTML={{
            __html: switchNewLineTxt(makeShortContent(contents)),
          }}></div>
      </div>
      <div
        className="text-content long-conetnts"
        style={{display: !isShort ? 'block' : 'none'}}
        dangerouslySetInnerHTML={{__html: switchNewLineTxt(contents)}}></div>
      <div className="contents-buttons">
        <ExpendContentsBtn
          isExpand={!isShort}
          setIsExpand={(is: boolean) => setIsShort(!is)}
        />
      </div>
    </div>
  );
}
