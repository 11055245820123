import React from 'react';

import ContentsHeader from './ContentsHeader';
import BottomNavigation from './BottomNavigation';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import BrandCardView from './BrandCardView';

const Layout = () => {
  return <div></div>;
};

Layout.Main = Main;
Layout.Header = Header;
Layout.ContentsHeader = ContentsHeader;
Layout.BottomNavigation = BottomNavigation;
Layout.Footer = Footer;
Layout.BrandCardView = BrandCardView;
export default Layout;
