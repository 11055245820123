import React from 'react';
import {useNavigate} from 'react-router-dom';

import {switchNewLineTxt} from '../../../utils';
import styles from './styles.module.scss';

interface Props {
  info: MagazineItem;
}

export default function ZipCard({info}: Props) {
  const navigator = useNavigate();
  const moveTo = () => {
    navigator(`/zip/${info.id}`, {
      state: {
        prev: location.pathname.replace('/', ''),
      },
    });
  };
  return (
    <div
      className={styles.zipCard}
      onClick={() => moveTo()}
      style={{
        background: `center / cover no-repeat url(${info.imageUrl})`,
        borderRadius: '5px',
      }}>
      <div className={styles.contentsWrapper}>
        <div className={styles.txtWrapper}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: switchNewLineTxt(info.title),
            }}></div>
          <div className={styles.subtitle}>{info.subtitle}</div>
        </div>
        {/* <div className="profile-wrapper">
          <div
            className={styles.profileImg}
            style={{
              background: `center / 100% 100% no-repeat url('${info.author.profileImage}')`,
            }}></div>
          <div className={styles.profileName}>by {info.author.nickname}</div>
        </div> */}
      </div>
    </div>
  );
}
