// src/components/LocationIcon.js
import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <g clipPath="url(#clip0_22_1749)">
        <path
          d="M7.33328 15.3031V13.9698C5.9444 13.8142 4.75262 13.2393 3.75795 12.2451C2.76328 11.2509 2.1884 10.0591 2.03328 8.66979H0.699951V7.33646H2.03328C2.18884 5.94757 2.76395 4.75579 3.75862 3.76113C4.75328 2.76646 5.94484 2.19157 7.33328 2.03646V0.703125H8.66662V2.03646C10.0555 2.19201 11.2473 2.76713 12.242 3.76179C13.2366 4.75646 13.8115 5.94801 13.9666 7.33646H15.3V8.66979H13.9666C13.8111 10.0587 13.2362 11.2505 12.242 12.2451C11.2477 13.2398 10.056 13.8147 8.66662 13.9698V15.3031H7.33328ZM7.99995 12.6698C9.28884 12.6698 10.3888 12.2142 11.3 11.3031C12.2111 10.392 12.6666 9.29201 12.6666 8.00313C12.6666 6.71424 12.2111 5.61424 11.3 4.70312C10.3888 3.79201 9.28884 3.33646 7.99995 3.33646C6.71106 3.33646 5.61106 3.79201 4.69995 4.70312C3.78884 5.61424 3.33328 6.71424 3.33328 8.00313C3.33328 9.29201 3.78884 10.392 4.69995 11.3031C5.61106 12.2142 6.71106 12.6698 7.99995 12.6698ZM7.99995 10.6698C7.26662 10.6698 6.63884 10.4087 6.11662 9.88646C5.5944 9.36424 5.33328 8.73646 5.33328 8.00313C5.33328 7.26979 5.5944 6.64201 6.11662 6.11979C6.63884 5.59757 7.26662 5.33646 7.99995 5.33646C8.73328 5.33646 9.36106 5.59757 9.88328 6.11979C10.4055 6.64201 10.6666 7.26979 10.6666 8.00313C10.6666 8.73646 10.4055 9.36424 9.88328 9.88646C9.36106 10.4087 8.73328 10.6698 7.99995 10.6698ZM7.99995 9.33646C8.36662 9.33646 8.68062 9.20601 8.94195 8.94513C9.20328 8.68424 9.33373 8.37024 9.33328 8.00313C9.33284 7.63601 9.2024 7.32224 8.94195 7.06179C8.68151 6.80135 8.36751 6.67068 7.99995 6.66979C7.6324 6.6689 7.31862 6.79957 7.05862 7.06179C6.79862 7.32401 6.66795 7.63779 6.66662 8.00313C6.66528 8.36846 6.79595 8.68246 7.05862 8.94513C7.32128 9.20779 7.63506 9.33824 7.99995 9.33646Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1749">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
