import React, {useEffect, useState} from 'react';
import SimpleImageSlider from 'react-simple-image-slider';

interface Props {
  images: ImageObj[] | undefined;
}

interface SliderImage {
  url: string;
}

export default function FoodLogHeader({images}: Props) {
  const [page, setPage] = useState<number>(1);
  const [sliderImages, setSliderImages] = useState<SliderImage[]>([]);
  const move = (p: number) => {
    const max = sliderImages.length;
    if (p > max) {
      setPage(1);
    } else if (p < 1) {
      setPage(max);
    } else {
      setPage(p);
    }
  };
  const updatePage = (toRight: boolean) => {
    if (toRight) {
      move(page + 1);
    } else {
      move(page - 1);
    }
  };
  useEffect(() => {
    if (images) {
      setSliderImages(
        images.map(img => {
          return {
            url: img.image,
          };
        }),
      );
    }
  }, [images]);
  return (
    <div className="food-log-header">
      <div className="food-log-slider">
        <div className="images">
          <SimpleImageSlider
            images={sliderImages}
            width={375}
            height={375}
            showBullets={true}
            showNavs={true}
            onClickNav={(toRight: boolean) => updatePage(toRight)}
          />
        </div>
        <div className="image-navigator">
          {images &&
            images.length > 0 &&
            images.map((img, idx) => {
              return (
                <span
                  key={img.id}
                  className={`dot ${page - 1 === idx ? 'active' : ''}`}></span>
              );
            })}
        </div>
        <div className="image-counter">
          <span>{page}</span> / {images ? images.length : 0}
        </div>
      </div>
    </div>
  );
}
