import React, {createContext, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import queryKeys from '@/hooks/queries/brands/queryKeys';
import {useGetBenefits, useGetBrandDetails} from '@/hooks/queries/brands';

interface BrandContextType {
  brandInfo: BrandListItemType | undefined;
  benefits: CollectionListItem[] | undefined;
  refetchBrand: () => void;
}

const BrandContext = createContext<BrandContextType | undefined>(undefined);

export const BrandProvider = ({children}: {children: React.ReactNode}) => {
  const {id, placeId} = useParams<{id: string; placeId: string}>();
  const {data: brandInfo, refetch: refetchBrand} = useGetBrandDetails(
    {
      id: Number(id || -1),
      placeId: Number(placeId || -1),
    },
    {
      enabled: !!id && !!placeId,
      queryKey: queryKeys.getBrandDetail({id, placeId}),
    },
  );

  const {data: benefits, refetch: refetchBenefit} = useGetBenefits(
    {
      brand: brandInfo?.id,
      limit: 20,
      offset: 0,
      category: 'benefits',
    },
    {
      enabled: !!brandInfo,
      queryKey: queryKeys.getBenefits({
        brand: brandInfo?.id,
        limit: 20,
        offset: 0,
        category: 'benefits',
      }),
    },
  );
  const value = {
    brandInfo,
    benefits,
    refetchBrand,
  };

  useEffect(() => {
    if (brandInfo) {
      refetchBenefit();
    }
  }, [brandInfo]);

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};

export const useBrandContext = () => {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrandContext must be used within a BrandProvider');
  }
  return context;
};
