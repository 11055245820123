import {AxiosResponse} from 'axios';
import {throttle} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {dummyCommunity, isAndroid} from '@/utils';

import Header from '../../layouts/Header';
import {getUsersReview} from '../../services/user';
import {user, UserInfo} from '../../store/user';
import UserContent from '../community/UserContent';
import useLogger, {Action} from '../../hooks/useLogger';

export default function MyContents() {
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const userInfo = useRecoilValue(UserInfo);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [page, setPage] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [updateList, setUpdateList] = useState(false);
  const sender = useLogger();

  const makeDummyList = (p: number) => {
    const list: Community[] = Array<Community>(20).fill(dummyCommunity);
    const _communities = [...communities];
    setCommunities(p === 0 ? [...list] : [..._communities, ...list]);
  };

  const init = async (p: number) => {
    makeDummyList(p);
    const res: AxiosResponse<CommunityRes> = await getUsersReview(
      userInfo.id,
      accessToken,
      {
        offset: p * 20,
      },
    );
    setMaxCount(res.data.count);
    const exceptDummy = communities.filter(c => c.id !== -1);
    setCommunities(
      p === 0 ? [...res.data.results] : [...exceptDummy, ...res.data.results],
    );
  };
  const updateCommunities = () => {
    if (maxCount > communities.length) {
      setPage(page + 1);
      init(page + 1);
      setUpdateList(false);
    }
  };
  const onScroll = useMemo(
    () =>
      throttle(event => {
        let clientHeight = event.target.scrollingElement.clientHeight;
        clientHeight = isAndroid() ? clientHeight + 57 : clientHeight;
        const currentY = window.scrollY + clientHeight;
        const maxY = document.querySelector('.main.community')?.clientHeight;
        if (Math.floor(currentY) > Math.floor(Number(maxY) - 20)) {
          setUpdateList(true);
        }
      }, 300),
    [page, communities],
  );
  useEffect(() => {
    if (updateList) {
      updateCommunities();
    }
  }, [updateList]);
  useEffect(() => {
    setPage(0);
    init(0);
    document.removeEventListener('scroll', onScroll);
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <div className="main my-contents-wrapper">
      <Header />
      <div className="back-navigation">
        <span className="back-btn" onClick={() => navigator('/user')}>
          <span className="icon go-to-list-icon">&nbsp;</span>
          이전으로
        </span>
      </div>
      <div className="my-contents-title">내 게시글</div>
      <div
        className="community-contents"
        style={{display: communities.length > 0 ? 'block' : 'none'}}>
        {communities.map((community, idx) => {
          return (
            <UserContent
              key={`community_${community.id > -1 ? community.id : idx}`}
              community={community}
              wrapperType={'my-contents'}
            />
          );
        })}
      </div>
      <div
        className="community-contents-none"
        style={{display: communities.length === 0 ? 'flex' : 'none'}}>
        <div className="txt-contents">
          <span className="info">작성한 글이 없습니다.</span>
          <span className="info">
            {userInfo.userType === 'normal'
              ? '뉴뉴 크리에이터에 지원하고 첫 게시물을 남겨보세요.'
              : '지금 커뮤니티 첫 게시물을 남겨보세요.'}
          </span>
          <div>
            <button
              className="go-to-write"
              onClick={() => {
                if (userInfo.userType === 'normal') {
                  sender({
                    _msg: 'mypape_writer_support',
                    _screen: '/apply/',
                    _action: Action.CLICK,
                  });

                  window.open(
                    'https://docs.google.com/forms/d/1B1L3oVuGj8Kn9MnpcPPyeg_-xiwMx6pu0t98OUDnImM/viewform?edit_requested=true',
                    '_blank',
                  );
                } else {
                  navigator('/community/add', {
                    state: {
                      history: 'my-content',
                    },
                  });
                }
              }}>
              {userInfo.userType === 'normal'
                ? '크리에이터 지원하기'
                : '게시글 작성하기'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
