import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import ContentsHeader from '@/layouts/ContentsHeader';
import Layout from '@/layouts';

import styles from './styles.module.scss';
import OrderCancelModal from './components/OrderCancelModal';

export default function BenefitDetailPage() {
  const location = useLocation();
  const navigator = useNavigate();
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState<OrderListItem>();
  const [status, setStatus] = useState<
    | '취소 완료'
    | '기간 만료'
    | '사용 완료'
    | '취소 요청'
    | '구매 완료'
    | '사용 전'
  >();

  const statusCode = useMemo(() => {
    let code = '';
    const _status = status;
    switch (_status) {
      case '취소 완료':
        code = 'cancel';
        break;
      case '기간 만료':
      case '사용 완료':
        code = 'used';
        break;
      case '취소 요청':
        code = 'cancel';
        break;
      default:
        code = '';
        break;
    }
    return code;
  }, [status]);
  const statusName = useMemo(() => {
    let code: typeof status = '구매 완료';
    const _status = status;
    switch (_status) {
      case '구매 완료':
        code = '사용 전';
        break;
      default:
        code = _status;
        break;
    }
    return code;
  }, [status]);
  const crewConfirmObj = useMemo(() => {
    let message = '';
    let disabled = false;
    const _status = status;
    switch (_status) {
      case '취소 완료':
        break;
      case '기간 만료':
        message = '유효 기간 만료';
        disabled = true;
        break;
      case '사용 완료':
        message = '사용 완료';
        disabled = true;
        break;
      case '취소 요청':
        break;
      default:
        message = '직원 확인';
        break;
    }
    return {
      message,
      disabled,
    };
  }, [status]);
  useEffect(() => {
    if (location.state?.item && location.state?.status) {
      setOrderInfo(location.state.item);
      setStatus(location.state.status);
    }
  }, [location.state]);
  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader txt={'혜택권 상세'} isOption="none" isBottomLine="" />
        {orderInfo && (
          <div className={`${styles.success_info} ${styles.detail_info}`}>
            <div
              className={`${styles.success_message} ${
                location.state?.fromOrderList ? styles.hasConfirm : ''
              }`}>
              <div className={styles.title_ticket}>혜택권 정보</div>
              <div className={`${styles.product_info} ${styles[statusCode]}`}>
                <div className={styles.header}>
                  <h2 className={styles.status}>{statusName}</h2>
                  <span className={styles.expired}>
                    {location.state.item.expired &&
                      `${dayjs(location.state.item.expired).format(
                        'YYYY-MM-DD',
                      )}까지`}
                  </span>
                </div>
                <div className={styles.product}>
                  <div
                    className={styles.img_wrapper}
                    style={{
                      borderRadius: '5px',
                      background: `url('${orderInfo.popup.imageUrl}') no-repeat center / cover`,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigator(
                        `/benefit/${
                          (location.state.item.popup as MagazineItem).id
                        }`,
                        {
                          state: {
                            benefit: location.state.item,
                            status: location.state.status,
                            prev: location.pathname.replace('/', ''),
                            postPrev: location.state.prev,
                          },
                          replace: true,
                        },
                      )
                    }></div>
                  <div className={styles.txt_wrapper}>
                    <h2>{orderInfo.popup.title}</h2>
                    <div className={styles.option_info}>
                      {/* <div className={styles.option}>
                        {orderInfo.options[0].value}
                      </div>
                      <div className={styles.option}>
                        {orderInfo.options[0].child[0].value}
                      </div> */}
                      <div
                        className={
                          styles.option
                        }>{`수량 ${orderInfo.purchaseCount}개`}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{display: statusCode === 'cancel' ? 'none' : 'block'}}>
                  <div className={styles.confirmBtnWrapper}>
                    <button
                      className={styles.confirmBtn}
                      disabled={crewConfirmObj.disabled}
                      onClick={() =>
                        navigator('/order/numbers?benefit=true', {
                          state: {
                            id: orderInfo.id,
                            name: orderInfo.userName,
                            option1: orderInfo.options[0].value,
                            option2: orderInfo.options[0].child[0].value,
                            status: location.state.status,
                            item: location.state.item,
                            fromOrderList: true,
                          },
                        })
                      }>
                      {crewConfirmObj.message}
                    </button>
                  </div>
                  <div className={styles.confirmWarning}>
                    <p>매장 방문 시 화면을 현장 직원에게 보여주세요.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.instruction}>
              <h2>혜택권 사용 안내</h2>
              <ul>
                <li>
                  <div>①</div>
                  <div>{'마이 > 스페셜 혜택권에서 혜택 내역 확인'}</div>
                </li>
                <li>
                  <div>②</div>
                  <div>{'유효 기간 내에 매장에 직접 방문'}</div>
                </li>
                <li>
                  <div>③</div>
                  <div>{'매장에서 직원 확인 요청 및 사용'}</div>
                </li>
                <li>
                  <div>④</div>
                  <div>{'메뉴 할인권의 경우 매장에서 할인가 현장 결제'}</div>
                </li>
              </ul>
              <div className={styles.expireWarning}>
                *사용 기간이 만료된 혜택권은 자동 소멸됩니다
              </div>
              {/* <div className={styles.qnaArea}>
              <button onClick={() => openBrowser()}>
                <span className={styles.kakao}></span>
                <span className={styles.txt}>1:1 문의</span>
              </button>
            </div> */}
            </div>
          </div>
        )}
        <OrderCancelModal
          item={location.state.item}
          isOpen={isOpenCancelModal}
          toggleOpen={(is: boolean) => setIsOpenCancelModal(is)}
        />
      </div>
    </Layout.Main>
  );
}
