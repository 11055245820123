import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Props {
  src: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

export default function Image({src, width, height, style}: Props) {
  return (
    <LazyLoadImage
      src={src}
      alt="example"
      effect="blur"
      width={`${width || 'auto'}`}
      height={`${height || 'auto'}`}
      style={style}
    />
  );
}
