import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';

import {isOpenToast, ToastMessage} from '../../../store';

export default function Toast() {
  const isOpen = useRecoilValue(isOpenToast);
  const [positionBottom, setPositionBottom] = useState(false);
  const [toastMessage, setToastMessage] = useRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const close = () => {
    setIsOpenToast(false);
  };

  useEffect(() => {
    if (window.location.pathname === '/user/brands/new') {
      setPositionBottom(true);
    } else if (positionBottom) {
      setPositionBottom(false);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        close();
        setToastMessage('아직 오픈되지 않은 기능이예요. 열심히 만들게요!');
      }, 2000);
    }
  }, [isOpen]);
  return (
    <div
      className={`toast-wrapper ${positionBottom ? 'position-bottom ' : ''}`}
      style={{display: isOpen ? 'flex' : 'none'}}>
      {toastMessage}
    </div>
  );
}
