import React from 'react';
import ContentLoader from 'react-content-loader';

export default function MagazineLoader() {
  return (
    <>
      <div style={{background: '#000', borderBottom: '3px solid #9F9CA3'}}>
        <ContentLoader
          speed={2}
          width={380}
          height={547}
          viewBox="0 0 380 547"
          backgroundColor="#1A1A1A"
          foregroundColor="#242424"
          style={{width: '100%'}}>
          <rect x="20" y="20" rx="0" ry="0" width="310" height="370" />
          <rect x="345" y="20" rx="0" ry="0" width="310" height="370" />
          <rect x="20" y="405" rx="0" ry="0" width="310" height="32" />
          <rect x="345" y="405" rx="0" ry="0" width="310" height="32" />
          <rect x="20" y="443" rx="0" ry="0" width="206" height="32" />
          <rect x="345" y="443" rx="0" ry="0" width="206" height="32" />
        </ContentLoader>
      </div>
      <div style={{background: '#fff'}}>
        <ContentLoader
          speed={2}
          width={380}
          height={1464}
          viewBox="0 0 380 1464"
          backgroundColor="#F5F5F5"
          foregroundColor="#EFEFEF"
          style={{width: '100%'}}>
          <rect x="20" y="60" rx="0" ry="0" width="206" height="32" />
          <rect x="20" y="98" rx="0" ry="0" width="335" height="32" />
          <rect x="20" y="150" rx="0" ry="0" width="260" height="314" />
          <rect x="295" y="150" rx="0" ry="0" width="260" height="314" />
          <rect x="20" y="524" rx="0" ry="0" width="206" height="32" />
          <rect x="20" y="562" rx="0" ry="0" width="206" height="32" />
          <rect x="20" y="614" rx="0" ry="0" width="335" height="400" />
          <rect x="20" y="1074" rx="0" ry="0" width="206" height="32" />
          <rect x="20" y="1112" rx="0" ry="0" width="335" height="32" />
          <rect x="20" y="1164" rx="0" ry="0" width="160" height="200" />
          <rect x="195" y="1164" rx="0" ry="0" width="160" height="200" />
        </ContentLoader>
      </div>
      <div style={{background: '#000'}}>
        <ContentLoader
          speed={2}
          width={380}
          height={460}
          viewBox="0 0 380 460"
          backgroundColor="#1A1A1A"
          foregroundColor="#242424"
          style={{width: '100%'}}>
          <rect x="84" y="40" rx="0" ry="0" width="206" height="32" />
          <rect x="20" y="78" rx="0" ry="0" width="335" height="32" />
          <rect x="20" y="150" rx="0" ry="0" width="150" height="200" />
          <rect x="180" y="150" rx="0" ry="0" width="150" height="200" />
          <rect x="340" y="150" rx="0" ry="0" width="150" height="200" />
          <rect x="105" y="390" rx="15" ry="15" width="165" height="30" />
        </ContentLoader>
      </div>
    </>
  );
}
