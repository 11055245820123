import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {Capacitor} from '@capacitor/core';

import {ReactComponent as WrongInfo} from '@/assets/icons/wrong_info_page_icon.svg';
import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import {user, ToastMessage, isOpenToast} from '@/store';
import useSlack from '@/hooks/useSlack';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';

import styles from './styles.module.scss';

export default function WrongInformation() {
  const {id, placeId} = useParams();
  const {brandInfo} = useBrandContext();
  const offsetTop = useRef(0);
  const [message, setMessage] = useState('');
  const token = useRecoilValue(user);
  const sender = useSlack();
  const setToastMessage = useSetRecoilState(ToastMessage);

  const setOpenToastModal = useSetRecoilState(isOpenToast);
  const navigator = useNavigate();

  const apply = () => {
    sender({
      _msg: `잘못된 정보 제보 \n브랜드이름: ${brandInfo?.title} \n내용: ${message}`,
    });
    if (!token) {
      location.href = '/login';
    } else {
      navigator(`/brand/${id}/${placeId}`, {
        replace: true,
      });
      setToastMessage('신청이 완료되었습니다');
      setOpenToastModal(true);
    }
  };

  const initKeyboard = () => {
    Keyboard.addListener('keyboardWillShow', () => {
      const mode: KeyboardResize = KeyboardResize.Body; // 타입 정의에 맞는 값 설정
      Keyboard.setResizeMode({
        mode: mode,
      });
    });

    Keyboard.addListener('keyboardDidShow', info => {
      window.scrollTo(0, offsetTop.current + info.keyboardHeight);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      console.log('keyboard will hide');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide');
    });
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      initKeyboard();
    }
  }, []);

  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt={'브랜드 정보 수정'}
          isOption={'none'}
          isPopup={true}
          back={() =>
            navigator(`/brand/${id}/${placeId}`, {
              replace: true,
            })
          }
        />
        {/* 이미지 */}
        <div className={styles.no_result_img}>
          <WrongInfo />
        </div>
        {/* 타이틀 */}
        <h3 className={styles.no_result_tit}>
          잘못된 정보가 있다면 알려주세요
        </h3>
        {/* 상세 */}
        <div className={styles.recommend_desc}>
          잘못되었거나 수정이 필요한 정보를 제보해주세요.
          <br />
          제보해주신 내용은 검수 과정을 거친 후 반영됩니다.
        </div>
        <div className={styles.divider}></div>
        <div className={styles.brandInput}>
          <h3>제보 내용</h3>
          <textarea
            placeholder="상세내용을 입력해주세요"
            onChange={($e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setMessage($e.target.value)
            }
            style={{resize: 'none', height: '160px', width: '100%'}}
          />
        </div>
        <div className={styles.footer}>
          <button
            className={styles.cancelBtn}
            onClick={() =>
              navigator(`/brand/${id}/${placeId}`, {
                replace: true,
              })
            }>
            취소
          </button>
          <button
            className={styles.apply}
            disabled={message.length === 0}
            onClick={() => apply()}>
            등록
          </button>
        </div>
      </div>
    </Layout.Main>
  );
}
