/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Suspense, useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useNavigate} from 'react-router-dom';

import BrandGroup from '@/components/cardList/BrandGroup/BrandGroup';
import NewsGroup from '@/components/cardList/NewsGroup/NewsGroup';
import ZipGroup from '@/components/cardList/ZipGroup/ZipGroup';
import {brandsArgs, listTypes} from '@/store';
import ContentsHeader from '@/layouts/ContentsHeader';
import MagazineCategoryModal from '@/components/magazine/CategoryModal';
import Loader from '@/components/common/Loader';
import Layout from '@/layouts';
import BottomNavigation from '@/layouts/BottomNavigation';

import styles from './styles.module.scss';

interface Props {
  type: 'brands' | 'zip' | 'news';
}

export default function CardList({type}: Props) {
  const navigator = useNavigate();
  const _listTypes = useRecoilValue(listTypes);
  const videoRef = useRef(null);
  const listRef = useRef(null);
  const [selectedType, setSelectedType] = useState<ListType>(_listTypes[0]);
  const [brandArgs, setBrandArgs] = useRecoilState(brandsArgs);
  const [fixScroll] = useState<boolean>(false);

  const changeTab = (tab: string) => {
    setBrandArgs({
      ...brandArgs,
      tab,
    });
  };
  const init = () => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  };

  const title = useMemo(() => {
    let t;
    switch (type) {
      case 'brands':
        t = 'Brand';
        break;
      case 'news':
        t = 'News';
        break;
      case 'zip':
        t = 'Pick';
        break;
    }
    return t;
  }, [type]);
  useEffect(() => {
    if (videoRef.current) {
      (videoRef.current as any).load();
      (videoRef.current as any).onloadeddata = () => {
        (videoRef.current as any).play();
      };
    }
  }, [selectedType]);
  useEffect(() => {
    init();
    if (type) {
      const item = _listTypes.filter(item => item.type === type)[0];
      setSelectedType(item);
    }
  }, [type]);
  return (
    <Layout.Main>
      <div className={`${styles.cardList} ${type}`}>
        <div ref={listRef}>
          {title == 'Brand' ? (
            <>
              <ContentsHeader
                txt={title}
                hasDropdown={true}
                isOption={'none'}
                leftText={false}
                noBack={true}
                back={() => navigator('/home')}
              />
            </>
          ) : (
            <>
              <ContentsHeader
                txt={title}
                hasDropdown={true}
                isOption={'none'}
                leftText={false}
                noBack={true}
                back={() => navigator('/home')}
              />
            </>
          )}
          <div
            className={`${styles.tabs} ${
              fixScroll
                ? `${styles.fixed} ${
                    Capacitor.getPlatform() === 'android' ? 'android' : ''
                  }`
                : ''
            }`}
            style={{display: title == 'Brand' ? '' : 'none'}}>
            {selectedType.tabs.map(tab => {
              return (
                <span
                  key={tab}
                  className={`${styles.tab} ${
                    brandArgs.tab === tab ? `${styles.active}` : ''
                  }`}
                  onClick={() => changeTab(tab)}>
                  {tab.replaceAll(' ', '')}
                </span>
              );
            })}
          </div>
          <div
            className={`${
              title == 'Pick' ? styles.listContentsZip : styles.listContents
            } ${type}`}>
            {type === 'brands' && (
              <Suspense fallback={<Loader />}>
                <BrandGroup />
              </Suspense>
            )}
            {type === 'zip' && <ZipGroup />}
            {type === 'news' && (
              <Suspense fallback={<Loader />}>
                <NewsGroup />
              </Suspense>
            )}
          </div>
        </div>
        <BottomNavigation />
        <MagazineCategoryModal />
      </div>
    </Layout.Main>
  );
}
