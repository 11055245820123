import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FaBullhorn} from 'react-icons/fa';
import {BsChevronRight} from 'react-icons/bs';
import {useRecoilValue} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import Layout from '@/layouts';
import {user} from '@/store';
import {getPayments} from '@/services/magazine';
import Fetcher from '@/components/common/Fetcher';
import FrequencyQuestion from '@/components/frequencyQuestion';
import LocationBottomSheet from '@/components/frequencyQuestion/LocationBottomSheet';

import styles from './styles.module.scss';
import GiftItem from './components/GiftItem';

export default function OrderBenefits() {
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);
  const [params] = useState<PaymentListParam>({
    offset: 0,
    type: 'benefits',
    pagination: 'true',
  });
  const [locationInfo, setLocationInfo] = useState<PlaceInfoProps>();
  const [benefits, setBenefits] = useState<OrderListItem[]>([]);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const getData = async () => {
    const res = await getPayments(params, accessToken);
    const orderedResponse = res.data.results;

    return orderedResponse;
  };
  return (
    <Layout.Main>
      <div style={{background: '#F7F7FC'}}>
        <ContentsHeader
          txt={'받은 할인권'}
          isOption="none"
          isBottomLine=""
          back={() => navigator('/user')}
        />

        <Fetcher fetcher={getData} setter={setBenefits} noLoader>
          <div className={styles.giftBeneftsNotice}>
            <FaBullhorn size={16} color="#424242" />
            <span style={{marginLeft: '8px'}}>
              현장 직원에게 <strong>할인권 사용</strong> 화면을 보여주세요
            </span>
          </div>
          <div className={styles.giftBenefitsCnts}>
            <span className={styles.cnts}>
              <strong>보유 할인권</strong>
              <span>{benefits.length}</span>
            </span>
            <span
              className={styles.questions}
              onClick={() => setOpenQuestion(true)}
              style={{cursor: 'pointer'}}>
              <span>자주 묻는 질문</span>
              <BsChevronRight size={12} />
            </span>
          </div>
          <div
            className={styles.order_list_wrapper}
            style={{minHeight: 'calc(100vh - 56px - 78px)'}}>
            {benefits && benefits.length === 0 ? (
              <div className={styles.noList} style={{marginTop: '100px'}}>
                <span className={styles.txt}>
                  {'보유중인 할인권이 없습니다.'}
                </span>
              </div>
            ) : (
              <div className={styles.list}>
                {benefits &&
                  benefits.map((d, idx) => {
                    return (
                      <GiftItem
                        key={`gift_item_${idx}`}
                        item={d}
                        type={'사용전'}
                        setLocationInfo={setLocationInfo}
                        setIsOpenLocation={setIsOpenLocation}
                      />
                    );
                  })}
              </div>
            )}
            {/* <GiftItem type={'사용완료'} />
          <GiftItem type={'만료'} /> */}
          </div>
        </Fetcher>
        <FrequencyQuestion
          isOpen={openQuestion}
          close={() => setOpenQuestion(!openQuestion)}
        />
        <LocationBottomSheet
          isOpen={isOpenLocation}
          placeInfo={locationInfo}
          close={() => setIsOpenLocation(false)}
        />
      </div>
    </Layout.Main>
  );
}
