import React, {Suspense, useEffect} from 'react';
import {useRecoilRefresher_UNSTABLE} from 'recoil';
import {useTranslation} from 'react-i18next';

import ContentsHeader from '@/layouts/ContentsHeader';
import {bookmarkItems} from '@/store/bookmark';
import Layout from '@/layouts';

import Pagination from '../common/Pagination';
import BookMarkList from './List';
import Loader from '../common/Loader';

export interface CntField {
  category: 'total' | 'brands' | 'zip' | 'news';
  bookmarkCount: number;
}
export interface ItemField {
  total: number;
  brands: number;
  zip: number;
  news: number;
}

export default function BookMark() {
  const refresh = useRecoilRefresher_UNSTABLE(bookmarkItems);
  const {t} = useTranslation(['components'], {keyPrefix: 'myKnewnew.BookMark'});

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Layout.Main>
      <div className="bookmark-wrapper">
        <ContentsHeader txt={t('headerTxt')} isOption={'none'} />
        <Suspense fallback={<Loader />}>
          <BookMarkList />
        </Suspense>
        <Pagination type={'bookmark'} />
      </div>
    </Layout.Main>
  );
}
