import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';

import {providerTypes} from '@/utils';

import {UserInfo} from '../../store/user';
import useStorage from '../../hooks/useStorage';

interface Props {
  doLogout: () => void;
}

export default function Menus({doLogout}: Props) {
  const {getLoginProvider} = useStorage();
  const userInfo = useRecoilValue(UserInfo);
  const navigator = useNavigate();
  const [korProviderType, setKorProviderType] = useState('');
  const getKorProviderTypes = async () => {
    const provider: keyof typeof providerTypes =
      (await getLoginProvider()) as keyof typeof providerTypes;
    setKorProviderType(providerTypes[provider]);
  };
  useEffect(() => {
    if (userInfo) {
      getKorProviderTypes();
    }
  }, [userInfo]);
  return (
    <div className="my-info-menus contents-body">
      <button
        className="my-info-menu btn"
        onClick={() => navigator('/push-config')}
        style={{
          display:
            Capacitor.getPlatform() !== 'web' && userInfo.id != -1
              ? 'flex'
              : 'none',
        }}>
        <span className="title">알림 설정</span>
        <span className="arrow-right2-icon">&nbsp;</span>
      </button>
      <button
        className="my-info-menu btn"
        onClick={() =>
          window.open(
            'https://knewnew.notion.site/f147749e136e42ecab4cdf1bec7270a1',
            '_blank',
          )
        }>
        <span className="title">이용 약관</span>
        <span className="arrow-right2-icon">&nbsp;</span>
      </button>
      <button className="my-info-menu btn" onClick={() => navigator('/qna')}>
        <span className="title">문의하기</span>
        <span className="arrow-right2-icon">&nbsp;</span>
      </button>
      {userInfo.id != -1 ? (
        <>
          <button className="my-info-menu btn" onClick={() => doLogout()}>
            <span className="title">로그아웃</span>
            <span className="arrow-right2-icon">&nbsp;</span>
          </button>
          <button
            className="my-info-menu btn with-text"
            style={{cursor: 'default'}}>
            <span className="title">로그인 정보</span>
            <div className="login-info">
              {korProviderType === '이메일' && (
                <div className="login-type">이메일 로그인</div>
              )}
              {korProviderType !== '이메일' && (
                <div className="login-type">SNS 로그인 ({korProviderType})</div>
              )}
              <div className="email">{userInfo ? userInfo.email : ''}</div>
            </div>
          </button>
        </>
      ) : (
        <button
          className="my-info-menu btn"
          onClick={() =>
            navigator('/login', {
              state: {
                prevPath: `${location.pathname}${location.search}`,
              },
            })
          }>
          <span className="title">로그인</span>
          <span className="arrow-right2-icon">&nbsp;</span>
        </button>
      )}
    </div>
  );
}
