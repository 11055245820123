import React from 'react';

import styles from './styles.module.scss';
import FilterModalHeader from '../FilterModal/FilterModalHeader';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
}

export default function Caution({isOpen, setIsOpen}: Props) {
  return (
    <div
      className={`modal-wrapper`}
      style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : ''}  ${
          styles.option_selector_wrapper
        }`}>
        <FilterModalHeader
          txt={'사용 유의사항'}
          close={() => setIsOpen(false)}
          fnTxt=""
          isHeaderPadding
        />
        <div className={styles.caution_txt}>
          • 서비스 이용을 위해 최초 1회 본인인증이 필요합니다.
          <br />• 쿠폰은 앱에서만 사용 가능합니다.
          <br />
          • 유효기간이 지난 쿠폰은 코드 등록이 불가합니다.
          <br />
          • 등록한 쿠폰은 재발급이 불가합니다.
          <br />
          • 등록된 쿠폰은 [마이페이지]-[쿠폰]에서 확인하실 수 있습니다.
          <br />
          • 쿠폰은 상품 구매 시에만 가능하며, 현금이나 적립금으로 환불은
          불가능합니다.
          <br />
          • 쿠폰은 유효기간 내에서만 사용가능하며, 유효기간이 지난 쿠폰은
          소멸됩니다.
          <br />• 쿠폰에 따라 할인이 적용되는 상품이 다를 수 있으며, 쿠폰 사용
          조건이 변경될 수 있습니다.
        </div>
      </div>
    </div>
  );
}
