import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {CommentType, isOpenLoginModal} from '../../../store';
import {user} from '../../../store/user';

interface Props {
  likeCount: number | undefined;
  commentCount: number | undefined;
  isLike: boolean | undefined;
  reviewId: number | undefined;
}

export default function FeedFooter({
  likeCount,
  commentCount,
  isLike,
  reviewId,
}: Props) {
  const navigator = useNavigate();
  const userName = useRecoilValue(user);
  const setCommentType = useSetRecoilState(CommentType);
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const makeReport = () => {
    if (!userName) {
      setLoginModal(true);
    }
  };
  const doLike = () => {
    if (!userName) {
      setLoginModal(true);
    }
  };
  const moveToComments = () => {
    setCommentType('foodlog');
    navigator(`/comments/${reviewId}`);
  };

  return (
    <div className="feed-footer">
      <div
        className="report-warning"
        style={{cursor: 'pointer'}}
        onClick={() => makeReport()}>
        <span className="icon-ciren"></span>
        <span className="report-text">광고,협찬 의심 신고</span>
      </div>
      <div className="feed-comments-wrapper">
        <div className="like" onClick={() => doLike()}>
          <span
            className={`icon-like ${isLike ? 'active' : ''}`}
            style={{cursor: 'pointer'}}></span>
          <span className="like-cnt">{likeCount ?? 0}</span>
        </div>
        <div className="comment">
          <span
            className="icon-comment"
            style={{cursor: 'pointer'}}
            onClick={() => moveToComments()}></span>
          <span className="comment-cnt">{commentCount ?? 0}</span>
        </div>
      </div>
    </div>
  );
}
