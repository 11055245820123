import React from 'react';
// import Chocolate from '../../../products/chocolate.svg';

export default function NewItem() {
  return (
    <div className="item-wrapper">
      <div className="image">
        {/* <img
          src={Chocolate}
          width={'130px'}
          height={'130px'}
          alt="chocolate 입니다"
        /> */}
      </div>
      <div className="item-contents">
        초콜릿은 이거지
        <br />
        파베초콜릿 맛있는 집
      </div>
      <div className="item-writer">뚜비언니</div>
    </div>
  );
}
