import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as Arrow} from '@/assets/icons/n-pic-how-to-use-arrow.svg';

import {PickBottomWrapper} from './npickheader.styled';

export default function NPickBottom() {
  const navigator = useNavigate();
  return (
    <PickBottomWrapper>
      <button
        className="flex justify-between items-center"
        onClick={() => navigator('/n-pick?detail=true')}
        style={{
          width: '100%',
          padding: '16px',
          border: '1px solid #E9E7EC',
          margin: '20px 20px 0 20px',
        }}>
        <span
          style={{
            color: '#433E48',
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: '500',
          }}>
          할인권 발급 및 사용 방법
        </span>
        <Arrow />
      </button>
    </PickBottomWrapper>
  );
}
