import React, {useMemo, useState, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useTranslation} from 'react-i18next';

import Layout from '@/layouts';
import ImageRail from '@/components/imageRail';
import NewBrandsLink from '@/components/newBrandsLink';
import Fetcher from '@/components/common/Fetcher';
import {getBrandsInHome} from '@/services/brands';
import {user} from '@/store/user';
import {useScroll} from '@/hooks/useScroll';

import styles from './styles.module.scss';

export interface BrandItems {
  [key: string]: {
    address1: number;
    address2: number;
    data: BrandItem[];
    info: string;
  };
}
export interface BrandAreaListItem {
  [key: string]: BrandItem[];
}

export default function BrandHome() {
  const {syncScroll} = useScroll();
  const navigator = useNavigate();

  const accessToken = useRecoilValue(user);
  const newBrandList = [];
  const {t} = useTranslation(['views'], {keyPrefix: 'Home'});

  const [homeItems, setHomeItems] = useState<BrandItems>({});

  const areaItems: BrandAreaListItem = useMemo(() => {
    const keys = Object.keys(homeItems);
    const areas = keys.map(area => {
      return {
        areaKey: area.split('·')[0],
        origin: area,
      };
    });
    const map: BrandAreaListItem = {};
    areas.forEach(area => {
      const k = area.origin;

      if (k.length > 0) {
        map[area.origin] = homeItems[k].data;
      }
    });
    return map;
  }, [homeItems]);

  const getData = async () => {
    try {
      const res = await getBrandsInHome(accessToken, 0, 20);
      const famousAddress: FamousAddress[] = [];
      const keys = Object.keys(res.data);
      keys.forEach((k, i) => {
        if (res.data[k].data.length > 0) {
          famousAddress.push({
            id: i,
            address: k,
            address1: res.data[k].address1 || -1,
            address2: res.data[k].address2 || -1,
          });
        }
      });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);

  return (
    <Layout.Main>
      <div className={styles.contents} style={{background: '#F7F7FC'}}>
        <Layout.Header
          title={t('headerTxt')}
          isBrand={true}
          isNoRing={true}
          isWhite={true}
        />
        <Fetcher
          fetcher={getData}
          setter={setHomeItems}
          syncScroll={syncScroll}>
          <div style={{marginBottom: '10px'}}>
            <NewBrandsLink
              title={t('linkTitle')}
              description={t('linkDesc')}
              linkText={`${newBrandList.length + t('linkTxt')}`}
              linkCallback={() =>
                navigator('/brands/new', {
                  state: {
                    prev: 'brand',
                  },
                })
              }
            />
          </div>
          <div className={styles.brandList}>
            {Object.keys(areaItems).map(key => {
              const totalCnt = areaItems[key].length;
              const imgs = areaItems[key].slice(0, 5).map(item => {
                return {
                  id: item.id,
                  parentId: item.parentId,
                  title: item.title,
                  url: item.imageList[0] ?? '',
                  description: item.info,
                };
              });
              const addressInfo = {
                address1: homeItems[key].address1 || -2,
                address2: homeItems[key].address2 || -2,
              };
              return totalCnt > 0 ? (
                <ImageRail
                  key={`area_items_key_${key}`}
                  title={key}
                  subtitle={homeItems[key].info}
                  titleWrapperStyle={{paddingRight: '20px'}}
                  titleStyle={{padding: '0 20px'}}
                  link={'/'}
                  images={imgs}
                  totalCnt={totalCnt}
                  callbackItemClick={(item: any) => {
                    navigator(`/brand/${item.parentId}/${item.id}`, {
                      state: {
                        prev: 'brand',
                      },
                    });
                  }}
                  callbackMoreIcon={() => {
                    navigator(
                      `/brands/${addressInfo.address1}/${addressInfo.address2}`,
                      {
                        state: {
                          prev: 'brand',
                        },
                      },
                    );
                  }}
                />
              ) : (
                <div></div>
              );
            })}
          </div>
        </Fetcher>
        <Layout.BottomNavigation />
      </div>
    </Layout.Main>
  );
}
