import React, {useEffect, useMemo, useState} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';

import Element from '@/components/marketNumber';
import ContentsHeader from '@/layouts/ContentsHeader';
import AuthModal from '@/components/common/AuthModal';
import {user} from '@/store';
import Layout from '@/layouts';
import {addComma} from '@/utils';
import {alterPayments, checkMarketNumber} from '@/services/magazine';

import styles from './gift.module.scss';

export default function UseGiftItem() {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const param = useParams();
  const [step, setStep] = useState<number>(2);
  const [item, setItem] = useState<CollectionListItem>();
  // const [brandName, setBrandName] = useState('');
  const [discountAmt, setDiscountAmt] = useState(0);
  const [amount, setAmount] = useState(0);
  const [benefitDiscountRatio, setBenefitDiscountRatio] = useState(0);
  const [minAmt, setMinAmt] = useState(0);
  const [maxDiscountAmt, setMaxDiscountAmt] = useState(0);
  const [isOpenNotice, setIsOpenNotice] = useState(true);
  const [isShowMinAmt, setIsShowMinAmt] = useState(false);
  const accessToken = useRecoilValue(user);
  const location = useLocation();
  const [numbers, setNumbers] = useState(['', '', '', '']);
  const [isOpenFailModal, setIsOpenFailModal] = useState(false);
  const keyNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '00', 0, 'back'];
  const secondKeyNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, 'back'];
  const enteredLength = useMemo(() => {
    let idx = 0;
    numbers.forEach((n, i) => {
      if (n) {
        idx = i;
      }
    });
    return idx;
  }, [numbers]);
  const keydown = (txt: string) => {
    let idx = 0;
    const _numbers = [...numbers];
    if (step === 1) {
      if (txt === 'back') {
        setAmount(Math.floor(amount / 10));
      } else {
        const amt = Number(`${amount === 0 ? '' : amount}` + txt);
        const maxDiscount =
          maxDiscountAmt == 0 ? Number.MAX_SAFE_INTEGER : maxDiscountAmt;
        const discountAmt =
          amt * (benefitDiscountRatio / 100) < maxDiscount
            ? amt * (benefitDiscountRatio / 100)
            : maxDiscountAmt;
        setAmount(amt);
        setDiscountAmt(Math.floor(discountAmt));
      }
    } else {
      if (txt !== 'back') {
        for (let i = 0, max = _numbers.length; i < max; i++) {
          if (!_numbers[i]) {
            idx = i;
            break;
          }
        }
        _numbers[idx] = txt;
      } else {
        _numbers.forEach((n, i) => {
          if (n) {
            idx = i;
          }
        });
        _numbers[idx] = '';
      }
      setNumbers(_numbers);
    }
  };
  const moveToStep1 = () => {
    const id = param.id;
    navigator(`/order/gift/use/${id}?step=1`, {
      state: {
        ...location.state,
      },
    });
  };
  const moveToStep2 = () => {
    const id = param.id;
    navigator(`/order/gift/use/${id}?step=2`, {
      state: {
        ...location.state,
      },
    });
  };
  const confirm = async () => {
    try {
      const code = numbers.join('');
      const params: MarketNumberParam = {
        checkCode: code,
        id: Number(param.id as string),
      };
      const res = await checkMarketNumber(accessToken, params);
      const _orderItem = {...location.state.item};
      _orderItem.usedStatus = res.data.usedStatus;
      const _params: PaymentRequestParam = {
        orderId: item?.orderId || '',
        popup: item?.popup?.id,
        option: item?.option,
        orderName: _orderItem.orderName || '',
        paymentKey: item?.orderId || '',
        purchaseCount: 1,
        purchaseAmount: amount,
        discountAmount: discountAmt,
      };
      await alterPayments(Number(param.id || 0), _params, accessToken);
      navigator('/order/gift/success', {
        state: {
          id: Number(param.id || 0),
        },
      });
    } catch (e) {
      console.log(e);
      setIsOpenFailModal(true);
    }
  };
  useEffect(() => {
    if (searchParams.get('step')) {
      setStep(Number(searchParams.get('step')));
    }
    // step=2 인데 amount가 0(새로고침 등)이면 step1로 변경
    if (Number(searchParams.get('step')) === 2 && amount === 0) {
      moveToStep1();
    }
  }, [searchParams]);
  useEffect(() => {
    if (location.state && location.state.item) {
      const _item = {...location.state.item};
      _item['imageUrl'] = location.state.imgUrl;
      setItem(_item);
      setMinAmt(_item.options[0].benefitMinAmount || 0);
      // setBrandName(location.state?.brandName);
      setBenefitDiscountRatio(_item.options[0].benefitDiscountRatio || 0);
      setMaxDiscountAmt(_item.options[0].benefitMaxDiscount || 0);
    }
  }, [location]);
  useEffect(() => {
    if (amount > 0 && amount < minAmt) {
      setIsShowMinAmt(true);
    } else {
      setIsShowMinAmt(false);
    }
  }, [amount]);
  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt={'할인권 사용'}
          isOption={'none'}
          isBottomLine={''}
          back={() => navigator(-1)}
        />
        <div className={styles.giftBeneftsNotice}>
          <div className={styles.notice_num}>{step}</div>
          <span className={styles.notice_txt}>
            {step === 2 ? (
              '할인 적용된 최종 금액으로 현장에서 결제해 주세요'
            ) : (
              <span>본 화면을 직원에게 보여주세요</span>
            )}
          </span>
        </div>
        {step === 1 && (
          <div className={styles.inner}>
            <div style={{padding: '16px 20px'}}>
              {item && (
                <div className={styles.itemWrapper}>
                  <div className={styles.imgWrapper}>
                    <img
                      src={item?.popup?.bannerImageUrl}
                      alt="item image url"
                      height={'80px'}
                    />
                  </div>
                  <div className={styles.txtWrapper}>
                    <div className={styles.titWrapper}>{item.orderName}</div>
                    <div className={styles.contWrapper}>
                      {minAmt === 0 ? (
                        <span>최소 주문 금액 제한없음</span>
                      ) : (
                        <span>{addComma(minAmt)}원 이상 결제 시</span>
                      )}
                      {maxDiscountAmt === 0 ? (
                        <span>최대 할인 금액 제한없음</span>
                      ) : (
                        <span>최대 {addComma(maxDiscountAmt)}원 할인</span>
                      )}
                      <span>메뉴 제한 없음</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.numberWrapper}>
              <h3>
                <span style={{color: '#FF3E48'}}>할인 적용 전</span> 주문 금액을
                입력해 주세요
              </h3>
              <div
                className={`${styles.numbers} ${amount > 0 && styles.entered}`}>
                {addComma(amount)}원
              </div>
              {isShowMinAmt && minAmt > 0 && (
                <div className={styles.warningNums}>
                  최소 {addComma(minAmt)}원 이상
                </div>
              )}
            </div>
            <div
              className={styles.bottomWrapper}
              style={{
                paddingBottom: Capacitor.getPlatform() === 'ios' ? '30px' : '0',
              }}>
              <Element.GiftKeyboardWrapper>
                {keyNumbers.map(k => {
                  return (
                    <Element.GiftKey
                      key={`numbers_element_${k}`}
                      txt={`${k}`}
                      onClick={keydown}
                    />
                  );
                })}
              </Element.GiftKeyboardWrapper>
              <Element.GiftConfirmBtnWrapper
                style={{marginTop: '10px !important'}}>
                <Element.ConfirmBtn
                  tit="다음"
                  disabled={amount === 0 || amount < minAmt}
                  onClick={() => moveToStep2()}
                />
              </Element.GiftConfirmBtnWrapper>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className={styles.inner}>
            <div style={{padding: '16px 20px'}}>
              <ul className={styles.pricePanel}>
                <li className={styles.final}>
                  <span>최종결제금액</span>
                  <span>{addComma(amount - discountAmt)}원</span>
                </li>
                <li>
                  <span>∙ 주문 금액</span>
                  <span>{addComma(amount)}원</span>
                </li>
                <li>
                  <span>∙ 할인 금액</span>
                  <span>-{addComma(discountAmt)}원</span>
                </li>
              </ul>
            </div>
            <div className={styles.numberWrapper}>
              <div>
                <strong>직원 확인 번호</strong>를 입력해 주세요
              </div>
              <div className={styles.numbers}>
                {numbers.map((n, i) => {
                  return (
                    <Element.InputElement
                      key={`number_${i}`}
                      txt={n}
                      active={(i > 0 && !!numbers[i]) || i === 0}
                    />
                  );
                })}
              </div>
              <div className={styles.warningNums}>
                * 직원 확인 번호를 입력해야 해당 할인권이 사용 처리됩니다.
              </div>
            </div>
            <div
              className={styles.bottomWrapper}
              style={{
                paddingBottom: Capacitor.getPlatform() === 'ios' ? '30px' : '0',
              }}>
              <Element.GiftKeyboardWrapper>
                {secondKeyNumbers.map(k => {
                  return (
                    <Element.GiftKey
                      key={`numbers_element_${k}`}
                      txt={`${k}`}
                      onClick={keydown}
                    />
                  );
                })}
              </Element.GiftKeyboardWrapper>

              <Element.GiftConfirmBtnWrapper
                style={{marginTop: '10px !important'}}>
                <Element.ConfirmBtn
                  disabled={enteredLength !== 3}
                  onClick={confirm}
                  tit={'할인권 사용 완료'}
                />
              </Element.GiftConfirmBtnWrapper>
            </div>
          </div>
        )}
        <AuthModal
          txt={
            <>
              확인 코드가 올바르지 않습니다.
              <br />
              다시 입력해 주세요.
            </>
          }
          isOpen={isOpenFailModal}
          setIsOpen={setIsOpenFailModal}
          btnType={'error'}
          action={() => setIsOpenFailModal(false)}
        />
        <AuthModal
          txt={
            <>
              매장 결제 시 본 화면을
              <br />
              직원에게 보여주세요
            </>
          }
          isOpen={isOpenNotice}
          setIsOpen={setIsOpenNotice}
          btnType={'error'}
          action={() => setIsOpenNotice(false)}
        />
      </div>
    </Layout.Main>
  );
}
