/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';
import Layout from '@/layouts';

import styles from './styles.module.scss';

interface MetaInfo {
  title: string;
  imageUrl: string;
  info: string;
  counts: string;
}

export default function BenefitReceivedPage() {
  const navigator = useNavigate();
  const location = useLocation();
  const [metaInfo, setMetaInfo] = useState<MetaInfo>();
  useEffect(() => {
    if (location.state) {
      const _metaInfo = {
        title: (location.state.benefit as MagazineItem).title,
        imageUrl: (location.state.benefit as MagazineItem).imageUrl,
        info: location.state.benefit.info,
        counts: '수량 1개',
      };
      setMetaInfo(_metaInfo);
    }
  }, [location.state]);
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      navigator(`/benefit/${(location.state.magazine as MagazineItem).id}`);
    });
  });

  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt={'혜택권 받기 완료'}
          isPopup
          isOption="none"
          isBottomLine=""
          back={() => {
            navigator(
              `/benefit/${(location.state.benefit as MagazineItem).id}`,
              {
                replace: true,
                state: {
                  prev: 'home',
                  ...location.state,
                },
              },
            );
          }}
        />
        {metaInfo && (
          <div className={styles.success_info}>
            <div className={styles.success_message}>
              <div className={styles.check_wrapper}>
                {/* <Check size={28} color={'#000'} /> */}
              </div>
              <div className={styles.message}>혜택권 받기 완료</div>
              <div className={styles.product_info}>
                <div className={styles.header}>
                  <h2>사용 전</h2>
                </div>
                <div className={styles.product}>
                  <div
                    className={styles.img_wrapper}
                    style={{
                      borderRadius: '2px',
                      background: `url('${metaInfo.imageUrl}') no-repeat center / cover`,
                    }}
                    onClick={() =>
                      navigator(
                        `/benefit/${
                          (location.state.magazine as MagazineItem).id
                        }`,
                        {
                          state: {
                            prev: 'home',
                            benefit: location.state.magazine,
                          },
                        },
                      )
                    }></div>
                  <div className={styles.txt_wrapper}>
                    <h2>{metaInfo.title}</h2>
                    <div className={styles.option_info}>
                      {/* {options.map((option, idx) => {
                        return (
                          <div
                            className={styles.option}
                            key={`${option}_${idx}`}>
                            {option}
                          </div>
                        );
                      })} */}
                      <div className={styles.option}>{metaInfo.counts}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.benefitUseNotice}>
              <h2>혜택권 사용 안내</h2>
              <div style={{marginTop: '20px'}}>
                <div>{'① 마이 > 스페셜 혜택권에서 혜택 내역 확인'}</div>
                <div>{'② 유효 기간 내에 매장에 방문'}</div>
                <div>{'③ 매장에서 직원 확인 요청 및 사용'}</div>
                <div>{'④ 메뉴 할인권의 경우 매장에서 할인가 현장 결제'}</div>
                <div>{'*사용 기간이 만료된 혜택권은 자동 소멸됩니다'}</div>
              </div>
            </div>
            <div className={styles.btn_wrapper}>
              <button
                className={styles.btn_home}
                onClick={() => navigator('/home')}>
                홈으로
              </button>
              <button
                className={styles.btn_buy_list}
                onClick={() => navigator('/order/benefits')}>
                받은 혜택 내역
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout.Main>
  );
}
