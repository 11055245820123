import React, {useEffect, useState} from 'react';
import {BiCheckCircle} from 'react-icons/bi';
import {useLocation, useNavigate} from 'react-router-dom';

import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';

import styles from './success_gift_use.module.scss';

export const SuccessGiftUse = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const [id, setId] = useState<number>();

  useEffect(() => {
    if (location && location.state) {
      setId(location.state.id);
    }
  }, [location]);

  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt={'할인권 사용 완료'}
          isPopup
          isOption="none"
          isBottomLine=""
          noBack
        />
        <div style={{marginTop: '80px'}}>
          <div className={styles.checkIcon}>
            <div className={styles.txtWrapper}>
              <BiCheckCircle size={32} color="white" fill={'black'} />
              <div className={styles.txt}>할인권 사용이 완료되었습니다</div>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <button
              className={styles.toHome}
              onClick={() => navigator('/home')}>
              홈으로
            </button>
            <button
              className={styles.toUsedList}
              onClick={() => navigator(`/order/gift/detail/${id}`)}>
              사용내역
            </button>
          </div>
        </div>
      </div>
    </Layout.Main>
  );
};
