import React from 'react';
import {useSearchParams} from 'react-router-dom';

import ContentsHeader from '../../../layouts/ContentsHeader';
import Layout from '../../../layouts';

function PaymentSuccess() {
  const [searchParams] = useSearchParams();

  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader txt={'결제하기'} isOption={'none'} isBottomLine={''} />
        <div>{`주문 아이디: ${searchParams.get('orderId')}`}</div>
        <div>{`결제 금액: ${Number(
          searchParams.get('amount'),
        ).toLocaleString()}원`}</div>
      </div>
    </Layout.Main>
  );
}

export default PaymentSuccess;
