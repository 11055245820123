import heic2any from 'heic2any';

export default function useCanvas() {
  const dataURIToBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  };
  const readFile = (
    file: File,
    callback: (fileSrc: string | ArrayBuffer | null) => void,
  ) => {
    const fr = new FileReader();
    fr.onload = () => {
      const result = fr.result;
      callback(result);
    };
    fr.readAsDataURL(file);
  };
  const translateJPGFromHeic = async (
    file: File,
    callback: (dataurl: string) => void,
  ) => {
    try {
      const jpegData = await heic2any({
        blob: file,
        toType: 'image/jpeg',
      });
      const fileReader = new FileReader();
      fileReader.onload = () => {
        callback(fileReader.result as string);
      };
      fileReader.readAsDataURL(jpegData as Blob);
    } catch (e) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        callback(fileReader.result as string);
      };
      fileReader.readAsDataURL(file as Blob);
    }
  };
  const translateImg = (
    srcFile: File,
    type: 'profile' | 'community',
    callback: (file: File) => void,
  ) => {
    const iphoneImgs = ['heic', 'HEIC', 'heif', 'HEIF'];
    const canvas = document.getElementById('fakeCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const isHeic = iphoneImgs.indexOf(srcFile.name.split('.')[1]) > -1;
    const maxWidth = type === 'profile' ? 400 : 1024;
    if (isHeic) {
      translateJPGFromHeic(srcFile, dataurl => {
        img.onload = function () {
          if ((this as any).width > maxWidth) {
            const ratio = maxWidth / (this as any).width;
            canvas.width = maxWidth;
            canvas.height = (this as any).height * ratio;
          } else {
            canvas.width = (this as any).width;
            canvas.height = (this as any).height;
          }
          ctx?.drawImage(this as any, 0, 0, canvas.width, canvas.height);
          const imgData = canvas.toDataURL('image/jpeg');
          const blob = dataURIToBlob(imgData);
          const file = new File([blob], `${srcFile.name.split('.')[0]}.jpeg`);
          callback(file);
        };
        img.src = dataurl;
      });
    } else {
      readFile(srcFile, fileSrc => {
        img.onload = function () {
          if ((this as any).width > maxWidth) {
            const ratio = maxWidth / (this as any).width;
            canvas.width = maxWidth;
            canvas.height = (this as any).height * ratio;
          } else {
            canvas.width = (this as any).width;
            canvas.height = (this as any).height;
          }
          ctx?.drawImage(this as any, 0, 0, canvas.width, canvas.height);
          const imgData = canvas.toDataURL('image/jpeg');
          const blob = dataURIToBlob(imgData);
          const file = new File([blob], `${srcFile.name.split('.')[0]}.jpeg`);
          callback(file);
        };
        img.src = fileSrc as string;
      });
    }
  };

  return {
    translateImg,
  };
}
