import React from 'react';

interface Props {
  fontSize?: number;
  fill?: string;
}

export default function OverlayHome({fontSize, fill}: Props) {
  return (
    <svg
      width={`${fontSize || 20}`}
      height={`${fontSize || 20}`}
      viewBox={`0 0 ${fontSize || 20} ${fontSize || 20}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="home">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0001 2.39062L19.3517 8.53091L18.5284 9.78478L17.3095 8.9844V17.457H2.68945V8.98522L1.47173 9.78478L0.648438 8.53091L10.0001 2.39062ZM10.0001 4.18507L15.8095 7.9995V15.957H11V13.7461H9V15.957H4.18945V8.00033L10.0001 4.18507Z"
          fill={fill || 'white'}
        />
      </g>
    </svg>
  );
}
