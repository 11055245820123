import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

interface Props {
  item: BrandListItemType;
}

export default function KnewBrand({item}: Props) {
  // const parser = new DOMParser();
  const navigator = useNavigate();
  const sender = useLogger();

  // const product = useMemo(() => {
  //   return item.product;
  // }, [item]);
  const brand = useMemo(() => {
    return item;
  }, [item]);
  // const parsed = parser.parseFromString(brand?.info || '', 'text/html');

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        navigator(`/brand/${item.parentId}/${item.id}`, {
          state: {
            prev: location.pathname.replace('/', ''),
          },
        });
        sender({
          _msg: 'homeDaysuv_' + item.id,
          _screen: window.location.pathname,
          _action: Action.CLICK,
        });
      }}>
      {/* 매거진 */}
      <div
        className={styles.card}
        onClick={() => {
          sender({
            _msg: 'cuMain_' + brand?.id,
            _screen: window.location.pathname,
            _action: Action.CLICK,
          });
        }}>
        <div className={styles.imgWrapper}>
          <img
            src={
              brand?.collectionImage
                ? brand?.collectionImage
                : brand?.imageList[0]
            }
          />
        </div>
        <div className={styles.contents_title_wrapper}>
          <span className={styles.title}>{item.collectionTitle}</span>
          {/* <span className={`${styles.icon} stick-arrow-right-icon`}>
            &nbsp;
          </span> */}
        </div>
        <div className={styles.contents}>{item.collectionDesc}</div>
        {/* 컬렉션 */}
        {/* <BrandGiftItem item={item.product} clickable={false} /> */}
      </div>
    </div>
  );
}
