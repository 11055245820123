import React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {isOpenLoginModal} from '../../../store';

export default function LoginModal() {
  const navigator = useNavigate();
  const openModal = useRecoilValue(isOpenLoginModal);
  const setOpenModal = useSetRecoilState(isOpenLoginModal);
  const moveToLogin = () => {
    setOpenModal(false);
    navigator('/login', {
      state: {
        prevPath: `${location.pathname}${location.search}`,
      },
    });
  };
  return (
    <div
      className="modal-wrapper"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        className="modal-contents"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="close-btns"
          style={{display: 'flex', justifyContent: 'flex-start'}}>
          <span
            className="icon close-gray-icon"
            style={{
              width: '14px',
              height: '14px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(false)}>
            &nbsp;
          </span>
        </div>
        <div
          className="login-modal-header"
          style={{
            marginTop: '25px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'left',
          }}>
          로그인시 이용할 수 있어요!
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '23px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}>
          가입에 걸리는 시간 딱 10초!
          <br /> 지금 뉴뉴 회원이 되면 더 많은 푸드 콘텐츠와 기능을 즐
          <br />길 수 있어요. 저희와 함께 하실래요?
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '30px'}}>
          <button
            className="modal btn main-pink-filled"
            style={{fontWeight: 600, fontSize: '14px'}}
            onClick={() => moveToLogin()}>
            네, 로그인 할래요
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => setOpenModal(false)}>
            아니요, 그만 볼래요
          </button>
        </div>
      </div>
    </div>
  );
}
