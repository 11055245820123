import React from 'react';

import HashTag from '../../common/HashTag';

interface Props {
  tags: string[] | undefined;
}

export default function FLHashTags({tags}: Props) {
  return (
    <div className="hash-tag-wrapper">
      {tags &&
        tags.map((h, idx) => {
          return <HashTag key={`${h}_${idx}`} text={h} />;
        })}
    </div>
  );
}
