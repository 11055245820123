import Axios, {AxiosResponse} from 'axios';

import {errorInterceptor} from '@/utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

export const getCouponList = (
  token: string | undefined,
  offset: number,
): Promise<AxiosResponse<Coupon[]>> => {
  return axios.get('/coupon/my/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: 20,
      offset,
    },
  });
};

export const requestAuthIdentify = (
  token: string,
  data: {
    ci: string;
    name: string;
    birth: string;
    gender: string;
    phone: string;
  },
) => {
  const {ci, name, birth, gender, phone} = data;
  return axios.post(
    '/auth/identify/',
    {
      ci,
      name,
      birth,
      gender,
      phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const applyCouponCode = (accessToken: string, code: string) => {
  return axios.post(
    '/coupon/code/',
    {
      code,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const getCertTryCnt = (accessToken: string) => {
  return axios.get('/auth/identify/try', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
