import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import ItemCard from '@/components/curator/ItemCard';
import ContentsHeader from '@/layouts/ContentsHeader';
import {getCuratorInfo, getCuratorsMagazines} from '@/services/magazine';

import styles from './styles.module.scss';

export default function CuratorInfo() {
  const navigator = useNavigate();
  const [userInfo, setUserInfo] = useState<Curator>();
  const [curatorsItems, setCuratorsItems] = useState<MagazineItem[]>([]);
  const [maxCnt, setMaxCnt] = useState(0);
  const [magazineLength, setMagazineLength] = useState(20);
  const [updateList, setUpdateList] = useState(false);

  const _getCuratorInfo = async (page: number) => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    const res = await getCuratorInfo(id);
    const contents = await getCuratorsMagazines(id, page * 20);
    setMaxCnt(contents.count);
    setCuratorsItems(contents.results);
    setUserInfo(res);
  };
  const _updateCuratorMagazines = async () => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    if (maxCnt > magazineLength) {
      const magazines = await getCuratorsMagazines(id, magazineLength + 20);
      setMagazineLength(magazineLength + 20);
      setCuratorsItems(magazines.results);
      setUpdateList(false);
    }
  };
  const onScroll = (event: any) => {
    const clientHeight = event.target.scrollingElement.clientHeight;
    const currentY = window.scrollY + clientHeight;
    const maxY = document.querySelector('.curator-info-wrapper')?.clientHeight;
    if (Math.floor(currentY) === Math.floor(maxY as number)) {
      setUpdateList(true);
    }
  };
  const moveToLink = (sns: string) => {
    const link = userInfo
      ? userInfo.snsInfo[sns as keyof typeof userInfo.snsInfo]
      : '';
    if (link && link.length > 0) {
      window.open(link.startsWith('http') ? link : `https://${link}`, '_blank');
    }
  };

  useEffect(() => {
    if (updateList) {
      _updateCuratorMagazines();
    }
  }, [updateList]);
  useEffect(() => {
    _getCuratorInfo(1);
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return userInfo ? (
    <div className={`main ${styles.curatorInfoWrapper}`}>
      {/** <Header /> */}
      <ContentsHeader txt={userInfo.nickname} isOption="none" />
      <div className={styles.curatorInfo}>
        <div className={styles.profile}>
          <div
            className={styles.curatorImage}
            style={{
              background: `center / cover no-repeat url(${userInfo.profileImage})`,
            }}></div>
          <div className={styles.txtInfo}>
            <div className={styles.nickName}>{userInfo.nickname}</div>
            <div className={styles.hashtags}>
              {userInfo.userTags?.profile?.map(tag => {
                return (
                  <span className={styles.tag} key={`tag_userinfo_${tag}`}>
                    #{tag}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.message}>{userInfo.headline}</div>
        <div className={styles.sns}>
          <div className="sns-links">
            <span
              className={`${styles.snsIcon} sns-icon youtube ${
                userInfo.snsInfo.youtube && userInfo.snsInfo.youtube.length > 0
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={() => moveToLink('youtube')}></span>
            <span
              className={`${styles.snsIcon} sns-icon instagram ${
                userInfo.snsInfo.instagram &&
                userInfo.snsInfo.instagram.length > 0
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={() => moveToLink('instagram')}></span>
            <span
              className={`${styles.snsIcon} sns-icon naver-blog ${
                userInfo.snsInfo.naver && userInfo.snsInfo.naver.length > 0
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={() => moveToLink('naver')}></span>
          </div>
        </div>
      </div>
      <div className={styles.curatorCreatedItems}>
        <div className="item-list" style={{width: '100%'}}>
          <InfiniteScroll
            pageStart={1}
            loadMore={(page: number) => {
              _getCuratorInfo(page);
            }}
            hasMore={true}>
            {curatorsItems.map((item, i) => {
              return <ItemCard key={`curator_created_teim_${i}`} item={item} />;
            })}
          </InfiniteScroll>
        </div>
        <div
          className={styles.noItems}
          style={{display: curatorsItems.length > 0 ? 'none' : 'block'}}>
          <div className={styles.noItemTxt}>아직 작성한 콘텐츠가 없습니다.</div>
          <div className={styles.noItemBtn}>
            <button
              className={styles.moveToList}
              onClick={() => navigator('/curators')}>
              다른 큐레이터 보기
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
