import {atom, selector} from 'recoil';

import {getBrandRecommends, getMagazine} from '../services/magazine';
import {user} from './user';

const getMagazines = async (
  token: string,
  tab: string,
  limit?: number,
  offset?: number,
) => {
  const res = await getMagazine(
    token,
    limit ?? 20,
    'brands',
    offset ?? 0,
    '',
    tab === 'All' ? '' : tab, // for brand category filtering
  );
  const magazines = res.data.results as MagazineItem[];
  return magazines;
};
const setupBrandStateData = (
  magazines: MagazineItem[],
  recommends: RecommendItem[],
  tab: string,
) => {
  let brands: any[] = [];
  const groupLength = Math.floor(magazines.length / 3);
  if (groupLength > 0 && tab === 'All') {
    for (let i = 0; i < groupLength; i++) {
      const m = magazines.slice(i * 3, i * 3 + 3);
      if (recommends.length > 0) {
        brands = [...brands, ...m, recommends[i]];
      }
    }
  }

  return {
    brandGroups: tab === 'All' ? brands : magazines,
    magazineItems: magazines,
    recommendItems: recommends,
  };
};

export const listTypes = atom<ListType[]>({
  key: 'cardLists/listTypes',
  default: [
    {
      type: 'brands',
      tabs: [
        'All',
        'Cafe',
        'Restaurant',
        'Bar / Pub',
        'Market',
        'Bakery',
        'Class',
      ],
      logo: '/assets/icons/brand-logo.svg',
      video: '/videos/knewbrands.mp4',
      title: 'KNEW BRANDS',
      subtitle: '맛과 멋이 있는\nF&B 브랜드 이야기',
    },
    {
      type: 'zip',
      tabs: ['All', 'Place', 'Product'],
      logo: '/assets/icons/zip-logo.svg',
      video: '/videos/knewzip.mp4',
      title: 'KNEW ZIP',
      subtitle: '감도높은 테마별\nF&B 브랜드 큐레이션',
    },
    {
      type: 'news',
      tabs: ['All', 'Issue', 'Archive', 'Story'],
      logo: '/assets/icons/news-logo.svg',
      video: '/videos/knewnews.mp4',
      title: 'KNEW NEWS',
      subtitle: '놓치기엔 아쉬운,\n트렌디한 F&B 브랜드 소식',
    },
  ],
});

export const brandItems = atom<BrandItems>({
  key: 'cardList/brandItems',
  default: {
    magazineItems: [],
    recommendItems: [],
  },
});

export const brandItemsState = selector<BrandItems>({
  key: 'cardList/brandItemState',
  get: async ({get}) => {
    const token = get(user);
    const args = get(brandsArgs);
    const magazines = get(magazineItems);
    const brands = await getMagazines(token, args.tab, args.limit, args.offset);
    const items = setupBrandStateData(brands, magazines, args.tab);
    return items;
  },
});

export const magazineItems = selector<RecommendItem[]>({
  key: 'cardList/recommendItems',
  get: async () => {
    const res = await getBrandRecommends();
    const recommends = res.data as RecommendItem[];
    const result = recommends.map(recommend => ({
      title: recommend.title,
      subtitle: recommend.subtitle,
      items: (recommend.magazines as MagazineItem[]).map(
        magazine => magazine.imageUrl,
      ),
      magazines: recommend.magazines,
      type: 'recommend',
    }));
    return result as RecommendItem[];
  },
});

export const brandsArgs = atom<{offset: number; limit: number; tab: string}>({
  key: 'cardList/brandItemsArgs',
  default: {
    offset: 0,
    limit: 20,
    tab: 'All',
  },
});

export const zipItems = atom<MagazineItem[]>({
  key: 'cardList/zipItems',
  default: [],
});

export const newsItems = selector<MagazineItem[]>({
  key: 'cardList/newsItems',
  get: async ({get}) => {
    const args = get(newArgsState);
    const userAccessToken = get(user);
    const res = await getMagazine(
      userAccessToken,
      args.limit ?? 20,
      'news',
      0,
      args.tab === 'All' ? '' : args.tab.toLowerCase(),
    );
    const magazines = res.data.results as MagazineItem[];
    return magazines;
  },
});

export const newArgsState = atom<{
  limit: number;
  tab: string;
}>({
  key: 'news/args',
  default: {
    limit: 20,
    tab: 'All',
  },
});
