import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {AxiosResponse} from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import {useNavigate} from 'react-router-dom';

import {getMagazineCollectionItems} from '@/services/magazine';
import {user} from '@/store/user';
import InstallAlert from '@/components/installAlert';
import CollectionLoader from '@/components/common/Skeleton/CollectionLoader';

import CollectionItem from './Item';
import styles from '../styles.module.scss';

interface Props {
  tab: string;
  setTab: (t: string) => void;
  promotionId?: number;
  type?: string;
  magazines?: CollectionListItem[];
}
export default function CollectionLists({promotionId, type, magazines}: Props) {
  const navigator = useNavigate();
  const lastElementRef = useRef<HTMLDivElement>(null);
  // const {t} = useTranslation(['views'], {keyPrefix: 'collection.Lists'});

  const accessToken = useRecoilValue(user);

  const [itemList, setItemList] = useState<CollectionListItem[]>([]);
  const [openInstallAlert, setOpenInstallAlert] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = [...itemList];
    _items[idx].isBookmark = isLike;
    setItemList(_items);
  };

  const loadImage = (imgUrl: string) => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        resolve('');
      };
      img.src = imgUrl;
    });
  };

  const loadAllImages = (images: string[]) => {
    const loadImageFnList = images.map(img => {
      return loadImage.bind(img);
    });
    return new Promise(resolve => {
      Promise.all(loadImageFnList).then(() => {
        resolve('');
      });
    });
  };

  const getData = async (offset: number) => {
    let _magazines: CollectionListItem[] = [];
    if (promotionId && magazines) {
      await loadAllImages(
        magazines.map((res: CollectionListItem) => {
          return res.imageUrl;
        }),
      );
      setItemList(magazines);
      setMaxCount(magazines.length);
    } else if (type) {
      const res: AxiosResponse<Promotion[]> = await getMagazineCollectionItems(
        accessToken,
        type,
        offset || 0,
      );
      _magazines = res.data[0].magazines;
      setMaxCount(res.data[0].magazinesCount || 0);
      await loadAllImages(
        _magazines.map((res: CollectionListItem) => {
          return res.imageUrl;
        }),
      );
      setItemList([...itemList, ..._magazines]);
    }
  };

  useEffect(() => {
    if (maxCount > 0 && itemList.length > 0) {
      setHasMore(itemList.length < maxCount);
    }
  }, [itemList, maxCount]);

  useEffect(() => {
    getData(0);
  }, [promotionId, type]);

  return (
    <div className={styles.collectionWrap}>
      <>
        {itemList.length === 0 ? (
          <div className={styles.no_list}></div>
        ) : (
          <div className={styles.listArea}>
            <InfiniteScroll
              pageStart={0}
              hasMore={hasMore}
              loadMore={(page: number) => {
                const offset = page * 20;
                getData(offset);
              }}>
              <div className={styles.list} style={{paddingBottom: '40px'}}>
                {itemList.map((item, idx) => {
                  return (
                    <div
                      key={`collection_item_${idx}`}
                      ref={idx === itemList.length - 1 ? lastElementRef : null}>
                      {item.id === -1 ? (
                        <CollectionLoader />
                      ) : (
                        <CollectionItem
                          idx={idx}
                          item={item}
                          toggleLike={toggleLike}
                          setOpenInstallAlert={setOpenInstallAlert}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
            <div style={{padding: '0 20px 40px 20px'}}>
              <button
                className={styles.other_collection}
                onClick={() => navigator('/collection/list')}>
                <span>다른 기획전 보기</span>
                <span className={styles.other_arrow_left}></span>
              </button>
            </div>
          </div>
        )}
      </>
      <InstallAlert
        ignoreRead={openInstallAlert}
        setIgnore={setOpenInstallAlert}
      />
    </div>
  );
}
