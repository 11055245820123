import {
  FirebaseMessaging,
  ListChannelsResult,
} from '@capacitor-firebase/messaging';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import dayjs from 'dayjs';

import {
  HasNewNotification,
  NotificationList,
  UserInfo,
  user,
} from '../store/user';
import useNative from './useNative';
import {getNoticeList} from '../services/user';
import {Notification} from '../views/myKnewnew/PushList/LIst';
import useStorage from './useStorage';
import {isOpenNotificationAlert} from '../store';

export default function usePushNotification() {
  const {Echo} = useNative();
  const navigator = useNavigate();
  const setHasNewNotification = useSetRecoilState(HasNewNotification);
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const {getPush, getItem} = useStorage();
  const setOpenModal = useSetRecoilState(isOpenNotificationAlert);
  const [notifications, setNotifications] = useRecoilState(NotificationList);
  const checkPermissions = async () => {
    const result = await FirebaseMessaging.checkPermissions();
    console.log('usePushNotification checkPermissions', result);
    return result.receive;
  };

  const requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    console.log('usePushNotification requestPermissions', result);
    return result.receive;
  };

  const openPermissionSystemPopup = async () => {
    await Echo.echo({
      value: 'push_notification',
    });
  };

  const getToken = async () => {
    const result = await FirebaseMessaging.getToken();
    return result.token;
  };

  // const deleteToken = async () => {
  //   await FirebaseMessaging.deleteToken();
  // };

  const getDeliveredNotifications = async () => {
    const result = await FirebaseMessaging.getDeliveredNotifications();
    return result.notifications;
  };

  // const removeDeliveredNotifications = async () => {
  // await FirebaseMessaging.removeDeliveredNotifications({
  //   ids: ['798dfhliblqew89pzads'],
  // });
  // };

  // const removeAllDeliveredNotifications = async () => {
  //   await FirebaseMessaging.removeAllDeliveredNotifications();
  // };

  // const subscribeToTopic = async () => {
  //   await FirebaseMessaging.subscribeToTopic({topic: 'news'});
  // };

  // const unsubscribeFromTopic = async () => {
  //   await FirebaseMessaging.unsubscribeFromTopic({topic: 'news'});
  // };

  const addTokenReceivedListener = async () => {
    await FirebaseMessaging.addListener('tokenReceived', event => {
      console.log('tokenReceived', {event});
    });
  };

  const addNotificationReceivedListener = async () => {
    await FirebaseMessaging.addListener('notificationReceived', event => {
      console.log('usePushNotification addNotificationReceivedListener', event);
      setHasNewNotification(true);
    });
  };

  const addNotificationActionPerformedListener = async () => {
    await FirebaseMessaging.addListener(
      'notificationActionPerformed',
      event => {
        const platform = Capacitor.getPlatform();
        console.log('notificationActionPerformed', {event});
        if (platform == 'android') {
          navigator((event.notification.data as any).link as string);
        } else if (platform == 'ios') {
          navigator(
            (event.notification.data as any).custom_data.link as string,
          );
        }
      },
    );
  };

  const removeAllListeners = async () => {
    await FirebaseMessaging.removeAllListeners();
  };

  const createChannel = async () => {
    await FirebaseMessaging.createChannel({
      id: 'knewnew_app_channel',
      name: '뉴뉴 앱 채널',
      importance: 4,
    });
  };

  const getChannels = async () => {
    const res: ListChannelsResult = await FirebaseMessaging.listChannels();
    return res.channels;
  };

  const registerNotifications = async () => {
    let permStatus = await checkPermissions();
    console.log('usePushNotification registerNotifications', permStatus);
    if (permStatus === 'prompt') {
      permStatus = await requestPermissions();
    }

    if (permStatus !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await getToken();
  };

  const addListeners = () => {
    addTokenReceivedListener();
    addNotificationReceivedListener();
    addNotificationActionPerformedListener();
  };

  const checkNewMessage = async (_notifications: Notification[]) => {
    const lastId = _notifications[0].id;
    const email = userInfo.email;
    const res = await getPush(email, lastId);
    if (!res) {
      setHasNewNotification(true);
    }
  };

  const checkMarketingAllow = async () => {
    const date = await getItem('knewnew_not_agree_marketing');
    if (date.length > 0) {
      const diff = Math.abs(dayjs(date).diff(dayjs(), 'dates'));
      if (diff > 14 && !userInfo.isMarketing) {
        setOpenModal(true);
      }
    } else {
      if (!userInfo.isMarketing) {
        setOpenModal(true);
      }
    }
  };

  const initNotificationList = async () => {
    const res = await getNoticeList(accessToken);
    setNotifications(res.data);
    checkNewMessage(res.data);
  };

  // useEffect(() => {
  //   if (Capacitor.getPlatform() !== 'web') {
  //     initNotificationList();
  //     checkMarketingAllow();
  //     // console.log('hide splash');
  //     // hide();
  //   }
  // }, []);

  return {
    addListeners,
    registerNotifications,
    getDeliveredNotifications,
    removeAllListeners,
    getToken,
    createChannel,
    getChannels,
    checkPermissions,
    requestPermissions,
    openPermissionSystemPopup,
    checkNewMessage,
    initNotificationList,
    checkMarketingAllow,
    notifications,
  };
}
