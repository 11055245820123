import React from 'react';
interface Props {
  recommendData: RecommendData;
}

export default function OtherItems({recommendData}: Props) {
  const moveOtherRecommend = () => {
    // navigator(`/recommend/${recommendData.id}`);
    window.location.href = `/recommend/${recommendData.id}`;
  };
  return (
    <div
      className="other-item-wrapper"
      style={{cursor: 'pointer'}}
      onClick={() => moveOtherRecommend()}>
      <div className="text-contents">
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: recommendData.title.split('\\n').join('<br />'),
          }}></div>
        <div className="description-wrapper">{recommendData.explain}</div>
      </div>
      <div className="image-contents">
        <img
          src={recommendData.contents[0].image}
          alt={`${recommendData.contents[0].id}`}
          height={'140px'}
          style={{maxWidth: '140px !important'}}
        />
      </div>
    </div>
  );
}
