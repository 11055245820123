import Axios from 'axios';

import {errorInterceptor} from '../utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

interface Params {
  limit: number;
  offset: number;
  recommend_id: string;
}

export const recommendList = (param: Pick<Params, 'limit' | 'offset'>) => {
  const paramObj = JSON.parse(JSON.stringify(param));
  const paramStr = new URLSearchParams(paramObj).toString();
  return axios.get(`/recommend/?${paramStr}`);
};
export const recommendContents = (
  param: Pick<Params, 'limit' | 'offset'>,
  id: string,
) => {
  const paramObj = JSON.parse(JSON.stringify(param));
  const paramStr = new URLSearchParams(paramObj).toString();
  return axios.get(`/recommend/${id}/?${paramStr}`);
};
export const recommendUnread = (param: Pick<Params, 'limit' | 'offset'>) => {
  const paramObj = JSON.parse(JSON.stringify(param));
  const paramStr = new URLSearchParams(paramObj).toString();
  return axios.get(`/recommend/unread/?${paramStr}`);
};
export const foodLogContents = (id: string) => {
  return axios.get(`/review/${id}/`);
};
export const getProductsFoodlogs = (id: string) => {
  return axios.get(`/review/product/${id}/`);
};
