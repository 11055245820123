import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  width: string;
  height: string;
  txt: string;
  background: string;
  color: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function ModalButton({
  width,
  height,
  txt,
  background,
  color,
  onClick,
  disabled,
}: Props) {
  return (
    <button
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
      style={{
        width: width,
        height: height,
        background: disabled ? '#E9E7EC' : background,
        color: color,
      }}>
      {txt}
    </button>
  );
}
