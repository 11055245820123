import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import {sum} from 'lodash';
import {useTranslation} from 'react-i18next';

import Comments from '../../components/comments/Comments';
import EnterComments from '../../components/comments/EnterComments';
import NestedComments from '../../components/comments/NestedComments';
import CommentModal from '../../components/common/CommentModal';
import useStorage from '../../hooks/useStorage';
import ContentsHeader from '../../layouts/ContentsHeader';
import {getComment} from '../../services/comment';
import {
  CommunityCommentCount,
  CommentType,
  isOpenModal,
  OpeningModalStatus,
} from '../../store';
import {user} from '../../store/user';
import Layout from '../../layouts';

export default function CommentList() {
  const {getToken} = useStorage();
  const [comments, setComments] = useState<ReviewComment[]>([]);
  const [commentModalType, setCommentModalType] = useState<'edit' | 'report'>(
    'report',
  );
  const setOpeningModalStatus = useSetRecoilState(OpeningModalStatus);
  const setCommentType = useSetRecoilState(CommentType);
  const accessToken = useRecoilValue(user);
  const setIsOpenCommentModal = useSetRecoilState(isOpenModal);
  const [cnt, setCnt] = useRecoilState(CommunityCommentCount);
  const {t} = useTranslation(['views'], {keyPrefix: 'comment.List'});

  const init = async () => {
    const token = accessToken || (await getToken());
    const paths = window.location.pathname.split('/');
    const id = paths[paths.length - 1];
    const res = await getComment(Number(id), token as string);
    setComments(res.data as ReviewComment[]);
    setCnt(getCntFromComments(res.data as ReviewComment[]));
  };
  const openCommentModal = async (type: 'edit' | 'report') => {
    await setCommentModalType(type);
    await setOpeningModalStatus(type);
    setIsOpenCommentModal(true);
  };
  const getCntFromComments = (comments: ReviewComment[]) => {
    return comments.length + sum(comments.map(c => c.child.length));
  };
  useEffect(() => {
    if (accessToken) {
      init();
    } else {
      setCommentType('community');
    }
  }, [accessToken]);
  return (
    <Layout.Main>
      <div className="comments-all">
        <ContentsHeader txt={t('header')} isOption={'none'} />
        <div className="comments-wrapper contents-body">
          <div className="comment-cnt">
            <span>{t('comment')}</span>
            <span className="cnt">{cnt}</span>
          </div>
          <div
            className="comment-list"
            style={{
              display: comments.length > 0 ? 'block' : 'none',
              paddingBottom: '110px',
            }}>
            {comments.map((comment, idx) => {
              return (
                <div className="comment-item" key={`${comment.id}_${idx}`}>
                  <Comments
                    info={comment}
                    fetch={init}
                    openModal={openCommentModal}
                    writer={''}
                  />
                  {comment.child &&
                    comment.child.length > 0 &&
                    comment.child.map((nc, id) => {
                      return (
                        <NestedComments
                          key={`${nc.id}_${id}`}
                          info={nc}
                          parentInfo={comment}
                          fetch={init}
                          openModal={openCommentModal}
                          writer={''}
                        />
                      );
                    })}
                </div>
              );
            })}
          </div>
          <div
            className="enter-comment-wrapper"
            style={{
              position: 'fixed',
              bottom: 0,
              width: '375px',
              marginLeft: '-20px',
              background: '#fff',
            }}>
            <div className="divider"></div>
            <EnterComments fetch={init} list={comments} />
          </div>
          <div
            className="no-reply"
            style={{display: comments.length === 0 ? 'block' : 'none'}}>
            {t('no-reply')}
          </div>
        </div>
        <CommentModal type={commentModalType} fetch={init} />
      </div>
    </Layout.Main>
  );
}
