import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useRecoilState, useRecoilValue} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import Options from '@/components/common/ProductOptionSelector/Options';
import ButtonFooter from '@/layouts/ButtonFooter';
import {ProductOptions} from '@/components/common/ProductOptionSelector/ProductOptionSelector';
import {IsOpenDuplicatedNotice, PathHistories} from '@/store';
import {
  applyEmptyOptionsNotice,
  getMagazineCollectionItems,
} from '@/services/magazine';
import {user} from '@/store/user';
import AuthModal from '@/components/common/AuthModal';
import Layout from '@/layouts';
import useHistory from '@/hooks/useHistory';
import CollectionLoader from '@/components/common/Skeleton/CollectionLoader';

import styles from './styles.module.scss';
import CollectionItem from './components/Item';
export default function EmptyOptionAlert() {
  const {state} = useLocation();
  const navigator = useNavigate();
  const {historyBack} = useHistory();
  const [isOpenDuplicatedNotice, setIsOpenDuplicatedNotice] = useRecoilState(
    IsOpenDuplicatedNotice,
  );
  const [histories, setHistories] = useRecoilState(PathHistories);
  const [selectedDate, setSelectedDate] = useState<{
    key: string;
    value: string;
  }>();
  const [selectedTime, setSelectedTime] = useState<{
    key: string;
    value: string;
  }>();
  const [isOpenOption1, setIsOpenOption1] = useState(false);
  const [isOpenOption2, setIsOpenOption2] = useState(false);
  const [option1PH] = useState('옵션 1');
  const [option2PH] = useState('옵션 2');
  const [phoneNumber, setPhoneNumber] = useState('010');
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [itemList, setItemList] = useState<CollectionListItem[]>();
  const [completed, setCompleted] = useState(false);

  const accessToken = useRecoilValue(user);
  const hasSoldoutChild = (child: PopupOptions[]) => {
    let hasSoldout = false;
    if (!child) {
      return hasSoldout;
    }
    child.forEach(c => {
      if (c.limit <= c.soldCount) {
        hasSoldout = true;
      }
    });
    return hasSoldout;
  };
  const option1: ProductOptions[] = useMemo(() => {
    let options: ProductOptions[] = [];
    if (state?.detailInfo) {
      const magazine = state?.detailInfo as MagazineItem;
      options = magazine.popupInfo
        ? magazine.popupInfo.options
            .map(option => {
              return {
                key: option.value,
                value: option.value,
                id: option.id,
                isSoldout:
                  option.limit <= option.soldCount ||
                  hasSoldoutChild(option.child),
              };
            })
            .filter(o => o.isSoldout === true)
            .map(o => {
              return {
                ...o,
                isSoldout: false,
              };
            })
        : [];
    }
    if (state?.option1 && options.length > 0) {
      const toggledOption = options.find(o => o.value === state?.option1.value);
      setSelectedDate({
        key: toggledOption?.key || '',
        value: toggledOption?.value || '',
      });
    }
    return options;
  }, [state]);
  const option2: ProductOptions[] = useMemo(() => {
    let options: ProductOptions[] = [];
    if (state?.detailInfo && selectedDate && selectedDate.key.length > 0) {
      const magazine = state?.detailInfo as MagazineItem;
      const selectedOption = magazine.popupInfo
        ? (magazine.popupInfo.options.find(
            o => o.value === selectedDate?.key,
          ) as PopupOptions)
        : {child: []};
      const child = selectedOption.child as PopupOptions[];
      options = child
        .map(c => {
          return {
            key: c.value,
            value: c.value,
            id: c.id,
            isSoldout: c.limit <= c.soldCount,
          };
        })
        .filter(o => o.isSoldout)
        .map(o => {
          return {
            ...o,
            isSoldout: false,
          };
        });
    }
    if (state?.option2) {
      const toggledOption = options.find(o => o.value === state.option2.value);
      setSelectedTime({
        key: toggledOption?.key || '',
        value: toggledOption?.value || '',
      });
      setSelectedOptionId(
        options.find(o => o.value === state.option2.value)?.id,
      );
    }
    return options;
  }, [state, selectedDate]);
  const toggleItem = (type: string, item: ProductOptions) => {
    if (type === 'date') {
      setSelectedDate(item);
      setIsOpenOption2(true);
    }
    if (type === 'time') {
      setSelectedTime(item);
      setSelectedOptionId(item.id);
      setIsOpenOption1(false);
    }
  };
  const enterPhoneNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const v = $event.currentTarget.value;
    if (v.length <= 3) {
      setPhoneNumber('010');
    } else {
      setPhoneNumber(v.replace(/[^0-9]/g, ''));
    }

    const isValidPhoneNumber =
      v.startsWith('01') && /(\d{3})(\d{4})(\d{4})/.test(v);
    setIsPhoneNumberError(!isValidPhoneNumber);
  };
  const openOption1 = (is: boolean, v: any) => {
    setIsOpenOption1(is);
    if (v) {
      setIsOpenOption2(!is);
    }
  };
  const openOption2 = (is: boolean) => {
    setIsOpenOption2(is);
  };
  const apply = async () => {
    try {
      if (selectedOptionId) {
        await applyEmptyOptionsNotice(
          accessToken,
          {
            phone: phoneNumber,
          },
          selectedOptionId,
        );
        getData();
        setCompleted(true);
      }
    } catch (e: any) {
      // TODO: move to back, open modal
      if (e.response && e.response.status === 558) {
        setIsOpenDuplicatedNotice(true);
      }
    }
  };

  // TODO: recoil state에 추가하기
  const getData = async () => {
    try {
      const {data} = await getMagazineCollectionItems(
        accessToken,
        'select_shop_famous',
      );
      const list = data?.[0]?.magazines ?? [];
      setItemList(list.slice(0, 8));
    } catch (e) {
      console.log(e);
    }
  };

  const isDisabledApplyBtn = useMemo(() => {
    return !(
      !isPhoneNumberError &&
      phoneNumber.length >= 11 &&
      !!selectedOptionId
    );
  }, [isPhoneNumberError, phoneNumber, selectedOptionId]);

  useEffect(() => {
    if (phoneNumber.length === 11) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    } else if (phoneNumber.length === 13) {
      const number = phoneNumber
        .replace(/-/g, '')
        .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      setPhoneNumber(number);
    } else if (phoneNumber.length < 7) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2'));
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (completed) {
      getData();
    }
  }, []);

  return (
    <Layout.Main>
      <div className={`${styles.emptyOptionAlertArea}`}>
        <ContentsHeader
          txt={'대기 신청하기'}
          isOption={'none'}
          isPopup={true}
          back={() => {
            setCompleted(false);
            console.log(histories);
            setHistories([...histories].splice(0, histories.length - 1));
            window.history.back();
          }}
        />
        {!completed ? (
          <>
            <div className={styles.contents}>
              <div className={styles.title}>
                {state?.detailInfo.title || ''}
              </div>
              <div className={styles.options}>
                <div className={styles.optionTitle}>옵션 *</div>
                <div className={styles.optionSelectorWrapper}>
                  <Options
                    type={'date'}
                    toggle={toggleItem}
                    placeholder={option1PH}
                    optionList={option1}
                    disabled={false}
                    selectedValue={selectedDate?.key || ''}
                    isOpenList={isOpenOption1}
                    setIsOpenList={openOption1}
                  />
                  <Options
                    type={'time'}
                    toggle={toggleItem}
                    placeholder={option2PH}
                    optionList={option2}
                    disabled={!(selectedDate && selectedDate?.key)}
                    selectedValue={selectedTime?.key || ''}
                    isOpenList={isOpenOption2}
                    setIsOpenList={openOption2}
                  />
                </div>
              </div>
              <div className={styles.phoneNumberInput}>
                <div className={styles.phoneNumberInputTitle}>
                  휴대폰 번호 *
                </div>
                <div className={styles.phoneNumberInputForm}>
                  <input
                    type="tel"
                    className={`${
                      isPhoneNumberError ? styles.error_border : ''
                    }`}
                    placeholder="알림을 받으실 휴대폰 번호를 입력해주세요"
                    maxLength={13}
                    value={phoneNumber}
                    onChange={enterPhoneNumber}
                  />
                  <div>
                    <div
                      className={styles.error}
                      style={{display: isPhoneNumberError ? 'block' : 'none'}}>
                      {'휴대폰 번호가 올바르지 않습니다.'}
                    </div>
                  </div>
                  <ul>
                    <li>• 구매 가능 수량 발생 시 문자메세지로 알려드립니다.</li>
                    <li>• 구매 시점에 따라 상품 매진이 발생할 수 있습니다.</li>
                  </ul>
                </div>
              </div>
            </div>
            <ButtonFooter>
              <div
                className={styles.btnWrapper}
                style={{
                  marginTop: '13px',
                  marginBottom: '13px',
                }}>
                <button
                  className={styles.cancelBtn}
                  onClick={() => historyBack()}>
                  취소
                </button>
                <button
                  className={styles.applyBtn}
                  onClick={() => apply()}
                  disabled={isDisabledApplyBtn}>
                  신청
                </button>
              </div>
            </ButtonFooter>
          </>
        ) : (
          <div className={styles.waitingArea}>
            <div className={styles.txtWrapper}>
              <strong className={styles.title}>
                대기 신청이 완료되었습니다
              </strong>
              <p className={styles.desc}>
                {
                  '추가 수량 발생 시 입력하신 연락처로\n 안내 문자를 발송해 드립니다.'
                }
              </p>
            </div>
            {!!itemList && itemList.length > 0 && (
              <>
                <div className={styles.list_title}>함께보면 좋은 상품</div>
                <div
                  className={styles.listArea}
                  style={{padding: '15px 20px 0'}}>
                  <div className={styles.list}>
                    {itemList.map((item, idx) => {
                      return (
                        <div key={`collection_item_${idx}`}>
                          {item?.id === -1 ? (
                            <CollectionLoader />
                          ) : (
                            <CollectionItem
                              idx={idx}
                              item={item}
                              toggleLike={() => console.log('')}
                              setOpenInstallAlert={() => console.log('')}
                              noBadge
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div style={{padding: '40px 20px'}}>
                  <button
                    className={styles.collectionViewMoreBtn}
                    onClick={() =>
                      navigator(`/collection/items/select_shop_famous`, {
                        state: {
                          backPath: `/collection/list`,
                        },
                      })
                    }>
                    <span className={styles.txt}>상품 전체 보기</span>
                    <span className={styles.collectionViewMoreIcon}></span>
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        <AuthModal
          txt={<>이미 알림이 신청된 상품입니다.</>}
          isOpen={isOpenDuplicatedNotice}
          setIsOpen={(is: boolean) => setIsOpenDuplicatedNotice(is)}
          action={() => setIsOpenDuplicatedNotice(false)}
          btnType={'error'}
        />
      </div>
    </Layout.Main>
  );
}
