/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {brandItemsState, brandsArgs} from '@/store';
import {useScroll} from '@/hooks/useScroll';

import BrandCard from '../BrandCard';
import RecommendList from '../recommends/RecommendList';

export default function BrandGroup() {
  const {syncScroll} = useScroll();
  const brandItems = useRecoilValue(brandItemsState);
  const setBrandArgs = useSetRecoilState(brandsArgs);
  const [lastElement, setLastElement] = useState<Element | null>(null);
  const dummy: MagazineItem = {
    id: -1,
    title: '',
    subtitle: '',
    bannerTitle: '',
    type: '',
    imageUrl: '',
    link: '',
    priority: -1,
    created: '',
    commentCount: -1,
    likeCount: -1,
    bookmarkCount: 0,
    viewCount: 0,
    isLike: false,
    hashtags: [],
    author: {
      badge: null,
      id: -1,
      nickname: '',
      profileImage: '',
    },
  };
  const dummies = Array(4).fill(dummy);
  const observer = useRef(
    new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setBrandArgs(prev => {
          return {
            ...prev,
            limit: prev.limit + 20,
          };
        });
      }
    }),
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    // remember scroll position
    syncScroll();

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <div className="brands-list">
      {brandItems.brandGroups && brandItems.brandGroups.length > 0
        ? brandItems.brandGroups.map(brand => {
            return brand ? (
              brand && brand.type === 'recommend' ? (
                <div ref={setLastElement} key={`recommend_${brand.title}`}>
                  <RecommendList item={brand} />
                </div>
              ) : (
                <div ref={setLastElement} key={`brand_${brand.id}`}>
                  <BrandCard info={brand as MagazineItem} type={'brands'} />
                </div>
              )
            ) : (
              <></>
            );
          })
        : dummies.map((d, idx) => {
            return (
              <BrandCard
                info={d as MagazineItem}
                key={`brands_dummy_${idx}`}
                type={'brands'}
              />
            );
          })}
    </div>
  );
}
