import React, {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';

import ContentsHeader from '../../layouts/ContentsHeader';
import styles from './styles.module.scss';
import {UserInfo, user} from '../../store/user';
import {requestAuthIdentify} from '../../services/coupon';
import {userDetail} from '../../services/user';
import Layout from '../../layouts';

export default function UserAuthProcess() {
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const accessToken = useRecoilValue(user);
  const localStorageAuthKey = 'CERT_ROOT_PATH';
  const setUserInfo = useSetRecoilState(UserInfo);
  const {t} = useTranslation(['views'], {keyPrefix: 'UserAuthProcess'});

  const updateUserInfo = async () => {
    const res = await userDetail(accessToken);
    setUserInfo(res.data);
  };

  const requestIdentify = async (requestParam: {
    ci: string;
    name: string;
    birth: string;
    gender: string;
    phone: string;
  }) => {
    try {
      await requestAuthIdentify(accessToken, requestParam);
      const prevPath = localStorage.getItem(localStorageAuthKey);
      const url =
        prevPath && prevPath.indexOf('order') > -1
          ? `${prevPath}?coupon=true`
          : prevPath;
      await updateUserInfo();
      navigator(`${url}`, {
        state: {
          certResult: 'success',
          ...location.state,
        },
      });
      localStorage.removeItem(localStorageAuthKey);
    } catch (e: any) {
      const prevPath = localStorage.getItem(localStorageAuthKey);
      const errorCode = e.response?.status === 554 ? 'duplicated' : 'failed';
      const url =
        prevPath && prevPath.indexOf('order') > -1
          ? `${prevPath}?coupon=true`
          : prevPath;
      navigator(`${url}`, {
        state: {
          certResult: errorCode,
          ...location.state,
        },
      });
      localStorage.removeItem(localStorageAuthKey);
    }
  };
  useEffect(() => {
    const param = {
      ci: searchParams.get('ci') as string,
      name: searchParams.get('name') as string,
      birth: searchParams.get('birth') as string,
      gender: searchParams.get('gender') as string,
      phone: searchParams.get('phone') as string,
    };
    requestIdentify(param);
  }, [searchParams]);
  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt={t('headerTxt')}
          isBottomLine=""
          isOption="none"
          isPopup={false}
        />
        <div className={styles.loading_wrapper}>
          <div className={styles.loading}>
            <span className={styles.loader_icon}></span>
            <h2>{t('loader-title')}</h2>
            <div className={styles.wait}>{t('loader-guide')}</div>
          </div>
        </div>
      </div>
    </Layout.Main>
  );
}
