import React from 'react';
import {useNavigate} from 'react-router-dom';

import {switchNewLineTxt} from '../../../utils';
import styles from './styles.module.scss';

interface Props {
  item: MagazineItem;
}

export default function ItemCard({item}: Props) {
  const navigator = useNavigate();
  return (
    <div
      className={styles.itemCard}
      onClick={() =>
        navigator(`/${item.category}/${item.id}`, {
          state: {
            history: 'curator',
          },
        })
      }
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%), center / cover no-repeat url(${item.imageUrl})`,
        cursor: 'pointer',
      }}>
      <div className={styles.txtWrapper}>
        <div className={styles.typeInfo}>
          {item.target?.replace(/^[a-z]/, char => char.toUpperCase())}
        </div>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: switchNewLineTxt(item.title),
          }}></div>
        <div className={styles.subtitle}>{item.subtitle}</div>
      </div>
    </div>
  );
}
