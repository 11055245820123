import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {tastTags, UserInfo} from '@/store';

export default function TastTagComplete() {
  const navigator = useNavigate();
  const tags = useRecoilValue(tastTags);
  const userInfo = useRecoilValue(UserInfo);
  const [selectedTags, setSelectedTag] = useState<typeof tags>([]);
  const moveToHome = () => {
    navigator('/magazine');
  };
  useEffect(() => {
    const userTasteTags = userInfo && userInfo.tags ? userInfo.tags.taste : [];
    setSelectedTag(
      tags.filter(t => {
        return userTasteTags.indexOf(t.title) > -1;
      }),
    );
  }, []);
  return (
    <div className="main" style={{minHeight: '100vh'}}>
      <div className="tast-tag-selector">
        <div className="tast-tag-title">
          <div className="tast-tag-icon-wrapper">
            <span className="tast-tag-icon">&nbsp;</span>
          </div>
          <div className="tast-tag-txt">
            {userInfo.nickname}님을 나타내는
            <br />
            입맛 태그가 선택되었습니다.
          </div>
        </div>
        <div className="tast-tag-body">
          <div className="tast-tag-line tast-tag-line-1">
            {selectedTags.slice(0, 5).map(t => {
              return (
                <span className={`tast-icon ${t.type} active`} key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-2">
            {selectedTags.slice(5, 9).map(t => {
              return (
                <span className={`tast-icon ${t.type} active`} key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-3">
            {selectedTags.slice(9, 14).map(t => {
              return (
                <span className={`tast-icon ${t.type} active`} key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-4">
            {selectedTags.slice(14, 18).map(t => {
              return (
                <span className={`tast-icon ${t.type} active`} key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-5">
            {selectedTags.slice(18, 23).map(t => {
              return (
                <span className={`tast-icon ${t.type} active`} key={t.type}>
                  {t.title}
                </span>
              );
            })}
          </div>
        </div>
        <div className="tast-tag-footer" style={{marginBottom: '60px'}}>
          <div className="message">
            입맛 태그는 마이페이지에서 언제든지 수정할 수 있습니다.
          </div>
          <button
            className="main-cont btn main-pink-filled"
            onClick={() => moveToHome()}>
            홈으로 이동
          </button>
        </div>
      </div>
    </div>
  );
}
