import React from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './styles.module.scss';

interface Props {
  item: Curator;
  isLeft: boolean;
}

export default function CuratorCard({item, isLeft}: Props) {
  const navigator = useNavigate();
  return (
    <div
      className={`${styles.curatorCard} ${isLeft ? 'left' : ''}`}
      onClick={() =>
        navigator(`/curators/${item.id}`, {
          state: {
            prev: `${window.location.pathname.slice(1, undefined)}`,
          },
        })
      }
      style={{cursor: 'pointer'}}>
      <div
        className={styles.curatorImg}
        style={{
          background: `center / cover no-repeat url(${item.profileImage})`,
        }}></div>
      <div className={styles.nickName}>{item.nickname}</div>
      <div className={styles.hashtags}>
        {item.userTags?.profile?.map((tag, idx) => {
          return (
            <span className={styles.tag} key={`userTag_${idx}`}>
              #{tag}
            </span>
          );
        })}
      </div>
    </div>
  );
}
