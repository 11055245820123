import React, {useEffect} from 'react';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import {HasNewNotification, NotificationList, UserInfo} from '@/store';
import PushListItem from '@/components/myKnewnew/PushListItem';
import useStorage from '@/hooks/useStorage';

import styles from './styles.module.scss';

export interface Notification {
  id: number;
  image: string;
  reviewImage: string;
  title: string;
  message: string;
  type: string;
  link: string;
  isRead: boolean;
  created: string;
}

export default function List() {
  const {readPush, getPush} = useStorage();
  const list = useRecoilValue(NotificationList);
  const userInfo = useRecoilValue(UserInfo);
  const [hasNewNotification, setHasNewNotification] =
    useRecoilState(HasNewNotification);
  const refresh = useRecoilRefresher_UNSTABLE(NotificationList);

  const makeAllRead = async (notifications: Notification[]) => {
    for (let i = 0, max = notifications.length; i < max; i++) {
      const res = await getPush(userInfo.email, notifications[i].id);
      if (!res) {
        await readPush(userInfo.email, notifications[i].id);
      }
    }
  };

  useEffect(() => {
    if (list && list.length > 0) {
      makeAllRead(list);
    }
  }, [list]);

  useEffect(() => {
    if (hasNewNotification) {
      refresh();
      setHasNewNotification(false);
    }
  }, [hasNewNotification]);

  return (
    <>
      {list && list.length === 0 && (
        <div className={styles.no_cnt}>
          <span>알림이 없습니다.</span>
        </div>
      )}
      {list && list.length > 0 && (
        <div className={styles.list}>
          {list.map(item => {
            return <PushListItem key={item.id} item={item} isRead={false} />;
          })}
        </div>
      )}
    </>
  );
}
