import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import cc from 'classcat';
import {throttle} from 'lodash';
import {useTranslation} from 'react-i18next';
import {StatusBar, Style} from '@capacitor/status-bar';
import {IoIosSearch} from 'react-icons/io';

import {isAndroid, isIos, isMobile} from '@/utils';
import DropDown from '@/layouts/Header/component/DropDown';

import styles from './styles.module.scss';
import {HasNewNotification, user} from '../../store/user';
import {HasPayments, isOpenInstallModal} from '../../store';

interface Props {
  title?: string | React.ReactElement;
  isTransparent?: boolean;
  isWhite?: boolean;
  isNoRing?: boolean;
  isCommunity?: boolean;
  isBrand?: boolean;
  isTicket?: boolean;
  children?: React.ReactNode;
  withBack?: boolean;
  backCallback?: () => void;
  titleStyle?: React.CSSProperties;
  hasDropdown?: boolean;
  isRealtime?: boolean;
  isShowSearch?: boolean;
  clickTitle?: () => void;
}

export default function Header({
  title,
  isWhite,
  isTransparent,
  isNoRing,
  isCommunity,
  isBrand,
  isTicket,
  children,
  withBack,
  backCallback,
  titleStyle,
  hasDropdown,
  isShowSearch,
  clickTitle,
}: Props) {
  const accessToken = useRecoilValue(user);
  const hasPayments = useRecoilValue(HasPayments);
  const [homeScroll, setHomeScroll] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const hasNewNotification = useRecoilValue(HasNewNotification);
  const navigator = useNavigate();
  const setIsOpenInstallModal = useSetRecoilState(isOpenInstallModal);
  const {t} = useTranslation(['layouts'], {keyPrefix: 'Header'});
  const clickPush = () => {
    const currentPath =
      location.pathname.indexOf('user') > -1 ? 'user' : 'home';
    if (Capacitor.getPlatform() === 'web') {
      setIsOpenInstallModal(false);
    } else {
      navigator('/push-list', {
        state: {
          prev: currentPath,
        },
      });
    }
  };

  const onScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 40) {
          setHomeScroll(true);
        } else {
          setHomeScroll(false);
        }
      }, 300),
    [homeScroll],
  );

  const ringIcon = useMemo(() => {
    let icon = <span></span>;
    if ((isWhite || homeScroll) && hasNewNotification) {
      icon = <span className={`${styles.icon} black-ring-icon-red`}></span>;
    } else if ((isWhite || homeScroll) && !hasNewNotification) {
      icon = <span className={`${styles.icon} black-ring-icon`}></span>;
    } else if (!isWhite && hasNewNotification) {
      icon = <span className={`${styles.icon} white-ring-icon-red`}></span>;
    } else if (!isWhite && !hasNewNotification) {
      icon = <span className={`${styles.icon} white-ring-icon`}></span>;
    }
    return icon;
  }, [isWhite, homeScroll, hasNewNotification]);

  const moveToAppScheme = () => {
    const visitedAt = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - visitedAt < 2000) {
        if (isAndroid()) {
          location.href = 'market://launch?id=com.knewnew.app';
        } else if (isIos()) {
          location.href =
            'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721';
        }
      }
    }, 1000);
  };

  const onClickContinueApp = () => {
    moveToAppScheme();
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    if (Capacitor.getPlatform() !== 'web') {
      if (isWhite) {
        StatusBar.setStyle({
          style: Style.Light,
        });
      } else {
        StatusBar.setStyle({
          style: Style.Dark,
        });
      }
    }
    return () => {
      setHomeScroll(false);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.header_wrapper} ${
          isWhite ? styles.bg_white : styles.bg_black
        } ${isTransparent ? styles.bg_transparent : ''} ${
          Capacitor.getPlatform() === 'android' ? styles.android : ''
        } ${homeScroll && isTransparent ? styles.scrolled : ''}`}>
        {Capacitor.getPlatform() === 'web' && (
          <div
            className={styles.app_install}
            style={{display: homeScroll ? 'none' : 'flex'}}>
            <div className={styles.lft}>
              <div className={styles.appIcon}>
                <span className={styles.appLogo}></span>
              </div>
              <span>{t('app_guide')}</span>
            </div>
            <button
              type="button"
              className={styles.install_btn}
              onClick={onClickContinueApp}>
              {t('app_link')}
            </button>
          </div>
        )}

        <div className={`main-content ${styles.header} `}>
          {!title && (
            <div
              className="flex"
              onClick={() => {
                navigator('/home');
              }}
              style={{cursor: 'pointer'}}>
              <div className={styles.logo} />
              <div
                className="font-bold flex header-text magazine-txt-wrapper"
                style={{
                  alignItems: 'center',
                }}></div>
            </div>
          )}
          {title && (
            <div>
              <div
                className={`${styles.title} flex`}
                onClick={() =>
                  clickTitle ? clickTitle() : backCallback ? backCallback() : ''
                }
                style={{...titleStyle, cursor: hasDropdown ? 'pointer' : ''}}>
                {withBack && (
                  <div className={styles.mainBack}>
                    <span className={styles.mainBackIcon}></span>
                  </div>
                )}
                {title}
                {hasDropdown && (
                  <span style={{marginLeft: '8px'}}>
                    <DropDown />
                  </span>
                )}
              </div>
            </div>
          )}
          {/* <HeaderDropdown type={type} /> */}

          <div className={styles.option_wrapper}>
            {isTicket && homeScroll && (
              <div
                className={styles.menu}
                style={{marginRight: 0}}
                onClick={() => {
                  navigator('/order/list');
                }}>
                <span
                  className={cc([
                    styles.ticketBtn,
                    {[styles.new]: hasPayments},
                  ])}></span>
              </div>
            )}
            {!isNoRing && isMobile() && (
              <div
                className={styles.menu}
                onClick={() => clickPush()}
                style={{marginRight: '0', marginLeft: '16px'}}>
                {ringIcon}
                &nbsp;
              </div>
            )}
          </div>
          {isCommunity && (
            <div className={styles.menu}>{children as React.ReactNode}</div>
          )}

          {isShowSearch && (
            <div
              className={styles.menu}
              onClick={() => navigator('/search?from=brands')}>
              <IoIosSearch color={'#000'} size={'20px'} />
            </div>
          )}
          {isBrand && (
            <div
              className={styles.menu}
              onClick={() => {
                if (!accessToken) {
                  navigator('/login', {
                    state: {
                      prevPath: `${location.pathname}${location.search}`,
                    },
                  });
                  return;
                }
                navigator('/my-collection', {
                  state: {
                    prev: 'brand',
                  },
                });
              }}>
              <span
                className={`${styles.icon} ${styles.brandIcon}`}
                style={{width: '17px', height: '16px'}}></span>
            </div>
          )}
        </div>
      </div>
      {isShowModal && (
        <div
          className={styles.dimmed_wrapper}
          style={{
            display: 'flex',
            zIndex: '15',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div className={styles.qr_popup}>
            <p>{`간편한 브랜드 탐색부터 \n구매 혜택까지 경험해 보세요.`}</p>
            <img
              src="/imgs/app_qr.png"
              alt="app qa image"
              width="80px"
              height="80px"
            />
            <span className={styles.desc}>앱 다운로드</span>
            <button
              type="button"
              className={styles.close_btn}
              onClick={() => {
                setIsShowModal(false);
              }}>
              닫기
            </button>
          </div>
        </div>
      )}
    </>
  );
}
