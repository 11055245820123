import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import FilterModal from '@/components/common/FilterModal';
import ContentsTitle from '@/components/recommend/contents/ContentsTitle';
import MarketTitle from '@/components/recommend/contents/MarketTitle';
import OtherRecommendContents from '@/components/recommend/contents/OtherRecommendContents';
import FeedList from '@/components/recommend/FeedList';
import ContentsHeader from '@/layouts/ContentsHeader';
import {getProductsFoodlogs, recommendContents} from '@/services/recommend';
import {FoodLogFeeds, MarketPageProductInfo} from '@/store';

interface Props {
  type: 'market' | 'normal';
}
export default function RecommendContents({type}: Props) {
  const [reviews, setReviews] = useState<RecommendContentReview[]>([]);
  const [date, setDate] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [productInfo, setProductInfo] = useState<RecommendContentRes>();
  const marketPageProduct = useRecoilValue(MarketPageProductInfo);
  const setFoodLogReviews = useSetRecoilState(FoodLogFeeds);
  const contentsInit = async () => {
    const ids = window.location.pathname.split('/');
    const id = ids[ids.length - 1];
    const res = await recommendContents(
      {
        limit: 20,
        offset: 0,
      },
      id,
    );
    setReviews(res.data[0].review);
    setDate(dayjs(res.data[0].created).format('YYYY.MM.DD'));
    setProductInfo(res.data[0] as RecommendContentRes);
    setTitle(productInfo?.title.replace('\\n', ' ') as string);
  };
  const marketInit = async () => {
    const ids = window.location.pathname.split('/');
    const id = ids[ids.length - 1];
    const res = await getProductsFoodlogs(id);
    setReviews(res.data as RecommendContentReview[]);
    setTitle(marketPageProduct.name);
    setFoodLogReviews(res.data as RecommendContentReview[]);
  };
  useEffect(() => {
    if (type === 'normal') {
      contentsInit();
    }
    if (type === 'market') {
      marketInit();
    }
    window.scrollTo(0, 0);
  }, [type]);
  return (
    <div className="main">
      <ContentsHeader txt={title} isOption={'home'} />
      {type === 'normal' && (
        <ContentsTitle
          txt={productInfo?.title}
          isBadge={false}
          explain={productInfo?.explain}
          subtitle={productInfo?.subtitle}
          createdAt={date}
        />
      )}
      {type === 'market' && <MarketTitle />}
      <FeedList reviews={reviews} />
      <OtherRecommendContents />
      {type === 'market' && <FilterModal />}
    </div>
  );
}
