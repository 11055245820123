import React from 'react';

interface Props {
  link: string;
}

export default function MoveToShopBtn({link}: Props) {
  const move = () => {
    window.open(link, '_blank');
  };
  return (
    <div
      className="move-shop-btn"
      onClick={() => move()}
      style={{cursor: 'pointer'}}>
      <span>구매처로 이동하기</span>
    </div>
  );
}
