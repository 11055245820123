import React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {isOpenLoginModal} from '../../../store';
import {user} from '../../../store/user';

export default function FollowBtn() {
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const userInfo = useRecoilValue(user);
  const clickFollow = () => {
    if (!userInfo) {
      setLoginModal(true);
    }
  };
  return (
    <button className="follow-btn" onClick={() => clickFollow()}>
      <div className="follow-contents">
        <span className="txt">팔로우</span>
        <span className="plus-icon"></span>
      </div>
    </button>
  );
}
