import React, {useCallback} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import FoodLifeForm from '@/components/join/FoodLifeForm';
import ContentsHeader from '@/layouts/ContentsHeader';
import {SignupUserInfo, UserInfo} from '@/store';
import {signup} from '@/services/user';

export default function TagInfo() {
  const signupInfo = useRecoilValue(SignupUserInfo);
  const userInfo = useRecoilValue(UserInfo);
  const navigator = useNavigate();
  const isActive = useCallback(() => {
    const key = Object.keys(
      signupInfo.tags,
    ) as (keyof typeof signupInfo.tags)[];
    let cnt = 0;
    key.forEach(k => {
      const selections = signupInfo.tags[k].length;
      if (selections > 0) {
        cnt += 1;
      }
    });
    return cnt === 4;
  }, [signupInfo]);
  const next = async () => {
    try {
      await signup(signupInfo, userInfo);
      navigator('/signup/complete');
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="main" style={{minHeight: '100vh'}}>
      <ContentsHeader txt={''} isOption={'home'} isBottomLine={'none'} />
      <div className="join-user-info">
        <div className="top-contents">
          <strong>😎 다음으로,</strong>
          <span>
            뉴저님의 푸드라이프가 궁금해요. <br /> (중복 선택 가능)
          </span>
        </div>
        {/* <UserInfoForm /> */}
        <FoodLifeForm />
        <div
          className="btn-wrapper"
          style={{
            padding: '0 20px',
            marginTop: '40px',
            marginBottom: '60px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <button
            className={`main-cont btn main-black-border ${
              isActive() ? '' : 'disabled'
            }`}
            onClick={() => next()}>
            다음으로
          </button>
          <div className="step-pointer">
            <span
              className="dot"
              style={{
                display: 'inline-block',
                width: '5px',
                height: '5px',
                borderRadius: '5px',
                marginRight: '5px',
                backgroundColor: '#D2D0D5',
              }}></span>
            <span
              className="dot"
              style={{
                display: 'inline-block',
                width: '5px',
                height: '5px',
                borderRadius: '5px',
                backgroundColor: '#FF4B84',
              }}></span>
          </div>
        </div>
      </div>
    </div>
  );
}
