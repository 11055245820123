import React from 'react';
import {useRecoilState} from 'recoil';

import styles from './styles.module.scss';
import {IsOpenQRCodePopup} from '../../store';

interface Props {
  isBranding?: boolean;
}

const QRCodePopup = ({isBranding}: Props) => {
  const [isShowModal, setIsShowModal] = useRecoilState(IsOpenQRCodePopup);
  return (
    <div
      className={styles.dimmed_wrapper}
      style={{
        display: isShowModal ? 'flex' : 'none',
        zIndex: '15',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div className={styles.qr_popup}>
        {isBranding ? (
          <p>{`간편한 브랜드 탐색부터 \n구매 혜택까지 경험해 보세요.`}</p>
        ) : (
          <p>{`본인인증은 APP에서만 \n이용가능한 서비스입니다.`}</p>
        )}
        <img
          src="/imgs/app_qr.png"
          alt="app qa image"
          width="80px"
          height="80px"
        />
        <span className={styles.desc}>앱 다운로드</span>
        <button
          type="button"
          className={styles.close_btn}
          onClick={() => {
            setIsShowModal(false);
          }}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default QRCodePopup;
