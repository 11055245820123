import React from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  action: () => void;
}

export default function GeolocationModal({isOpen, setIsOpen, action}: Props) {
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
      }}>
      <div
        className="modal-contents  community-auth-alert"
        style={{
          width: '295px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '40px',
        }}>
        <div>
          <strong>위치서비스 설정이 필요합니다.</strong>
          <p>
            위치서비스를 허용하면 내주변 브랜드와
            <br />
            혜택을 확인할 수 있습니다.
          </p>
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '30px'}}>
          <>
            <button
              className="modal btn black-filled"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                border: '1px solid #E9E7EC',
                background: '#000',
                borderRadius: '5px',
                color: '#fff',
              }}
              onClick={() => action()}>
              설정 바로가기
            </button>
            <button
              className="modal btn black-filled"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                background: '#fff',
                color: '#9F9CA3',
              }}
              onClick={() => setIsOpen(false)}>
              취소
            </button>
          </>
        </div>
      </div>
    </div>
  );
}
