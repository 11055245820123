import React, {useEffect, useMemo, useState} from 'react';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';
import {useTranslation} from 'react-i18next';

import Button from '@/components/Button';
import QRCodePopup from '@/components/QRCodePopup';
import {UserActivityCounts, UserInfo, user} from '@/store/user';
import {isAndroid, isIos, isMobile} from '@/utils';
import useStorage from '@/hooks/useStorage';
import {getUserProfile, userDetail} from '@/services/user';
import emptyKnewnew from '@/products/user_default.svg';
import useCoupon from '@/hooks/useCoupon';
import {IsOpenQRCodePopup} from '@/store';
import useAuth from '@/hooks/useAuth';
import Modal from '@/layouts/Modal';

import styles from './styles.module.scss';
import ShortCuts from './Shortcuts';

export default function Profile() {
  const param = useParams();
  const navigator = useNavigate();
  const {getToken} = useStorage();
  const {openAuth} = useAuth();
  const {hasNewCoupon} = useCoupon();

  const [accessToken, setAccessToken] = useRecoilState(user);
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [isShowQRCode, setIsShowQRCode] = useRecoilState(IsOpenQRCodePopup);
  const userActivityCount = useRecoilValue(UserActivityCounts);
  const {t} = useTranslation(['components'], {keyPrefix: 'my.Profile'});
  const refetchUserActivityCounts =
    useRecoilRefresher_UNSTABLE(UserActivityCounts);

  const [profile, setProfile] = useState<UserInfoObj>();

  const isNoNormal = useMemo(() => {
    return userInfo.userType !== 'normal';
  }, [userInfo]);
  const userTypeName = useMemo(() => {
    return userInfo.userType === 'creator'
      ? t('userType_creator')
      : userInfo.userType === 'curator'
      ? t('userType_curator')
      : '';
  }, [userInfo]);

  const moveToAppScheme = () => {
    setTimeout(() => {
      if (isAndroid()) {
        window.location.href = 'market://launch?id=com.knewnew.app';
      } else if (isIos()) {
        window.open(
          'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
          '_blank',
        );
      }
    }, 1000);
    // setTimeout(() => {
    //   window.location.href = 'knewnew://home';
    // }, 0);
  };

  const init = async () => {
    try {
      const accessToken = await getToken();
      if (accessToken && accessToken.length > 0) {
        const res = await userDetail(accessToken);
        setUserInfo(res.data);
        setAccessToken(accessToken);
      }
    } catch (e) {
      navigator('/login', {
        state: {
          prevPath: `${location.pathname}${location.search}`,
        },
      });
    }
  };

  const getProfileInfo = async (id: number) => {
    const res = await getUserProfile(id, accessToken);
    setProfile(res.data);
  };

  const moveSns = async (link: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(link, '_blank');
    } else {
      await Browser.open({
        url: link,
      });
    }
  };

  useEffect(() => {
    if (param.id) {
      // writer 프로필 진입 시
      getProfileInfo(Number(param.id));
    } else {
      // my page 진입 시
      if (userInfo.id > -1) {
        // 이미 로그인 된 경우
        setProfile(userInfo);
      } else {
        // 로그인 되어있지 않은 경우
        init();
      }
    }
  }, [userInfo, param]);

  useEffect(() => {
    refetchUserActivityCounts();
  }, []);

  return (
    <div className={styles.profileArea}>
      <div className={styles.userInfo}>
        <div className={styles.user}>
          {profile?.profileImage ? (
            <div
              className={styles.profileImg}
              style={{
                background: `url(${profile?.profileImage}) no-repeat center / cover`,
              }}></div>
          ) : (
            <div
              className="profile-image-wrapper"
              style={{
                background: `url(${emptyKnewnew}) center no-repeat`,
                width: '60px',
                height: '60px',
              }}></div>
          )}
          <div className={styles.name}>
            <div
              className={styles.userTypeName}
              style={{display: isNoNormal ? 'block' : 'none'}}>
              {userTypeName}
            </div>
            <div>
              <span className={styles.nameFields}>{profile?.nickname}</span>
              <span className={styles.prefix}>{t('nickname_suffix')}</span>
            </div>
          </div>
        </div>
        <div className={styles.snsArea}>
          <span
            className={`${styles.sns} ${styles.instagram}`}
            onClick={() => moveSns(userInfo.snsInfo.instagram)}
            style={{
              display: userInfo.snsInfo.instagram ? 'inline-block' : 'none',
            }}></span>
          <span
            className={`${styles.sns} ${styles.blog}`}
            onClick={() => moveSns(userInfo.snsInfo.naver)}
            style={{
              display: userInfo.snsInfo.naver ? 'inline-block' : 'none',
            }}></span>
          <span
            className={`${styles.sns} ${styles.youtube}`}
            onClick={() => moveSns(userInfo.snsInfo.youtube)}
            style={{
              display: userInfo.snsInfo.youtube ? 'inline-block' : 'none',
            }}></span>
        </div>
      </div>
      <div
        className={styles.creatorDesc}
        style={{display: isNoNormal ? 'block' : 'none'}}>
        {profile?.headline}
      </div>
      <ShortCuts
        shortcutItems={
          userInfo.userType === 'creator'
            ? [
                {
                  name: t('shortcut_order'),
                  cnt: userActivityCount.payments,
                  onClick: () => navigator('/order/list'),
                },
                {
                  name: t('shortcut_coupon'),
                  cnt: userActivityCount.coupon,
                  hasNew: !userInfo.isIdentified || hasNewCoupon,
                  onClick: () => navigator('/user/coupon'),
                },
                {
                  name: t('shortcut_bookmark'),
                  cnt: userActivityCount.bookmark,
                  onClick: () => navigator('/my-collection'),
                },
                {
                  name: t('shortcut_community'),
                  cnt: userActivityCount.community,
                  onClick: () => navigator('/my-articles'),
                },
              ]
            : [
                {
                  name: t('shortcut_order'),
                  cnt: userActivityCount.payments,
                  onClick: () => navigator('/order/list'),
                },
                {
                  name: t('shortcut_coupon'),
                  cnt: userActivityCount.coupon,
                  hasNew: !userInfo.isIdentified || hasNewCoupon,
                  onClick: () => navigator('/user/coupon'),
                },
                {
                  name: t('shortcut_bookmark'),
                  cnt: userActivityCount.bookmark,
                  onClick: () =>
                    navigator('/my-collection', {
                      state: {
                        prev: 'user',
                      },
                    }),
                },
              ]
        }
      />
      <div
        className={styles.certBanner}
        onClick={() => {
          if (Capacitor.getPlatform() === 'web') {
            setIsShowQRCode(true);
          } else {
            openAuth('/user');
          }
        }}
        style={{
          display: !profile?.isIdentified ? 'flex' : 'none',
          cursor: 'pointer',
        }}>
        <span className={styles.certTxt}>{t('certBannerTxt')}</span>
        <span className={styles.certArrow}></span>
      </div>
      {isMobile() ? (
        <Modal.Alert width={'315px'} height={'224px'} isOpen={isShowQRCode}>
          <div
            className={styles.contents}
            style={{padding: '30px 16px 10px 16px'}}>
            <div className={styles.iconArea}>
              <span className={styles.bookmarkIcon}></span>
            </div>
            <div className={styles.txtArea}>
              본인인증은 APP에서만
              <br />
              이용가능한 서비스입니다.
            </div>
            <div className={styles.buttonArea} style={{marginTop: '30px'}}>
              <Button.ModalButton
                width={'100%'}
                height={'50px'}
                txt={'앱 다운로드'}
                background={'#000'}
                color={'#fff'}
                onClick={() => {
                  moveToAppScheme();
                  setIsShowQRCode(false);
                }}
              />
              <Button.ModalButton
                width={'100%'}
                height={'50px'}
                txt={'닫기'}
                background={'#fff'}
                color={'#9F9CA3'}
                onClick={() => setIsShowQRCode(false)}
              />
            </div>
          </div>
        </Modal.Alert>
      ) : (
        <QRCodePopup />
      )}
    </div>
  );
}
