import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';

export default function AboutCommunity() {
  const {t} = useTranslation(['components'], {keyPrefix: 'about.Community'});
  return (
    <div className={styles.community_wrapper}>
      <div className={styles.number_wrapper}>
        <span className={styles.number}>02</span>
      </div>
      <div className={styles.title}>Community</div>
      <div className={styles.detail}>
        {t('detail1')}
        <br />
        {t('detail2')}
      </div>
      <div className={styles.img}></div>
    </div>
  );
}
