import React from 'react';

import styles from './styles.module.scss';

interface InputElementProps {
  txt: string;
  active: boolean;
}

interface PasswordInputWrapperProps {
  children: React.ReactNode;
}

interface KeyboardWrapperProps {
  children: React.ReactNode;
}

interface KeyProps {
  txt: string;
  onClick: (t: string) => void;
}

interface BtnWrapperProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

interface ConfirmBtnProps {
  disabled: boolean;
  onClick?: () => void;
  tit?: string;
}

const InputElement = ({txt, active}: InputElementProps) => {
  return (
    <div className={`${styles.input} ${active ? styles.active : ''}`}>
      <span>{txt}</span>
    </div>
  );
};

const InputWrapper = ({children}: PasswordInputWrapperProps) => {
  return <div className={styles.inputWrapper}>{children}</div>;
};

const KeyboardWrapper = ({children}: KeyboardWrapperProps) => {
  return <div className={styles.keyboardWrapper}>{children}</div>;
};

const GiftKeyboardWrapper = ({children}: KeyboardWrapperProps) => {
  return <div className={styles.giftKeyboardWrapper}>{children}</div>;
};

const Key = ({txt, onClick}: KeyProps) => {
  return (
    <button className={styles.key} onClick={() => onClick(txt)}>
      {txt === 'back' ? <span className={styles.backBtn}></span> : txt}
    </button>
  );
};

const GiftKey = ({txt, onClick}: KeyProps) => {
  return (
    <button className={styles.giftKey} onClick={() => onClick(txt)}>
      {txt === 'back' ? <span className={styles.backBtn}></span> : txt}
    </button>
  );
};

const ConfirmBtnWrapper = ({children, style}: BtnWrapperProps) => {
  return (
    <div className={styles.confirmBtnWrapper} style={{...style}}>
      {children}
    </div>
  );
};

const GiftConfirmBtnWrapper = ({children, style}: BtnWrapperProps) => {
  return (
    <div className={styles.giftConfirmBtnWrapper} style={{...style}}>
      {children}
    </div>
  );
};

const ConfirmBtn = ({disabled, onClick, tit}: ConfirmBtnProps) => {
  return (
    <button
      className={`${styles.confirmBtn} ${disabled ? styles.disabled : ''}`}
      disabled={disabled}
      onClick={onClick}>
      {tit ? tit : '확인'}
    </button>
  );
};

export default {
  InputElement,
  InputWrapper,
  KeyboardWrapper,
  Key,
  ConfirmBtnWrapper,
  ConfirmBtn,
  GiftKeyboardWrapper,
  GiftKey,
  GiftConfirmBtnWrapper,
};
