import React from 'react';
import {useNavigate} from 'react-router-dom';

import NewItem from './items/NewItem';

export default function NewContent() {
  const navigate = useNavigate();
  const moveToFoodLog = () => {
    navigate('/recommend/foodlog/1');
  };
  return (
    <div className="new-content" onClick={() => moveToFoodLog()}>
      <div className="dot"></div>
      <div className="title">
        뚜비언니님을 위한
        <br />
        새로운 추천이 있어요!
      </div>
      <div className="title-line"></div>
      <div className="new-content-item-wrapper">
        <div className="sub-title">수능 선물 고르셨나요?</div>
        <div className="description">
          올해도 어김없이 다가온 대학수학능력시험!
          <br />
          수험생 자녀나 친척 혹은 친한 친구가 있다면 주목!
        </div>
        <div className="new-items slide-wrapper">
          <NewItem />
          <NewItem />
          <NewItem />
          <NewItem />
          <div className="show-all">
            <div className="show-content">
              <div className="arrow"></div>
              <div className="text">
                <span>전체보기</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
