import React, {useEffect} from 'react';
import {Capacitor} from '@capacitor/core';

import useAuth from '@/hooks/useAuth';

import styles from './styles.module.scss';

export default function NaverRedirectHandler() {
  const {loginBySns} = useAuth();
  const getAccessToken = (hash: string) => {
    const accessToken = hash.split('&')[0];
    return accessToken.split('=')[1];
  };
  useEffect(() => {
    const accessToken = getAccessToken(window.location.hash);
    if (accessToken) {
      loginBySns(accessToken, 'naver');
    }
  }, []);
  return (
    <div className="main">
      <div
        className={`${styles.joinContents} ${
          Capacitor.getPlatform() === 'android' ? 'android' : ''
        }`}>
        <div style={{marginTop: '146px'}}>
          <div className={styles.place}>Place for taste & Vibe</div>
          <div className={styles.taste}>맛과 멋이 있는 공간, 뉴뉴</div>
        </div>
        <div className={styles.logo}></div>
        <div>
          <div className={styles.joinTxt}>SNS 계정으로 시작하기</div>
          <div className={styles.snsIcons}>
            <div className={styles.snsIconWrapper}>
              <div className={`${styles.sns} ${styles.kakao}`}>
                <span className="kakao-login-icon">&nbsp;</span>
              </div>
              <span className="txt">카카오</span>
            </div>
            <div className={styles.snsIconWrapper}>
              <div className={`${styles.sns} ${styles.google}`}>
                <span className="google-login-icon">&nbsp;</span>
              </div>
              <span className="txt">구글</span>
            </div>
            {/*<div className={styles.snsIconWrapper}>
              <div className={`${styles.sns} ${styles.naver}`}>
                <span className="naver-login-icon">&nbsp;</span>
              </div>
              <span className="txt">네이버</span>
  </div>*/}
            {Capacitor.getPlatform() === 'ios' && (
              <div className={styles.snsIconWrapper}>
                <div className={`${styles.sns} ${styles.apple}`}>
                  <span className="apple-login-icon">&nbsp;</span>
                </div>
                <span className="txt">애플</span>
              </div>
            )}
          </div>
        </div>
        <div style={{marginTop: '40px'}}>
          <button className={styles.joinEmailBtn}>
            <span className="icon email-icon">&nbsp;</span>
            이메일로 시작하기
          </button>
          <button
            className={styles.joinProblem}
            onClick={() =>
              window.open('https://pf.kakao.com/_YQFcb/chat', '_self')
            }>
            로그인에 문제가 있으신가요?
          </button>
        </div>
        <div className={styles.policyInfo}>
          회원가입 시 뉴뉴 서비스 필수 동의 항목인
          <br />
          <span className={styles.policyLink} style={{cursor: 'pointer'}}>
            개인정보처리방침
          </span>
          과{' '}
          <span className={styles.policyLink} style={{cursor: 'pointer'}}>
            서비스 이용약관
          </span>
          에 동의한 것으로 간주합니다.
        </div>
      </div>
    </div>
  );
}
