import React, {useEffect, useMemo, useState} from 'react';
import {Capacitor} from '@capacitor/core';
import DayJS from 'dayjs';
import {useRecoilState} from 'recoil';

import {isAndroid, isIos, isMobile} from '@/utils';
import {isOpenInstallModal} from '@/store';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

interface Props {
  ignoreRead?: boolean;
  setIgnore?: (is: boolean) => void;
}

export default function InstallAlert({ignoreRead, setIgnore}: Props) {
  const [isMobileWeb, setIsMobileWeb] = useState(false);
  const [isWeb, setIsWeb] = useState(false);
  const [hasRead, setHasRead] = useRecoilState(isOpenInstallModal);
  const [qrPopupShow, setQrPopupShow] = useState(false);
  const key = 'knewnew_has_read_install_alert_with_time';
  const sender = useLogger();
  const setRead = () => {
    const currentTime = DayJS();
    window.localStorage.setItem(key, `${currentTime}`);
    setHasRead(true);
  };
  const onClickContinueWeb = ($event: React.MouseEvent<HTMLButtonElement>) => {
    $event.stopPropagation();
    setRead();
    setIgnore && setIgnore(false);
  };

  const onClickContinueApp = () => {
    sender({
      _msg: 'appmodal_click',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    // pc일 때 팝업 노출
    if (platform === 'web' && !isMobileWeb) {
      setQrPopupShow(true);
    } else {
      moveToAppScheme();
      setRead();
      setIgnore && setIgnore(false);
    }
  };

  const closePopup = () => {
    setRead();
    setIgnore && setIgnore(false);
    setQrPopupShow(false);
  };
  const platform = Capacitor.getPlatform();

  const moveToAppScheme = () => {
    const visitedAt = new Date().getTime();
    console.log(navigator.userAgent);
    setTimeout(() => {
      if (new Date().getTime() - visitedAt < 2000) {
        if (isAndroid()) {
          location.href = 'market://launch?id=com.knewnew.app';
        } else if (isIos()) {
          window.open(
            'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
            '_blank',
          );
        }
      }
    }, 1000);
    // setTimeout(() => {
    //   window.location.href = 'knewnew://home';
    // }, 0);
  };

  const isOpen = useMemo(() => {
    const isCollection = location.pathname.indexOf('collection') > -1;
    const isPopup = location.pathname.indexOf('popup') > -1;
    const isOrder = location.pathname.indexOf('order') > -1;
    const isNoShowPage = isCollection || isPopup || isOrder;
    return (
      (isMobileWeb && ignoreRead && !isNoShowPage) ||
      (isWeb && !isMobileWeb && ignoreRead && !isNoShowPage) ||
      (!hasRead && (isMobileWeb || isWeb) && !isNoShowPage)
    );
  }, [hasRead, isWeb, isMobileWeb, ignoreRead]);

  useEffect(() => {
    setIsWeb(Capacitor.getPlatform() === 'web');
    if (Capacitor.getPlatform() === 'web' && isMobile()) {
      setIsMobileWeb(true);
    }
    setHasRead(true);
    /*
    if (window.localStorage) {
      const readTime = window.localStorage.getItem(key);
      if (readTime) {
        const readedDate = DayJS(readTime);
        const currentTime = DayJS();
        const diffTime = readedDate.diff(currentTime, 'h');
        if (diffTime < 24) {
          setHasRead(true);
        } else {
          setHasRead(false);
          window.localStorage.setItem(
            key,
            currentTime.format('YYYY-MM-DD h:mm:ss'),
          );
        }
      }
    }*/
  }, []);
  return (
    <>
      <div
        className={styles.dimmed_wrapper}
        style={{
          display: isOpen ? 'flex' : 'none',
          zIndex: '15',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <div
          className={`${styles.container} ${
            Capacitor.getPlatform() === 'ios' ? styles.ios : ''
          }}`}>
          <div className={styles.content_wrapper}>
            <div className={styles.badgeArea}>
              <span className={styles.badge}>App</span>
            </div>
            <div className={styles.message_wrapper}>
              <div className={styles.message}>
                <div className={styles.txt}>
                  {`지금 앱을 설치하면\n할인 쿠폰 2장을 드려요!`}
                </div>
                <div className={styles.slog}>
                  뉴뉴 앱에서 더 많은 할인 혜택을 받아보세요.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.btn_wrapper}>
            <button
              className={styles.black_filled_btn}
              onClick={onClickContinueApp}
              style={{cursor: 'pointer'}}>
              {'앱 설치하고 쿠폰받기'}
            </button>
            <button
              className={styles.txt_button}
              onClick={onClickContinueWeb}
              style={{cursor: 'pointer'}}>
              {ignoreRead ? '닫기' : '웹으로 계속 보기'}
            </button>
          </div>
        </div>
      </div>
      {qrPopupShow && (
        <div
          className={styles.dimmed_wrapper}
          style={{
            display: 'flex',
            zIndex: '15',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div className={styles.qr_popup}>
            <p>{`간편한 브랜드 탐색부터 \n구매 혜택까지 경험해 보세요.`}</p>
            <img
              src="/imgs/app_qr.png"
              alt="app qa image"
              width="80px"
              height="80px"
            />
            <span className={styles.desc}>앱 다운로드</span>
            <button
              type="button"
              className={styles.close_btn}
              onClick={closePopup}>
              닫기
            </button>
          </div>
        </div>
      )}
    </>
  );
}
