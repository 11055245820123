import {useEffect, useRef, useState} from 'react';

export default function useInterval(
  callback: () => void,
  interval: number = 5 * 1000,
) {
  const savedCallback = useRef<() => void>();
  const [timerId, setTimerId] = useState<NodeJS.Timer>();
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    const tick = () => {
      (savedCallback.current as typeof callback)();
    };
    const id = setInterval(() => tick(), interval);
    setTimerId(id);
    return () => clearInterval(id);
  }, [interval]);

  return {
    timerId,
  };
}
