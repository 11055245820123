/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router';

import {
  answerNickName,
  answerTargetId,
  commentEditContents,
  isOpenLoginModal,
  ModalCommentID,
  ModalCommentStatus,
  openAnswer,
  OpeningModalStatus,
} from '@/store';
import {likeComment} from '@/services/comment';
import {user, UserInfo} from '@/store/user';
import {timeForToday} from '@/utils';
import emptyKnewnew from '@/products/user_default.svg';

interface Props {
  info: ReviewComment;
  fetch: () => void;
  openModal: (type: 'edit' | 'report') => void;
  writer: string;
}
export default function Comments({
  info,
  fetch,
  openModal: openCommentModal,
  writer,
}: Props) {
  const navigator = useNavigate();
  const {author, content, isLike, created, likeCount, isActive} = info;
  const {nickname, profileImage} = author;
  const userInfo = useRecoilValue(UserInfo);
  const userAccessToken = useRecoilValue(user);
  const commentStatus = useRecoilValue(ModalCommentStatus);
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const setOpeningStatus = useSetRecoilState(OpeningModalStatus);
  const [_answerTargetId, setAnswerTargetId] = useRecoilState(answerTargetId);
  const [openAnswerWrapper, setOpenAnswerWrapper] = useRecoilState(openAnswer);
  const setNickName = useSetRecoilState(answerNickName);
  const commentId = useRecoilValue(ModalCommentID);
  const setModalCommentID = useSetRecoilState(ModalCommentID);
  const setCommentEditContents = useSetRecoilState(commentEditContents);
  const [isDisplay, setIsDisplay] = useState(false);
  const isWriter = useMemo(() => {
    return userInfo && userInfo.nickname === writer;
  }, [info, userInfo, writer]);
  const toggleCommentModal = async () => {
    await setModalCommentID(info.id);
    await setOpeningStatus(
      userInfo && userInfo.nickname === nickname ? 'edit' : 'report',
    );
    openCommentModal(
      userInfo && userInfo.nickname === nickname ? 'edit' : 'report',
    );
  };
  const _likeComment = async () => {
    const id = info.id;
    if (userInfo) {
      await likeComment(id, !isLike, userAccessToken);
      fetch();
    } else {
      setLoginModal(true);
    }
  };
  const cancelAnswer = () => {
    setOpenAnswerWrapper(false);
    setNickName('');
    setAnswerTargetId(-1);
  };
  const makeAnswerMode = (nickName: string) => {
    setOpenAnswerWrapper(true);
    setNickName(nickName);
    setAnswerTargetId(info.id);
    const scrollHeight = document.body.scrollHeight;
    window.scrollTo(0, scrollHeight);
  };
  const moveToProfile = ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.stopPropagation();
    if (info.author.isMarketing) {
      navigator(
        info.author.id === userInfo.id
          ? '/user'
          : `/community/writer/${info.author.id}`,
      );
    }
  };
  useEffect(() => {
    setIsDisplay(isActive === true && author.isActive === true);
    if (commentStatus === 'edit' && commentId === info.id) {
      setCommentEditContents(info.content);
    }
  }, [info, commentStatus, commentId]);
  return (
    <div className="main-comment">
      <div className="user-info">
        <div
          className="user-cont"
          onClick={($event: React.MouseEvent<HTMLDivElement>) =>
            author.isActive && moveToProfile($event)
          }>
          {isDisplay ? (
            nickname === '' && profileImage === undefined ? (
              <div className="writer-image-default"></div>
            ) : (
              <div
                className="writer-image"
                style={{
                  background: `url(${
                    profileImage ? profileImage : emptyKnewnew
                  }) center no-repeat`,
                  backgroundSize: 'cover',
                  width: '30px',
                  height: '30px',
                  borderRadius: '30px',
                }}></div>
            )
          ) : (
            <></>
          )}
          <div className="user-contents">
            <div className="user">
              {isDisplay ? (
                <div className="name">{nickname}</div>
              ) : (
                <div className="name" style={{color: 'grey'}}>
                  {author.isActive
                    ? '삭제된 댓글입니다.'
                    : '탈퇴한 회원입니다.'}
                </div>
              )}
              {isDisplay ? (
                <div
                  className="writer-sticker"
                  style={{display: isWriter ? 'block' : 'none'}}>
                  작성자
                </div>
              ) : (
                <></>
              )}
              {isDisplay ? (
                <div className="date">
                  {timeForToday(created.split('.')[0])}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {isDisplay ? (
          <div className="options" onClick={() => toggleCommentModal()}>
            <span className="dot">&nbsp;</span>
            <span className="dot">&nbsp;</span>
            <span className="dot">&nbsp;</span>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isDisplay ? (
        <p
          className="comment-body"
          style={{
            display:
              commentStatus === 'edit' && commentId === info.id
                ? 'none'
                : 'block',
          }}>
          {content}
        </p>
      ) : (
        <></>
      )}
      {isDisplay ? (
        <div className="comment-footer">
          <div className="enter-nested">
            {info.id !== _answerTargetId && (
              <span
                style={{cursor: 'pointer'}}
                onClick={() => makeAnswerMode(nickname)}>
                답글달기
              </span>
            )}
            {openAnswerWrapper && info.id === _answerTargetId && (
              <span style={{cursor: 'pointer'}} onClick={() => cancelAnswer()}>
                닫기
              </span>
            )}
          </div>
          <div className="dot">
            <div>&nbsp;</div>
          </div>
          <div className="like-btn-wrapper">
            <button
              className={isLike ? 'bold' : ''}
              onClick={() => _likeComment()}>
              좋아요
            </button>
            <span className={isLike ? 'bold cnt' : 'cnt'}>
              {likeCount > 0 ? likeCount : ''}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
