import React from 'react';

import styles from './styles.module.scss';

interface Props {
  wrapperStyle?: React.CSSProperties;
  children: React.ReactElement;
}

export default function ButtonFooter({children, wrapperStyle}: Props) {
  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      {children}
    </div>
  );
}
