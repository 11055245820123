import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useNavigate, useParams} from 'react-router-dom';
import {Carousel} from 'react-responsive-carousel';

import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import BrandAddressSelector from '@/components/brandAddressSelector';
import {BrandListItems, BrandSortInfo, PopularAreas} from '@/store/brand';
import {getNewBrands, getSpeicalBrandBanners} from '@/services/brands';
import {user} from '@/store/user';
import Fetcher from '@/components/common/Fetcher';
import BrandSortBottomSheet from '@/components/brandSortBottomSheet';
import {useScroll} from '@/hooks/useScroll';

import styles from './styles.module.scss';

export default function SpecialList() {
  const {syncScroll, hasScrollPosition, resetScroll} = useScroll();
  const params = useParams();
  const navigator = useNavigate();
  const popularAddresses = useRecoilValue(PopularAreas);
  const accessToken = useRecoilValue(user);
  const sortItems = useRecoilValue(BrandSortInfo);
  const [activeIndicator, setActiveIndicator] = useState(0);
  const [brandItems, setBrandItems] = useRecoilState(BrandListItems);
  const [banners, setBanners] = useState<BrandBannerType[]>([]);
  const [isOpenAS, setIsOpenAS] = useState(false);
  const [isOpenSortModal, setIsOpenSortModal] = useState(false);
  const [args, setArgs] = useState<{
    address1: number;
    address2: number;
    limit: number;
    offset: number;
    sort: 'newest' | 'famous';
  }>({
    address1: 0,
    address2: 0,
    limit: 20,
    offset: 0,
    sort: 'newest',
  });
  const [mode, setMode] = useState<'brand' | 'user'>('brand');

  const swipeEnd = (idx: number) => {
    setActiveIndicator(idx);
  };
  const selectedArea = useMemo(() => {
    const {address1, address2} = params;
    const addressObj =
      address1 === '-2'
        ? popularAddresses.find(o => {
            return o.address === '전국';
          })
        : popularAddresses.find(
            o =>
              address1 &&
              address2 &&
              o.address1 === Number(address1 || -1) &&
              o.address2 === Number(address2 || -1),
          );
    setArgs({
      ...args,
      sort: 'newest',
      address1: Number(address1 || -1),
      address2: Number(address2 || -1),
    });
    return !address1 && !address2
      ? {
          address: 'SPECIAL',
          address1: -1,
          address2: -1,
          id: -2,
        }
      : addressObj;
  }, [params]);
  const getBanners = async () => {
    const res = await getSpeicalBrandBanners();
    return res.data;
  };
  const getData = async () => {
    const {limit, offset, sort} = args;
    const reqParam: BrandListParam = {
      limit,
      offset,
      sort,
      address_1: -1,
      address_2: -1,
      type: 'special',
    };

    const res = await getNewBrands(accessToken, reqParam);
    return res.data.results;
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === '/user/brands/new') {
      setMode('user');
    }
  }, []);
  return (
    <Layout.Main>
      <div className={styles.contents}>
        <ContentsHeader
          txt={'Special'}
          hasDropdown={false}
          isOption={'none'}
          titleClick={() => setIsOpenAS(!isOpenAS)}
          back={async () => {
            console.log('in back');
            await resetScroll('/brand');
            navigator('/brand', {
              replace: true,
            });
          }}
        />
        {mode === 'brand' && (
          <Fetcher fetcher={getBanners} setter={setBanners}>
            <div
              className={styles.banner}
              style={{
                display: selectedArea?.address === 'NEW' ? 'block' : 'none',
              }}>
              <Carousel
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                emulateTouch={false}
                interval={4000}
                className={styles.carousel}
                onClickItem={(index: number) => {
                  navigator(banners[index].link);
                }}
                renderIndicator={(onClickHandler, isSelected, index) => {
                  return (
                    <span
                      className={styles.bannerIndicator}
                      key={`brand_indicators_${index}`}>
                      {activeIndicator + 1} / {banners.length}
                    </span>
                  );
                }}
                onChange={(idx: number) => swipeEnd(idx)}>
                {banners &&
                  banners.map(b => {
                    return (
                      <img
                        key={b.id}
                        src={b.image}
                        alt=""
                        onClick={() => navigator(b.link)}
                        style={{cursor: 'pointer', width: '100%'}}
                      />
                    );
                  })}
              </Carousel>
            </div>
          </Fetcher>
        )}

        <div
          className={styles.filterWrapper}
          style={{
            display: selectedArea?.address !== 'NEW' ? 'flex' : 'none',
          }}>
          <div className={styles.cnt}>{brandItems.length}개의 브랜드</div>
          <div
            className={styles.filter}
            onClick={() => setIsOpenSortModal(true)}>
            <span className={styles.filterIcon}></span>
            <span className={styles.filterTxt}>
              {sortItems.find(o => o.value === args.sort)?.txt}
            </span>
          </div>
        </div>
        <Fetcher
          fetcher={getData}
          setter={setBrandItems}
          args={args}
          syncScroll={
            hasScrollPosition() ? syncScroll : () => window.scrollTo(0, 0)
          }>
          <div
            className={styles.brandList}
            style={{
              minHeight: `calc(100vh - ${mode === 'brand' ? '209' : '85'}px)`,
            }}>
            {/*{brandItems &&*/}
            {/*  brandItems.map(item => {*/}
            {/*    return <BrandListItem key={item.id} item={item} />;*/}
            {/*  })}*/}
          </div>
        </Fetcher>
        {mode === 'brand' && <Layout.BottomNavigation />}

        <BrandAddressSelector
          isOpen={isOpenAS}
          setIsOpen={setIsOpenAS}
          selectedArea={selectedArea as FamousAddress}
        />
        <BrandSortBottomSheet
          isOpen={isOpenSortModal}
          setIsOpen={setIsOpenSortModal}
          currentSort={args.sort}
          toggle={(sort: 'famous' | 'newest') => {
            setArgs({
              ...args,
              sort,
            });
            setIsOpenSortModal(false);
          }}
        />
      </div>
    </Layout.Main>
  );
}
