import React, {useRef} from 'react';
import DaumPostcode from 'react-daum-postcode';
import {ChevronLeft, X} from 'react-feather';
import {Capacitor} from '@capacitor/core';

import styles from '@/components/common/AddressSearchModal/styles.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setupAddress: (address: string, postCode: number) => void;
}
export default function AddressSearchModal({
  isOpen,
  setIsOpen,
  setupAddress,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleComplete = (data: any) => {
    // 상세주소 앞 2단어 제외하고 저장 ('서울 강남구' 제외하고 저장)
    // const splitAddress = data.address.split(' ').splice(2).join(' ');
    setupAddress(data.address, data.zonecode);
    setIsOpen(false);
  };
  return (
    <div
      className={`modal-wrapper`}
      style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : ''}  ${
          styles.option_selector_wrapper
        }`}
        ref={wrapperRef}
        style={
          Capacitor.getPlatform() !== 'web'
            ? {
                padding: '5px 20px 13px 20px',
                top: '35%',
              }
            : {
                padding: '5px 0 13px 0',
              }
        }>
        <div
          className={`filter-modal-header`}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0',
          }}>
          <ChevronLeft
            style={{cursor: 'pointer'}}
            onClick={() => setIsOpen(false)}
          />
          <span>우편번호 검색</span>
          <X style={{cursor: 'pointer'}} onClick={() => setIsOpen(false)} />
        </div>
        <div
          className={`brand-modal-body ${styles.modalBody}`}
          style={{paddingTop: '0px', height: '550px'}}>
          <DaumPostcode onComplete={handleComplete} />
        </div>
      </div>
    </div>
  );
}
