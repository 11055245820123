import React, {useEffect, useState, useRef} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';

import LocationIcon from '@/components/community/AddressSelector/component/LocationIcon';
import useGeoLocation from '@/hooks/useGeoLocation';

import {
  AddressSelectorList,
  BrandAreaListPage,
  CommunityAddress1,
  CommunityAddress2,
  CommunityAddressTxt,
  CommunityListArgs,
  IsAreaSelected,
  isOpenAddressSelector,
  isOpenToast,
  ToastMessage,
} from '../../../store';
import FilterModalHeader from '../../common/FilterModal/FilterModalHeader';
import styles from './styles.module.scss';

interface Props {
  addressList: Address[];
  updateAddress: (address2: string, address3: string) => void;
  setIsGeolocationModalOpen?: (is: boolean) => void;
  setIsLoad?: (is: boolean) => void;
  callback?: () => void;
}

export default function AddressSelector({
  addressList,
  updateAddress,
  setIsGeolocationModalOpen,
  setIsLoad,
  callback,
}: Props) {
  const {permissionState, openGeolocationChecker} = useGeoLocation();
  const [isOpen, setIsOpen] = useRecoilState(isOpenAddressSelector);
  const [selectedAddress1, setSelectedAddress1] = useState<string>('인기 지역');
  const [selectedAddress2, setSelectedAddress2] = useState<string>('');
  const setAddress1 = useSetRecoilState(CommunityAddress1);
  const setAddress2 = useSetRecoilState(CommunityAddress2);
  const setAddressTxt = useSetRecoilState(CommunityAddressTxt);
  const [addressGroups, setAddressGroups] = useRecoilState(AddressSelectorList);
  const [args, setArgs] = useRecoilState(CommunityListArgs);
  const setBrandsPage = useSetRecoilState(BrandAreaListPage);
  const setIsAreaSelected = useSetRecoilState(IsAreaSelected);
  const setIsOpenToastModal = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const close = () => {
    setIsOpen(false);
    initFn();
  };
  const initFn = () => {
    setSelectedAddress1('인기 지역');
    setSelectedAddress2('');
    setArgs({
      address1: '',
      address2: '',
      page: 0,
    });
    setIsOpen(false);
  };
  const complete = () => {
    setAddress1(selectedAddress1);
    setAddress2(selectedAddress2);
    setArgs({
      ...args,
      address1: selectedAddress1,
      address2: selectedAddress2,
      page: 0,
    });
    setAddressTxt(`${selectedAddress2}`);
    updateAddress(selectedAddress1, selectedAddress2);
    setIsOpen(false);
  };
  const clickAddress1 = (txt: string) => {
    setSelectedAddress1(txt);
  };
  const clickAddress2 = (txt: string) => {
    setSelectedAddress2(txt);
  };

  const toggleCurrentPosition = () => {
    if (permissionState?.location === 'granted') {
      setIsAreaSelected(false);
      setIsLoad && setIsLoad(false);
      setIsOpen(false);
      setBrandsPage(0);
      callback && callback();
    } else {
      if (Capacitor.getPlatform() === 'web') {
        setIsOpenToastModal(true);
        setToastMessage('브라우저 설정에서 위치정보 사용을 허용해주세요');
      } else {
        openGeolocationChecker && openGeolocationChecker();
        // checkGeolocation && checkGeolocation();
        // setIsGeolocationModalOpen && setIsGeolocationModalOpen(true);
        console.log(setIsGeolocationModalOpen);
      }
      setIsOpen(false);
    }
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // document.addEventListener('mousedown', handleClickOutside);
    document.querySelector('.addressGroup1')?.addEventListener('scroll', () => {
      console.log('scrolled');
    });
    document
      .querySelector('.addressGroup1')
      ?.addEventListener('touchmove', () => {
        console.log('in touch move');
      });
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
      document
        .querySelector('.addressGroup1')
        ?.removeEventListener('scroll', () => {
          console.log('scrolled');
        });
    };
  }, []);

  useEffect(() => {
    if (addressList) {
      let _adddressGroup: AddressSelectorInfo[] = [];
      addressList.forEach(address => {
        _adddressGroup.push({
          groupName: address.address,
          addressType: 1,
          address1: [address.id],
          addressList: (address.child as Address[]).map(o => {
            return {
              txt: o.address,
              address2: [o.id],
            };
          }),
        });
      });
      _adddressGroup = _adddressGroup.map(group => {
        const item: AddressItemInfo = {
          txt: '전체',
          address2: [undefined],
        };
        return {
          ...group,
          addressList: [item, ...group.addressList],
        };
      });
      setAddressGroups(_adddressGroup);
      clickAddress1(_adddressGroup[0].groupName);
    }
  }, [addressList]);

  return (
    <div className={`modal-wrapper ${isOpen ? '' : styles.hide}`}>
      <div
        className={`modal-contents category ${styles.address}`}
        ref={wrapperRef}>
        <FilterModalHeader
          txt={'지역 선택'}
          close={() => close()}
          hasInit={true}
          withPadding={true}
          initFn={() => initFn()}
          fnTxt=""
        />
        <div style={{padding: '0 20px 10px 20px'}}>
          <button
            className={styles.btnLocation}
            onClick={() => toggleCurrentPosition()}
            style={{
              height: '46px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              border: '1px solid var(--color-gray-2, #E9E7EC)',
              background: 'var(--color-white, #FFF)',
            }}>
            <LocationIcon />
            <span style={{marginLeft: '10px'}}>현위치에서 찾기</span>
          </button>
        </div>
        {isOpen && (
          <div className={`category-modal-body ${styles.address_selector}`}>
            <div className={`${styles.address1_group} addressGroup1`}>
              {addressGroups.map((group, idx) => {
                return (
                  <div
                    key={`address_group1_${idx}`}
                    className={
                      selectedAddress1 === group.groupName
                        ? `${styles.toggled}`
                        : ''
                    }
                    onClick={() => clickAddress1(group.groupName)}
                    style={{cursor: 'pointer'}}>
                    {group.groupName}
                  </div>
                );
              })}
            </div>
            <div className={styles.address2_group}>
              <ul>
                {selectedAddress1 &&
                  selectedAddress1.length > 0 &&
                  addressGroups
                    .find(ag => ag.groupName === selectedAddress1)
                    ?.addressList.map((o, i) => {
                      return (
                        <li
                          key={`address_group2_${i}`}
                          className={selectedAddress2 === o.txt ? 'active' : ''}
                          onClick={() => clickAddress2(o.txt)}
                          style={{cursor: 'pointer'}}>
                          {o.txt}
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        )}
        <div className={`filter-modal-footer ${styles.address_footer}`}>
          <button
            className="modal btn black-filled"
            disabled={
              !(selectedAddress1.length > 0 && selectedAddress2.length > 0)
            }
            onClick={() => complete()}
            style={{
              background: !(
                selectedAddress1.length > 0 && selectedAddress2.length > 0
              )
                ? '#E9E7EC'
                : '#000',
            }}>
            검색
          </button>
        </div>
      </div>
    </div>
  );
}
