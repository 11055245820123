import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CollectionLoader() {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={380}
        height={1450}
        viewBox="0 0 380 1450"
        backgroundColor="#F5F5F5"
        foregroundColor="#EFEFEF"
        style={{width: '100%'}}>
        <rect x="20" y="20" rx="0" ry="0" width="335" height="335" />
        <rect x="20" y="78" rx="0" ry="0" width="335" height="32" />
        <rect x="20" y="375" rx="0" ry="0" width="206" height="32" />
        <rect x="20" y="413" rx="0" ry="0" width="335" height="200" />
        <rect x="20" y="505" rx="0" ry="0" width="335" height="335" />
        <rect x="20" y="860" rx="0" ry="0" width="206" height="32" />
        <rect x="20" y="990" rx="0" ry="0" width="335" height="335" />
        <rect x="20" y="1345" rx="0" ry="0" width="206" height="32" />
        <rect x="20" y="1383" rx="0" ry="0" width="335" height="32" />
      </ContentLoader>
    </div>
  );
}
