import styled from 'styled-components';

export const BenefitShortCutWrapper = styled.button`
  z-index: 2;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px 6px 6px;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: rgba(18, 71, 120, 0.6);
`;
