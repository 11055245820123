import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import {isAndroid, isMobile} from '@/utils';

import BrandImageSliderHeader from '../../molecules/BrandImageSliderHeader/BrandImageSliderHeader';
import {
  ImageSlideWrapper,
  ImgWrapper,
  SliderItemsWrapper,
} from './brandImageSlider.styled';
import Image from '../../atoms/Image/Image';

interface ImageType {
  src: string;
}

interface Props<T extends ImageType> {
  images: T[];
  selectedImg: number;
  setSelectedImg: (idx: number) => void;
  close: () => void;
}

export default function BrandImageSlider<T extends ImageType>({
  images,
  selectedImg,
  setSelectedImg,
  close,
}: Props<T>) {
  return (
    <ImageSlideWrapper mobile={isMobile()} android={isAndroid()}>
      <BrandImageSliderHeader
        close={close}
        images={images}
        selectedImg={selectedImg}
      />
      <SliderItemsWrapper mobile={isMobile()}>
        <Swiper
          initialSlide={selectedImg}
          className="squareSwiper"
          slideToClickedSlide={true}
          onRealIndexChange={(el: any) => setSelectedImg(el.activeIndex)}>
          {images?.map((o, i) => (
            <SwiperSlide key={`slide-item-${i}`}>
              <ImgWrapper
                key={`brand_image_carousel_${i}`}
                mobile={isMobile()}
                data-order={`${i}`}>
                <Image src={o.src} width={100} height={100} />
              </ImgWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderItemsWrapper>
    </ImageSlideWrapper>
  );
}
