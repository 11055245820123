import React from 'react';

interface Props {
  link: string;
}

export default function LinkToShopBtn({link}: Props) {
  const moveTo = () => {
    window.open(link, '_blank');
  };
  return (
    <div
      className="link-to-shop-button"
      style={{cursor: 'pointer'}}
      onClick={() => moveTo()}>
      <span className="link-to-shop-icon">&nbsp;</span>
      <span>마켓컬리에서 보기</span>
    </div>
  );
}
