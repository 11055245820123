import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {doBookMark} from '../../../services/magazine';
import {MagazineDetailInfo} from '../../../store';
import {user} from '../../../store/user';
import {switchNewLineTxt} from '../../../utils';
import BrandItemLoader from '../../common/Skeleton/BrandItemLoader';

interface Props {
  info: MagazineItem;
  type: 'news' | 'zip' | 'brands' | 'showroom' | undefined;
  isBookMarked?: boolean;
  makeToast?: (is: boolean) => void;
}

export default function BookmarkCard({
  info,
  type,
  isBookMarked,
  makeToast,
}: Props) {
  const userToken = useRecoilValue(user);
  const navigator = useNavigate();
  const setMagazineDetailInfo = useSetRecoilState(MagazineDetailInfo);
  const [isLoadImage, setIsLoadImage] = useState(false);
  const [isDisableBookMark, setIsDisableBookMark] = useState(false);
  const disableBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.stopPropagation();
    $event.preventDefault();
    await doBookMark(info.id, false, userToken);
    setIsDisableBookMark(true);
    makeToast && makeToast(true);
  };
  const enableBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.stopPropagation();
    $event.preventDefault();
    await doBookMark(info.id, true, userToken);
    setIsDisableBookMark(false);
    makeToast && makeToast(false);
  };
  const loadImag = (imgPath: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        setIsLoadImage(true);
        resolve(img);
      };
      img.onerror = () => {
        reject(null);
      };
      img.src = imgPath;
    });
  };
  const moveTo = async () => {
    const isFromBookmark = location.pathname.indexOf('bookmark') > -1;
    await setMagazineDetailInfo(info);
    navigator(`/${type}/${info.id}`, {
      state: {
        bookmark: isFromBookmark,
      },
    });
  };

  const init = () => {
    // init component state
    setIsLoadImage(false);
    setIsDisableBookMark(false);
  };

  useEffect(() => {
    init();
    loadImag(info.imageUrl);
  }, [info]);

  return isLoadImage ? (
    <div className="brand-card" onClick={() => moveTo()}>
      <div className="picture-info-wrapper">
        <div
          className="picture-wrapper"
          style={{
            background: `center / cover no-repeat url('${info.imageUrl}')`,
            borderRadius: '5px',
          }}></div>
        <div className="info-wrapper">
          <div className="brand-type">
            <div className="type">
              {`Knew ${info.category?.replace(/^[a-z]/, char =>
                char.toUpperCase(),
              )}`}
            </div>
          </div>
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: switchNewLineTxt(info.title || ''),
            }}></div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: switchNewLineTxt(info.subtitle || ''),
            }}></div>
        </div>
      </div>
      <div className="bookmark-info-wrapper">
        <div
          className="bookmarked-icon-wrapper"
          onClick={($event: React.MouseEvent<HTMLDivElement>) =>
            disableBookmark($event)
          }
          style={{
            display: isBookMarked && !isDisableBookMark ? 'block' : 'none',
          }}>
          <span className="bookmark-pink-filled">&nbsp;</span>
        </div>
        <div
          className="bookmarked-icon-wrapper"
          onClick={($event: React.MouseEvent<HTMLDivElement>) =>
            enableBookmark($event)
          }
          style={{
            display: isBookMarked && isDisableBookMark ? 'block' : 'none',
          }}>
          <span className="bookmark-pink">&nbsp;</span>
        </div>
      </div>
    </div>
  ) : (
    <BrandItemLoader />
  );
}
