/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {Capacitor} from '@capacitor/core';
import {useRecoilState} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import {addComma} from '@/utils';
import Layout from '@/layouts';
import useNative from '@/hooks/useNative';
import useKakao from '@/hooks/useKakao';
import ContactModal from '@/components/contactModal';
import {PathHistories} from '@/store';

import styles from './styles.module.scss';

interface MetaInfo {
  title: string;
  options: string[];
  name: string;
  phoneNumber: string;
  price: string;
  imageUrl: string;
  issuerName: string;
  discountPrice: number;
  address1?: string;
  address2?: string;
  postCode?: string;
  requests?: string;
  popupReserveLink?: string;
  popupReserveNumber?: string;
  deliveryFee?: number;
  orderRequest?: string;
}

export default function OrderSuccess() {
  const {Echo} = useNative();
  const kakao = useKakao();
  const navigator = useNavigate();
  const location = useLocation();
  const [histories, setHistories] = useRecoilState(PathHistories);
  const [isOpenContactModal, setIsOpenContactModal] = useState(false);
  const [metaInfo, setMetaInfo] = useState<MetaInfo>();
  const [isDelivery, setIsDelivery] = useState(false);
  const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

  const makeUrl = () => {
    const host =
      process.env.NODE_ENV !== 'development'
        ? 'https://knewnew.co.kr'
        : 'https://dev.knewnew.co.kr';
    return `${host}/popup/${(location.state.magazine as MagazineItem).id}`;
  };

  const getOptions = () => {
    const items: {item: string; itemOp: string}[] =
      metaInfo?.options.map(option => {
        return {
          item: '-',
          itemOp: option,
        };
      }) ?? [];
    items.push({item: '-', itemOp: metaInfo?.name ?? ''});
    return items;
  };

  const shareKakao = async () => {
    if (Capacitor.getPlatform() === 'web') {
      kakao.shareOrder({
        title: metaInfo?.title ?? '',
        imageUrl: metaInfo?.imageUrl ?? '',
        link: makeUrl(),
        items: getOptions(),
      });
    } else {
      await Echo.echo({
        value: `share*${metaInfo?.title}*${''}*${
          metaInfo?.imageUrl || ''
        }*${makeUrl()}*${`/popup/${
          (location.state.magazine as MagazineItem).id
        }`}`,
      });
    }
  };

  useEffect(() => {
    if (location.state) {
      const _metaInfo = {
        title: (location.state.magazine as MagazineItem).title,
        options: location.state.options,
        name: location.state.name,
        phoneNumber: location.state.phoneNumber,
        price: location.state.price,
        imageUrl: (location.state.magazine as MagazineItem).imageUrl,
        issuerName: location.state.issuerName,
        discountPrice: location.state.discountPrice || 0,
        productPrice: location.state.productPrice || 0,
        address1: location.state.address,
        address2: location.state.detailAddress,
        postCode: location.state.postCode,
        requests: location.state.userOption,
        count: location.state,
        popupReserveLink: location.state.popupReserveLink,
        popupReserveNumber: location.state.popupReserveNumber,
        deliveryFee: location.state.deliveryFee,
        orderRequest: location.state.orderRequest,
      };
      setIsDelivery(!!location.state.address);
      setMetaInfo(_metaInfo);
    }
  }, [location.state]);

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      navigator(`/popup/${(location.state.magazine as MagazineItem).id}`);
    });
  });

  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt={'결제 완료'}
          isPopup
          isOption="none"
          isBottomLine=""
          back={() => {
            setHistories([...histories].splice(0, histories.length - 1));
            navigator(
              `/popup/${(location.state.magazine as MagazineItem).id}`,
              {
                replace: true,
                state: {
                  prev: 'home',
                },
              },
            );
          }}
        />

        {metaInfo && (
          <div className={styles.success_info}>
            <div className={styles.success_message}>
              <div className={styles.check_wrapper}>
                {/* <Check size={28} color={'#000'} /> */}
              </div>
              <div className={styles.message}>주문이 완료되었습니다</div>
              <div className={styles.product_info}>
                <div className={styles.header}>
                  <h2>구매완료</h2>
                </div>
                <div className={styles.product}>
                  <div
                    className={styles.img_wrapper}
                    style={{
                      borderRadius: '2px',
                      background: `url('${metaInfo.imageUrl}') no-repeat center / cover`,
                    }}
                    onClick={() =>
                      navigator(
                        `/popup/${
                          (location.state.magazine as MagazineItem).id
                        }`,
                        {
                          state: {
                            prev: 'home',
                          },
                        },
                      )
                    }></div>
                  <div className={styles.txt_wrapper}>
                    <h2>{metaInfo.title}</h2>
                    <div className={styles.option_info}>
                      {metaInfo.options.map(option => {
                        return (
                          <div className={styles.option} key={option}>
                            {option}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {((metaInfo?.popupReserveLink || '').length > 0 ||
                  (metaInfo?.popupReserveNumber || '').length > 0) && (
                  <div className={styles.date_btn_wrapper}>
                    <button
                      className={styles.date_btn}
                      onClick={() => setIsOpenContactModal(true)}>
                      <span className={styles.icon}></span>
                      날짜 선택
                    </button>
                    <div className={styles.confirmWarning}>
                      <p>{`*마이 > 구매내역 > 주문상세에서도 가능합니다.`}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* 안내사항 */}
            {(location.state.magazine as MagazineItem)?.popupInfo?.guideTxt && (
              <div className={styles.info_area}>
                <h2>안내사항</h2>
                <p className={styles.info_text}>
                  {location.state.magazine?.popupInfo.guideTxt}
                </p>
                {location.state.magazine?.popupInfo.guideUrl && (
                  <a
                    href={location.state.magazine?.popupInfo.guideUrl}
                    target="_black"
                    className={styles.info_link}>
                    {location.state.magazine?.popupInfo.guideUrl}
                  </a>
                )}
              </div>
            )}
            {isDelivery ? (
              <div className={styles.buyer_info_row}>
                <h2>배송지 정보</h2>
                <div className={styles.info_row}>
                  <div className={styles.label}>이름</div>
                  <div className={styles.contents}>{metaInfo.name}</div>
                </div>
                <div className={styles.info_row}>
                  <div className={styles.label}>휴대폰 번호</div>
                  <div className={styles.contents}>{metaInfo.phoneNumber}</div>
                </div>
                <div className={styles.info_row}>
                  <div className={styles.label}>주소</div>
                  <div
                    className={
                      styles.contents
                    }>{`[${metaInfo.postCode}]${metaInfo.address1} ${metaInfo.address2}`}</div>
                </div>
                {metaInfo.requests && (
                  <div className={styles.info_row}>
                    <div className={styles.label}>배송 요청사항</div>
                    <div className={styles.contents}>{metaInfo.requests}</div>
                  </div>
                )}
                {metaInfo.orderRequest && (
                  <div className={styles.info_row}>
                    <div className={styles.label}>주문 요청사항</div>
                    <div className={styles.contents}>
                      {metaInfo.orderRequest}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.buyer_info_row}>
                <h2>구매자 정보</h2>
                <div className={styles.info_row}>
                  <div className={styles.label}>이름</div>
                  <div className={styles.contents}>{metaInfo.name}</div>
                </div>
                <div className={styles.info_row}>
                  <div className={styles.label}>휴대폰 번호</div>
                  <div className={styles.contents}>{metaInfo.phoneNumber}</div>
                </div>
                {metaInfo.orderRequest && (
                  <div className={styles.info_row}>
                    <div className={styles.label}>주문 요청사항</div>
                    <div className={styles.contents}>
                      {metaInfo.orderRequest}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={`${styles.pay_info_row}`}>
              <h2>결제 정보</h2>
              <div className={`${styles.info_row}  ${styles.with_sub}`}>
                <div className={styles.label}>결제금액</div>
                <div className={styles.contents}>{`${addComma(
                  Number.parseInt(metaInfo.price),
                )}원`}</div>
              </div>
              <div className={styles.sub_info_row}>
                <div>∙ 상품 금액</div>
                <div>
                  {addComma(
                    Number.parseInt(metaInfo.price) +
                      metaInfo.discountPrice -
                      (metaInfo.deliveryFee || 0),
                  )}
                  원
                </div>
              </div>
              <div className={styles.sub_info_row}>
                <div>∙ 쿠폰 할인</div>
                <div>{'-' + addComma(metaInfo.discountPrice) + '원'}</div>
              </div>
              {isDelivery && (
                <div className={styles.sub_info_row}>
                  <div>∙ 배송비</div>
                  <div>{addComma(metaInfo.deliveryFee || 0) + '원'}</div>
                </div>
              )}
              <div
                className={styles.info_row}
                style={{
                  borderTop: '1px solid #F7F7FC',
                }}>
                <div className={styles.label}>결제수단</div>
                <div className={styles.contents}>{metaInfo.issuerName}</div>
              </div>
              <div className={styles.info_row}>
                <div className={styles.label}>결제일시</div>
                <div className={styles.contents}>{date}</div>
              </div>
            </div>
            <div className={styles.alert_message}>
              {`* 결제 및 계좌 안내 시 상호명은 (주)뉴뉴로 표기됩니다.`}
            </div>
            <div className={styles.tooltip_area}>
              <div className={styles.tooltip}>
                구매 정보를 친구에게 공유해보세요
              </div>
            </div>
            <div className={styles.btn_wrapper}>
              <button
                className={styles.share_btn}
                onClick={() => shareKakao()}
              />
              <button
                className={styles.btn_home}
                onClick={() => navigator('/home')}>
                홈으로
              </button>
              <button
                className={styles.btn_buy_list}
                onClick={() => navigator('/order/list')}>
                구매내역
              </button>
            </div>
            <ContactModal
              isOpen={isOpenContactModal}
              setIsOpen={(is: boolean) => setIsOpenContactModal(is)}
              info={{
                tel: metaInfo.popupReserveNumber || '',
                link: metaInfo.popupReserveLink || '',
              }}
            />
          </div>
        )}
      </div>
    </Layout.Main>
  );
}
