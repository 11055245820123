import {useEffect, useRef, useState} from 'react';

export default function useImageHeight() {
  const imageRef = useRef<HTMLImageElement>(null);
  const [height, setHeight] = useState(0);
  const callbackResize = () => {
    if (imageRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      setHeight(width);
    }
  };
  useEffect(() => {
    if (imageRef.current) {
      const width = imageRef.current.getBoundingClientRect().width;
      setHeight(width);
    }
    window.addEventListener('resize', callbackResize, false);
    return () => {
      window.removeEventListener('resize', callbackResize, false);
    };
  }, [imageRef]);

  return {
    imageRef,
    height,
  };
}
