import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {user} from '@/store';
import {getNewBrands} from '@/services/brands';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from '../styles.module.scss';

interface RecommendBrands {
  id: number;
  subtitle: string;
  brandList: BrandListItemType[];
  title: string;
  type: string;
}

export default function PopularBrands() {
  const navigator = useNavigate();
  const token = useRecoilValue(user);
  const sender = useLogger();
  const [recommendBrands, setRecommendBrands] = useState<RecommendBrands[]>([]);
  const getRecommendBrandData = async () => {
    const res = await getNewBrands(token, {
      limit: 9999,
      offset: 0,
      sort: 'famous',
      type: 'search_popular',
      image: false,
    });
    setRecommendBrands(res.data);
  };
  const moveTo = (id: number, parentId: number) => {
    navigator(`/brand/${parentId}/${id}`);
  };
  useEffect(() => {
    getRecommendBrandData();
  }, []);
  return (
    <div className={styles.popularBrands}>
      {recommendBrands.map((brand: RecommendBrands) => {
        return (
          <div
            key={`brand_list_wrapper_${brand.id}`}
            style={{marginBottom: '40px'}}>
            <h3 className={styles.tit}>{brand.title}</h3>
            <div className={styles.popBrandsContainer}>
              {brand.brandList.map((b, idx) => {
                return (
                  <span
                    className={styles.brandInfo}
                    onClick={() => {
                      moveTo(b.id, b.parentId);
                      sender({
                        _msg: 'searchList' + idx + '_' + b.id,
                        _screen: window.location.pathname,
                        _action: Action.CLICK,
                      });
                    }}
                    key={`recommend_brand_${b.id}`}
                    style={{cursor: 'pointer'}}>
                    <span className={styles.brandName}>{b.title}</span>
                    <span className={styles.brandCategory}>
                      ∙{b.collectionKeyword}
                    </span>
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
