import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';
interface Props {
  isBlack?: boolean;
  fixedHeader?: boolean;
}

export default function Loader({isBlack, fixedHeader}: Props) {
  return (
    <div className={cc([styles.wrapper, {[styles.fixedHeader]: fixedHeader}])}>
      <span className={`${styles.icon} ${isBlack ? 'black' : ''}`}></span>
    </div>
  );
}
