import {StatusBar, Style} from '@capacitor/status-bar';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {useTranslation} from 'react-i18next';

import AuthButton from '@/components/join/AuthButton';
import JoinButtons from '@/components/join/Button';
import JoinField from '@/components/join/JoinField';
import {useDebouncedEffect} from '@/hooks/useDebounceEffect';
import useInterval from '@/hooks/useInterval';
import useStorage from '@/hooks/useStorage';
import ContentsHeader from '@/layouts/ContentsHeader';
import {
  checkValidEmail,
  login,
  userDetail,
  verifyEmail,
  verifyEmailCode,
} from '@/services/user';
import {countdown} from '@/utils';
import {
  LoginPrevPath,
  ToastMessage,
  isOpenToast,
  user,
  UserInfo,
} from '@/store';

import styles from './styles.module.scss';

type loginType = 'kakao' | 'naver' | 'google' | 'apple' | 'email';
type Fields = 'email' | 'code' | 'password' | 'rePassword';
type emailErrorMsg = {
  msg: string;
};
export type EmailErrorMsg = Record<loginType, emailErrorMsg>;
type EmailValidError = {
  msg: string;
  providerType: loginType;
};
type UserType = 'existed-user' | 'new-user' | 'signed-user' | 'entered-email';

export default function EmailJoin() {
  const navigator = useNavigate();
  const {t} = useTranslation(['views'], {keyPrefix: 'join.EmailJoin'});
  const {setToken, setLoginProvider} = useStorage();
  const [fields, setFields] = useState<Fields[]>(['email']);
  const [userType, setUserType] = useState<UserType>();
  const [isShowEmailVerifyReq, setisShowEmailVerifyReq] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasCodeError, setHasCodeError] = useState(false);
  const [hasPasswdError, setHasPasswdError] = useState(false);
  const [hasConfirmPwdError, setHasConfirmPwdError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [code, setCode] = useState('');
  const [emailAuthState, setEmailAuthState] =
    useState<VerificationType>('active');
  const [codeAuthState, setCodeAuthState] =
    useState<VerificationType>('code-active');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [headerMessage, setHeaderMessage] = useState(t('headerMsg_default'));
  const [hasConfirmedPwd, setHasConfirmedPwd] = useState(true);
  const [timeTxt, setTimeTxt] = useState('3:00');
  const [counter, setCounter] = useState(3 * 60);
  const [codeErrorMessage, setCodeErrorMessage] = useState(t('codeErrMsg'));
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setAccessToken = useSetRecoilState(user);
  const prevPath = useRecoilValue(LoginPrevPath);
  const isAllSet = useMemo(() => {
    let isSet = false;
    if (userType === 'new-user') {
      const validChecker =
        !hasEmailError &&
        !hasPasswdError &&
        !hasCodeError &&
        !hasConfirmPwdError;
      const lengthChecker =
        email.length > 0 &&
        password.length > 0 &&
        code.length > 0 &&
        confirmPwd.length > 0;

      isSet = validChecker && lengthChecker;
    } else {
      const validChecker = !hasEmailError && !hasPasswdError;
      const lengthChekcer = email.length > 0 && password.length > 0;
      isSet = validChecker && lengthChekcer;
    }
    return isSet;
  }, [
    email,
    userType,
    hasEmailError,
    hasCodeError,
    hasPasswdError,
    hasConfirmPwdError,
    password,
    confirmPwd,
    hasConfirmedPwd,
    code,
  ]);
  const emailErrorMsgObj: EmailErrorMsg = {
    kakao: {
      msg: t('dupKakao'),
    },
    naver: {
      msg: t('dupNaver'),
    },
    google: {
      msg: t('dupGoogle'),
    },
    apple: {
      msg: t('dupApple'),
    },
    email: {
      msg: t('dupEmail'),
    },
  };
  const updateFields = (field: Fields[]) => {
    let f = [...fields];
    f = [...f, ...field];
    setFields(f);
  };
  const isShowField = useCallback(
    (f: Fields) => {
      return fields.indexOf(f) > -1;
    },
    [fields],
  );
  const verifyEmailValid = async (email: string) => {
    try {
      await checkValidEmail(email);
      setisShowEmailVerifyReq(true);
      setHeaderMessage(t('headerMsg_email'));
      setUserType('new-user');
    } catch (e: any) {
      if (e.response && e.response.status) {
        const providerType = (e.response.data as EmailValidError).providerType;
        if (providerType === 'email') {
          setHasEmailError(false);
          updateFields(['password']);
          setUserType('existed-user');
          setHeaderMessage(t('headerMsg_exist'));
        } else {
          const msg =
            emailErrorMsgObj[(e.response.data as EmailValidError).providerType]
              ?.msg;
          setHasEmailError(true);
          setUserType('signed-user');
          setEmailErrorMsg(`${t('dupErrMsg1') + msg + t('dupErrMsg2')}`);
        }
      }
    }
  };

  const sendVerifyEmail = async () => {
    if (emailAuthState === 'active' || emailAuthState === 're-send') {
      const _userInfo = {...userInfo};
      await verifyEmail(email);
      setEmailAuthState('re-send');
      updateFields(['code']);
      setCounter(3 * 60);
      setTimeTxt('3:00');
      setToastMessage(t('toastMsg'));
      setHasCodeError(false);
      setCodeAuthState('code-active');
      setCode('');
      setIsOpenToast(true);
      setUserInfo({
        ..._userInfo,
        email,
      });
      setTimeout(() => {
        setIsOpenToast(false);
      }, 2000);
    }
  };

  const checkVerifyEmail = async () => {
    try {
      const _userInfo = {...userInfo};
      await verifyEmailCode(email, code);
      setCodeAuthState('success');
      updateFields(['password', 'rePassword']);
      clearInterval(timerId);
      setHasCodeError(false);
      setUserInfo({
        ..._userInfo,
        code,
      });
    } catch (e) {
      setHasCodeError(true);
      setCodeAuthState('error');
      setCodeErrorMessage(t('codeErrMsg'));
    }
  };

  const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    $event.currentTarget.value = $event.currentTarget.value.toLocaleLowerCase();
    setEmail($event.currentTarget.value);
    setisShowEmailVerifyReq(false);
  };

  const onCodeChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setCode($event.currentTarget.value);
    setCodeAuthState('code-active');
  };

  const onPasswordChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const pwd = $event.currentTarget.value;
    setPassword(pwd);
    const passwordRegexp =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const isNewUser = userType === 'new-user';
    if (isNewUser && pwd.length > 0 && !passwordRegexp.test(pwd)) {
      setHasPasswdError(true);
    } else {
      setHasPasswdError(false);
    }
  };

  const onConfirmPwdChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const _userInfo = {...userInfo};
    if (
      $event.currentTarget.value.length > 0 &&
      $event.currentTarget.value !== password
    ) {
      setHasConfirmPwdError(true);
      setHasConfirmedPwd(false);
    } else {
      setHasConfirmPwdError(false);
      setHasConfirmedPwd(true);
      setUserInfo({
        ..._userInfo,
        password: $event.currentTarget.value,
      });
    }
    setConfirmPwd($event.currentTarget.value);
  };

  const toggleLogin = useCallback(async () => {
    try {
      const res = await login('provider_key', 'email', email, password);
      setToken(res.data.accessToken);
      await setAccessToken(res.data.accessToken);
      const userRes = await userDetail(res.data.accessToken);
      setUserInfo({...userRes.data});
      setLoginProvider('email');
      navigator(prevPath ? prevPath : '/home');
    } catch (e) {
      setHasPasswdError(true);
    }
  }, [password, email]);

  const toggleSignup = useCallback(async () => {
    setLoginProvider('email');
    navigator('/signup');
  }, [email, password, code]);

  const checkLogin = () => {
    navigator('/login', {
      replace: true,
    });
  };

  const updateCounterTxt = (cnt: number) => {
    return new Promise(resolve => {
      const obj = countdown(cnt);
      setTimeTxt(obj.txt);
      resolve('');
    });
  };

  const btnType = useMemo(() => {
    const enteredEmail = userType === 'entered-email';
    const signedUser = !isAllSet && userType === 'signed-user';
    const completeNewUser = isAllSet && userType === 'new-user';
    const completeExistedUser = isAllSet && userType === 'existed-user';
    let type: 'grayFilled' | 'blackFilled' | 'whiteFilled' = 'grayFilled';
    if (enteredEmail || signedUser || completeNewUser) {
      type = 'whiteFilled';
    } else if (completeExistedUser) {
      type = 'blackFilled';
    }
    return type;
  }, [userType, isAllSet]);

  const btnCallback = useMemo(() => {
    return userType === 'existed-user'
      ? toggleLogin
      : userType === 'entered-email'
      ? verifyEmailValid
      : userType === 'signed-user'
      ? checkLogin
      : toggleSignup;
  }, [userType, email, password, confirmPwd, code]);

  const btnTxt = useMemo(() => {
    let txt = '계속하기';
    switch (userType) {
      case 'entered-email':
        txt = '계속하기';
        break;
      case 'existed-user':
        txt = '로그인';
        break;
      case 'new-user':
        txt = '다음으로';
        break;
      case 'signed-user':
        txt = '계속하기';
        break;
    }
    return txt;
  }, [userType]);

  useDebouncedEffect(
    () => {
      if (email.length > 0) {
        if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
          setHasEmailError(false);
          setUserType('entered-email');
        } else {
          setEmailErrorMsg(t('emailFormatErrMsg'));
          setHasEmailError(true);
          setisShowEmailVerifyReq(false);
        }
      }
    },
    500,
    [email],
  );

  const init = () => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Default,
      });
    }
  };

  const {timerId} = useInterval(async () => {
    if (isShowField('code')) {
      if (counter === 0) {
        setHasCodeError(true);
        setCodeAuthState('error');
        setCodeErrorMessage(t('codeTimeoutErrMsg'));
      } else {
        await updateCounterTxt(counter);
        setCounter(counter - 1);
      }
    }
  }, 1000);

  useEffect(() => {
    init();
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    return () => {
      window.removeEventListener('reisze', () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    };
  }, []);

  return (
    <div className={`main ${styles.container}`}>
      <ContentsHeader txt={t('header')} isOption={'none'} />
      <div className={`${styles.welcome} contents-body`}>
        <strong>Welcome!</strong>
        <span>{headerMessage}</span>
      </div>
      <div className="join-fields">
        <JoinField
          type={'email'}
          value={email}
          isShow={isShowField('email')}
          disabled={isShowEmailVerifyReq}
          onChange={onChange}
          rightInfo={
            <AuthButton
              verification={emailAuthState}
              isShow={isShowEmailVerifyReq}
              onClick={() => sendVerifyEmail()}
            />
          }
          bottomInfo={
            <div>
              <div
                className={styles.error}
                style={{display: hasEmailError ? 'block' : 'none'}}>
                {emailErrorMsg}
              </div>
              <div
                className={styles.retry}
                style={{
                  display:
                    isShowEmailVerifyReq && !isShowField('code')
                      ? 'block'
                      : 'none',
                }}
                onClick={() => location.reload()}>
                {t('resendEmailBtn')}
              </div>
            </div>
          }
        />
        <JoinField
          type={'verification'}
          value={code}
          isShow={isShowField('code')}
          onChange={onCodeChange}
          disabled={codeAuthState === 'success'}
          rightInfo={
            <div className={styles.auth_button_wrapper}>
              <div
                className={styles.time}
                style={{
                  display: codeAuthState === 'code-active' ? 'block' : 'none',
                }}>
                {timeTxt}
              </div>
              <AuthButton
                verification={codeAuthState}
                isShow={true}
                disabled={code.length === 0}
                onClick={() =>
                  codeAuthState === 'code-active' ? checkVerifyEmail() : {}
                }
              />
            </div>
          }
          bottomInfo={
            <div
              className={styles.error}
              style={{display: hasCodeError ? 'block' : 'none'}}>
              <span className="small-pink-x-icon">&nbsp;</span>
              {codeErrorMessage}
            </div>
          }
        />
        <JoinField
          type={'password'}
          value={password}
          isShow={isShowField('password')}
          onChange={onPasswordChange}
          bottomInfo={
            <div>
              <div
                className={styles.error}
                style={{
                  display:
                    hasPasswdError && userType === 'existed-user'
                      ? 'flex'
                      : 'none',
                }}>
                <span className="small-pink-x-icon">&nbsp;</span>
                {t('pwdErrTxt_inexact')}
              </div>
              <div
                className={
                  hasPasswdError ? styles.passwordError : styles.password
                }
                style={{
                  display: userType === 'new-user' ? 'block' : 'none',
                }}>
                {t('pwdErrTxt_format')}
              </div>
              <div
                className={styles.passwordReset}
                style={{
                  display: userType === 'existed-user' ? 'flex' : 'none',
                }}>
                <span
                  onClick={() =>
                    navigator('/login/password', {
                      state: {
                        email,
                      },
                    })
                  }>
                  {t('pwdResetBtn')}
                </span>
              </div>
            </div>
          }
        />
        <JoinField
          type={'passwordConfirm'}
          value={confirmPwd}
          isShow={isShowField('rePassword')}
          onChange={onConfirmPwdChange}
          bottomInfo={
            <div
              className={
                !hasConfirmPwdError ? styles.hide : styles.passwordError
              }
              style={{
                display: userType === 'new-user' ? 'block' : 'none',
              }}>
              {t('pwdErrTxt_inexact')}
            </div>
          }
        />
      </div>
      <div className={styles.bottom}>
        <JoinButtons
          type={btnType}
          text={btnTxt}
          onClick={() => btnCallback(email)}
        />
      </div>
    </div>
  );
}
