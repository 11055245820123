import {atom, selector} from 'recoil';
import {AxiosResponse} from 'axios';

import {getMyActivityCounts, getMyBookmarks} from '../services/user';
import {Notification} from '../views/myKnewnew/PushList/LIst';

export const user = atom<string>({
  key: 'user/info',
  default: undefined,
});

export const userIP = atom<string>({
  key: 'user/ip',
  default: undefined,
});

export const UserInfo = atom<UserInfoObj>({
  key: 'user/info-object',
  default: {
    id: -1,
    userType: 'normal',
    providerType: 'email',
    nickname: '',
    email: '',
    password: '',
    code: '',
    headline: '',
    profileImage: '',
    tags: {
      taste: [],
      menu: [],
      method: [],
      market: [],
      flavor: [],
    },
    birth: -1,
    gender: '',
    snsInfo: {
      youtube: '',
      instagram: '',
      naver: '',
    },
  },
});

export const SnsToken = atom<string>({
  key: 'user/sns-token',
  default: '',
});

export const SignInType = atom<
  'kakao' | 'email' | 'naver' | 'apple' | 'google'
>({
  key: 'user/sign-in-type',
  default: 'email',
});

export const SignupUserInfo = atom<SignupInfo>({
  key: 'user/signup-user-info',
  default: {
    providerType: 'kakao',
    nickname: '',
    email: '',
    password: '',
    headline: '',
    profileImage: '',
    tags: {
      taste: [],
      menu: [],
      method: [],
      market: [],
      flavor: [],
    },
    birth: -1,
    gender: 'none',
    snsInfo: {
      youtube: '',
      instagram: '',
      naver: '',
    },
  },
});

export const Curators = atom<Curator[]>({
  key: 'user/curators',
  default: [],
});

export const deviceToken = atom<string>({
  key: 'user/device-token',
  default: '',
});

export const deviceId = atom<string>({
  key: 'user/device-id',
  default: '',
});

export const duplicatedProviderType = atom<
  'kakao' | 'naver' | 'google' | 'apple' | 'email'
>({
  key: 'user/duplicated-provider-type',
  default: 'kakao',
});

export const duplicatedEmail = atom<string>({
  key: 'user/duplicated-email',
  default: '',
});

export const JoinFieldInfo = atom<JoinFields>({
  key: 'user/join-field-info',
  default: {
    email: {
      label: '이메일 주소',
      helper: '정확하게 입력해주세요',
      placeholder: '이메일 주소를 입력하세요',
      inputType: 'text',
    },
    password: {
      label: '비밀번호',
      helper: '',
      placeholder: '비밀번호를 입력하세요',
      inputType: 'password',
    },
    verification: {
      label: '인증번호',
      helper: '',
      placeholder: '인증번호를 입력해주세요',
      inputType: 'text',
    },
    newPassword: {
      label: '새 비밀번호',
      helper: '',
      placeholder: '새 비밀번호를 입력하세요',
      inputType: 'text',
    },
    passwordConfirm: {
      label: '새 비밀번호 확인',
      helper: '',
      placeholder: '새 비밀번호를 한번 더 입력하세요',
      inputType: 'text',
    },
  },
});

export const NotificationList = atom<Notification[]>({
  key: 'user/notification-list',
  default: [],
});

// export const NotificationList = selector<Notification[]>({
//   key: 'user/notification-list',
//   get: async ({get}) => {
//     if (get(user)) {
//       const res = await getNoticeList(get(user));
//       return res.data;
//     }
//     return [];
//   },
// });

export const MyBrandsParam = atom<{
  limit: number;
  offset: number;
  category: undefined | number;
}>({
  key: 'user/my-brands-param',
  default: {
    limit: 9999,
    offset: 0,
    category: undefined,
  },
});

export const MyMagazinesParam = atom({
  key: 'user/my-magazines-param',
  default: {
    limit: 20,
    offset: 0,
  },
});

export const MyCollectionsParam = atom({
  key: 'user/my-collections-param',
  default: {
    limit: 20,
    offset: 0,
  },
});

export const MyBrandsList = selector<BrandParentInfo[]>({
  key: 'user/my-brands',
  get: async ({get}) => {
    const userInfo = get(UserInfo);
    const accessToken = get(user);
    const param = get(MyBrandsParam);
    const id = userInfo.id;
    const brands = await getMyBookmarks(id, param, 'brand', accessToken);
    return brands.data.results;
  },
  set: ({set}, newVal) => {
    set(MyBrandsList, newVal);
  },
});

export const MyMagazinesList = selector<MagazineItem[]>({
  key: 'user/my-magazines',
  get: async ({get}) => {
    const userInfo = get(UserInfo);
    const accessToken = get(user);
    const param = get(MyBrandsParam);
    const id = userInfo.id;
    const magazines = await getMyBookmarks(
      id,
      {remove_count: 'true', ...param},
      'magazine',
      accessToken,
    );
    const results = magazines.data.results;
    return results;
  },
  set: ({set}, newVal) => {
    set(MyMagazinesList, newVal);
  },
});

export const MyCollectionsList = selector<CollectionListItem[]>({
  key: 'user/my-collections',
  get: async ({get}) => {
    const userInfo = get(UserInfo);
    const accessToken = get(user);
    const param = get(MyCollectionsParam);
    const id = userInfo.id;
    const collections = await getMyBookmarks(id, param, 'product', accessToken);
    return collections.data.results;
  },
  set: ({set}, newVal) => {
    set(MyCollectionsList, newVal);
  },
});

export const HasNewNotification = atom<boolean>({
  key: 'user/notification-id',
  default: false,
});

export const WebPaymentsInfo = atom<{
  magazineId: number;
  options: any[];
  price: number;
  name: string;
  phoneNumber: string;
  amount: number;
  optionId: number | string;
  title: string;
  pk: string;
  ck: string;
  magazine: MagazineItem;
  selectedDate: any;
  selectedTime: any;
  orderId: string | number;
}>({
  key: 'user/payment-info',
  default: undefined,
});

export const UserActivityCounts = selector<ActivityCount>({
  key: 'user/activity-count',
  get: async ({get}) => {
    const counts: ActivityCount = {
      bookmark: 0,
      coupon: 0,
      payments: 0,
      community: 0,
    };
    const accessToken = get(user);
    // 북만크 카운트
    const userInfo = get(UserInfo);
    const id = userInfo.id;
    const res: AxiosResponse<ActivtyCountResponse> = await getMyActivityCounts(
      accessToken,
      id,
    );
    counts.bookmark = res.data.bookmarkCount.total;
    counts.coupon = res.data.couponCount;
    counts.community = res.data.communityCount;
    counts.payments = res.data.paymentsCount;
    return counts;
  },
});
