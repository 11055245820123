import {atom, selector} from 'recoil';

import {UserInfo, user} from './user';
import {getBookmarkMagazines} from '../services/magazine';
import {CntField} from '../components/myKnewnew/BookMark';

export const BookMarkCount = atom<number>({
  key: 'bookmark/count',
  default: 0,
});

export const CollectionCount = atom<number>({
  key: 'collection/count',
  default: 0,
});

export const BookMarkPage = atom<number>({
  key: 'bookmark/page',
  default: 1,
});

export const BookMarkTotalPage = atom<number>({
  key: 'bookmark/total-page',
  default: 0,
});

export const CollectionTotalPage = atom<number>({
  key: 'collection/total-page',
  default: 0,
});

export const BookmarkTab = atom<'' | 'brands' | 'zip' | 'news'>({
  key: 'bookmark/tab',
  default: '',
});

export const bookmarkItems = selector<(MagazineItem | CntField[])[]>({
  key: 'bookmark/list',
  get: async ({get}) => {
    const res = await getBookmarkMagazines(
      get(user),
      get(UserInfo).id,
      8,
      get(BookmarkTab),
      (get(BookMarkPage) - 1) * 8,
    );
    return res.results;
  },
});
