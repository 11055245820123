import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  description?: string;
  titleContents?: React.ReactElement;
  children: React.ReactElement;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
}

const BrandCardView = React.memo(
  ({title, description, titleContents, children, style, titleStyle}: Props) => {
    return (
      <div className={styles.cardView} style={style}>
        {title && (
          <div className={styles.cardTitle}>
            <h3 style={titleStyle}>{title}</h3>
            {titleContents && <div>{titleContents}</div>}
          </div>
        )}
        {description && (
          <div className={styles.cardDiscription}>
            <p>{description}</p>
          </div>
        )}
        {children}
      </div>
    );
  },
);

export default BrandCardView;
// export default function BrandCardView({
//   title,
//   description,
//   titleContents,
//   children,
//   style,
//   titleStyle,
// }: Props) {
//   return (
//     <div className={styles.cardView} style={style}>
//       {title && (
//         <div className={styles.cardTitle}>
//           <h3 style={titleStyle}>{title}</h3>
//           {titleContents && <div>{titleContents}</div>}
//         </div>
//       )}
//       {description && (
//         <div className={styles.cardDiscription}>
//           <p>{description}</p>
//         </div>
//       )}
//       {children}
//     </div>
//   );
// }
