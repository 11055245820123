import {atom} from 'recoil';

export const HomeOpacity = atom<number>({
  key: 'animation/home-opacity',
  default: 0,
});

export const CollectionOpacity = atom<number>({
  key: 'animation/collection-opacity',
  default: 0,
});

export const CommunityOpacity = atom<number>({
  key: 'animation/community-opacity',
  default: 0,
});

export const MyOpacity = atom<number>({
  key: 'animation/my-opacity',
  default: 0,
});

export const HomeScroll = atom<boolean>({
  key: 'animation/home-scroll',
  default: false,
});
