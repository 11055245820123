import React from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './styles.module.scss';

interface Props {
  item: RecommendItem;
}

export default function RecommendList({item}: Props) {
  const navigator = useNavigate();
  return (
    <div className={styles.recommendListWrapper}>
      <div className={styles.titles}>
        <h1>{item.title}</h1>
        <div className={styles.subTitle}>{item.subtitle}</div>
      </div>
      <div className={styles.recommendCards} style={{paddingRight: '20px'}}>
        {item.magazines.map((i, idx) => {
          return (
            <div
              key={`recommend_card_item_${i.id}`}
              className={styles.cardItem}
              onClick={() => navigator(`/${i.category}/${i.id}`)}
              style={{
                background: `center / cover no-repeat url(${i.imageUrl})`,
                cursor: `pointer`,
                margin:
                  idx === item.magazines.length - 1
                    ? '0 0 0 10px'
                    : idx === 0
                    ? '0 10px 0 20px'
                    : '0 10px',
              }}></div>
          );
        })}
      </div>
    </div>
  );
}
