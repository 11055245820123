import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import {useNavigate} from 'react-router';

import Comments from './Comments';
import EnterComments from './EnterComments';
import NestedComments from './NestedComments';
import {getComment} from '../../services/comment';
import {user} from '../../store/user';
import CommentModal from '../common/CommentModal';
import {
  CommunityCommentCount,
  isOpenModal,
  OpeningModalStatus,
} from '../../store';

interface Props {
  id: number;
  refetch?: () => void;
  commentCnt?: number;
}

export default function CommentsWrapper({id, refetch, commentCnt}: Props) {
  const navigator = useNavigate();
  const [comments, setComments] = useState<ReviewComment[]>([]);
  const [commentModalType, setCommentModalType] = useState<'edit' | 'report'>(
    'report',
  );
  const accessToken = useRecoilValue(user);
  const setIsOpenCommentModal = useSetRecoilState(isOpenModal);
  const setOpeningModalStatus = useSetRecoilState(OpeningModalStatus);
  const [cnt, setCommentsCnt] = useRecoilState(CommunityCommentCount);

  const init = async () => {
    const res = await getComment(id, accessToken);
    setComments(res.data as ReviewComment[]);
  };

  const openCommentModal = async (type: 'edit' | 'report') => {
    await setOpeningModalStatus(type);
    setCommentModalType(type);
    setIsOpenCommentModal(true);
  };

  useEffect(() => {
    setCommentsCnt(commentCnt || 0);
  }, [commentCnt]);

  useEffect(() => {
    if (id > -1) {
      init();
    }
  }, [id]);

  return (
    <div className="comments-wrapper">
      <div className="comment-cnt">
        <span>댓글</span>
        <span className="cnt">{cnt}</span>
      </div>
      <div
        className="comment-list"
        style={{display: comments.length > 0 ? 'block' : 'none'}}>
        {[...comments].splice(0, 3).map((comment, idx) => {
          return (
            <div className="comment-item" key={`${comment.id}_${idx}`}>
              <Comments
                info={comment}
                fetch={init}
                openModal={openCommentModal}
                writer={''}
              />
              {comment.child &&
                comment.child.length > 0 &&
                comment.child.map((nc, id) => {
                  return (
                    <NestedComments
                      key={`${nc.id}_${id}`}
                      info={nc}
                      parentInfo={comment}
                      fetch={init}
                      openModal={openCommentModal}
                      writer={''}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
      <div
        className="no-reply"
        style={{display: comments.length === 0 ? 'block' : 'none'}}>
        작성된 댓글이 없습니다.
      </div>
      <div
        className="more-comment-btn"
        onClick={() => navigator(`/comments/${id}`)}
        style={{
          display: comments.length > 3 ? 'flex' : 'none',
          cursor: 'pointer',
        }}>
        <span>{cnt}개의 댓글 모두보기</span>
        <span className="arrow-right-icon">&nbsp;</span>
      </div>
      <EnterComments fetch={init} refetchContents={refetch} list={comments} />
      <CommentModal
        type={commentModalType}
        fetch={init}
        refetchContents={refetch ? refetch : () => ''}
      />
    </div>
  );
}
