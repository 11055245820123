import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import dayjs from 'dayjs';

import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import {getPaymentsDetail} from '@/services/magazine';
import {user} from '@/store';
import {addComma} from '@/utils';

import styles from './success_gift_use.module.scss';

export default function GiftUseDetail() {
  const navigator = useNavigate();
  const params = useParams();
  const token = useRecoilValue(user);
  const [item, setItem] = useState<OrderListItem>();
  const [isBtnFixed, setIsBtnFixed] = useState(true);
  const getData = async (id: number) => {
    const res = await getPaymentsDetail(`${id}`, token);
    setItem(res.data);
  };

  useEffect(() => {
    if (window && window.innerHeight < 750) {
      setIsBtnFixed(false);
    }
  }, []);

  useEffect(() => {
    if (params && params.id) {
      getData(Number(params.id));
    }
  }, [params]);

  return (
    <Layout.Main>
      <div>
        <ContentsHeader
          txt={'할인권 사용 내역'}
          isPopup
          isOption="none"
          isBottomLine=""
          noBack
        />
        {item && (
          <div>
            <div className={styles.imgWrapper}>
              <div
                className={styles.giftcardImg}
                style={{
                  background: `url("${item.popup.imageUrl}") no-repeat center / cover`,
                  width: '125px',
                  height: '125px',
                  borderRadius: '4px',
                }}></div>
            </div>
            <div className={styles.itemInfo}>
              <div className={styles.tit}>{item.popup.title}</div>
              <div className={`${styles.itemCnts} ${styles.withLines}`}>
                <div className={styles.label}>발급일</div>
                <div className={styles.val}>
                  {dayjs(item.created).format('YYYY.MM.DD')}
                </div>
              </div>
              <div className={`${styles.itemCnts} ${styles.withLines}`}>
                <div className={styles.label}>유효기한</div>
                <div className={styles.val}>
                  {dayjs(item.expired).format('YYYY.MM.DD')}
                </div>
              </div>
            </div>
            <div className={styles.useInfo}>
              <div className={styles.tit}>{'사용 내역'}</div>
              <div className={`${styles.itemCnts} ${styles.withLines}`}>
                <div className={styles.label}>사용일</div>
                <div className={styles.val}>
                  {dayjs(item.created).format('YYYY.MM.DD')}
                </div>
              </div>
              <div className={styles.itemCntsWrapper}>
                <div className={styles.itemCnts}>
                  <div className={styles.label}>결제금액</div>
                  <div className={styles.val}>
                    {addComma(
                      (item.purchaseAmount || 0) - (item.discountAmount || 0),
                    )}
                  </div>
                </div>
                <ul className={styles.detailWrapper}>
                  <li>
                    <span className={styles.smLabel}>주문 금액</span>
                    <span className={styles.smVal}>
                      {addComma(item.purchaseAmount || 0)}
                    </span>
                  </li>
                  <li>
                    <span className={styles.smLabel}>할인 금액</span>
                    <span className={styles.smVal}>
                      {addComma(item.discountAmount || 0)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className={`${styles.btns} ${
                isBtnFixed ? styles.normal : styles.ex
              }`}>
              <button
                className={styles.closeBtn}
                onClick={() => navigator(`/order/benefits`)}>
                닫기
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout.Main>
  );
}
