import React from 'react';

interface Props {
  isBookmark: boolean | undefined;
}

export default function FoodLogBookMark({isBookmark}: Props) {
  return (
    <div className="food-log-book-mark-btn">
      <span className={`pink-book-mark-icon ${isBookmark ? 'active' : ''}`}>
        &nbsp;
      </span>
      <span>담기</span>
    </div>
  );
}
