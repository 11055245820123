import React from 'react';

import {addComma} from '@/utils';
import {ReactComponent as MenuDefault} from '@/assets/icons/brandMenuDefault.svg';

import {MenuItemWrapper, TextContents} from './menuitem.styled';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';

interface Props {
  menu: MenuItemI;
  style: React.CSSProperties;
}

export default function MenuItem({menu, style}: Props) {
  return (
    <MenuItemWrapper style={style}>
      {menu.image ? (
        <Image
          src={menu.image}
          width={80}
          height={80}
          style={{objectFit: 'cover', height: '80px'}}
        />
      ) : (
        <MenuDefault />
      )}
      <TextContents>
        <div>
          <Text
            fontSize={16}
            color="#000"
            txt={menu.name}
            style={{
              fontWeight: '600',
              lineHeight: '150%',
              letterSpacing: '-0.32px',
            }}
          />
        </div>
        <div>
          <Text
            fontSize={16}
            color="#000"
            txt={`${addComma(menu.price)}원`}
            style={{
              fontWeight: '400',
              lineHeight: '150%',
              letterSpacing: '-0.32px',
            }}
          />
        </div>
      </TextContents>
    </MenuItemWrapper>
  );
}
