import React, {useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';

import Element from '@/components/marketNumber';
import ContentsHeader from '@/layouts/ContentsHeader';
import {checkMarketNumber} from '@/services/magazine';
import AuthModal from '@/components/common/AuthModal';
import {PathHistories, ToastMessage, isOpenToast, user} from '@/store';
import Layout from '@/layouts';

import styles from './styles.module.scss';

export default function NumberEnterPage() {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = useRecoilValue(user);
  const setIsOpenToastModal = useSetRecoilState(isOpenToast);
  const setToastModalMessage = useSetRecoilState(ToastMessage);
  const location = useLocation();
  const [histories, setHistories] = useRecoilState(PathHistories);
  const [numbers, setNumbers] = useState(['', '', '', '']);
  const [isOpenFailModal, setIsOpenFailModal] = useState(false);
  const keyNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, 'back'];
  const enteredLength = useMemo(() => {
    let idx = 0;
    numbers.forEach((n, i) => {
      if (n) {
        idx = i;
      }
    });
    return idx;
  }, [numbers]);
  const keydown = (txt: string) => {
    let idx = 0;
    const _numbers = [...numbers];
    if (txt !== 'back') {
      for (let i = 0, max = _numbers.length; i < max; i++) {
        if (!_numbers[i]) {
          idx = i;
          break;
        }
      }
      _numbers[idx] = txt;
    } else {
      _numbers.forEach((n, i) => {
        if (n) {
          idx = i;
        }
      });
      _numbers[idx] = '';
    }
    setNumbers(_numbers);
  };
  const confirm = async () => {
    try {
      const code = numbers.join('');
      const params: MarketNumberParam = {
        checkCode: code,
        id: location.state.id,
      };
      const res = await checkMarketNumber(accessToken, params);
      const _orderItem = {...location.state.item};
      _orderItem.usedStatus = res.data.usedStatus;
      // 직원확인 페이지에 대한 라우터 삭제
      const _histories = JSON.parse(JSON.stringify(histories));
      _histories.splice(-2, 2);
      setHistories(_histories);
      if (searchParams.get('benefit')) {
        setToastModalMessage('혜택권 사용이 완료되었습니다.');
        navigator(-1);
      } else {
        setToastModalMessage('티켓 사용이 완료되었습니다.');
        navigator(-1);
      }
      setIsOpenToastModal(true);
    } catch (e) {
      setIsOpenFailModal(true);
    }
  };
  return (
    <Layout.Main>
      <div
        style={{
          background: '#F7F7FC',
        }}>
        <ContentsHeader
          txt={'직원 확인'}
          isOption={'none'}
          isBottomLine={''}
          back={() => navigator(-1)}
        />
        <div className={styles.inner}>
          <Element.InputWrapper>
            <div className={styles.numberWrapper}>
              <h3>화면을 현장 직원에게 보여주세요.</h3>
              <div className={styles.numbers}>
                {numbers.map((n, i) => {
                  return (
                    <Element.InputElement
                      key={`number_${i}`}
                      txt={n}
                      active={(i > 0 && !!numbers[i]) || i === 0}
                    />
                  );
                })}
              </div>
              <div className={styles.divider}></div>
              <div className={styles.payInfo}>
                <div
                  style={{
                    display: searchParams.get('benefit') ? 'none' : 'bock',
                  }}>
                  {' '}
                  • 구매자 : {location.state?.name || ''}
                </div>
                <div> • {location.state?.option1 || ''}</div>
                <div> • {location.state?.option2 || ''}</div>
              </div>
            </div>
          </Element.InputWrapper>
          <div
            className={styles.bottomWrapper}
            style={{
              paddingBottom: Capacitor.getPlatform() === 'ios' ? '30px' : '0',
            }}>
            <Element.KeyboardWrapper>
              {keyNumbers.map(k => {
                return (
                  <Element.Key
                    key={`numbers_element_${k}`}
                    txt={`${k}`}
                    onClick={keydown}
                  />
                );
              })}
            </Element.KeyboardWrapper>
            <Element.ConfirmBtnWrapper>
              <Element.ConfirmBtn
                disabled={enteredLength !== 3}
                onClick={confirm}
              />
            </Element.ConfirmBtnWrapper>
          </div>
        </div>
        <AuthModal
          txt={
            <>
              확인 코드가 올바르지 않습니다.
              <br />
              다시 입력해 주세요.
            </>
          }
          isOpen={isOpenFailModal}
          setIsOpen={setIsOpenFailModal}
          btnType={'error'}
          action={() => setIsOpenFailModal(false)}
        />
      </div>
    </Layout.Main>
  );
}
