import React, {useEffect, useState} from 'react';
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from 'recoil';
import {useLocation, useSearchParams} from 'react-router-dom';

import {
  MyBrandsList,
  MyCollectionsList,
  MyMagazinesList,
} from '../../../store/user';
import styles from '../styles.module.scss';
import MyBrands from './MyBrands';
import MyMagazines from './MyMagazines';
import MyCollections from './MyCollections';

export default function MyCollectionList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [activeIdx, setActiveIdx] = useState(0);
  const myCollections = useRecoilValue(MyCollectionsList);
  const myBrands = useRecoilValue(MyBrandsList);
  const myMagazines = useRecoilValue(MyMagazinesList);
  const refetchCollections = useRecoilRefresher_UNSTABLE(MyCollectionsList);
  const refetchBrands = useRecoilRefresher_UNSTABLE(MyBrandsList);
  const refetchMagazines = useRecoilRefresher_UNSTABLE(MyMagazinesList);
  useEffect(() => {
    const types = ['brands', 'collections', 'magazines'];
    setSearchParams(
      {
        type: types[activeIdx],
      },
      {
        state: {
          ...location.state,
        },
      },
    );
  }, [activeIdx]);
  useEffect(() => {
    refetchCollections();
    refetchBrands();
    refetchMagazines();
  }, []);
  return (
    <div className={styles.collectionList}>
      <div className={styles.tab}>
        <div
          className={`${styles.tabItem} ${
            activeIdx === 0 ? styles.active : ''
          }`}
          onClick={() => setActiveIdx(0)}>
          브랜드 {myBrands.length}
        </div>
        <div
          className={`${styles.tabItem} ${
            activeIdx === 1 ? styles.active : ''
          }`}
          onClick={() => setActiveIdx(1)}>
          셀렉숍 {myCollections.length}
        </div>
        <div
          className={`${styles.tabItem} ${
            activeIdx === 2 ? styles.active : ''
          }`}
          onClick={() => setActiveIdx(2)}>
          매거진 {myMagazines.length}
        </div>
      </div>
      {searchParams.get('type') === 'brands' && <MyBrands brands={myBrands} />}
      {searchParams.get('type') === 'collections' && (
        <MyCollections collections={myCollections} />
      )}
      {searchParams.get('type') === 'magazines' && (
        <MyMagazines magazines={myMagazines} />
      )}
    </div>
  );
}
