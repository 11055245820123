import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  title: string;
  children: React.ReactElement;
  onClick?: () => void;
  hide?: boolean;
}

export default function MenuItem({title, hide, children, onClick}: Props) {
  return (
    <div
      className={styles.menuItem}
      onClick={onClick ? () => onClick() : () => ''}
      style={{
        display: hide ? 'none' : 'flex',
        cursor: title === '로그인 정보' ? 'default' : 'pointer',
      }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.right}>{children}</div>
    </div>
  );
}
