import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState, useRecoilState} from 'recoil';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';

import ContentsHeader from '@/layouts/ContentsHeader';
import {signup, userDetail} from '@/services/user';
import {
  isOpenDuplicatedEmailModal,
  UserInfo as StoredUserInfo,
  SignupUserInfo,
  user,
  duplicatedEmail,
  duplicatedProviderType,
} from '@/store';
import UserInfoForm from '@/components/join/UserInfoForm';

export default function UserInfo() {
  const signupInfo = useRecoilValue(SignupUserInfo);
  const [userInfo, setUserInfo] = useRecoilState(StoredUserInfo);
  const setOpenDuplicatedModal = useSetRecoilState(isOpenDuplicatedEmailModal);
  const setToken = useSetRecoilState(user);
  const setDuplicatedEmail = useSetRecoilState(duplicatedEmail);
  const setDuplicatedProviderType = useSetRecoilState(duplicatedProviderType);
  const {t} = useTranslation(['views'], {keyPrefix: 'join.UserInfo'});
  const navigator = useNavigate();
  const next = async () => {
    try {
      const signupRes = await signup(signupInfo, userInfo);
      setToken(signupRes.data.accessToken);
      const res = await userDetail(signupRes.data.accessToken);
      setUserInfo({...res.data});
      navigator('/signup/complete');
    } catch (e) {
      const code = (e as AxiosError).response?.status;
      const existedProviderType = ((e as AxiosError).response?.data as any)
        .providerType as 'kakao' | 'naver' | 'google' | 'apple' | 'email';
      if (code === 600) {
        setDuplicatedProviderType(existedProviderType);
        setDuplicatedEmail(((e as AxiosError).response?.data as any).email);
        setOpenDuplicatedModal(true);
      }
      console.log(e);
    }
  };
  return (
    <div className="main" style={{height: '100vh'}}>
      <ContentsHeader txt={t('headerTxt')} isOption={'none'} />
      <div className="join-user-info contents-body">
        <div className="top-contents">
          <strong>Welcome!</strong>
          <span>{t('guideTxt')}</span>
        </div>
        <UserInfoForm />
        <div
          className="btn-wrapper"
          style={{
            padding: '0 20px',
            marginTop: '40px',
            marginBottom: '214px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          {signupInfo.nickname ? (
            <button
              className="main-cont btn main-black-border"
              onClick={() => next()}
              style={{
                marginBottom: '20px',
              }}>
              {t('signupBtn')}
            </button>
          ) : (
            <button
              className="main-cont btn disabled"
              disabled={true}
              onClick={() => next()}
              style={{
                marginBottom: '20px',
              }}>
              {t('signupBtn')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
