import styled from 'styled-components';

export const TextWrapper = styled.span<TextWrapperI>`
  color: ${({color}) => color || '#9F9CA3'};
  font-size: ${({fontSize}) => `${fontSize}px` || '14px'};
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
`;
