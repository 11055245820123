import styled, {css} from 'styled-components';

export const ModalWrapper = styled.div<BottomSheetI>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
  ${({close}) => close && closeStyle};
`;

const activeStyle = css`
  animation: slideUp 0.5s ease;
`;

const iosStyle = css`
  padding: 10px 20px 50px 20px;
`;

const closeStyle = css`
  display: none !important;
`;

export const BottomSheetWrapper = styled.div<BottomSheetI>`
  position: absolute;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 20px 20px 20px;
  width: ${({mobile}) => (mobile ? '100%' : '375px')};
  z-index: 12;
  ${({active}) => active && activeStyle};
  ${({ios}) => ios && iosStyle};
  ${({close}) => close && closeStyle};
`;

export const BottomSheetHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const HeaderIcon = styled.div`
  display: inline-block;
  width: 16px;
  height: 8px;
  cursor: pointer;
`;
