import React, {ReactNode, useState} from 'react';

import CustomModal from '@/components/modal/CustomModal';

export const useInfoModal = (children: ReactNode) => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);
  const renderModal = () => {
    if (typeof window === 'undefined') return null;

    return (
      <CustomModal visible={isVisible} onClose={closeModal}>
        {children}
      </CustomModal>
    );
  };
  return {
    showModal,
    renderModal,
    closeModal,
  };
};
