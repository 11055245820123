/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router';

import {timeForToday} from '@/utils';

import {editComment, likeComment, makeComment} from '../../services/comment';
import {
  isOpenLoginModal,
  ModalCommentID,
  ModalCommentStatus,
} from '../../store';
import {user, UserInfo} from '../../store/user';
import emptyKnewnew from '../../products/user_default.svg';

interface Props {
  parentInfo: ReviewComment;
  info: ReviewComment;
  fetch: () => void;
  openModal: (type: 'edit' | 'report') => void;
  writer: string;
}

export default function NestedComments({
  parentInfo,
  info,
  fetch,
  openModal: openCommentModal,
  writer,
}: Props) {
  const answerRef = useRef(null);
  const editRef = useRef(null);
  const navigator = useNavigate();
  const {author, content, isLike, created, likeCount, isActive} = info;
  const {nickname, profileImage} = author;
  const [answer, setAnswer] = useState('');
  const userAccessToken = useRecoilValue(user);
  const commentStatus = useRecoilValue(ModalCommentStatus);
  const resetCommentStatus = useResetRecoilState(ModalCommentStatus);
  const commentId = useRecoilValue(ModalCommentID);
  const userInfo = useRecoilValue(UserInfo);
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const setModalCommentID = useSetRecoilState(ModalCommentID);
  const [comments, setComments] = useState('');
  const [openAnswerWrapper, setOpenAnswerWrapper] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const isWriter = useMemo(() => {
    return true;
    return userInfo && userInfo.nickname === writer;
  }, [info, userInfo, writer]);
  const change = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer($event.currentTarget.value);
  };
  const keydown = ($event: React.KeyboardEvent<HTMLInputElement>) => {
    if ($event.keyCode === 13) {
      saveAnswer();
    }
  };
  const toggleCommentModal = async () => {
    await setModalCommentID(info.id);
    openCommentModal(
      userInfo && userInfo.nickname === nickname ? 'edit' : 'report',
    );
  };
  const _likeComment = async () => {
    const id = info.id;
    if (userInfo) {
      await likeComment(id, !isLike, userAccessToken);
      fetch();
    } else {
      setLoginModal(true);
    }
  };
  const changeContents = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setComments($event.currentTarget.value);
  };
  const keydownContents = ($event: React.KeyboardEvent<HTMLInputElement>) => {
    if ($event.keyCode === 13) {
      saveAnswer();
    }
  };
  const edit = async () => {
    try {
      const paths = window.location.pathname.split('/');
      const id = Number(paths[paths.length - 1]);
      if (userInfo) {
        await editComment(info.id, id, comments, userAccessToken, undefined);
        resetCommentStatus();
      } else {
        setLoginModal(true);
      }
      if (fetch) {
        fetch();
      }
    } catch (e) {
      resetCommentStatus();
    }
  };
  const saveAnswer = async () => {
    try {
      const paths = window.location.pathname.split('/');
      const id = Number(paths[paths.length - 1]);
      if (userInfo) {
        await makeComment(id, answer, userAccessToken, info.id);
        setAnswer('');
        setOpenAnswerWrapper(false);
      } else {
        setLoginModal(true);
      }
      if (fetch) {
        fetch();
      }
    } catch (e) {
      setAnswer('');
    }
  };
  const moveToProfile = ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.stopPropagation();
    console.log('movetoProfile' + info.author.id);
    navigator(
      info.author.id === userInfo.id
        ? '/user'
        : `/community/writer/${info.author.id}`,
    );
  };
  useEffect(() => {
    if (openAnswerWrapper) {
      setAnswer(`@${nickname} `);
      (answerRef.current as any).focus();
    }
  }, [openAnswerWrapper]);
  useEffect(() => {
    setComments(info.content);
    if (isActive === true && author.isActive === true) setIsDisplay(true);
  }, [info]);

  return (
    <div className="nested-comment-wrapper">
      <div className="user-info-wrapper">
        <div
          className="user-info"
          onClick={($event: React.MouseEvent<HTMLDivElement>) =>
            author.isActive && moveToProfile($event)
          }>
          <span className="nested-comment-icon"></span>
          {isDisplay ? (
            <div className="pic-wrapper">
              {profileImage != undefined ? (
                <div
                  style={{
                    background: `url(${
                      profileImage ? profileImage : emptyKnewnew
                    }) center no-repeat`,
                    backgroundSize: 'cover',
                    width: '24px',
                    height: '24px',
                    borderRadius: '24px',
                  }}></div>
              ) : (
                <div className="child-writer-image-default"></div>
              )}
            </div>
          ) : (
            <></>
          )}
          {isDisplay ? (
            <div className="name">{nickname}</div>
          ) : (
            <div className="name" style={{color: 'grey'}}>
              {author.isActive ? '삭제된 댓글입니다.' : '탈퇴한 회원입니다.'}
            </div>
          )}
          {isDisplay ? (
            <div
              className="writer-sticker"
              style={{display: isWriter ? 'block' : 'none'}}>
              작성자
            </div>
          ) : (
            <></>
          )}
          {isDisplay ? (
            <div className="date">{timeForToday(created.split('.')[0])}</div>
          ) : (
            <></>
          )}
        </div>
        {isDisplay ? (
          <div className="options" onClick={() => toggleCommentModal()}>
            <span className="dot">&nbsp;</span>
            <span className="dot">&nbsp;</span>
            <span className="dot">&nbsp;</span>
          </div>
        ) : (
          <></>
        )}
      </div>
      {commentStatus === 'edit' && commentId === info.id && (
        <div className="enter-answer-wrapper">
          <input
            ref={editRef}
            className="enter-comments"
            type="text"
            placeholder="댓글을 수정해 주세요"
            value={comments}
            onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
              changeContents($event)
            }
            onKeyDown={($event: React.KeyboardEvent<HTMLInputElement>) =>
              keydownContents($event)
            }
          />
          <button className="submit" onClick={() => edit()}>
            수정
          </button>
        </div>
      )}
      {isDisplay ? (
        <div
          className="comment-body"
          style={{
            display:
              commentStatus === 'edit' && commentId === info.id
                ? 'none'
                : 'block',
          }}
          dangerouslySetInnerHTML={{
            __html:
              '<span class="author">@' +
              parentInfo.author.nickname +
              '</span> ' +
              content,
          }}></div>
      ) : (
        <></>
      )}
      {isDisplay ? (
        <div className="comment-footer">
          {/* <div className="enter-nested">
          {!openAnswerWrapper && (
            <span
              style={{cursor: 'pointer'}}
              onClick={() => setOpenAnswerWrapper(true)}>
              답글달기
            </span>
          )}
          {openAnswerWrapper && (
            <span
              style={{cursor: 'pointer'}}
              onClick={() => setOpenAnswerWrapper(false)}>
              닫기
            </span>
          )}
        </div> */}
          {/* <div className="dot">
          <div>&nbsp;</div>
        </div> */}
          <div className="like-btn-wrapper">
            <button
              className={isLike ? 'bold' : ''}
              onClick={() => _likeComment()}>
              좋아요
            </button>
            <span className={isLike ? 'bold cnt' : 'cnt'}>
              {likeCount > 0 ? likeCount : ''}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className="enter-answer-wrapper"
        style={{display: openAnswerWrapper ? 'flex' : 'none'}}>
        <input
          ref={answerRef}
          className="enter-comments"
          type="text"
          value={answer}
          onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
            change($event)
          }
          onKeyDown={($event: React.KeyboardEvent<HTMLInputElement>) =>
            keydown($event)
          }
        />
        <button className="submit" onClick={() => saveAnswer()}>
          작성
        </button>
      </div>
    </div>
  );
}
