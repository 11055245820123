/* eslint-disable  @typescript-eslint/no-explicit-any */
import Axios from 'axios';
import {useRecoilState, useRecoilValue} from 'recoil';
import {publicIpv4} from 'public-ip';
import {Capacitor} from '@capacitor/core';

import {UserInfo, userIP} from '../store/user';

export interface logParam {
  _screen: string;
  _action: string;
  _msg?: string;
  _device?: string;
  _eventLevel?: string;
  _data?: any;
  _keyword?: string;
  _userId?: string;
}
export enum EventLevel {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
}
export enum Action {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
  SCROLL = 'SCROLL',
  INIT = 'INIT',
  ACTIVE = 'ACTIVE',
}

const logger_instance = Axios.create({
  baseURL: process.env.REACT_APP_LOGGER_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + process.env.REACT_APP_LOGGER_TOKEN,
  },
});

export default function useLogger() {
  const _userInfo = useRecoilValue(UserInfo);
  const [_userIP, setIP] = useRecoilState(userIP);
  const _userAgent = window.navigator.userAgent;

  const sender = async ({
    _msg,
    _screen,
    _action,
    _eventLevel = EventLevel.INFO,
    _data,
    _keyword,
  }: logParam) => {
    const now = new Date();
    let myIP;
    if (typeof _userIP === 'undefined') {
      try {
        myIP = await publicIpv4();
        setIP(myIP);
      } catch (err) {
        myIP = '';
        setIP(myIP);
      }
    } else {
      myIP = _userIP;
    }

    let myId = myIP;

    if (_userInfo) {
      if (_userInfo.id !== -1) myId = _userInfo.id.toString();
    }

    const _body = {
      Message: _msg,
      Screen: _screen,
      Action: _action,
      eventDate: now.toISOString(),
      eventLevel: _eventLevel,
      device: Capacitor.getPlatform(),
      keyword: _keyword,
      userId: myId,
      data: {
        userInfo: _userInfo ? _userInfo.id : -1,
        userIP: myIP,
        userAgent: _userAgent,
        more: _data,
      },
    };

    if (process.env.REACT_APP_ENV === 'production') {
      logger_instance.post(`/logs`, _body);
    } else {
      logger_instance.post(`/logs-devs`, _body);
    }
  };
  return sender;
}

/*
const getStackTrace = () => {
  let stack;

  try {
    throw new Error('');
  } catch (error: any) {
    stack = error.stack || '';
  }
  stack = stack
    .split('\n')
    .slice(1, -1)
    .map(
      // remove itself and root function
      function (line: string) {
        return line.trim().substring(3).split(' (')[0].split('<')[0]; // remove file path ex) <anonymous:1:13>
      },
    )
    .splice(stack[0] == 'Error' ? 2 : 1); // remove useless err msg
  return String(stack);
};
*/
