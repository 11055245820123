import React, {ReactElement} from 'react';

interface Props {
  txt: ReactElement;
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  btnType: 'error' | 'auth';
  action: () => void;
}

export default function AuthModal({
  txt,
  isOpen,
  setIsOpen,
  btnType,
  action,
}: Props) {
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
      }}>
      <div
        className="modal-contents  community-auth-alert"
        style={{
          width: '295px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '40px',
        }}>
        {txt}
        <div className="login-btn-wrapper" style={{marginTop: '30px'}}>
          {btnType === 'error' && (
            <button
              className="modal btn black-filled"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                border: '1px solid #E9E7EC',
                background: '#fff',
                borderRadius: '5px',
                color: '#9F9CA3',
              }}
              onClick={() => setIsOpen(false)}>
              확인
            </button>
          )}
          {btnType === 'auth' && (
            <>
              <button
                className="modal btn black-filled"
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  border: '1px solid #E9E7EC',
                  background: '#000',
                  borderRadius: '5px',
                  color: '#fff',
                }}
                onClick={() => action()}>
                본인인증 하기
              </button>
              <button
                className="modal btn black-filled"
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  background: '#fff',
                  color: '#9F9CA3',
                }}
                onClick={() => setIsOpen(false)}>
                다음에
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
