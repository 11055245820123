import React from 'react';

import Modal from '../../layouts/Modal';
import styles from './styles.module.scss';
import useKakao from '../../hooks/useKakao';

interface Props<T> {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  item: T;
}

export default function BrandShareBottomSheet({
  isOpen,
  setIsOpen,
  item,
}: Props<
  BrandListItemType | {title: string; info: string; imageList: string[]}
>) {
  const {shareKakao, copyLink} = useKakao();
  const pathname = location.pathname;
  const url = location.href;

  return (
    <Modal.BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={styles.shareSheet}>
        <div
          className={styles.item}
          onClick={async () => {
            await shareKakao(
              pathname,
              item.title,
              item.info ? item.info : '',
              item.imageList[0],
            );
            setIsOpen(false);
          }}>
          <span className={styles.kakaoIcon}></span>
          <span className={styles.txt}>카카오톡 공유하기</span>
        </div>
        <div className={styles.divider}></div>
        <div
          className={styles.item}
          onClick={async () => {
            await copyLink(url, item.title, item.info);
            setIsOpen(false);
          }}>
          <span className={styles.linkIcon}></span>
          <span className={styles.txt}>링크 복사하기</span>
        </div>
      </div>
    </Modal.BottomSheet>
  );
}
