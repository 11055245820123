import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router';
import {useSetRecoilState} from 'recoil';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import {App} from '@capacitor/app';
import {useTranslation} from 'react-i18next';

import useKakao from '@/hooks/useKakao';
import useAuth from '@/hooks/useAuth';
import useSplash from '@/hooks/useSplash';
import Layout from '@/layouts';
import {LoginHistoryPath, LoginPrevPath, SignInType} from '@/store';
import {isAndroid, isIos, isMobile} from '@/utils';

import styles from './styles.module.scss';

export default function Join() {
  const kakao = useKakao();
  const location = useLocation();
  const {hide} = useSplash();
  const setLoginPrevPath = useSetRecoilState(LoginPrevPath);
  const {
    signInWithGoogle: googleSignin,
    loginWithKakaoMobile,
    signInWithApple,
  } = useAuth();
  const navigator = useNavigate();
  const setProviderType = useSetRecoilState(SignInType);
  const setHistoryPath = useSetRecoilState(LoginHistoryPath);
  const [isShowModal, setIsShowModal] = useState(false);
  const {t} = useTranslation(['views'], {keyPrefix: 'join.index'});
  const clickLogin = async () => {
    await setProviderType('kakao');
    if (Capacitor.getPlatform() === 'web') {
      kakao.login();
    } else {
      loginWithKakaoMobile();
    }
  };
  const clickLoginEmail = async () => {
    await setProviderType('email');
    navigator('/login/email', {
      replace: true,
    });
  };
  const clickLoginWithApple = async () => {
    await setProviderType('apple');
    if (Capacitor.getPlatform() === 'ios') {
      await signInWithApple();
    }
  };
  const moveToPolicy = (link: string) => {
    window.open(link, '_blank');
  };
  const loginWithGoogle = async () => {
    try {
      await setProviderType('google');
      await googleSignin();
    } catch (e) {
      console.log(e);
    }
  };

  const addBackButtonEventListener = async () => {
    await App.addListener('backButton', async () => {
      if (window.location.pathname === '/login') {
        await App.exitApp();
      }
    });
  };
  const removeBackButtonEvent = () => {
    App.removeAllListeners();
  };

  const init = () => {
    // initNaver();
    // removeToken();
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Dark,
      });
      hide();
    }
    localStorage.removeItem('knewnew_t_k');
  };

  const moveToAppScheme = () => {
    const visitedAt = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - visitedAt < 2000) {
        if (isAndroid()) {
          window.location.href = 'market://launch?id=com.knewnew.app';
        } else if (isIos()) {
          window.open(
            'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
            '_blank',
          );
        }
      }
    }, 1000);
    setTimeout(() => {
      window.location.href = 'knewnew://home';
    }, 0);
  };

  const onClickContinueApp = () => {
    if (Capacitor.getPlatform() === 'web' && !isMobile()) {
      //pc
      setIsShowModal(true);
    } else {
      // 모바일
      moveToAppScheme();
    }
  };

  useEffect(() => {
    if (location.state && location.state.prevPath) {
      setLoginPrevPath(location.state.prevPath);
      setHistoryPath(location.state.history);
    }
  }, [location.state]);

  useEffect(() => {
    init();
    addBackButtonEventListener();
    return () => removeBackButtonEvent();
  }, []);

  useEffect(() => {
    if (location.state && location.state.prevPath) {
      setLoginPrevPath(location.state.prevPath);
    }
  }, [location.state]);

  return (
    <Layout.Main>
      <div>
        {Capacitor.getPlatform() === 'web' && (
          <div className={styles.app_install} style={{display: 'flex'}}>
            <div className={styles.lft}>
              <div className={styles.appIcon}>
                <span className={styles.appLogo}></span>
              </div>
              <span>{t('join_app_guide')}</span>
            </div>
            <button
              type="button"
              className={styles.install_btn}
              onClick={onClickContinueApp}>
              {t('join_app_link')}
            </button>
          </div>
        )}
        <div
          className={`${styles.joinContents} ${
            Capacitor.getPlatform() === 'android'
              ? styles.android
              : Capacitor.getPlatform() === 'ios'
              ? styles.ios
              : ''
          }`}>
          <div
            className={`${styles.navigator} ${
              Capacitor.getPlatform() === 'android' ? 'android' : ''
            }`}
            style={{
              display: Capacitor.getPlatform() === 'web' ? 'flex' : 'none',
            }}>
            <span
              className={`icon arrow-left-white ${styles.back}`}
              onClick={() =>
                navigator(location.state ? location.state.prevPath : -1)
              }>
              &nbsp;
            </span>
            {Capacitor.getPlatform() === 'web' && (
              <span
                className={`icon white-home-icon ${styles.home}`}
                onClick={() => navigator('/home')}>
                &nbsp;
              </span>
            )}
          </div>
          <div className={styles.top}>
            <div className={styles.title}>
              <div className={styles.place}></div>
              <div className={styles.taste}>{t('title')}</div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div>
              <button
                className={`${styles.snsLoginButton} ${styles.kakao}`}
                onClick={() => clickLogin()}>
                <span className="icon kakao-login-icon">&nbsp;</span>
                {t('login_kakao')}
              </button>
              <button
                className={`${styles.snsLoginButton} ${styles.apple}`}
                onClick={() => clickLoginWithApple()}
                style={{
                  display: Capacitor.getPlatform() === 'ios' ? 'flex' : 'none',
                }}>
                <span className="icon apple-login-icon">&nbsp;</span>
                {t('login_apple')}
              </button>
              <button
                className={`${styles.snsLoginButton} ${styles.google}`}
                onClick={() => loginWithGoogle()}>
                <span className="icon google-login-icon">&nbsp;</span>
                {t('login_google')}
              </button>
            </div>
            <div className={styles.emailLogin}>
              <a
                href="#"
                style={{marginRight: '10px', color: '#D2D0D5'}}
                onClick={() => clickLoginEmail()}>
                {t('login_email')}
              </a>{' '}
              |{' '}
              <a
                href="#"
                style={{marginLeft: '10px', color: '#D2D0D5'}}
                onClick={() =>
                  window.open('http://pf.kakao.com/_qmUqG/chat', '_self')
                }>
                {t('qna')}
              </a>
            </div>
            <div className={styles.policyInfo}>
              {t('policy1')}
              <br />
              <span
                className={styles.policyLink}
                onClick={() =>
                  moveToPolicy(
                    'https://knewnew.notion.site/6a9aedfc556a4d87a3be3a32c8810abf',
                  )
                }
                style={{cursor: 'pointer'}}>
                {t('policy2')}
              </span>
              {t('policy3')}{' '}
              <span
                className={styles.policyLink}
                onClick={() =>
                  moveToPolicy(
                    'https://knewnew.notion.site/f147749e136e42ecab4cdf1bec7270a1',
                  )
                }
                style={{cursor: 'pointer'}}>
                {t('policy4')}
              </span>
              {t('policy5')}
            </div>
          </div>
        </div>
        {isShowModal && (
          <div
            className={styles.dimmed_wrapper}
            style={{
              display: 'flex',
              zIndex: '15',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div className={styles.qr_popup}>
              <p>{`간편한 브랜드 탐색부터 \n구매 혜택까지 경험해 보세요.`}</p>
              <img
                src="/imgs/app_qr.png"
                alt="app qa image"
                width="80px"
                height="80px"
              />
              <span className={styles.desc}>앱 다운로드</span>
              <button
                type="button"
                className={styles.close_btn}
                onClick={() => {
                  setIsShowModal(false);
                }}>
                닫기
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout.Main>
  );
}
