import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Layout from '@/layouts';
import {CollectionParam, getCollections} from '@/services/magazine';
import {user} from '@/store/user';
import Fetcher from '@/components/common/Fetcher';
import InstallAlert from '@/components/installAlert';
import {PathHistories} from '@/store';

import CollectionItem from '../../collection/components/Item';

export default function BrandCollections() {
  const navigator = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [histories, setHistories] = useRecoilState(PathHistories);
  const [openInstallAlert, setOpenInstallAlert] = useState(false);
  const [itemList, setItemList] = useState<CollectionListItem[]>([]);
  const {t} = useTranslation(['views'], {keyPrefix: 'BrandCollections'});
  const [listParam] = useState<CollectionParam>({
    limit: 10,
    status: 'all',
  });
  const accessToken = useRecoilValue(user);
  const loadImage = (imgUrl: string) => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        resolve('');
      };
      img.src = imgUrl;
    });
  };
  const loadAllImages = (images: string[]) => {
    const loadImageFnList = images.map(img => {
      return loadImage.bind(img);
    });
    return new Promise(resolve => {
      Promise.all(loadImageFnList).then(() => {
        resolve('');
      });
    });
  };
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = [...itemList];
    _items[idx].isBookmark = isLike;
    setItemList(_items);
  };
  const getData = async () => {
    const _param = {...listParam};
    _param.brand_group = Number(params.brandId);
    const res = await getCollections(accessToken, _param);
    await loadAllImages(
      res.data.results.map((res: CollectionListItem) => {
        return res.imageUrl;
      }),
    );
    return res.data.results;
  };
  const historyBack = () => {
    const _histories = JSON.parse(JSON.stringify(histories));
    console.log(_histories);
    if (_histories.length > 1) {
      _histories.splice(-1, 1);
      const {path, state} = _histories[_histories.length - 1];
      console.log(_histories);
      setHistories(_histories);
      console.log(path);
      setTimeout(() => {
        navigator(`/${path}`, {
          state,
        });
      }, 0);
    } else {
      const last = _histories.splice(-1, 1);
      console.log(last);
      if (last) {
        const {path, state} = last;
        setHistories(_histories);
        setTimeout(() => {
          navigator(`/${path}`, {
            state,
          });
        }, 0);
      } else {
        navigator('/home');
      }
    }
  };
  useEffect(() => {
    scrollTo(0, 0);
  }, []);
  return (
    <Layout.Main>
      <div>
        <Layout.Header
          title={t('headerTxt')}
          isCommunity
          isNoRing
          isWhite
          withBack
          backCallback={() => historyBack()}
          titleStyle={{fontSize: '18px', fontWeight: '500'}}>
          <span style={{cursor: 'default'}}>{location.state?.brandName}</span>
        </Layout.Header>
        <div style={{padding: '30px 20px'}}>
          <Fetcher fetcher={getData} setter={setItemList}>
            {itemList.map((item, idx) => {
              return (
                <CollectionItem
                  key={`collection_item_${idx}`}
                  idx={idx}
                  item={item}
                  toggleLike={toggleLike}
                  setOpenInstallAlert={setOpenInstallAlert}
                />
              );
            })}
          </Fetcher>
        </div>
        <InstallAlert
          ignoreRead={openInstallAlert}
          setIgnore={setOpenInstallAlert}
        />
      </div>
    </Layout.Main>
  );
}
