import React from 'react';

import {ImageCounterWrapper} from './brandImageCounter.styled';

interface Props {
  total: number;
  carouselIndex: number;
  onClick: () => void;
}

export default function BrandImageCounter({
  total,
  carouselIndex,
  onClick,
}: Props) {
  return (
    <ImageCounterWrapper
      onClick={() => {
        document.body.style.overflow = 'hidden';
        onClick();
      }}>
      {carouselIndex} / {`${total} +`}
    </ImageCounterWrapper>
  );
}
