import React, {useEffect, useState} from 'react';
import cc from 'classcat';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useRecoilValue} from 'recoil';
import {AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';

import BottomNavigation from '@/layouts/BottomNavigation';
import Header from '@/layouts/Header';
import Layout from '@/layouts';
import {getSelectShopItems} from '@/services/magazine';
import {user} from '@/store';
import useLogger, {Action} from '@/hooks/useLogger';
import {switchNewLineTxt} from '@/utils';

import styles from './styles.module.scss';

export interface CollectionItemInfo {
  title: string;
  ticker: string;
  subTxt: string;
  isLike: boolean;
  preOpen?: boolean;
}

export default function Collections() {
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const [selectShopItems, setSelectShopItems] = useState<SelectShopItem[]>([]);
  const getData = async (page: number) => {
    const res: AxiosResponse<SelectShopItem[]> = await getSelectShopItems(
      accessToken,
      page,
    );
    setSelectShopItems(res.data);
  };
  const sender = useLogger();
  const moveTo = (id: number) => {
    sender({
      _msg: 'promotion_' + id,
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    navigator(`/collection/promotion-details/${id}`);
  };

  // const moveToItems = (type: string) => {
  //   sender({
  //     _msg: 'allList_' + type,
  //     _screen: window.location.pathname,
  //     _action: Action.CLICK,
  //   });
  //   navigator(`/collection/items/${type}`, {
  //     state: {
  //       backPath: `/collection/list`,
  //     },
  //   });
  // };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
    getData(0);
  }, [window.location.pathname]);

  return (
    <Layout.Main>
      <div>
        <Header title={'셀렉숍'} isCommunity isWhite isNoRing />
        <div className={cc([styles.wrapper, 'collection'])}>
          {/* <CollectionTop /> */}
          {/* <CollectionLists tab={tab} setTab={setTab} /> */}
          {selectShopItems.map((item, index) => (
            <div
              className={styles.promotionItem}
              key={`promotionItem-${index}`}
              onClick={() => moveTo(item.id)}
              style={{cursor: 'pointer'}}>
              <div
                className={`${styles.txtWrap} ${
                  item.colorCode !== '#000' ? styles.white : ''
                }`}>
                <p className={styles.title}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: switchNewLineTxt(item.title),
                    }}></div>
                  <span
                    className={`${styles.icon} arrow-right-thick-icon${
                      item.colorCode !== '#000' ? '-white' : ''
                    }`}
                  />
                </p>
                <span className={styles.subTitle}>{item.subtitle}</span>
              </div>
              <img src={item.thumbnailUrl} />
            </div>
          ))}

          <div className={styles.btnWrap}>
            {/* <button
              type="button"
              className={styles.btn}
              onClick={() => moveToItems('select_shop_opened')}>
              전체 상품 보기
              <span className={styles.icon}></span>
            </button> */}
          </div>
        </div>
        <BottomNavigation />
      </div>
    </Layout.Main>
  );
}
