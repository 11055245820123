import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';

import {isOpenDuplicatedEmailModal} from '../../../store';
import {
  SignInType,
  duplicatedEmail,
  duplicatedProviderType,
} from '../../../store/user';
import useAuth from '../../../hooks/useAuth';
import useKakao from '../../../hooks/useKakao';
import {EmailErrorMsg} from '../../../views/join/EmailJoin';

export default function DuplicatedEmailModal() {
  const navigator = useNavigate();
  const kakao = useKakao();
  const {
    signInWithGoogle: googleSignin,
    loginWithKakaoMobile,
    signInWithApple,
  } = useAuth();
  const [msg, setMsg] = useState<string>('');
  const [openModal, setOpenModal] = useRecoilState(isOpenDuplicatedEmailModal);
  const providerType = useRecoilValue(duplicatedProviderType);
  const email = useRecoilValue(duplicatedEmail);
  const setProviderType = useSetRecoilState(SignInType);
  const emailErrorMsgObj: EmailErrorMsg = {
    kakao: {
      msg: '카카오톡 간편가입',
    },
    naver: {
      msg: '네이버 간편가입',
    },
    google: {
      msg: '구글 간편가입',
    },
    apple: {
      msg: '애플 간편가입',
    },
    email: {
      msg: '이메일 가입',
    },
  };

  const login = () => {
    setOpenModal(false);
    switch (providerType) {
      case 'kakao':
        clickLogin();
        break;
      case 'email':
        clickLoginEmail();
        break;
      case 'apple':
        clickLoginWithApple();
        break;
      case 'google':
        loginWithGoogle();
        break;
    }
  };
  const close = () => {
    setOpenModal(false);
    navigator('/login');
  };
  const clickLogin = async () => {
    await setProviderType('kakao');
    if (Capacitor.getPlatform() === 'web') {
      kakao.login();
    } else {
      loginWithKakaoMobile();
    }
  };
  const clickLoginEmail = async () => {
    await setProviderType('email');
    navigator('/login/email');
  };
  const clickLoginWithApple = async () => {
    await setProviderType('apple');
    if (Capacitor.getPlatform() === 'ios') {
      await signInWithApple();
    }
  };
  const loginWithGoogle = async () => {
    try {
      await setProviderType('google');
      await googleSignin();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setMsg(emailErrorMsgObj[providerType].msg);
  }, [providerType]);

  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="login-modal-header"
          style={{
            marginTop: '30px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          이미 {msg}으로
          <br /> 가입된 이메일입니다
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          {email}
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn black-filled"
            style={{fontWeight: 600, fontSize: '14px'}}
            onClick={() => login()}>
            기존 계정으로 로그인
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => close()}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
}
