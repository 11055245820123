import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {CommentType, isOpenLoginModal, isOpenToast} from '../../../store';
import {user} from '../../../store/user';
import FoodLogBookMark from '../../common/buttons/FoodLogBookMark';

interface Props {
  likeCnt: number | undefined;
  commentCnt: number | undefined;
  isBookmark: boolean | undefined;
  id: number;
}

export default function FoodLogFooter({
  likeCnt,
  commentCnt,
  isBookmark,
  id,
}: Props) {
  const navigator = useNavigate();
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const setToastOpen = useSetRecoilState(isOpenToast);
  const setCommentType = useSetRecoilState(CommentType);
  const userInfo = useRecoilValue(user);
  const clickLike = () => {
    if (!userInfo) {
      setLoginModal(true);
    }
  };
  const moveToComment = () => {
    setCommentType('foodlog');
    navigator(`/comments/${id}`);
  };
  const clickShare = () => {
    setToastOpen(true);
  };
  const clickBookmark = () => {
    if (!userInfo) {
      setLoginModal(true);
    }
  };
  return (
    <div className="food-log-footer">
      <div className="like-share-comment">
        <div
          className="like-wrapper"
          style={{cursor: 'pointer'}}
          onClick={() => clickLike()}>
          <span className="like-icon">&nbsp;</span>
          <span className="like-cnt">{likeCnt}</span>
        </div>
        <div
          className="comment-wrapper"
          onClick={() => moveToComment()}
          style={{cursor: 'pointer'}}>
          <span className="comment-icon">&nbsp;</span>
          <span className="like-cnt">{commentCnt}</span>
        </div>
        <div
          className="share-wrapper"
          style={{cursor: 'pointer'}}
          onClick={() => clickShare()}>
          <span className="share-icon">&nbsp;</span>
        </div>
      </div>
      <div
        className="bookmark"
        style={{cursor: 'pointer'}}
        onClick={() => clickBookmark()}>
        <FoodLogBookMark isBookmark={isBookmark} />
      </div>
    </div>
  );
}
