import Axios from 'axios';

import {errorInterceptor} from '@/utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

export const getLastVersion = () => {
  return axios.get(`/version/`);
};
