import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useLocation} from 'react-router';
import {Capacitor} from '@capacitor/core';
import dayjs from 'dayjs';

import {UserInfo, user} from '@/store/user';
import {getCouponList} from '@/services/coupon';
import {addComma, isAndroid, isIos, isMobile} from '@/utils';
import useAuth from '@/hooks/useAuth';
import {
  IsBrandingMessageOnInstall,
  IsOpenQRCodePopup,
  ToastMessage,
  isOpenToast,
} from '@/store';

import styles from './styles.module.scss';
import FilterModalHeader from '../FilterModal/FilterModalHeader';
import CouponItem from './CouponItem';
import Fetcher from '../Fetcher';
import AuthModal from '../AuthModal';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  productPrice: number;
  applyCoupon: (id: number, txt: string) => void;
  applyedCoupon: number;
}

export default function CouponSelector({
  isOpen,
  setIsOpen,
  productPrice,
  applyCoupon,
  applyedCoupon,
}: Props) {
  const location = useLocation();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
  const [errorCnt, setErrorCnt] = useState(0);
  const setIsShowQRCode = useSetRecoilState(IsOpenQRCodePopup);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setIsBrandingMessage = useSetRecoilState(IsBrandingMessageOnInstall);
  const [isClickedAuth, setIsClickedAuth] = useState(false);
  const [args, setArgs] = useState({
    count: 0,
  });
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const {openAuth} = useAuth();
  const messages = [
    <>
      본인인증에 실패했습니다
      <br />
      다시 시도해 주세요
    </>,
    <>
      1일 인증 가능 횟수를 초과했습니다. <br />
      <span>24시간</span> 이후에 다시 시도해 주세요.
    </>,
    <>
      이미 다른 계정으로
      <br />
      본인 인증이 완료되었습니다.
    </>,
  ];
  const discountPrice = useMemo(() => {
    if (selectedId === -1) {
      return `0`;
    }
    const item = coupons.find(coupon => coupon.id === selectedId);

    if (item) {
      let str = '';
      if (Number(item.discountAmount) === 0) {
        const price =
          Number(item.maxDiscount) <
          productPrice * (Number(item.discountRatio) / 100)
            ? Number(item.maxDiscount)
            : Math.floor(productPrice * (Number(item.discountRatio) / 100));
        str = `-${addComma(price)}`;
      } else {
        const price =
          Number(item.discountAmount) > productPrice
            ? productPrice
            : Number(item.discountAmount);
        str = `-${addComma(price)}`;
      }
      return str;
    } else {
      return `0`;
    }
  }, [selectedId, coupons]);
  const btnStyles = {
    noAuth: styles.noDiscount,
    noCoupon: styles.confirm,
    list: selectedId > -1 ? styles.applyCoupon : styles.confirm,
  };
  const btnTitles = {
    noAuth: <>할인 적용 안 함</>,
    noCoupon: <>확인</>,
    list:
      selectedId > -1 ? <>{discountPrice}원 할인 적용</> : <>할인 적용 안 함</>,
  };
  const close = () => {
    if (applyedCoupon === -1) {
      setSelectedId(-1);
    } else if (applyedCoupon !== -1 && selectedId === -1) {
      setSelectedId(applyedCoupon);
    }
    setIsOpen(false);
  };
  const modalType = useMemo(() => {
    if (!userInfo.isIdentified) {
      return 'noAuth';
    }
    if (coupons.length > 0) {
      return 'list';
    } else {
      return 'noCoupon';
    }
  }, [userInfo, coupons]);

  const calcDiscountAmountsForProduct = (coupon: Coupon) => {
    if (Number(coupon.discountAmount) === 0) {
      let amt = productPrice * (Number(coupon.discountRatio) / 100);
      amt =
        Number(coupon.maxDiscount) > 0 && amt > Number(coupon.maxDiscount)
          ? Number(coupon.maxDiscount)
          : Math.floor(amt);
      return amt;
    } else {
      return Number(coupon.discountAmount);
    }
  };
  const getData = async () => {
    const res = await getCouponList(accessToken, 0);
    let coupons = res.data.map(o => {
      return {
        ...o,
        discountAmt: calcDiscountAmountsForProduct(o),
      };
    });
    coupons = coupons.filter(
      c => !c.magazineId || c.magazineId === location.state?.magazine.id,
    );
    coupons = coupons.map(c => {
      const disabledCoupon =
        Number(c.minAmount) >
        Number(location.state?.price || '0') *
          Number(location.state?.amount || '0');
      return {
        ...c,
        disabledCoupon,
      };
    });
    coupons = coupons.filter(c => {
      return dayjs().diff(c.expireDate) < 0;
    });
    coupons = coupons.sort((a: Coupon, b: Coupon) => {
      let result = 0;
      if (a.discountAmt && b.discountAmt) {
        if (a.discountAmt > b.discountAmt) {
          result = -1;
        } else {
          result = 1;
        }
      }
      return result;
    });
    const notDisabledCoupons = coupons.filter(c => !c.disabledCoupon);
    const disabledCoupons = coupons.filter(c => c.disabledCoupon);

    return [...notDisabledCoupons, ...disabledCoupons];
  };

  const moveToAppScheme = () => {
    setTimeout(() => {
      if (isAndroid()) {
        window.location.href = 'market://launch?id=com.knewnew.app';
      } else if (isIos()) {
        window.open(
          'https://apps.apple.com/kr/app/knewnew-%EB%89%B4%EB%89%B4-f-b-%EB%B8%8C%EB%9E%9C%EB%93%9C-%ED%81%90%EB%A0%88%EC%9D%B4%EC%85%98/id6447512721',
          '_blank',
        );
      }
    }, 1000);
    setTimeout(() => {
      window.location.href = 'knewnew://home';
    }, 0);
  };

  const changeSelectedId = (id: number) => {
    if (selectedId === id) {
      setSelectedId(-1);
    } else {
      setSelectedId(id);
    }
  };

  const confirm = () => {
    if (modalType === 'list') {
      applyCoupon(
        selectedId,
        discountPrice !== '0' ? `${discountPrice}원 할인` : '',
      );
      setIsOpen(false);
    } else {
      close();
    }
  };

  const processAuth = () => {
    const states = {
      selectedDate: location.state?.selectedDate,
      selectedTime: location.state?.selectedTime,
      amount: location.state?.amount,
      name: location.state?.name,
      phoneNumber: location.state?.phoneNumber,
      price: location.state?.price,
      option: location.state?.option,
    };
    if (!isClickedAuth) {
      openAuth(location.pathname, states);
      setIsClickedAuth(true);
      setTimeout(() => {
        setIsClickedAuth(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const result = location.state?.certResult;
    const _args = {...args};
    if (result) {
      switch (result) {
        case 'duplicated':
          setErrorCnt(2);
          setIsOpenAuthModal(true);
          break;
        case 'failed':
          setErrorCnt(0);
          setIsOpenAuthModal(true);
          break;
        case 'success':
          _args.count = _args.count + 1;
          setArgs(_args);
          setToastMessage('쿠폰이 발급되었습니다.');
          setIsOpenToast(true);
          break;
        default:
          break;
      }
      setIsClickedAuth(false);
    }
  }, [location.state]);

  return (
    <div
      className={`modal-wrapper`}
      style={{display: isOpen ? 'flex' : 'none'}}>
      <Fetcher fetcher={getData} setter={setCoupons} args={args}>
        <div
          className={`modal-contents category ${isOpen ? 'active' : ''}  ${
            styles.option_selector_wrapper
          }`}
          style={{height: '550px', padding: '0 23px'}}>
          <FilterModalHeader
            txt={'쿠폰 선택'}
            close={() => close()}
            fnTxt=""
            isHeaderPadding
          />
          <div
            className={styles.onlyMobile}
            style={{
              display:
                userInfo.isIdentified && Capacitor.getPlatform() === 'web'
                  ? 'flex'
                  : 'none',
            }}>
            <div>
              <span className={styles.errorIcon}></span>
              쿠폰은 앱에서만 사용 가능합니다.
            </div>
            <div
              onClick={() => {
                if (isMobile()) {
                  moveToAppScheme();
                } else {
                  setIsBrandingMessage(true);
                  setIsShowQRCode(true);
                }
              }}>
              앱 다운로드
              <span className={styles.arrowRight}></span>
            </div>
          </div>
          <div
            className={styles.noCouponArea}
            style={{display: modalType === 'noCoupon' ? 'flex' : 'none'}}>
            <span className={styles.noCouponIcon}>&nbsp;</span>
            <p>적용 가능한 쿠폰이 없습니다.</p>
          </div>
          <div
            className={styles.noAuthArea}
            style={{display: modalType === 'noAuth' ? 'flex' : 'none'}}>
            <div className={styles.txtArea}>
              <h3>발급 가능한 쿠폰이 있습니다.</h3>
              <p>본인인증하고 다양한 할인 혜택을 받아보세요.</p>
            </div>
            <button
              className={styles.authButton}
              onClick={() => {
                if (Capacitor.getPlatform() === 'web') {
                  setIsBrandingMessage(false);
                  setIsShowQRCode(true);
                } else {
                  processAuth();
                }
              }}>
              <span>본인인증하고 쿠폰 받기</span>
              <span className={styles.arrow_icon}>&nbsp;</span>
            </button>
            <div className={styles.dummyCoupons}></div>
          </div>
          <div
            className={styles.coupons}
            style={{display: modalType === 'list' ? 'block' : 'none'}}>
            {coupons.map(coupon => {
              return (
                <CouponItem
                  isActive={selectedId === coupon.id}
                  key={coupon.id}
                  coupon={coupon}
                  productPrice={productPrice}
                  clickCallback={() => changeSelectedId(coupon.id)}
                  isDisabled={
                    coupon.disabledCoupon || Capacitor.getPlatform() === 'web'
                  }
                />
              );
            })}
            <div className={styles.dummy_coupon}></div>
          </div>
          <div
            className={styles.couponModalFooter}
            style={{
              paddingBottom:
                Capacitor.getPlatform() === 'ios' ? '30px' : '20px',
            }}>
            <button className={btnStyles[modalType]} onClick={() => confirm()}>
              {btnTitles[modalType]}
            </button>
          </div>
        </div>
      </Fetcher>
      <AuthModal
        txt={messages[errorCnt]}
        isOpen={isOpenAuthModal}
        setIsOpen={setIsOpenAuthModal}
        btnType={'error'}
        action={() => setIsOpenAuthModal(false)}
      />
    </div>
  );
}
