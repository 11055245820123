import React from 'react';
import {useNavigate} from 'react-router-dom';
interface Props {
  item: RecommendContent;
  foodlogID: number;
}
export default function ListItem({item, foodlogID}: Props) {
  const navigate = useNavigate();
  const move = () => {
    navigate(`/recommend/foodlog/${foodlogID}`);
  };
  return (
    <div
      className="item-wrapper"
      style={{cursor: 'pointer'}}
      onClick={() => move()}>
      <div className="image" style={{height: '130px'}}>
        <img
          src={item.image}
          height={'130px'}
          alt={item.comment}
          style={{height: '130px'}}
        />
      </div>
      <div className="item-contents">{item.comment}</div>
      <div className="item-writer">{item.author}</div>
    </div>
  );
}
