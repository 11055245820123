import {atom} from 'recoil';

export const tastTags = atom<TastTag[]>({
  key: 'tastTag/tags',
  default: [
    {
      title: '빵식가',
      type: 'bread',
    },
    {
      title: '건강식',
      type: 'healthy',
    },
    {
      title: '다이어터',
      type: 'diet',
    },
    {
      title: '비건',
      type: 'began',
    },
    {
      title: '애주가',
      type: 'alchol',
    },
    {
      title: '디저트 러버',
      type: 'desert',
    },
    {
      title: '육식파',
      type: 'meat',
    },
    {
      title: '해산물 매니아',
      type: 'seafood',
    },
    {
      title: '향신료 좋아',
      type: 'spice',
    },
    {
      title: '초딩 입맛',
      type: 'childhood',
    },
    {
      title: '아재 입맛',
      type: 'adult',
    },
    {
      title: '할매 입맛',
      type: 'gm',
    },
    {
      title: '대식가',
      type: 'bigtast',
    },
    {
      title: '소식좌',
      type: 'small',
    },
    {
      title: '달달',
      type: 'sweet',
    },
    {
      title: '단짠단짠',
      type: 'salt',
    },
    {
      title: '맵고수',
      type: 'hot',
    },
    {
      title: '느끼 좋아',
      type: 'greasy',
    },
    {
      title: '신상 탐험가',
      type: 'newest',
    },
    {
      title: '일편단심 늘먹던거',
      type: 'always',
    },
    {
      title: '가성비파',
      type: 'price',
    },
    {
      title: '간편식',
      type: 'simple',
    },
    {
      title: '식재료 수집가',
      type: 'grocery',
    },
  ],
});
export const selectedTastTags = atom<TastTag[]>({
  key: 'tastTag/selectedTags',
  default: [
    {
      title: '빵식가',
      type: 'bread',
    },
    {
      title: '건강식',
      type: 'healthy',
    },
    {
      title: '다이어터',
      type: 'diet',
    },
    {
      title: '비건',
      type: 'began',
    },
    {
      title: '애주가',
      type: 'alchol',
    },
  ],
});
