import React from 'react';
import {useRecoilState} from 'recoil';

import ReactionModalBody from './body/ReactionModalBody';
import SortModalBody from './body/SortModalBody';
import StoreModalBody from './body/StoreModalBody';
import FilterModalFooter from './FilterModalFooter';
import FilterModalHeader from './FilterModalHeader';
import {filterType, isOpenFilterModal} from '../../../store';

interface TXTObj {
  type: 'shop' | 'reaction' | 'sort';
  txt: string;
}

export default function FilterModal() {
  const [filter] = useRecoilState(filterType);
  const [openFilterModal] = useRecoilState(isOpenFilterModal);
  const headerTxts: TXTObj[] = [
    {
      type: 'shop',
      txt: '구매처 선택',
    },
    {
      type: 'reaction',
      txt: '반응별로 보기',
    },
    {
      type: 'sort',
      txt: '푸드로그 정렬',
    },
  ];
  return (
    <div
      className="modal-wrapper"
      style={{display: openFilterModal ? 'flex' : 'none'}}>
      <div className="modal-contents filter">
        <FilterModalHeader
          txt={headerTxts.filter(t => t.type === filter)[0].txt}
          fnTxt="초기화"
        />
        {filter === 'shop' && <StoreModalBody />}
        {filter === 'reaction' && <ReactionModalBody />}
        {filter === 'sort' && <SortModalBody />}
        <FilterModalFooter
          useRetry={filter === 'shop' || filter === 'reaction'}
          type={filter}
        />
      </div>
    </div>
  );
}
