import React from 'react';
import {BsChevronRight} from 'react-icons/bs';
import {FaMapMarkerAlt} from 'react-icons/fa'; // 또는 { FaMapPin }
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import {addComma} from '@/utils';

import styles from './gift.module.scss'; // CSS Module 파일을 불러옵니다.

interface Props {
  type: string;
  item: OrderListItem;
  setLocationInfo: (l: PlaceInfoProps) => void;
  setIsOpenLocation: (b: boolean) => void;
}

const GiftItem = ({type, item, setLocationInfo, setIsOpenLocation}: Props) => {
  const navigator = useNavigate();
  const parentId = item.popup.brands ? item.popup.brands.parentId : -1;
  const id = item.popup.brands ? item.popup.brands.id : -1;
  const expired = dayjs(item.options[0].expired).format('YYYY.MM.DD');
  const benefitMinAmount = addComma(item.options[0].benefitMinAmount || 0);
  const moveToUse = () => {
    navigator(`/order/gift/use/${item.id}?step=1`, {
      state: {
        item,
        brandName: item.popup.brands?.title,
        imgUrl: item.popup.imageUrl,
      },
    });
  };

  const renderUseBtn = (status: string) => {
    switch (status) {
      case '사용 완료':
        return (
          <>
            <button className={styles['used-coupon']} disabled>
              사용 완료
            </button>
            <button
              className={styles['usage-history']}
              onClick={() => navigator(`/order/gift/detail/${item.id}`)}>
              사용 내역
            </button>
          </>
        );
      case '기간 만료':
        return (
          <>
            <button className={styles['expired-coupon']} disabled>
              유효기간 만료
            </button>
          </>
        );
      default:
        return (
          <button className={styles['use-coupon']} onClick={() => moveToUse()}>
            할인권 사용
          </button>
        );
    }
  };

  const openLocation = () => {
    setLocationInfo({
      address: item.popup.brands?.address || '',
      openingHours:
        `${item.popup.brands?.openingHours || ''}`.split('\\n') || [],
      brandTel: item.popup.brands?.brandTel || '',
      brandLink: item.popup.brands?.brandLink || '',
      reserveLink: item.popup.brands?.reserveLink || '',
      name: item.popup.brands?.title || '',
    });
    setIsOpenLocation(true);
  };

  return (
    <div className={`${styles['coupon-card']} ${styles[type]}`}>
      <div className={styles['coupon-content']}>
        <div
          className={styles['coupon-image']}
          onClick={() => navigator(`/brand/${parentId}/${id}`)}
          style={{
            background: `url('${item.popup.imageUrl}') no-repeat center / cover`,
            cursor: 'pointer',
          }}>
          <div className={styles['brand-home']}>
            브랜드홈
            <BsChevronRight size={12} />
          </div>
        </div>
        <div className={styles['coupon-details']}>
          <div
            className={styles['coupon-title']}
            onClick={() =>
              navigator(`/brand/giftcard/${item.popup.id}`, {
                replace: true,
              })
            }
            style={{cursor: 'pointer'}}>
            {item.popup.title}{' '}
            <BsChevronRight size={14} style={{marginLeft: '10px'}} />
          </div>
          <div className={styles['coupon-expiry']}>{expired}까지</div>
          <div className={styles['coupon-condition']}>
            {benefitMinAmount === '0' ? (
              '최소 주문 금액 제한 없음'
            ) : (
              <>{benefitMinAmount}원 이상 결제 시</>
            )}
          </div>
        </div>
      </div>
      <div className={styles['coupon-actions']}>
        {renderUseBtn(item.usedStatus || type)}
        <button
          className={styles['location-icon']}
          onClick={() => openLocation()}>
          {/* 여기에 위치 아이콘을 넣습니다. */}
          <FaMapMarkerAlt size={20} color="#9E9E9E" />
        </button>
      </div>
    </div>
  );
};

export default GiftItem;
