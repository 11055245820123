/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import JoinCompleteImage from '@/assets/banner/join_complete.webp';
import {LoginPrevPath} from '@/store';

import styles from './styles.module.scss';

export default function SignupComplete() {
  const videoRef = useRef(null);
  const navigator = useNavigate();
  const prevPath = useRecoilValue(LoginPrevPath);
  const {t} = useTranslation(['views'], {keyPrefix: 'join.Complete'});
  const next = async () => {
    try {
      // await process();
      navigator(prevPath ? prevPath : '/home');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [videoRef]);
  return (
    <div
      className="main sign-up-complete-wrapper"
      style={{
        height: '100vh',
        background: '#fff',
        position: 'relative',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <div className={styles.completeMessage}>
          <h1>Welcome!</h1>
          <div>
            {t('msg1')}
            <br />
            {t('msg2')}
          </div>
        </div>
        <div className={styles.completeImg}>
          <img
            src={JoinCompleteImage}
            alt={t('imgAlt')}
            style={{width: '100%'}}
          />
        </div>
        <div className={styles.completeInfo}>
          {t('infoTxt1')}
          <br />
          {t('infoTxt2')}
        </div>
        <div className={styles.startButtonWrapper}>
          <button className={styles.startButton} onClick={() => next()}>
            {t('btnTxt')}
          </button>
        </div>
      </div>
    </div>
  );
}
