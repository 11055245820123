import React from 'react';
import {useRecoilState} from 'recoil';

import {isOpenMagazineModal} from '../../../store';

export default function MagazineModal() {
  const [isOpen, setIsOpen] = useRecoilState(isOpenMagazineModal);
  return (
    <div
      className="modal-wrapper"
      style={{
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        className="modal-contents"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          textAlign: 'left',
          padding: 0,
        }}>
        <div className="modal-body" style={{padding: '20px 25px 10px 25px'}}>
          <strong style={{fontWeight: 700, fontSize: '14px'}}>
            뉴뉴 매거진이란?
          </strong>
          <br />
          <br />
          <span
            style={{fontSize: '14px', fontWeight: '400', lineHeight: '16.8px'}}>
            신상 푸드 소식과 후기, 핫플과 레시피 정보 등을 모아둔{' '}
            <strong>푸드 아카이브</strong>와 푸드라이프스타일 인터뷰 콘텐츠{' '}
            <strong>푸드라잎</strong>으로 이루어진 뉴뉴 브랜디드 콘텐츠
            채널입니다.
          </span>
        </div>
        <div
          className="modal-footer"
          style={{
            display: 'flex',
            marginTop: '30px',
            borderTop: '1px solid #E9E7EC',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
            color: '#78737D',
          }}>
          <button onClick={() => setIsOpen(false)}>확인</button>
        </div>
      </div>
    </div>
  );
}
