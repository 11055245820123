import React from 'react';

interface Props {
  item: RecommendContent;
}

export default function PickItem({item}: Props) {
  return (
    <div className="item-wrapper" style={{cursor: 'pointer'}}>
      <div className="item-image">
        <img
          src={item.image}
          alt="약과"
          width="180px"
          height="180px"
          style={{height: '180px'}}
        />
      </div>
      <div className="item-contents">{item.comment}</div>
      <div className="item-writer">{item.author}</div>
    </div>
  );
}
