import React from 'react';
import cc from 'classcat';

import styles from './location.styles.module.scss';
interface Props {
  isBlack?: boolean;
  fixedHeader?: boolean;
}

export default function LocationLoader({isBlack, fixedHeader}: Props) {
  return (
    <div className={cc([styles.wrapper, {[styles.fixedHeader]: fixedHeader}])}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <span className={`${styles.icon} ${isBlack ? 'black' : ''}`}></span>
        <span style={{marginTop: '10px'}}>잠시만 기다려주세요.</span>
      </div>
    </div>
  );
}
