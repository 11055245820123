import React, {Suspense} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import ContentsHeader from '../../layouts/ContentsHeader';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import MyCollectionList from './components/MyCollectionList';
import Layout from '../../layouts';

export default function MyCollection() {
  const navigator = useNavigate();
  const location = useLocation();
  return (
    <Layout.Main>
      <div className="bookmark-wrapper">
        <ContentsHeader
          txt={'스크랩'}
          isOption={'none'}
          isBottomLine="none"
          back={() => {
            navigator(
              location.state && location.state.prev
                ? `/${location.state.prev}`
                : '/user',
            );
          }}
        />
        <Suspense fallback={<Loader />}>
          <MyCollectionList />
        </Suspense>
        <Pagination type={'collection'} />
      </div>
    </Layout.Main>
  );
}
