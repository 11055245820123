import React from 'react';

import {ReactComponent as BenefitShortcutArrowRight} from '@/assets/icons/benefit_shortcut_arrow_right.svg';

import {BenefitShortCutWrapper} from './brandbenefit.styled';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
interface Props {
  img: string;
  title: string;
  onClick: () => void;
}

export default function BrandBenefitShortCut({img, title, onClick}: Props) {
  return (
    <BenefitShortCutWrapper onClick={() => onClick()}>
      <Image src={img} width={28} height={28} style={{borderRadius: '28px'}} />
      <div className="flex flex-1 items-center">
        <Text
          txt={title}
          color={'#fff'}
          fontSize={14}
          style={{
            letterSpacing: '-2%',
            fontWeight: '600',
            textAlign: 'left',
            marginRight: '5px',
          }}
        />
        <BenefitShortcutArrowRight />
      </div>
    </BenefitShortCutWrapper>
  );
}
