import React from 'react';
import {useRecoilState} from 'recoil';

import {SignupUserInfo} from '../../store/user';

export default function FoodLifeForm() {
  const [signupInfo, setSignupInfo] = useRecoilState(SignupUserInfo);
  const update = (type: keyof typeof signupInfo.tags, value: string) => {
    const _signupInfo = JSON.parse(JSON.stringify(signupInfo)) as SignupInfo;
    const eIdx = _signupInfo.tags[type].indexOf(value);
    if (eIdx > -1) {
      _signupInfo.tags[type].splice(eIdx, 1);
    } else {
      _signupInfo.tags[type].push(value);
    }
    setSignupInfo(_signupInfo);
  };
  const isExist = (type: keyof typeof signupInfo.tags, value: string) => {
    return signupInfo.tags[type].indexOf(value) > -1;
  };
  return (
    <div className="food-life-form">
      <div className="food-life-selector" style={{marginTop: 0}}>
        <strong>선호하는 유통사</strong>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className="food-life-toggler">
            <button
              className={
                isExist('market', '마켓컬리')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', '마켓컬리')}>
              마켓컬리
            </button>
            <button
              className={
                isExist('market', 'SSG')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', 'SSG')}>
              SSG
            </button>
            <button
              className={
                isExist('market', '쿠팡')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', '쿠팡')}>
              쿠팡
            </button>
            <button
              className={
                isExist('market', '네이버쇼핑')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', '네이버쇼핑')}>
              네이버쇼핑
            </button>
          </div>
          <div className="food-life-toggler">
            <button
              className={
                isExist('market', 'B마트')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', 'B마트')}>
              B마트
            </button>
            <button
              className={
                isExist('market', '기타')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('market', '기타')}>
              기타
            </button>
          </div>
        </div>
      </div>
      <div className="food-life-selector">
        <strong>즐겨먹는 메뉴</strong>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className="food-life-toggler">
            <button
              className={
                isExist('menu', '한식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '한식')}>
              한식
            </button>
            <button
              className={
                isExist('menu', '일식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '일식')}>
              일식
            </button>
            <button
              className={
                isExist('menu', '중식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '중식')}>
              중식
            </button>
            <button
              className={
                isExist('menu', '양식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '양식')}>
              양식
            </button>
          </div>
          <div className="food-life-toggler">
            <button
              className={
                isExist('menu', '비건디')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '비건디')}>
              비건식
            </button>
            <button
              className={
                isExist('menu', '간단식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '간단식')}>
              간단식
            </button>
            <button
              className={
                isExist('menu', '다이어트식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('menu', '다이어트식')}>
              다이어트식
            </button>
          </div>
        </div>
      </div>
      <div className="food-life-selector">
        <strong>식사 방식</strong>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className="food-life-toggler">
            <button
              className={
                isExist('method', '직접요리')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('method', '직접요리')}>
              직접요리
            </button>
            <button
              className={
                isExist('method', '외식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('method', '외식')}>
              외식
            </button>
            <button
              className={
                isExist('method', '간편조리')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('method', '간편조리')}>
              간편조리
            </button>
            <button
              className={
                isExist('method', '편의점')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('method', '편의점')}>
              편의점
            </button>
          </div>
          <div className="food-life-toggler">
            <button
              className={
                isExist('method', '배달음식')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('method', '배달음식')}>
              배달음식
            </button>
          </div>
        </div>
      </div>
      <div className="food-life-selector">
        <strong>좋아하는 맛</strong>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className="food-life-toggler">
            <button
              className={
                isExist('flavor', '매운 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '매운 맛')}>
              매운 맛
            </button>
            <button
              className={
                isExist('flavor', '단 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '단 맛')}>
              단 맛
            </button>
            <button
              className={
                isExist('flavor', '느끼한 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '느끼한 맛')}>
              느끼한 맛
            </button>
            <button
              className={
                isExist('flavor', '신 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '신 맛')}>
              신 맛
            </button>
          </div>
          <div className="food-life-toggler">
            <button
              className={
                isExist('flavor', '짭짤한 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '짭짤한 맛')}>
              짭짤한 맛
            </button>
            <button
              className={
                isExist('flavor', '씁쓸한 맛')
                  ? 'food-life-btn active'
                  : 'food-life-btn'
              }
              onClick={() => update('flavor', '씁쓸한 맛')}>
              씁쓸한 맛
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
