import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';

import {getMagazine} from '@/services/magazine';
import {MagazineImotion, MagazineItem} from '@/store';
import {user, UserInfo} from '@/store/user';
import {switchNewLineTxt} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';
import ContentsFooter from '@/components/contents/ContentsFooter';
import FreeComment from '@/assets/banner/its-free.svg';

export default function News() {
  const navigator = useNavigate();
  const userToken = useRecoilValue(user);
  const userInfo = useRecoilValue(UserInfo);
  const [id, setId] = useState(0);
  const [magazineItem] = useRecoilState(
    MagazineItem({
      id,
      type: 'news',
    }),
  );
  const setSelectedEmotion = useSetRecoilState(MagazineImotion);
  const [latestContentsRows, setLatestContentsRows] = useState<
    MagazineItem[][]
  >([]);
  const [hasLocked, setHasLocked] = useState(false);

  const getRecentMagazines = async (info: MagazineItem) => {
    const res = await getMagazine(userToken, 5, 'news');
    const latests: MagazineItem[] = (res.data.results as MagazineItem[])
      .filter(item => item.id !== info.id)
      .splice(0, 4);
    const latestLength = Math.ceil(latests.length / 2);
    const rows: MagazineItem[][] = [];
    for (let i = 0; i < latestLength; i++) {
      rows.push([...latests].splice(i * 2, 2));
    }
    setLatestContentsRows(rows);
  };

  const onScroll = () => {
    if (window.scrollY > 400 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
    }
  };

  const init = () => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    setId(id);
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  };

  const sender = useLogger();

  useEffect(() => {
    if (magazineItem.info) {
      getRecentMagazines(magazineItem.info);
      setSelectedEmotion(magazineItem.info.myRating || 0);
    }
  }, [magazineItem]);

  useEffect(() => {
    init();
    window.scrollTo(0, 0);
    document.addEventListener('scroll', onScroll);
    sender({
      _msg: 'ToNews',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    return () => document.removeEventListener('scroll', onScroll);
  }, [window.location.pathname]);

  return magazineItem.info ? (
    <div>
      <div
        className="brand-banner"
        style={{
          background: `center / cover no-repeat  url(${magazineItem.info.imageUrl})`,
        }}>
        <div className="txt-content">
          {/**
          <div className="type-info">
            {magazineItem.info.target?.replace(/^[a-z]/, (char: string) =>
              char.toUpperCase(),
            )}
          </div>
          */}
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: switchNewLineTxt(magazineItem.info.title || ''),
            }}></div>
          <div className="sub-title">{magazineItem.info.subtitle}</div>
        </div>
      </div>
      <div
        className={`main-content magazine-detail-wrapper ${
          hasLocked ? 'show-login' : ''
        }`}>
        <div
          className="logins"
          style={{display: userInfo.id === -1 ? 'flex' : 'none', zIndex: 3}}>
          <div className="login-txt">
            <p>다음 내용이 궁금하다면?</p>
            <h1>
              딱 3초만에 가입하고 <br /> 계속 볼 수 있어요!
            </h1>
            <div
              className="main-cont btn black"
              onClick={() => {
                sender({
                  _msg: 'joinmodal_click',
                  _screen: window.location.pathname,
                  _action: Action.CLICK,
                });
                navigator('/login', {
                  state: {
                    prevPath: `${location.pathname}${location.search}`,
                  },
                });
              }}
              style={{cursor: 'pointer', background: '#000', color: '#fff'}}>
              <div
                className="message-box"
                style={{
                  background: `url("${FreeComment}") no-repeat center / cover`,
                }}></div>
              <div>로그인하러 가기</div>
            </div>
          </div>
        </div>
        <div className="contents-info">
          <div className="dates">{magazineItem.info.created.split('T')[0]}</div>
          <div className="see-count">
            <span className="icon see-count-icon">&nbsp;</span>
            <span className="cnt">{magazineItem.info.viewCount}</span>
          </div>
        </div>
        <div
          className="detail-main-contents"
          dangerouslySetInnerHTML={{__html: magazineItem.file}}></div>
      </div>
      {/*<div
        className="evaluate-contents"
        style={{display: userInfo ? 'block' : 'none'}}>
        <h1>이 콘텐츠는 어땠나요?</h1>
        <div className="sub-title">여러분의 소중한 의견을 기다리고 있어요!</div>
        <div className={`smile-icons`}>
          <div
            className={`smile-icon ${selectedEmotion === -1 ? 'active' : ''}`}
            onClick={() => clickEmotion(-1)}>
            <span className="bad-icon">&nbsp;</span>
            <span className="txt">불만</span>
          </div>
          <div
            className={`smile-icon ${selectedEmotion === 1 ? 'active' : ''}`}
            onClick={() => clickEmotion(1)}>
            <span className="normal-icon">&nbsp;</span>
            <span className="txt">보통</span>
          </div>
          <div
            className={`smile-icon ${selectedEmotion === 2 ? 'active' : ''}`}
            onClick={() => clickEmotion(2)}>
            <span className="good-icon">&nbsp;</span>
            <span className="txt">만족</span>
          </div>
        </div>
        <div className="take-comments">
          <div className="comment">더 하고싶은 이야기가 있다면?</div>
          <div className="go-to-comments">
            <span
              className="go-to-comment-btn"
              onClick={() =>
                window.open('https://pf.kakao.com/_YQFcb/chat', '_blank')
              }>
              의견 남기기
            </span>
          </div>
        </div>
      </div>
      */}
      <div className="content-divider"></div>
      <div className="curator-other-contents news">
        <h1 className="hangul">놓치기 아쉬운 F&B 이슈</h1>
        {latestContentsRows.map((c, idx) => {
          return (
            <div className="contents-info" key={`latests_contents_info_${idx}`}>
              {c.map(item => {
                return (
                  <div
                    className="item"
                    key={`latests_contens_item_${item.id}`}
                    onClick={() => navigator(`/news/${item.id}`)}
                    style={{cursor: 'pointer'}}>
                    <div
                      className="item-img"
                      style={{
                        background: `center / cover no-repeat  url(${item.imageUrl})`,
                      }}></div>
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: switchNewLineTxt(item.title || ''),
                      }}></div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <ContentsFooter
        detailInfo={magazineItem.info as MagazineItem}
        type="news"
      />
    </div>
  ) : (
    <div>{/* <ContentsHelmet></ContentsHelmet> */}</div>
  );
}
