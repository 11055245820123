import React, {Suspense, useEffect} from 'react';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';

import MagazineBanner from '@/components/magazine/Banner';
import MagazineKnewZip from '@/components/magazine/KnewZip';
import MagazineKnewBrand from '@/components/magazine/KnewBrand';
import MagazineKnewNews from '@/components/magazine/KnewNews';
import MagazineCurators from '@/components/magazine/Curators';
import MagazineLoader from '@/components/common/Skeleton/MagazineLoader';
import {useScroll} from '@/hooks/useScroll';
import MagazineLayout from '@/layouts/Magazine';

export default function MagazineMain() {
  const {syncScroll} = useScroll();
  useEffect(() => {
    document.title = '뉴뉴, 푸드 큐레이션 플랫폼 (knewnew)';

    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Dark,
      });
    }
    syncScroll();
  }, []);
  return (
    <MagazineLayout>
      <Suspense fallback={<MagazineLoader />}>
        <MagazineBanner />
        <MagazineKnewZip />
        <MagazineKnewBrand />
        <MagazineKnewNews />
        <MagazineCurators />
      </Suspense>
    </MagazineLayout>
  );
}
