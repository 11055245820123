import React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {isOpenLoginModal, isOpenToast} from '../../../store';
import {user} from '../../../store/user';
import FeedProfile from '../../common/FeedProfile';

interface Props {
  imgPath: string;
  name: string;
}

export default function FeedHeader({imgPath, name}: Props) {
  const userName = useRecoilValue(user);
  const setLoginModal = useSetRecoilState(isOpenLoginModal);
  const setToastOpen = useSetRecoilState(isOpenToast);
  const clickBookmark = () => {
    if (!userName) {
      setLoginModal(true);
    }
  };
  return (
    <div className="feed-header-wrapper">
      <div className="profile-info">
        <FeedProfile path={imgPath} />
        <div className="profile-name">{name}</div>
      </div>
      <div className="share-info">
        <div className="icon-wrapper">
          <span
            className="share-icon"
            style={{cursor: 'pointer'}}
            onClick={() => setToastOpen(true)}></span>
          <span
            className="bookmark-icon"
            style={{cursor: 'pointer'}}
            onClick={() => clickBookmark()}></span>
        </div>
      </div>
    </div>
  );
}
