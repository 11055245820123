import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import cc from 'classcat';
import {useSetRecoilState} from 'recoil';

import {useScroll} from '@/hooks/useScroll';
import {getBrandsCategories} from '@/services/brands';
import {MyBrandsParam} from '@/store';

import styles from '../styles.module.scss';
import MyBrandItem from './MyBrandItem';

interface Props {
  brands: BrandParentInfo[];
}

export default function MyBrands({brands}: Props) {
  const setMyBrandsParam = useSetRecoilState(MyBrandsParam);
  const {syncScroll} = useScroll();
  const navigator = useNavigate();
  const [categoryList, setCategoryList] = useState([
    {
      id: 0,
      category: 'ALL',
    },
  ]);
  const [selectedTab, setSelectedTab] = useState({
    id: 0,
    category: 'ALL',
  });
  const [myBrands, setMyBrands] = useState<BrandParentInfo[]>([]);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = _.cloneDeep(myBrands);
    _items[idx].isBookmark = isLike;
    setMyBrands(_items);
  };

  const clickHandler = (item: {id: number; category: string}) => {
    setSelectedTab(item);
    setMyBrandsParam({
      limit: 9999,
      offset: 0,
      category: item.id === 0 ? undefined : item.id,
    });
  };

  const init = async () => {
    try {
      const {data} = await getBrandsCategories();
      setCategoryList([...categoryList, ...data]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setMyBrands(brands);
    syncScroll();
  }, [brands]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className={styles.brandCategoryArea}>
        <div className={styles.categoryList}>
          {categoryList.map((item, index) => (
            <button
              type="button"
              className={cc([
                styles.categoryItem,
                {[styles.on]: selectedTab.id === item.id},
              ])}
              key={`category-item-${index}`}
              onClick={() => clickHandler(item)}>
              {item.category}
            </button>
          ))}
        </div>
      </div>
      <div
        className="no-contents"
        style={{
          display: myBrands.length === 0 ? 'flex' : 'none',
        }}>
        <span className="txt">
          {`스크랩한 브랜드가 없습니다.\n마음에 드는 브랜드를 모아 보세요.`}
        </span>
        <button
          className={styles.go_to_contents}
          onClick={() => navigator('/brand')}>
          브랜드 보러가기
        </button>
      </div>

      <div
        className="contents"
        style={{display: myBrands.length > 0 ? 'block' : 'none'}}>
        <div className={styles.brandArea}>
          {myBrands.map((item, idx) => {
            return (
              <MyBrandItem
                info={item}
                idx={idx}
                key={`order_collection_item_${idx}`}
                toggleLike={toggleLike}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
