import React from 'react';

interface Props {
  type: 'edit' | 'cancel' | 'report' | 'contents-report' | 'contents-edit';
  click: () => void;
  remove?: () => void;
  report?: () => void;
  edit?: () => void;
  style?: React.CSSProperties;
}

export default function Button({
  type,
  click,
  remove,
  report,
  edit,
  style,
}: Props) {
  return (
    <div
      className={`comment-modal-btn ${type}`}
      onClick={() => click()}
      style={{...style}}>
      {type === 'cancel' && (
        <button className="cancel-txt" style={{cursor: 'pointer'}}>
          취소
        </button>
      )}
      {type === 'edit' && (
        <div className="edit-body">
          <button
            className="edit-comment"
            onClick={() => (edit ? edit() : null)}>
            댓글 수정
          </button>
          <button
            className="delete-comment"
            onClick={() => (remove ? remove() : null)}>
            댓글 삭제
          </button>
        </div>
      )}
      {type === 'report' && (
        <div className="edit-body">
          <button
            className="delete-comment"
            onClick={() => (report ? report() : null)}>
            댓글 신고하기
          </button>
        </div>
      )}
      {type === 'contents-report' && (
        <div className="edit-body">
          <button
            className="delete-comment"
            onClick={() => (report ? report() : null)}>
            게시글 신고
          </button>
        </div>
      )}
      {type === 'contents-edit' && (
        <div className="edit-body">
          <button
            className="edit-comment"
            onClick={() => (edit ? edit() : null)}>
            게시글 수정
          </button>
          <button
            className="delete-comment"
            onClick={() => (remove ? remove() : null)}>
            게시글 삭제
          </button>
        </div>
      )}
    </div>
  );
}
