import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import Layout from '@/layouts';
import CollectionLists from '@/views/collection/components/Lists';
import Header from '@/layouts/Header';

export default function CollectionItems() {
  const navigator = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [tab, setTab] = useState('');
  const [type, setType] = useState('');
  const [backPath, setBackPath] = useState('/home');
  const tit = useMemo(() => {
    let title = '';
    if (params.type) {
      switch (params.type) {
        case 'select_shop_opened':
          title = '전체 상품';
          break;
        case 'select_shop_new':
          title = '신규 상품';
          break;
        case 'select_shop_famous':
          title = '인기 상품';
          break;
        case 'select_shop_discount':
          title = '할인 상품';
          break;
        case 'select_shop_closed':
          title = '지난 상품';
          break;
        default:
          break;
      }
    }
    return title;
  }, [params]);

  useEffect(() => {
    if (params.type) {
      setType(params.type);
    }
  }, [params]);

  useEffect(() => {
    if (location.state && location.state.backPath) {
      setBackPath(location.state.backPath);
    }
  }, [location]);

  return (
    <Layout.Main>
      <div>
        <Header
          title={`${tit}`}
          isCommunity
          isWhite
          isNoRing
          withBack
          backCallback={() => (backPath ? navigator(backPath) : navigator(-1))}
          titleStyle={{
            fontSize: '18px',
          }}
        />
        <CollectionLists tab={tab} setTab={setTab} type={type} />
      </div>
    </Layout.Main>
  );
}
