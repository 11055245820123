import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import styles from './styles.module.scss';

interface Props {
  type: 'home' | 'about';
}

export default function Footer({type}: Props) {
  const navigator = useNavigate();
  const {t} = useTranslation(['layouts'], {keyPrefix: 'Footer'});
  const [isOpen, setIsOpen] = useState(false);

  const openBrowser = async (url: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(url, '_blank');
    } else {
      await Browser.open({
        url,
      });
    }
  };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerRow}>
        <a href={'#'} onClick={() => navigator('/report')}>
          고객지원실
        </a>
        <a href={'#'} onClick={() => navigator('/proposal')}>
          {t('qna-coop')}
        </a>
        <a
          href="#"
          onClick={() => openBrowser('https://www.knewnewbiz.co.kr/')}>
          입점 신청
        </a>
      </div>
      <div
        className={styles.logoWrapper}
        style={{display: type === 'home' ? 'flex' : 'none'}}>
        <div
          className={styles.footerTxt}
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <span> {t('footerTxt')}</span>
          <span
            className={`${styles.icon} ${'arrow-down-black-darker-icon'} ${
              isOpen ? styles.up : ''
            }`}></span>
        </div>
        {isOpen && (
          <div className={styles.footerInfoTxt}>
            {t('footerInfo1')}
            <br />
            {t('footerInfo4')}
            <br />
            {t('footerInfo5')}
            <br />
            {t('footerInfo3')}
            <br />
            {t('footerInfo7')}
            <br />
            {t('footerInfo2')}
          </div>
        )}
        <div className={styles.footerInfoTxt}>
          <span className={styles.termOfUse}>
            <a
              href="https://knewnew.notion.site/f147749e136e42ecab4cdf1bec7270a1"
              target="_blank"
              rel="noreferrer">
              {t('termOfUse1')}
            </a>{' '}
            •{' '}
            <a
              href="https://knewnew.notion.site/6a9aedfc556a4d87a3be3a32c8810abf"
              target={'_blank'}
              rel="noreferrer">
              {t('termOfUse2')}
            </a>
          </span>
          <p>{t('termsDesc')}</p>
          <span>©2023 knewnew. ALL RIGHTS RESERVED.</span>
        </div>
      </div>
      <div
        className={styles.aboutFooterContent}
        style={{display: type === 'about' ? 'flex' : 'none'}}>
        <div className={styles.description}>{t('footerDescription')}</div>
        <button
          className={styles.askSomething}
          onClick={() =>
            window.open(
              'https://pf.kakao.com/_YQFcb/chat',
              '_blank',
              'noreferrer',
            )
          }>
          {t('footerAsk')}
        </button>
      </div>
    </div>
  );
}
