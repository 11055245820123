import React from 'react';

import Badge from '../../common/Badge';

interface Props {
  txt: string | undefined;
  isBadge: boolean;
  explain: string | undefined | null;
  subtitle: string | undefined | null;
  createdAt: string | undefined | null;
}

export default function ContentsTitle({
  txt,
  isBadge,
  explain,
  subtitle,
  createdAt,
}: Props) {
  return (
    <div className="main-content contents-title-wrapper">
      <div className="title-wrapper">
        <Badge type="pick" isShow={isBadge} />
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: txt?.replace('\\n', '<br />') as string,
          }}></div>
        <div className="description">{subtitle}</div>
        <div className="date">{createdAt}</div>
      </div>
      <div className="title-description">
        <p>{explain}</p>
      </div>
      <div className="divider"></div>
    </div>
  );
}
