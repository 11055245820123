import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';

export type VerifyButton = {
  txt: string;
  clsName: string;
  icon?: React.ReactElement;
};

interface Props {
  verification: VerificationType;
  isShow?: boolean;
  onClick?: ($event?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export default function AuthButton({
  verification,
  isShow,
  disabled,
  onClick,
}: Props) {
  const {t} = useTranslation(['components'], {keyPrefix: 'join.AuthButton'});
  const btnTypes: Record<VerificationType, VerifyButton> = {
    disabled: {
      txt: t('auth-email'),
      clsName: styles.disabled,
    },
    active: {
      txt: t('auth-email'),
      clsName: styles.active,
    },
    'code-active': {
      txt: t('auth'),
      clsName: styles.active,
    },
    're-send': {
      txt: t('auth-resend'),
      clsName: styles.reSend,
    },
    error: {
      txt: t('auth-error'),
      clsName: styles.error,
      icon: <span className="large-pink-x-icon">&nbsp;</span>,
    },
    success: {
      txt: t('auth-success'),
      clsName: styles.success,
      icon: <span className="pink-checker-icon">&nbsp;</span>,
    },
  };
  return (
    <button
      className={`${btnTypes[verification].clsName} ${styles.txt}`}
      onClick={onClick}
      disabled={disabled}
      style={{display: isShow ? 'flex' : 'none'}}>
      {btnTypes[verification].icon}
      {`${btnTypes[verification].txt}`}
    </button>
  );
}
