import React, {useEffect, useState} from 'react';
import {useRecoilValue, useRecoilState, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {updateProfile, userDetail} from '@/services/user';
import {tastTags, user, UserInfo} from '@/store';
import ContentsHeader from '@/layouts/ContentsHeader';

interface Props {
  type: 'join' | 'edit';
}

export default function TastTag({type}: Props) {
  const navigator = useNavigate();
  const tags = useRecoilValue(tastTags);
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const accessToken = useRecoilValue(user);
  const setAccessToken = useSetRecoilState(user);
  const [selectedTags, setSelectedTag] = useState<string[]>([]);
  const updateSelectedTag = (type: string) => {
    const _selectedTags = JSON.parse(JSON.stringify(selectedTags)) as string[];
    const eIdx = _selectedTags.indexOf(type);
    if (eIdx > -1) {
      _selectedTags.splice(eIdx, 1);
      setSelectedTag(_selectedTags);
    } else {
      _selectedTags.push(type);
      setSelectedTag(_selectedTags);
    }
  };
  const moveToOut = () => {
    if (type === 'edit') {
      navigator('/user/edit');
    } else {
      navigator('/signup/tast-tag/complete');
    }
  };
  const complete = async () => {
    try {
      const _userInfo = JSON.parse(JSON.stringify(userInfo)) as UserInfoObj;
      if (_userInfo.tags) {
        _userInfo.tags.taste = selectedTags;
      }
      await updateProfile(_userInfo.id, accessToken, _userInfo);
      setUserInfo(_userInfo);
      if (type === 'edit') {
        navigator('/user/edit');
      } else {
        navigator('/signup/tast-tag/complete');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const init = () => {
    const _userInfo = JSON.parse(JSON.stringify(userInfo)) as UserInfoObj;
    if (_userInfo.tags) {
      setSelectedTag(_userInfo.tags.taste || []);
    }
  };
  const initUser = async () => {
    try {
      const accessToken = window.localStorage.getItem('knewnew_t_k');
      if (accessToken) {
        const res = await userDetail(accessToken);
        setUserInfo(res.data);
        setAccessToken(accessToken);

        await init();
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (!userInfo) {
      initUser();
    } else {
      init();
    }
  }, [userInfo]);
  return (
    <div className="main" style={{minHeight: '100vh'}}>
      {type === 'edit' && (
        <ContentsHeader txt={'입맛태그 선택'} isOption={'home'} />
      )}
      <div className="tast-tag-selector">
        <div className="tast-tag-title">
          <div className="tast-tag-icon-wrapper">
            <span className="tast-tag-icon">&nbsp;</span>
          </div>
          <div className="tast-tag-txt">
            {userInfo ? userInfo.nickname : ''}님을 나타내는
            <br />
            입맛 태그를 마음껏 선택해주세요.
          </div>
        </div>
        <div className="tast-tag-body">
          <div className="tast-tag-line tast-tag-line-1">
            {tags.slice(0, 5).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.title) > -1 ? 'active' : ''
                  }`}
                  key={t.type}
                  onClick={() => updateSelectedTag(t.title)}
                  style={{cursor: 'pointer'}}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-2">
            {tags.slice(5, 9).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.title) > -1 ? 'active' : ''
                  }`}
                  key={t.type}
                  onClick={() => updateSelectedTag(t.title)}
                  style={{cursor: 'pointer'}}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-3">
            {tags.slice(9, 14).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.title) > -1 ? 'active' : ''
                  }`}
                  key={t.type}
                  onClick={() => updateSelectedTag(t.title)}
                  style={{cursor: 'pointer'}}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-4">
            {tags.slice(14, 18).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.title) > -1 ? 'active' : ''
                  }`}
                  key={t.type}
                  onClick={() => updateSelectedTag(t.title)}
                  style={{cursor: 'pointer'}}>
                  {t.title}
                </span>
              );
            })}
          </div>
          <div className="tast-tag-line tast-tag-line-5">
            {tags.slice(18, 23).map(t => {
              return (
                <span
                  className={`tast-icon ${t.type} ${
                    selectedTags.indexOf(t.title) > -1 ? 'active' : ''
                  }`}
                  key={t.type}
                  onClick={() => updateSelectedTag(t.title)}
                  style={{cursor: 'pointer'}}>
                  {t.title}
                </span>
              );
            })}
          </div>
        </div>
        <div className="tast-tag-footer">
          <button
            className="main-cont btn main-black-border"
            onClick={() => complete()}>
            선택 완료
          </button>
          {type !== 'edit' && (
            <button className="late-check" onClick={() => moveToOut()}>
              나중에 할래요
              <span className="light-gray-close-icon">&nbsp;</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
