import React, {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {useRecoilValue} from 'recoil';

import styles from './styles.module.scss';
import Carousel from '../../common/Carousel';
import {isMobile, switchNewLineTxt} from '../../../utils';
import {MagazineZipCards} from '../../../store';

export default function MagazineKnewZip() {
  const navigator = useNavigate();
  const zips = useRecoilValue(MagazineZipCards);
  const [activeIndex, setActiveIndex] = useState(0);

  const lastSize = useMemo(() => {
    if (Capacitor.getPlatform() === 'web') {
      if (isMobile()) {
        return innerWidth - 305;
      }
      return 375 - 305;
    }
    return innerWidth - 305;
  }, [innerWidth]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <div className={styles.titles}>
          <strong>Pick</strong>
          <span>감도높은 테마별 F&B 브랜드 큐레이션</span>
        </div>
        <span
          className={`${styles.icon} arrow-right-for-magazine-icon`}
          onClick={() =>
            navigator('/zip', {
              state: {
                prev: 'magazine',
              },
            })
          }>
          &nbsp;
        </span>
      </div>
      <div className={styles.scroll}>
        {zips.length > 0 && (
          <Carousel
            size={265}
            lastSize={lastSize}
            onChange={(idx: number) => setActiveIndex(idx)}>
            {zips.map((zip, idx) => {
              return (
                <div
                  className={`${styles.item} ${
                    activeIndex === idx ? styles.active : styles.inactive
                  }`}
                  key={zip.id}
                  onClick={() =>
                    navigator(`/zip/${zip.id}`, {
                      state: {
                        prev: 'magazine',
                      },
                    })
                  }
                  style={{
                    background: `url("${zip.imageUrl}") no-repeat center / cover`,
                  }}>
                  <div className={styles.text_wrapper}>
                    <div
                      className={styles.contents_title}
                      dangerouslySetInnerHTML={{
                        __html: switchNewLineTxt(zip.title || ''),
                      }}></div>
                    <div className={styles.contents_desc}>{zip.subtitle}</div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
}
