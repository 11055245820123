import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';
import {foodLogContents} from '@/services/recommend';
import CommentsWrapper from '@/components/comments/Wrapper';
import MoveToShopBtn from '@/components/common/buttons/MoveToShopBtn';
import CommentModal from '@/components/common/CommentModal';
import FoodLogFooter from '@/components/recommend/foodlog/Footer';
import FLHashTags from '@/components/recommend/foodlog/HashTags';
import FoodLogHeader from '@/components/recommend/foodlog/Header';
import FoodLogProfile from '@/components/recommend/foodlog/Profile';
import {switchNewLineTxt} from '@/utils';

export default function FoodLog() {
  const [foodLog, setFoodLog] = useState<FoodLog>();
  const navigator = useNavigate();
  const init = async () => {
    const paths = window.location.pathname.split('/');
    const id = paths[paths.length - 1];
    const res = await foodLogContents(id);
    (res.data as FoodLog).created = dayjs((res.data as FoodLog).created).format(
      'YYYY.MM.DD',
    );
    setFoodLog(res.data as FoodLog);
  };
  const moveToMarket = (pId: number) => {
    navigator(`/recommend/market/${pId}`);
  };
  useEffect(() => {
    init();
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  return (
    <div className="main">
      <div className="main-content food-log-wrapper">
        <ContentsHeader txt={''} isOption={'option'} />
        <FoodLogHeader images={foodLog?.images} />
        <FoodLogProfile author={foodLog?.author} />
        <div className="linker">
          {/* 마켓컬리 */}
          <div
            className="buy-info"
            style={{cursor: 'pointer'}}
            onClick={() => moveToMarket(foodLog?.product.id as number)}>
            <div className="shop-name">{foodLog?.market}</div>
            <div className="product-info">
              {foodLog?.product.name}
              <span className="right-direction-icon"></span>
            </div>
          </div>
          {/* 구매처로 이동하기 */}
          <MoveToShopBtn link={foodLog?.product.link || ''} />
        </div>
        {/* 최고에요 */}
        <div
          className="is-best-wrapper"
          style={{display: foodLog?.isLike ? 'flex' : 'none'}}>
          <span className="is-best-icon"></span>
          <span className="txt">최고에요</span>
        </div>
        <p
          className="blogs"
          dangerouslySetInnerHTML={{
            __html: switchNewLineTxt(foodLog?.content || ''),
          }}></p>
        <FLHashTags tags={foodLog?.tags.interest} />
        <div className="dates">{foodLog?.created}</div>
        <div className="divider">&nbsp;</div>
        <CommentsWrapper id={foodLog?.id || -1} />
        <FoodLogFooter
          likeCnt={foodLog?.likeCount}
          commentCnt={foodLog?.commentCount}
          isBookmark={foodLog?.isLike}
          id={foodLog?.id || -1}
        />
      </div>
      <CommentModal type={'edit'} fetch={() => null} />
    </div>
  );
}
