import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {PathHistories} from '@/store';

const useHistory = () => {
  const navigator = useNavigate();
  const [histories, setHistories] = useRecoilState(PathHistories);
  const historyBack = () => {
    const _histories = JSON.parse(JSON.stringify(histories));
    if (_histories.length > 1) {
      _histories.splice(-1, 1);
      const {path, state} = _histories[_histories.length - 1];
      setHistories(_histories);
      setTimeout(() => {
        navigator(`/${path}`, {
          state,
        });
      }, 0);
    } else {
      const last = _histories.splice(-1, 1);
      if (last) {
        const {path, state} = last;
        setHistories(_histories);
        setTimeout(() => {
          navigator(`/${path}`, {
            state,
          });
        }, 0);
      } else {
        navigator('/home');
      }
    }
  };
  return {
    historyBack,
  };
};

export default useHistory;
