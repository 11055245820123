import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

import styles from '../styles.module.scss';
import MyMagazineItem from './MyMagazineItem';
import {useScroll} from '../../../hooks/useScroll';

interface Props {
  magazines: MagazineItem[];
}

export default function MyMagazines({magazines}: Props) {
  const {syncScroll} = useScroll();
  const navigator = useNavigate();
  const [myMagazines, setMyMagazines] = useState<MagazineItem[]>([]);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = _.cloneDeep(myMagazines);
    _items[idx].isBookmark = isLike;
    setMyMagazines(_items);
  };
  useEffect(() => {
    setMyMagazines(magazines);
    syncScroll();
  }, [magazines]);
  return (
    <div>
      <div
        className="no-contents"
        style={{
          display: myMagazines.length === 0 ? 'flex' : 'none',
        }}>
        <span className="txt">
          스크랩한 매거진이 없습니다.
          <br />
          마음에 드는 매거진을 모아 보세요.
        </span>
        <button
          className={styles.go_to_contents}
          onClick={() =>
            navigator('/zip', {
              state: {
                prev: 'my-collection?type=magazines',
              },
            })
          }>
          매거진 보러가기
        </button>
      </div>
      <div
        className={`contents ${styles.scrapMagazineList}`}
        style={{
          display: myMagazines.length > 0 ? 'grid' : 'none',
          padding: '20px 20px 86px 20px',
        }}>
        {myMagazines.map((item, idx) => {
          return (
            <MyMagazineItem
              info={item}
              idx={idx}
              key={`order_collection_item_${idx}`}
              toggleLike={toggleLike}
            />
          );
        })}
      </div>
    </div>
  );
}
