import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './styles.module.scss';

interface Props {
  title: string;
  subtitle: string;
  type: 'brands' | 'zip' | 'news';
}

export default function MagazineItem({title, subtitle, type}: Props) {
  const navigator = useNavigate();

  const typeStyle = useMemo(() => {
    let typeName;
    switch (type) {
      case 'brands':
        typeName = styles.brands;
        break;
      case 'news':
        typeName = styles.news;
        break;
      case 'zip':
        typeName = styles.zip;
        break;
    }
    return typeName;
  }, [type]);

  return (
    <div
      className={`${styles.magazineItemWrapper} ${typeStyle}`}
      onClick={() => navigator(`/${type}`)}>
      <div className={styles.txtWrapper}>
        <div className={styles.title}>
          <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
          <span className={`arrow-right-white-icon ${styles.icon}`}>
            &nbsp;
          </span>
        </div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
}
