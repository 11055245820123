import styled from 'styled-components';

export const ParagraphWrapper = styled.p<ParagraphWrapperI>`
  color: ${({color}) => color || '#9F9CA3'};
  font-size: ${({fontSize}) => `${fontSize}px` || '14px'};
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  text-align: left;
`;
