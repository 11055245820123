import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';

import {useScroll} from '@/hooks/useScroll';

import styles from '../styles.module.scss';
import CollectionItem from './Item';

interface Props {
  collections: CollectionListItem[];
}

export default function MyCollections({collections}: Props) {
  const {syncScroll} = useScroll();
  const navigator = useNavigate();

  const [myCollections, setMyCollections] = useState<CollectionListItem[]>([]);
  const toggleLike = (idx: number, isLike: boolean) => {
    const _items = _.cloneDeep(myCollections);
    _items[idx].isBookmark = isLike;
    setMyCollections(_items);
  };
  useEffect(() => {
    setMyCollections(collections);
    syncScroll();
  }, [collections]);
  return (
    <div>
      <div
        className="no-contents"
        style={{
          display: myCollections.length === 0 ? 'flex' : 'none',
        }}>
        <span className="txt">
          스크랩한 상품이 없습니다.
          <br />
          마음에 드는 상품을 모아 보세요.
        </span>
        <button
          className={styles.go_to_contents}
          onClick={() => navigator('/collection/list')}>
          상품 보러가기
        </button>
      </div>
      <div
        className={`contents ${styles.list}`}
        style={{
          display: myCollections.length > 0 ? 'grid' : 'none',
          padding: '20px 20px 86px 20px',
        }}>
        {myCollections.map((item, idx) => {
          return (
            <CollectionItem
              key={item.id}
              idx={idx}
              item={item}
              toggleLike={toggleLike}
              setOpenInstallAlert={() => ''}
            />
          );
        })}
      </div>
    </div>
  );
}
