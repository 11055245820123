import React, {useMemo} from 'react';
import {useNavigate} from 'react-router';

import styles from './styles.module.scss';

interface Props {
  item: Community;
}

export default function MyCommunityCard({item}: Props) {
  const navigator = useNavigate();
  const move = () => {
    return item.id > -1
      ? navigator(`/community/${item.id}`, {
          state: {
            history: 'user-page',
          },
        })
      : -1;
  };
  const background = useMemo(() => {
    return item.images.length > 0
      ? `url(${item.images[0].image}) center no-repeat`
      : '#FFF';
  }, [item]);
  return item ? (
    <div
      className={styles.card}
      onClick={() => move()}
      style={{
        background,
        backgroundSize: 'cover',
        cursor: item.id > -1 ? 'pointer' : 'inherit',
      }}>
      {item.id > -1 ? (
        <span className={styles.txt}>
          {item.category.replace(/^[a-z]/, char => char.toUpperCase())}
        </span>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div></div>
  );
}
