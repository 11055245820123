import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  shortcutItems: {
    name: string;
    cnt: number;
    hasNew?: boolean;
    onClick?: () => void;
  }[];
}

export default function ShortCuts({shortcutItems}: Props) {
  return (
    <div className={styles.shortCutArea}>
      {shortcutItems.map((shortcut, idx) => {
        return (
          <div
            className={styles.shortCutItem}
            key={idx}
            onClick={() => (shortcut.onClick ? shortcut.onClick() : '')}>
            <span
              className={`${styles.name} ${
                shortcut.hasNew ? styles.hasnew : ''
              }`}>
              {shortcut.name}
            </span>
            <span className={styles.cnt}>{shortcut.cnt}</span>
          </div>
        );
      })}
    </div>
  );
}
