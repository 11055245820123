import React, {useEffect, useState, useRef} from 'react';
import {useRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {ChevronDown} from 'react-feather';
import {Capacitor} from '@capacitor/core';

import {OpenCategoryModal} from '../../../store';
import styles from './styles.module.scss';

export default function MagazineCategoryModal() {
  const navigator = useNavigate();
  const [isOpen, setIsOpen] = useRecoilState(OpenCategoryModal);
  const [toggledCategory, setToggledCategory] = useState<CategoryItem>();
  const categories: CategoryItem[] = [
    {
      txt: 'Pick',
      param: 'zip',
      lastPath: 'zip',
    },
    {
      txt: 'Brand',
      param: 'brands',
      lastPath: 'knewbrands',
    },
    {
      txt: 'News',
      param: 'news',
      lastPath: 'news',
    },
  ];

  useEffect(() => {
    const paths = window.location.pathname.split('/');
    const lastPath = paths[paths.length - 1];
    const currentCategory = categories.find(c => c.lastPath === lastPath);
    setToggledCategory(currentCategory);
  }, [window.location.pathname]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div className="modal-wrapper" style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={`modal-contents category ${isOpen ? 'active' : ''} ${
          Capacitor.getPlatform() === 'ios' ? styles.ios : ''
        }`}
        ref={wrapperRef}>
        <div className={styles.header}>
          <ChevronDown
            size={'30px'}
            color={'#D2D0D5'}
            style={{cursor: 'pointer'}}
            onClick={() => (setIsOpen ? setIsOpen(false) : '')}
          />
        </div>
        <ul className="category-modal-body">
          {categories.map(category => {
            return (
              <li
                className={`${
                  toggledCategory &&
                  toggledCategory.lastPath === category.lastPath
                    ? 'active'
                    : ''
                }`}
                key={`modal-category-${category.txt}`}
                onClick={() => {
                  setIsOpen(false);
                  navigator(`/${category?.lastPath}`);
                }}>
                {category.txt}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
