import React, {useMemo} from 'react';
import dayjs from 'dayjs';

import styles from './styles.module.scss';
import {addComma} from '../../../utils';
interface Props {
  isActive: boolean;
  isDisabled?: boolean;
  coupon: Coupon;
  productPrice: number;
  clickCallback: () => void;
}

export default function CouponItem({
  isActive,
  isDisabled,
  coupon,
  productPrice,
  clickCallback,
}: Props) {
  const {
    discountAmount,
    discountRatio,
    expireDate,
    maxDiscount,
    minAmount,
    title,
  } = coupon;
  const discountPrice = useMemo(() => {
    if (Number(discountAmount) === 0) {
      let amt = productPrice * (Number(discountRatio) / 100);
      amt =
        Number(maxDiscount) > 0 && amt > Number(maxDiscount)
          ? Number(maxDiscount)
          : Math.floor(amt);
      return `-${addComma(amt)}`;
    } else {
      const price =
        Number(discountAmount) > productPrice
          ? productPrice
          : Number(discountAmount);
      return `-${addComma(Number(price.toFixed(0)))}`;
    }
  }, [productPrice, coupon]);
  const clickItem = () => {
    if (!isDisabled) {
      clickCallback();
    }
  };
  return (
    <div
      className={`${styles.couponWrapper} ${isActive ? styles.active : ''} ${
        isDisabled ? styles.disabledCard : ''
      }`}
      onClick={() => clickItem()}>
      <div className={styles.titleWrapper}>
        <div>
          <h3>
            {Number(discountAmount) > 0
              ? `${addComma(Number(discountAmount))}원`
              : discountRatio + '%'}{' '}
            할인
          </h3>
          <p>{title}</p>
        </div>
        <div className={`${styles.checkerWrapper}`}>
          <span
            className={`${styles.checker} ${
              isActive ? styles.active : styles.disabled
            }`}>
            &nbsp;
          </span>
        </div>
      </div>
      <div className={styles.infoArea}>
        <div className={styles.info}>
          <p>{dayjs(expireDate).format('YYYY-MM-DD HH:mm')}까지</p>
          <p>{addComma(Number(minAmount))}원 이상 구매 시</p>
          <p style={{display: Number(maxDiscount) > 0 ? 'block' : 'none'}}>
            최대 {addComma(Number(maxDiscount))}원 할인
          </p>
        </div>
        <div
          className={styles.discountPriceArea}
          style={{display: isDisabled ? 'none' : 'block'}}>
          {discountPrice}원 할인
        </div>
      </div>
    </div>
  );
}
