import React, {useEffect, useMemo, useState} from 'react';
import {useSwipeable} from 'react-swipeable';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactElement[];
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  size?: number;
  lastSize?: number;
  showStatus?: boolean;
  disableXSize?: boolean;
  time?: number;
  onChange?: (idx: number) => void;
}

export default function Carousel({
  children,
  autoPlay,
  infiniteLoop,
  size,
  lastSize,
  showStatus,
  disableXSize,
  time,
  onChange,
}: Props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (ni: number) => {
    if (ni < 0) {
      ni = infiniteLoop ? children.length - 1 : 0;
    } else if (ni >= children.length) {
      ni = infiniteLoop ? 0 : children.length - 1;
    }
    setActiveIndex(ni);
    if (onChange) {
      onChange(ni);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused && autoPlay) {
        updateIndex(activeIndex + 1);
      }
    }, time ?? 3000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const swipeLeft = () => {
    updateIndex(activeIndex + 1);
  };

  const swipeRight = () => {
    updateIndex(activeIndex - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => swipeLeft(),
    onSwipedRight: () => swipeRight(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const xSize = useMemo(() => {
    if (size) {
      const isLast = children.length - 1 === activeIndex;
      const isFirst = activeIndex === 1;
      return `${
        isLast
          ? activeIndex * size - (lastSize ? lastSize : 30)
          : isFirst
          ? activeIndex * size - (lastSize ? 15 : 0)
          : activeIndex * size
      }px`;
    }
    return `${activeIndex * 100}%`;
  }, [size, activeIndex, children]);

  return (
    <div
      {...handlers}
      className={styles.carousel}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}>
      <div
        className={cc([styles.inner, `active${activeIndex + 1}`])}
        style={{
          transform: disableXSize ? 'translateX(0)' : `translateX(-${xSize})`,
        }}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {width: '100%'});
        })}
      </div>
      <p
        className={styles.status}
        style={{display: showStatus ? 'flex' : 'none'}}>
        {activeIndex + 1} / {children.length}
      </p>
    </div>
  );
}
