import React from 'react';
import {useRecoilState} from 'recoil';

import {MarketPageFilters} from '../../../../store/recommend';

export default function SortModalBody() {
  const [filterInfo, setFilterInfo] = useRecoilState(MarketPageFilters);
  const clickIcon = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'sort')[0];
    const fIdx = f.selection.indexOf(selection);
    if (fIdx > -1) {
      f.selection.splice(fIdx, 1);
    } else {
      f.selection = [selection];
    }
    _filterInfo.forEach(filter => {
      if (filter.type === f.type) {
        filter.selection = f.selection;
      }
    });
    setFilterInfo(_filterInfo);
  };
  const isActive = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'sort')[0];
    return f.selection.indexOf(selection) > -1;
  };
  return (
    <div className="filter-modal-body sort">
      <div className="sort-filter">
        <button
          className={`recent ${isActive('recent') ? 'active' : ''}`}
          onClick={() => clickIcon('recent')}>
          최신순
        </button>
        <button
          className={`popular ${isActive('popular') ? 'active' : ''}`}
          onClick={() => clickIcon('popular')}>
          인기순
        </button>
      </div>
      <div className="random-filter">
        <button
          className={`random ${isActive('random') ? 'active' : ''}`}
          onClick={() => clickIcon('random')}>
          <span className="dice-icon">&nbsp;</span>
          <span className="txt">랜덤보기</span>
        </button>
      </div>
    </div>
  );
}
