import React from 'react';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import {ReactComponent as NaverMapIcon} from '@/assets/icons/naverModalIcon.svg';
import {ReactComponent as KakaoMapIcon} from '@/assets/icons/kakaoModalIcon.svg';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';

import ModalMapItem from '../../molecules/Modal/Map';
import BottomSheet from '../../templates/bottomSheet/BottomSheet';

interface Props {
  close: () => void;
  closed: boolean;
  name: string;
}

export default function MapBottomSheet({closed, close}: Props) {
  const {brandInfo} = useBrandContext();
  const openLink = (link: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(link, '_blank');
    } else {
      Browser.open({
        url: link,
      });
    }
  };
  const openMap = (serviceType: 'kakao' | 'naver' | 'google') => {
    if (brandInfo) {
      switch (serviceType) {
        case 'kakao':
          openLink(brandInfo?.locationKakao || '');
          break;
        case 'naver':
          openLink(brandInfo?.locationNaver || '');
          break;
      }
    }
  };
  return (
    <BottomSheet close={close} closed={closed}>
      <ModalMapItem
        icon={<NaverMapIcon />}
        txt={'네이버 지도'}
        onClick={() => openMap('naver')}
      />
      <ModalMapItem
        icon={<KakaoMapIcon />}
        txt={'카카오맵'}
        onClick={() => openMap('kakao')}
      />
    </BottomSheet>
  );
}
