/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import {requestPayments} from '@/services/magazine';
import {user} from '@/store/user';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

export default function OrderProcess() {
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const accessToken = useRecoilValue(user);
  const localStorageKey = 'WEB_PAYMENT_INFO';
  const sender = useLogger();

  const requestPay = async (requestParam: PaymentRequestParam) => {
    try {
      const res = await requestPayments(requestParam, accessToken);
      if (res.data.id) {
        const popupId = Number.parseInt(searchParams.get('popupId') as string);
        navigator(`/order/${popupId}/success`, {
          state: {
            magazine: location.state?.magazine as MagazineItem,
            options: location.state?.options,
            name: location.state?.name,
            phoneNumber: location.state?.phoneNumber,
            price: location.state?.price,
            issuerName: res.data.card?.issuerName,
            couponId: location.state?.couponId || 0,
            discountPrice: location.state?.discountPrice || 0,
            productPrice: location.state?.productPrice || 0,
            address: location.state?.address || '',
            postCode: location.state?.postCode || '',
            detailAddress: location.state?.detailAddress || '',
            userOption: location.state?.userOption || '',
            popupReserveLink: res.data.popupReserveLink || '',
            popupReserveNumber: res.data.popupReserveNumber || '',
            deliveryFee: location.state?.deliveryFee,
            orderRequest: location.state?.orderRequest,
          },
        });
        sender({
          _msg: 'buy_click_success',
          _screen: window.location.pathname,
          _action: Action.CLICK,
        });
        localStorage.removeItem(localStorageKey);
      }
    } catch (e: any) {
      navigator('/order/failed', {
        state: {
          magazine: location.state?.magazine as MagazineItem,
        },
      });
      sender({
        _msg: 'buy_click_fail',
        _screen: window.location.pathname,
        _action: Action.CLICK,
      });
      localStorage.removeItem(localStorageKey);
    }
  };

  useEffect(() => {
    const magazine = location.state?.magazine as MagazineItem;
    const couponId = location.state?.couponId
      ? Number(location.state?.couponId)
      : 0;
    const requestParam: PaymentRequestParam = {
      popup: magazine.id || Number(searchParams.get('magazineId') as string),
      option: Number.parseInt(searchParams.get('option') as string),
      orderId: searchParams.get('orderId') as string,
      orderName: searchParams.get('orderName') as string,
      paymentKey:
        searchParams.get('paymentKey') ??
        (searchParams.get('orderId') as string),
      purchaseCount: Number.parseInt(location.state?.amount || '0'),
      purchaseAmount: Number.parseInt(location.state?.price || '0'),
      userName: location.state?.name,
      userPhone: location.state?.phoneNumber,
      couponIssued: couponId,
      address1: location.state?.address,
      post_code: location.state?.postCode,
      address2: location.state?.detailAddress,
      requests: location.state?.userOption,
      orderRequest: location.state?.orderRequest,
    };
    requestPay(requestParam);
  }, [searchParams]);

  return (
    <div className="main" style={{height: '100vh', position: 'relative'}}>
      <ContentsHeader
        txt={'주문 / 결제'}
        isBottomLine=""
        isOption="none"
        isPopup={false}
      />
      <div className={styles.loading_wrapper}>
        <div className={styles.loading}>
          <span className={styles.loader_icon}></span>
          <h2>결제가 진행중입니다.</h2>
          <div className={styles.wait}>잠시만 기다려주세요.</div>
        </div>
      </div>
    </div>
  );
}
