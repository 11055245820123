import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {AxiosResponse} from 'axios';

import Layout from '@/layouts';
import OverlayHeader from '@/layouts/OverlayHeader';
import {getPromotionDetail} from '@/services/magazine';
import {user} from '@/store';
import CollectionLists from '@/views/collection/components/Lists';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

export default function Promotion() {
  const params = useParams();
  const accessToken = useRecoilValue(user);
  const [data, setData] = useState<Promotion>();
  const [tab, setTab] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const sender = useLogger();
  const getData = async (id: number) => {
    const res: AxiosResponse<Promotion> = await getPromotionDetail(
      accessToken,
      id,
    );
    setData(res.data);
  };
  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
  };
  useEffect(() => {
    if (params.id) {
      const id = Number(params.id);
      getData(id);
      sender({
        _msg: 'promotionList_' + id,
        _screen: window.location.pathname,
        _action: Action.CLICK,
      });
    }
  }, [params]);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Layout.Main>
      {data ? (
        <div>
          <OverlayHeader
            isScrolled={isScrolled}
            clickShare={() => setIsOpenShareSheet(true)}
          />
          {/* img */}
          <div className={styles.img}>
            <img src={data.imageUrl} alt="" />
          </div>
          {/* list */}
          <CollectionLists
            tab={tab}
            setTab={setTab}
            promotionId={data.id}
            magazines={data.magazines}
          />
          <BrandShareBottomSheet
            isOpen={isOpenShareSheet}
            setIsOpen={setIsOpenShareSheet}
            item={{
              title: data.title,
              info: data.subtitle,
              imageList: [data.thumbnailUrl],
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </Layout.Main>
  );
}
