import {Preferences} from '@capacitor/preferences';
import {Storage} from '@capacitor/storage';
import {Capacitor} from '@capacitor/core';

export default function useStorage() {
  const setItem = async (key: string, value: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.localStorage.setItem(key, value);
    } else {
      await Preferences.set({
        key: key,
        value: value,
      });
    }
  };
  const getItem = async (key: string) => {
    let token = '';
    if (Capacitor.getPlatform() === 'web') {
      token = window.localStorage.getItem(key) || '';
    } else {
      const {value} = await Preferences.get({
        key: key,
      });
      token = value || '';
    }
    return token;
  };
  const setToken = async (token: string) => {
    try {
      if (Capacitor.getPlatform() === 'web') {
        window.localStorage.setItem('knewnew_t_k', token);
        await Storage.set({
          key: 'knewnew_t_k',
          value: token,
        });
      } else {
        await Preferences.set({
          key: 'auth',
          value: token,
        });
        await Storage.set({
          key: 'knewnew_t_k',
          value: token,
        });
      }
    } catch (e) {
      console.log(`Preference222:`, e);
    }
  };
  const getToken = async () => {
    let token = '';
    if (Capacitor.getPlatform() === 'web') {
      // token = window.localStorage.getItem('knewnew_t_k') as string;
      token = (await Storage.get({key: 'knewnew_t_k'})).value || '';
    } else {
      const {value} = await Preferences.get({
        key: 'auth',
      });
      token = value || '';
      token = (await Storage.get({key: 'knewnew_t_k'})).value || '';
    }
    return token;
  };
  const removeToken = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.localStorage.removeItem('knewnew_t_k');
      await Storage.remove({key: 'knewnew_t_k'});
    } else {
      Preferences.remove({
        key: 'auth',
      });
      await Storage.remove({key: 'knewnew_t_k'});
    }
  };
  const readPush = async (email: string, id: number) => {
    if (Capacitor.getPlatform() !== 'web') {
      await Preferences.set({
        key: `${email}_push_${id}`,
        value: 'read',
      });
    }
  };
  const getPush = async (email: string, id: number) => {
    const val = await Preferences.get({
      key: `${email}_push_${id}`,
    });
    console.log(val);
    return val.value;
  };
  const setLoginProvider = async (provider: string) => {
    const key = 'knewnew_login_provider';
    if (Capacitor.getPlatform() === 'web') {
      window.localStorage.setItem(key, provider);
    } else {
      Preferences.set({
        key,
        value: provider,
      });
    }
  };
  const getLoginProvider = async () => {
    const key = 'knewnew_login_provider';
    let provider = '';
    if (Capacitor.getPlatform() === 'web') {
      provider = window.localStorage.getItem(key) || '';
    } else {
      provider = (
        await Preferences.get({
          key,
        })
      ).value as string;
    }
    console.log(provider);
    return provider;
  };

  return {
    setItem,
    getItem,
    setToken,
    getToken,
    removeToken,
    readPush,
    getPush,
    setLoginProvider,
    getLoginProvider,
  };
}
