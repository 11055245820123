import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import styles from './styles.module.scss';
import {RandomCurators} from '../../../store';

export default function MagazineCurators() {
  const navigator = useNavigate();
  const curators = useRecoilValue(RandomCurators);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <strong>Knewnew Curator</strong>
        <span>맛과 멋이 있는 F&B브랜드를 발굴하는 Knewnew 큐레이터</span>
      </div>
      {curators.length > 0 && (
        <div className={styles.scroll}>
          {curators.map((curator, idx) => {
            return (
              <div
                className={styles.curatorCard}
                key={curator.id}
                style={{
                  marginRight: idx == 3 ? `20px` : `10px`,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigator(`/curators/${curator.id}`, {
                    state: {
                      prev: 'magazine',
                    },
                  })
                }>
                <div
                  className={styles.curatorImg}
                  style={{
                    background: `center / cover no-repeat url(${curator.profileImage})`,
                  }}></div>
                <div className={styles.nickName}>{curator.nickname}</div>
                <div className={styles.hashtags}>
                  {curator.userTags?.profile?.map((tag, idx) => {
                    return (
                      <span className={styles.tag} key={`userTag_${idx}`}>
                        #{tag}
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.btn_wrapper}>
        <button
          onClick={() =>
            navigator('/curators', {
              state: {
                prev: 'magazine',
              },
            })
          }>
          큐레이터 더보기
        </button>
      </div>
    </div>
  );
}
