import React from 'react';
import ContentLoader from 'react-content-loader';

export default function BrandItemLoader() {
  return (
    <ContentLoader
      speed={2}
      width={335}
      height={492}
      viewBox="0 0 335 492"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="196" y="315" rx="0" ry="0" width="1" height="1" />
      <rect x="20" y="0" rx="0" ry="0" width="335" height="400" />
      <rect x="20" y="409" rx="0" ry="0" width="333" height="30" />
      <rect x="20" y="446" rx="0" ry="0" width="332" height="65" />
    </ContentLoader>
  );
}
