import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useLocation, useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';

import styles from './styles.module.scss';
import {PopularAreas} from '../../store/brand';
import Modal from '../../layouts/Modal';

interface Props {
  isOpen: boolean;
  selectedArea: FamousAddress;
  setIsOpen: (is: boolean) => void;
}

export default function BrandAddressSelector({
  isOpen,
  selectedArea,
  setIsOpen,
}: Props) {
  const navigator = useNavigate();
  const location = useLocation();
  const addressList = useRecoilValue(PopularAreas);
  const [famousArea, setFamousArea] = useState<FamousAddress[]>([]);

  const onChange = (a: FamousAddress) => {
    setIsOpen(false);
    if (a.address === 'NEW') {
      navigator('/brands/new', {
        state: location.state,
      });
    } else {
      navigator(`/brands/${a.address1}/${a.address2}`, {
        state: location.state,
      });
    }
  };
  useEffect(() => {
    if (addressList && addressList.length > 0) {
      const addresses = addressList.map(a => {
        return {
          ...a,
          address1: a.address === '전국' ? -2 : a.address1,
          address2: a.address === '전국' ? -2 : a.address2,
        };
      });
      setFamousArea([
        {
          address: 'NEW',
          address1: -1,
          address2: -1,
          id: -2,
        },
        ...addresses,
      ]);
    }
  }, [addressList]);
  return (
    <Modal.BottomSheet
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      height={Capacitor.getPlatform() === 'ios' ? 'auto' : '415px'}>
      <ul
        className={styles.modalBody}
        style={{overflow: 'auto', height: '350px'}}>
        {famousArea.map((a, i) => {
          return (
            <li
              key={`${a.address}_${i}`}
              className={`${
                a.address === selectedArea.address ? styles.active : ''
              }`}
              onClick={() => onChange(a)}>
              {a.address}
            </li>
          );
        })}
      </ul>
    </Modal.BottomSheet>
  );
}
