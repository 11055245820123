/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {AxiosError} from 'axios';
import React, {useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';

import useKakao from '@/hooks/useKakao';
import {login, userDetail} from '@/services/user';
import useStorage from '@/hooks/useStorage';

import styles from './styles.module.scss';
import {
  LoginPrevPath,
  isOpenDuplicatedEmailModal,
  duplicatedEmail,
  duplicatedProviderType,
  user,
  UserInfo,
} from '../../store';

const {Kakao} = window as any;

export default function KakaoRedirectHandler() {
  const [paramCode, setParamCode] = useState('');
  const {setToken, setLoginProvider} = useStorage();
  const {getStatusInfo} = useKakao();
  const navigator = useNavigate();
  const setUserInfo = useSetRecoilState(UserInfo);
  const setAccessToken = useSetRecoilState(user);
  const setLoginPrevPath = useSetRecoilState(LoginPrevPath);
  const setOpenDuplicatedModal = useSetRecoilState(isOpenDuplicatedEmailModal);
  const setDuplicatedEmail = useSetRecoilState(duplicatedEmail);
  const setDuplicatedProviderType = useSetRecoilState(duplicatedProviderType);
  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get('code');
    const grant_type = 'authorization_code';
    const client_id = '880fcc7a517e1baf65b23567f2581be0';
    setParamCode(code as string);
    if (paramCode.length > 0) {
      const origin = window.location.origin;
      axios
        .post(
          `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${origin}/login/auth&code=${code}`,
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
          },
        )
        .then(async (res: any) => {
          try {
            Kakao.Auth.setAccessToken(res.data.access_token);
            const token = await getStatusInfo();
            if (token) {
              const res = await login(token as string, 'kakao');
              if (res.data.email) {
                let obj = {...res.data} as UserInfoObj;
                obj = {
                  ...obj,
                  nickname: obj.nickname.slice(0, 15),
                };
                setUserInfo(obj);
                setAccessToken(token as string);
                const prevPath = localStorage.getItem('knewnew_kakao_redirect');
                setLoginPrevPath(prevPath || '/home');
                navigator('/signup');
                localStorage.removeItem('knewnew_kakao_redirect');
              } else {
                setAccessToken(res.data.accessToken);
                setToken(res.data.accessToken);
                const userInfoRes = await userDetail(
                  res.data.accessToken as string,
                );
                setLoginProvider('kakao');
                setUserInfo(userInfoRes.data as UserInfoObj);
                const prevPath = localStorage.getItem('knewnew_kakao_redirect');
                navigator(prevPath ? `${prevPath}` : '/home');
                localStorage.removeItem('knewnew_kakao_redirect');
              }
            }
          } catch (e) {
            const code = (e as AxiosError).response?.status;
            const existedProviderType = (
              (e as AxiosError).response?.data as any
            ).providerType as 'kakao' | 'naver' | 'google' | 'apple' | 'email';
            if (code === 600) {
              setDuplicatedProviderType(existedProviderType);
              setDuplicatedEmail(
                ((e as AxiosError).response?.data as any).email,
              );

              setOpenDuplicatedModal(true);
              // loginWithToken(token, e.response.data.providerType);
            } else {
              navigator('/login');
            }
          }
        });
    }
  }, [paramCode]);
  return (
    <div className="main">
      <div
        className={`${styles.joinContents} ${
          Capacitor.getPlatform() === 'android' ? 'android' : ''
        }`}>
        <div className={styles.top}>
          <div
            className={`${styles.navigator} ${
              Capacitor.getPlatform() === 'android' ? 'android' : ''
            }`}
            style={{
              display: Capacitor.getPlatform() === 'web' ? 'flex' : 'none',
            }}>
            <span
              className={`icon arrow-left-white ${styles.back}`}
              onClick={() => navigator('/home')}>
              &nbsp;
            </span>
            {Capacitor.getPlatform() === 'web' && (
              <span
                className={`icon white-home-icon ${styles.home}`}
                onClick={() => navigator('/home')}>
                &nbsp;
              </span>
            )}
          </div>
          <div
            style={{
              marginTop: '250px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div className={styles.place}></div>
            <div className={styles.taste}>F&B 컬쳐 플랫폼 뉴뉴</div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div>
            <button className={`${styles.snsLoginButton} ${styles.kakao}`}>
              <span className="icon kakao-login-icon">&nbsp;</span>
              카카오로 시작하기
            </button>
            <button
              className={`${styles.snsLoginButton} ${styles.apple}`}
              style={{
                display:
                  Capacitor.getPlatform() !== 'android' ? 'flex' : 'none',
              }}>
              <span className="icon apple-login-icon">&nbsp;</span>
              Apple로 시작하기
            </button>
            <button className={`${styles.snsLoginButton} ${styles.google}`}>
              <span className="icon google-login-icon">&nbsp;</span>
              Google로 시작하기
            </button>
          </div>
          <div className={styles.emailLogin}>
            <a href="#" style={{marginRight: '10px'}}>
              이메일로 로그인
            </a>{' '}
            |{' '}
            <a href="#" style={{marginLeft: '10px'}}>
              문의하기
            </a>
          </div>
          <div className={styles.policyInfo}>
            회원가입 시 뉴뉴 서비스 필수 동의 항목인
            <br />
            <span className={styles.policyLink} style={{cursor: 'pointer'}}>
              개인정보처리방침
            </span>
            과{' '}
            <span className={styles.policyLink} style={{cursor: 'pointer'}}>
              서비스 이용약관
            </span>
            에 동의한 것으로 간주합니다.
          </div>
        </div>
      </div>
    </div>
  );
}
