import {atom} from 'recoil';

export const isOpenModal = atom<boolean>({
  key: 'comments/is-open-modal',
  default: false,
});

export const CommentType = atom<string>({
  key: 'comments/comment-type',
  default: 'community',
});

export const ModalCommentID = atom<number>({
  key: 'comments/modal-comment-id',
  default: -1,
});

export const ModalCommentStatus = atom<string>({
  key: 'comments/modal-comment-status',
  default: '',
});

export const OpeningModalStatus = atom<string>({
  key: 'comments/opening-modal-status',
  default: '',
});

export const openAnswer = atom<boolean>({
  key: 'comments/open-answer',
  default: false,
});

export const answerNickName = atom<string>({
  key: 'comments/answer-nick-name',
  default: '',
});

export const answerTargetId = atom<number>({
  key: 'comments/answer-target-id',
  default: -1,
});

export const commentEditContents = atom<string>({
  key: 'comments/comment-edit-contents',
  default: '',
});

export const CommentCnt = atom<number>({
  key: 'comments/cnt',
  default: 0,
});
