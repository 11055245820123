import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';

import styles from './styles.module.scss';

export default function Agreement() {
  const navigator = useNavigate();
  const location = useLocation();
  return (
    <div
      className="main"
      style={{
        minHeight: '100vh',
        position: 'relative',
        paddingBottom: '40px',
        overflowX: 'hidden',
      }}>
      <ContentsHeader
        txt="개인정보 수집∙제공 동의"
        isPopup={true}
        isOption="none"
        isBottomLine=""
        back={() => {
          navigator(
            `/order/detail/${(location.state?.magazine as MagazineItem).id}`,
            {
              replace: true,
              state: {
                magazine: location.state?.magazine as MagazineItem,
                selectedDate: location.state?.selectedDate,
                selectedTime: location.state?.selectedTime,
                amount: location.state?.amount,
                price: location.state?.price,
                option: location.state?.option,
                name: location.state?.name,
                phoneNumber: location.state?.phoneNumber,
              },
            },
          );
        }}
      />
      <div className={styles.personal_info_agree}>
        <h2>개인정보 수집 동의 (필수)</h2>
        <div>
          <div className={styles.options}>
            <div>1.</div>
            <p className={styles.contents}>
              기본수집 항목 : [필수] 뉴뉴 아이디, 이름, 전화번호
            </p>
          </div>
          <div className={styles.options}>
            <div>2.</div>
            <p className={styles.contents}>
              수집 및 이용 목적 : 사업자 회원과 예약 이용자의 원 활한 거래 진행,
              고객상담, 불만 처리 등 민원 처리, 분쟁 조정 해결을 위한 기록 보존
            </p>
          </div>
          <div className={styles.options}>
            <div>3.</div>
            <div className={styles.contents}>
              <div>보관기간</div>
              <p>
                a. 회원탈퇴 시 지체없이 파기 b. 단, 관련 법령에 의하여 일정 기간
                보관이 필요한 경우에는 해당 기간 동안 보관
              </p>
            </div>
          </div>
          <div className={styles.options}>
            <div>4.</div>
            <p className={styles.contents}>
              동의 거부권 등에 대한 고지: 정보주체는 개인정보의 수집 및 이용
              동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한될
              수 있습니다. 그 밖에 내용은 네이버 개인정보 처리 방침을 따릅니다.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.personal_info_agree} style={{marginTop: '30px'}}>
        <h2>개인정보 제공 동의 (필수)</h2>
        <div>
          <div className={styles.options}>
            <div>1.</div>
            <div className={styles.contents}>
              개인정보를 제공받는 자 :{' '}
              {`${(location.state?.magazine as MagazineItem).brands?.title}`}
            </div>
          </div>
          <div className={styles.options}>
            <div>2.</div>
            <div className={styles.contents}>
              기본수집 항목 : [필수] 뉴뉴 아이디, 이름, 전화번호
            </div>
          </div>
          <div className={styles.options}>
            <div>3.</div>
            <div className={styles.contents}>
              수집 및 이용 목적 : 사업자 회원과 예약 이용자의 원 활한 거래 진행,
              고객상담, 불만 처리 등 민원 처리, 분쟁 조정 해결을 위한 기록 보존
            </div>
          </div>
          <div className={styles.options}>
            <div>4.</div>
            <div className={styles.contents}>
              <div>보관기간</div>
              <p>
                a. 회원탈퇴 시 지체없이 파기 b. 단, 관련 법령에 의하여 일정 기간
                보관이 필요한 경우에는 해당 기간 동안 보관함
              </p>
            </div>
          </div>
          <div className={styles.options}>
            <div>5.</div>
            <p className={styles.contents}>
              동의 거부권 등에 대한 고지 : 정보주체는 개인정보의 수집 및 이용
              동의를 거부할 권리가 있으나, 이 경우 상품 및 서비스 예약이 제한될
              수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
