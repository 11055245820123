import styled from 'styled-components';

export const BrandAllImageWrapper = styled.div<{
  android?: boolean;
  mobile?: boolean;
}>`
  z-index: 9;
  width: ${({mobile}) => (mobile ? '100%' : '375px')};
  position: fixed;
  top: 0px;
  bottom: 0;
  overflow: auto;
  background-color: #000;
  padding-top: ${({android}) =>
    android ? 'var(--status-bar-height)' : 'env(safe-area-inset-top)'};
`;

export const ImgList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2px;
  column-gap: 2px;

  div {
    width: 100%;
    position: relative;
  }
`;
