import styled, {css} from 'styled-components';

const mobileStyle = css`
  left: 0;
  right: 0;
  width: 100% !important;
`;

export const ImageSliderHeaderWrapper = styled.div<{mobile: boolean}>`
  z-index: 20;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 20px;
  top: 20px;
  width: 375px;
  display: flex;
  line-height: 20px;
  color: #fff;
  ${({mobile}) => mobile && mobileStyle}
  padding-top: env(safe-area-inset-top);
`;

export const ImageIndex = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.28px;
`;
