/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useTranslation} from 'react-i18next';

import useCanvas from '../../hooks/useCanvas';
import useS3 from '../../hooks/useS3';
import {uploadImage} from '../../services/user';
import {isOpenToast, ToastMessage} from '../../store';
import {SignupUserInfo, UserInfo} from '../../store/user';

export default function UserInfoForm() {
  const uploaderRef = useRef(null);
  const s3 = useS3();
  const navigator = useNavigate();
  const {translateImg} = useCanvas();
  const [signupInfo, setSignupInfo] = useRecoilState(SignupUserInfo);
  const [userInfo] = useRecoilState(UserInfo);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const {t} = useTranslation(['components'], {keyPrefix: 'join.UserInfoForm'});

  const update = (key: (keyof typeof signupInfo)[], value: any[]) => {
    return new Promise(resolve => {
      const _signupInfo = JSON.parse(JSON.stringify(signupInfo));
      key.forEach((k, i) => {
        _signupInfo[k] = value[i];
      });
      setSignupInfo(_signupInfo);
      resolve('');
    });
  };

  const changeNickName = ($event: React.ChangeEvent<HTMLInputElement>) => {
    update(['nickname'], [$event.currentTarget.value]);
  };

  const changeBirthday = ($event: React.ChangeEvent<HTMLInputElement>) => {
    update(['birth'], [Number($event.currentTarget.value)]);
  };

  const changeGender = (type: string) => {
    update(['gender'], [type]);
  };

  const callFileUploader = () => {
    (uploaderRef.current as any).click();
  };

  const removeImg = () => {
    update(['profileImage'], ['']);
  };

  const upload = async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      translateImg(file, 'profile', async (makedFile: File) => {
        try {
          const presignedRes = await uploadImage(file.name, 'user');
          await s3.uploadImage(
            makedFile,
            makedFile.name,
            `${presignedRes.data[0].url}`,
            presignedRes.data[0].fields,
          );
          resolve(
            `${presignedRes.data[0].url}${presignedRes.data[0].fields.key}`,
          );
        } catch (e) {
          reject(e);
        }
      });
    });
  };

  const changeImage = async ($event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const imgs = $event.currentTarget.files as FileList;
      console.log(imgs);
      if ((imgs as FileList).length > 0) {
        const presignedPath = await upload(imgs[0]);
        update(['profileImage'], [`${presignedPath}`]);
      }
    } catch (e) {
      console.log(e);
      setToastMessage(t('image-error'));
      setIsOpenToast(true);
    }
  };

  const bgStyle = () => {
    console.log(signupInfo);
    if (signupInfo && signupInfo.profileImage) {
      // await loadImage(signupInfo.profileImage);
      return `url(${signupInfo.profileImage})`;
    }
    return '#E9E7EC';
  };

  const init = async () => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
    const profileImage =
      userInfo.profileImage && userInfo.profileImage.length > 0
        ? userInfo.profileImage
        : signupInfo.profileImage;
    await update(
      ['profileImage', 'nickname'],
      [profileImage, userInfo.nickname],
    );
  };
  useEffect(() => {
    if (!userInfo) {
      navigator('/login', {
        state: {
          prevPath: `${location.pathname}${location.search}`,
        },
      });
    } else {
      init();
    }
  }, []);
  return (
    <div className="user-info-form">
      <div className="user-profile">
        <div
          className="profile-image"
          style={{
            background: `${bgStyle()} center no-repeat`,
            backgroundSize: '100% 100%',
          }}>
          <span
            className="no-image-icon"
            style={{
              display:
                signupInfo && signupInfo.profileImage ? 'none' : 'inline-block',
            }}></span>
          <span
            className="circle-white-plus-icon"
            onClick={() => callFileUploader()}></span>
        </div>
        <span
          className="remove-image-txt"
          onClick={() => removeImg()}
          style={{cursor: 'pointer'}}>
          {t('remove-image')}
        </span>
      </div>
      <div className="info-form">
        <div className="input-form name">
          <div className="label">
            {t('input-nickname')} <span style={{color: '#FF4B84'}}>*</span>
            &nbsp;
            <span className="option">({signupInfo.nickname.length}/15)자</span>
          </div>
          <div className="input">
            <input
              type="text"
              value={signupInfo.nickname}
              maxLength={15}
              placeholder={t('input-nickname-placeholder')}
              onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                changeNickName($event)
              }
              style={{padding: '5px'}}
            />
          </div>
        </div>
        <div className="input-form">
          <div className="label">{t('input-additional-title')}</div>
          <div className="input age">
            <div
              className="age-group"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <input
                type="number"
                placeholder="YYYY"
                onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                  changeBirthday($event)
                }
                maxLength={4}
                style={{width: '83px', padding: '5px'}}
              />
              <span>{t('input-additional-birth')}</span>
            </div>
            <div className="gender-group">
              <input
                type="radio"
                name="gender"
                checked={signupInfo.gender === 'female'}
                onClick={() => changeGender('female')}
              />
              <span className="r-txt">{t('input-additional-female')}</span>
              <input
                type="radio"
                name="gender"
                checked={signupInfo.gender === 'male'}
                onClick={() => changeGender('male')}
              />
              <span className="r-txt">{t('input-additional-male')}</span>
            </div>
          </div>
        </div>
      </div>
      <input
        ref={uploaderRef}
        type="file"
        accept="image/*,camera=capture"
        style={{visibility: 'hidden'}}
        onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
          changeImage($event)
        }
      />
    </div>
  );
}
