import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';
import MenuItem from './MenuItem';
import {isMobile, providerTypes} from '../../../utils';
import useStorage from '../../../hooks/useStorage';
import {UserInfo} from '../../../store/user';
import useAppStore from '../../../hooks/useAppStore';

export default function Menus() {
  const {getLoginProvider} = useStorage();
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const {getCurrentAppVersion, getAvailableAppVersion} = useAppStore();
  const [korProviderType, setKorProviderType] = useState('');
  const getKorProviderTypes = async () => {
    const provider: keyof typeof providerTypes =
      (await getLoginProvider()) as keyof typeof providerTypes;
    setKorProviderType(providerTypes[provider]);
  };
  const [versionText, setVersionText] = useState('');
  const {t} = useTranslation(['components'], {keyPrefix: 'my.Menus'});
  const getVersionText = async () => {
    const currentVersion = await getCurrentAppVersion();
    const availableVersion = await getAvailableAppVersion();
    let text = 'v' + currentVersion;
    if (currentVersion === availableVersion)
      text = text + t('versionTxt_latest');
    else text = text + t('versionTxt_updatable');
    setVersionText(text);
  };

  useEffect(() => {
    if (userInfo) {
      getKorProviderTypes();
    }
    if (Capacitor.getPlatform() !== 'web') {
      getVersionText();
    }
  }, [userInfo]);
  const menus = [
    {
      title: t('menu_profile_update'),
      right: <div></div>,
      hide: false,
      onClick: () => navigator('/user/edit'),
    },
    {
      title: t('menu_noti'),
      right: <div></div>,
      hide: !(isMobile() && Capacitor.getPlatform() !== 'web'),
      onClick: () => navigator('/push-config'),
    },
    {
      title: t('menu_login_info'),
      hide: false,
      right: (
        <div>
          <div>
            {korProviderType === '이메일' && (
              <div style={{textAlign: 'left'}}>{t('login_info_email')}</div>
            )}
            {korProviderType !== '이메일' && (
              <div style={{textAlign: 'left'}}>
                {t('login_info_sns')}({korProviderType})
              </div>
            )}
          </div>
          <div>{userInfo?.email}</div>
        </div>
      ),
    },
    {
      title: t('menu_app_version'),
      hide: false,
      right: <div>{versionText}</div>,
    },
  ];
  const filterNoLogin = (title: string) => {
    return title === t('menu_qna') || title === t('menu_license');
  };
  const filterWeb = (title: string) => {
    return Capacitor.getPlatform() === 'web'
      ? title !== t('menu_app_version')
      : true;
  };
  return (
    <div className={styles.menuArea}>
      {menus
        .filter(m =>
          userInfo.id === -1 ? filterNoLogin(m.title) : filterWeb(m.title),
        )
        .map((m, i) => {
          return (
            <MenuItem
              key={`menu_${i}`}
              title={m.title}
              hide={m.hide}
              onClick={m.onClick ? m.onClick : () => ''}>
              {m.right}
            </MenuItem>
          );
        })}
    </div>
  );
}
