import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import BrandPage from '@/components/v2/ui/templates/detail/BrandPage';
import {AddressList} from '@/store';
import BrandImageCarousel from '@/components/v2/ui/atoms/BrandImageCarousel/BrandImageCarousel';
import BrandDetailInfo from '@/components/v2/ui/organisms/BrandDetailInfo/BrandDetailInfo';
import MenuList from '@/components/v2/ui/organisms/MenuList/MenuList';
import PlaceInfo from '@/components/Brand/PlaceInfo';
import RecommendBrand from '@/components/Brand/RecommendBrand';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import BrandBenefitShortCut from '@/components/v2/ui/molecules/BrandBenefitShortcut/BrandBenefitShortCut';
import BrandImageCounter from '@/components/v2/ui/molecules/BrandImageCounter/BrandImageCounter';
import BrandImageSlider from '@/components/v2/ui/organisms/BrandImageSlider/BrandImageSlider';
import BrandAllImage from '@/components/v2/ui/organisms/BrandAllImage/BrandAllImage';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {isMobile} from '@/utils';
import Layout from '@/layouts';
import {OverlayHeader} from '@/components/v2/ui/templates/layouts/headers/OverlayHeader';

export function PlaceV2() {
  const navigator = useNavigate();
  const {brandInfo: data, benefits, refetchBrand} = useBrandContext();
  const addressList = useRecoilValue(AddressList);
  const [isOpenShareSheet, setIsOpenShareSheet] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShowImageSlide, setIsShowImageSlide] = useState(false);
  const [isShowAllImage, setIsShowAllImage] = useState(false);
  const [images, setImages] = useState<{src: string}[]>([]);
  const [selectedImg, setSelectedImg] = useState<number>(1);
  const [carouselIndex, setCarouselIndex] = useState(1);

  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
  };

  const findAddressObj = (id: number, list: Address[]): Address => {
    let addressInfo: Address = {
      id: -1,
      address: '',
      child: [],
    };
    list.forEach(address => {
      if (id === address.id) {
        addressInfo = address;
      }
    });
    return addressInfo;
  };

  const changeBrandInfo = (_brandInfo: BrandListItemType) => {
    navigator(`/brand/${_brandInfo.parentId}/${_brandInfo.id}`);
  };

  const openMenuImageSlider = () => {
    setIsShowImageSlide(true);
    setSelectedImg(0);
    setImages(
      data?.menuBoardImageList.map(o => {
        return {
          src: o,
        };
      }) || [],
    );
  };

  const onClickShareBtn = () => setIsOpenShareSheet(true);

  const moveToBack = () => {
    if (isShowAllImage) {
      document.body.style.overflow = 'auto';
      return setIsShowAllImage(false);
    }
    if (isShowImageSlide) {
      document.body.style.overflow = 'auto';
      return setIsShowImageSlide(false);
    }
    navigator(-1);
  };

  const toggleShowSlideImg = (selectedImgIdx: number) => {
    setSelectedImg(selectedImgIdx);
    document.body.style.overflow = 'auto';
    const imgs = (data?.imageList || []).map(o => {
      return {
        src: o,
      };
    });
    setImages(imgs);
    setIsShowAllImage(false);
    setIsShowImageSlide(true);
  };

  const shortAddress = useMemo(() => {
    let address1Item;
    let address2Item;
    if (addressList.length > 0 && data?.address1) {
      address1Item = '서울시';
      address2Item = findAddressObj(data?.address2, addressList);
      return `${address1Item} ${address2Item.address}`;
    }
    return '';
  }, [addressList, data]);

  const carouselHeight = () => {
    return isMobile() ? window.innerWidth : 375;
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  if (!data) {
    return <div></div>;
  }

  if (isShowAllImage) {
    return (
      <Layout.Main>
        <OverlayHeader
          clickShare={onClickShareBtn}
          isScrolled={false}
          title={''}
          back={moveToBack}
        />
        <BrandAllImage
          images={data.imageList || []}
          setSelectedImage={(idx: number) => toggleShowSlideImg(idx)}
        />
      </Layout.Main>
    );
  }

  if (isShowImageSlide) {
    return (
      <Layout.Main>
        <OverlayHeader
          clickShare={onClickShareBtn}
          isScrolled={false}
          title={''}
          back={moveToBack}
        />
        <BrandImageSlider
          images={images}
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          close={() => setIsShowImageSlide(false)}
        />
      </Layout.Main>
    );
  }

  return (
    <BrandPage
      isScrolled={isScrolled}
      onClickShare={onClickShareBtn}
      title={data.title}
      back={moveToBack}>
      <BrandImageCarousel
        images={data.imageList}
        height={carouselHeight()}
        isScrolled={true}
        onChangeIndex={(el: any) => {
          setCarouselIndex(el.realIndex + 1);
        }}>
        <div
          className="absolute flex justify-between items-end"
          style={{
            zIndex: 2,
            left: 0,
            right: 0,
            bottom: '16px',
            padding: '0 20px',
          }}>
          {benefits && benefits.length > 0 ? (
            <BrandBenefitShortCut
              img={benefits[0]?.imageUrl || ''}
              title={benefits[0]?.title}
              onClick={() => navigator(`/brand/giftcard/${benefits[0]?.id}`)}
            />
          ) : (
            <div></div>
          )}
          <BrandImageCounter
            total={data.imageList?.length || 0}
            carouselIndex={carouselIndex}
            onClick={() => setIsShowAllImage(true)}
          />
        </div>
      </BrandImageCarousel>
      <div className="relative" style={{zIndex: 8, background: '#F7F7FC'}}>
        <BrandDetailInfo
          title={data.title}
          address={shortAddress}
          description={data.subtitle || ''}
          hasBookmark={data.isBookmark}
          refetch={refetchBrand}
          instagram={data.brandLink}
        />
        <MenuList
          menus={data.menuList}
          menuImgLength={data.menuBoardImageList.length}
          openMenuImg={() => openMenuImageSlider()}
        />
        <PlaceInfo
          placeInfo={{
            address: data.address || '',
            openingHours: (data.openingHours || '').split('\\n') || [''],
            brandTel: data.brandTel || '',
            brandLink: data.brandLink || '',
            reserveLink: data.reserveLink || '',
            name: data.title || '',
          }}
        />
        <Suspense>
          <RecommendBrand
            brandId={data.id || -1}
            item={data}
            reload={(brandInfo: BrandListItemType) =>
              changeBrandInfo(brandInfo)
            }
          />
        </Suspense>
      </div>
      <BrandShareBottomSheet
        isOpen={isOpenShareSheet}
        setIsOpen={setIsOpenShareSheet}
        item={data}
      />
    </BrandPage>
  );
}
