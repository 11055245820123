import React, {useMemo, useEffect, useState} from 'react';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {ToastMessage, isOpenToast, user} from '@/store';
import {requestProposal} from '@/services/community';
import ContentsHeader from '@/layouts/ContentsHeader';
import Layout from '@/layouts';

import styles from './styles.module.scss';

interface QnaParam {
  qnaType: 'qna_coop' | 'qna_else' | 'qna_ad' | 'qna_entry';
  content: string;
}

export default function QnaPage() {
  const navigator = useNavigate();
  const [reasonContents, setReasonContents] = useState('');
  const [qnaType, setQnaType] = useState<QnaParam['qnaType']>();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const accessToken = useRecoilValue(user);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);

  const isEnabled = useMemo(() => {
    return name.length > 0 && phoneNumber.length > 0 && !!qnaType;
  }, [name, phoneNumber, qnaType]);

  const changeDetailReason = (
    $event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setReasonContents($event.currentTarget.value);
  };

  const check = (type: QnaParam['qnaType']) => {
    if (type !== qnaType) {
      setQnaType(type);
    } else {
      setQnaType(undefined);
    }
  };

  const changeName = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setName($event.currentTarget.value);
  };

  const changePhoneNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber($event.currentTarget.value);
  };

  const submit = async () => {
    try {
      await requestProposal(
        {
          qnaType,
          objectType: 'proposal',
          content: JSON.stringify(
            {name, phoneNumber, reasonContents},
            null,
            '\t',
          ),
          status: 'unresolved',
        },
        accessToken,
      );
      setIsOpenToast(true);
      setToastMessage('문의하신 내용이 정상적으로 접수되었습니다.');
      navigator('/home', {
        replace: true,
      });
    } catch (e: any) {
      setIsOpenToast(true);
      setToastMessage('문의하기 중에 에러가 발생했습니다');
      navigator('/home', {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);

  return (
    <Layout.Main>
      <div className="report-page">
        <ContentsHeader txt={'문의하기'} isOption={'none'} />
        <div className={`${styles.report_reason} contents-body`}>
          <div className={`${styles.report_title}`}>
            문의 내용
            <span className="mendetory" style={{color: '#FF3E48'}}>
              &nbsp;*
            </span>
          </div>
          <div className={styles.report_reason_checker}>
            <div
              className={styles.report_reason_item}
              onClick={() => check('qna_coop')}>
              <input
                type="checkbox"
                name="reason"
                checked={qnaType === 'qna_coop'}
              />
              <label htmlFor="reason3" style={{cursor: 'pointer'}}>
                협업 제안
              </label>
            </div>
            <div
              className={styles.report_reason_item}
              onClick={() => check('qna_entry')}>
              <input
                type="checkbox"
                name="reason"
                checked={qnaType === 'qna_entry'}
              />
              <label htmlFor="reason3" style={{cursor: 'pointer'}}>
                입점 문의
              </label>
            </div>
            <div
              className={styles.report_reason_item}
              onClick={() => check('qna_ad')}>
              <input
                type="checkbox"
                name="reason"
                checked={qnaType === 'qna_ad'}
              />
              <label htmlFor="reason3" style={{cursor: 'pointer'}}>
                광고 문의
              </label>
            </div>
            <div
              className={styles.report_reason_item}
              onClick={() => check('qna_else')}>
              <input
                type="checkbox"
                name="reason"
                checked={qnaType === 'qna_else'}
              />
              <label htmlFor="reason4" style={{cursor: 'pointer'}}>
                기타
              </label>
            </div>
          </div>
          <div className={styles.detail_reason_wrapper}>
            <textarea
              className="detail-reason"
              placeholder="상세 문의 사항을 입력해주세요"
              value={reasonContents}
              onChange={($event: React.ChangeEvent<HTMLTextAreaElement>) =>
                changeDetailReason($event)
              }></textarea>
          </div>
          <div className={styles.input_form}>
            <div className={styles.label}>
              성함<span className={styles.asterisk}>*</span>
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={'문의하시는 분의 성함을 입력해주세요'}
                onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                  changeName($event)
                }
              />
            </div>
          </div>
          <div className={styles.input_form}>
            <div className={styles.label}>
              연락처<span className={styles.asterisk}>*</span>
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={
                  '답변 받으실 연락처를 입력해주세요 (전화번호 또는 이메일)'
                }
                onChange={($event: React.ChangeEvent<HTMLInputElement>) =>
                  changePhoneNumber($event)
                }
              />
            </div>
          </div>
          <div className={styles.extra_text}>
            문의 내용은 담당자가 확인 후 영업일 기준 1-2일 내로
            답변드리겠습니다.
          </div>
        </div>
        <div className={styles.report_btn_wrapper}>
          <button
            className={`main-cont btn main-black-filled ${
              isEnabled ? '' : 'disabled'
            }`}
            onClick={() => submit()}
            disabled={!isEnabled}>
            문의하기
          </button>
        </div>
        <div className={styles.request_email}>
          이메일 문의 : info@knewnew.co.kr
        </div>
      </div>
    </Layout.Main>
  );
}
