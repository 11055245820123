// src/components/LocationIcon.js
import React from 'react';

const LocationIconRed = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="&#235;&#184;&#140;&#235;&#158;&#156;&#235;&#147;&#156;"
        clipPath="url(#clip0_22_2155)">
        <path
          id="Vector"
          d="M7.00002 0.936687C4.39602 0.936687 2.27502 3.05769 2.27502 5.66169C2.27502 6.29869 2.40102 6.92169 2.65302 7.50969C2.66002 7.53769 2.67402 7.56569 2.68802 7.58669C2.71602 7.64269 2.97502 8.14669 3.11502 8.34269C3.59102 9.11969 4.62003 10.6457 6.27903 12.4027L7.00702 13.1657L7.73502 12.4027C9.39402 10.6457 10.423 9.12669 10.878 8.37069C11.039 8.14669 11.298 7.64269 11.326 7.58669C11.34 7.55869 11.347 7.53769 11.361 7.50969C11.613 6.92169 11.739 6.29869 11.739 5.65469C11.739 3.05069 9.61803 0.929688 7.01403 0.929688L7.00002 0.936687Z"
          fill="#FF4B84"
        />
        <path
          id="Vector_2"
          d="M7.00063 7.95756C5.73363 7.95756 4.69763 6.92856 4.69763 5.65456C4.69763 4.38056 5.72663 3.35156 7.00063 3.35156C8.27463 3.35156 9.30363 4.38056 9.30363 5.65456C9.30363 6.92856 8.27463 7.95756 7.00063 7.95756Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_2155">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIconRed;
