import React, {useMemo} from 'react';
import {ChevronDown, ChevronUp} from 'react-feather';

import styles from './styles.module.scss';
import {ProductOptions} from './ProductOptionSelector';

interface Props {
  type: string;
  toggle: (type: string, item: ProductOptions) => void;
  placeholder: string;
  optionList: ProductOptions[];
  disabled: boolean;
  selectedValue: string;
  isOpenList: boolean;
  setIsOpenList: (is: boolean, v: any) => void;
  onClickEmptyNotice?: (item: ProductOptions) => void;
}

export default function Options({
  placeholder,
  optionList,
  type,
  toggle,
  disabled,
  selectedValue,
  isOpenList,
  setIsOpenList,
  onClickEmptyNotice,
}: Props) {
  const toggleItem = (item: ProductOptions) => {
    toggle(type, item);
    setIsOpenList(false, item.value);
  };
  const moveToEmptyNotice = (item: ProductOptions) => {
    if (onClickEmptyNotice) {
      onClickEmptyNotice(item);
    }
  };

  const txtColor = useMemo(() => {
    return !selectedValue && !isOpenList ? '#9F9CA3' : '#000';
  }, [selectedValue, isOpenList]);

  return (
    <div className={styles.option_wrapper}>
      <div
        className={`${styles.option_info} ${isOpenList && styles.open} ${
          disabled && styles.disabled_option_info
        }`}
        style={{cursor: !disabled ? 'pointer' : 'auto'}}
        onClick={() => !disabled && setIsOpenList(!isOpenList, selectedValue)}>
        <span
          style={{
            color: txtColor,
            flex: '1',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}>
          {selectedValue || placeholder}
        </span>
        {!isOpenList && (
          <ChevronDown
            size={'20px'}
            strokeWidth={1}
            color={disabled ? '#9F9CA3' : '#000'}
          />
        )}
        {isOpenList && (
          <ChevronUp
            size={'20px'}
            strokeWidth={1}
            color={disabled ? '#9F9CA3' : '#000'}
          />
        )}
      </div>
      <ul
        className={`${styles.option_list} ${
          isOpenList ? styles.open_list : ''
        }`}
        style={{display: isOpenList ? 'block' : 'none'}}>
        {optionList.map(option => {
          return (
            <li
              className={`${option.isSoldout ? styles.soldout_options : ''}`}
              key={option.key}
              onClick={() => (!option.isSoldout ? toggleItem(option) : '')}
              style={{cursor: 'pointer'}}>
              <div style={{flex: '1'}}>{option.key}</div>
              <div
                onClick={() => moveToEmptyNotice(option)}
                style={{
                  display: option.isSoldout ? 'flex' : 'none',
                  textDecoration: 'underline',
                  color: '#433E48',
                  cursor: 'pointer',
                  fontSize: '14px',
                  marginLeft: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                대기 신청
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
