import React, {useLayoutEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {addComma, openBrowserForLink, switchNewLineTxt} from '@/utils';
import {UserInfo, user} from '@/store/user';
import {doBookMark} from '@/services/magazine';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from '../styles.module.scss';

interface Props {
  idx: number;
  item: CollectionListItem;
  toggleLike: (idx: number, isLike: boolean) => void;
  setOpenInstallAlert: (is: boolean) => void;
  ref?: any;
  noBadge?: boolean;
}

export default function CollectionItem({
  idx,
  item,
  toggleLike,
  ref,
  noBadge,
}: Props) {
  const {
    title,
    badgeTxt,
    info,
    isBookmark,
    status,
    imageUrl,
    popupType,
    discountNumber,
  } = item;
  const imgRef = useRef<HTMLImageElement>(null);
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const moveTo = () => {
    navigator(`/${item.category}/${item.id}`);
  };

  const openLink = async (link: string) => {
    await openBrowserForLink(link);
  };
  const _doBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.preventDefault();
    $event.stopPropagation();
    try {
      if (userInfo.id == -1) {
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const id = item.id;
        const next = !isBookmark;
        await doBookMark(id, next, accessToken);
        toggleLike(idx, !isBookmark);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sender = useLogger();

  const preloadImage = () => {
    const img = new Image();
    img.src = item.imageUrl;
  };

  useLayoutEffect(() => {
    preloadImage();
  }, []);

  return (
    <div
      ref={ref}
      className={styles.collection_item_wrapper}
      onClick={() => {
        if (status !== '2_before_open') {
          if (item.link && item.link.length > 0) {
            openLink(item.link);
            sender({
              _msg: 'externalLink_' + item.id,
              _screen: window.location.pathname,
              _action: Action.CLICK,
              _data: {to: item.link},
            });
          } else {
            moveTo();
          }
        }
      }}
      style={{cursor: status !== '2_before_open' ? 'pointer' : 'default'}}>
      <div className={`${styles.img_wrapper}`}>
        <div
          className={`${styles.pre_open} ${
            status !== '2_before_open' ? styles.hide : ''
          }`}>
          <div className={styles.pre_open_title}>{'COMING\nSOON'}</div>
        </div>
        <img ref={imgRef} src={imageUrl} alt={title} />
        {!noBadge && (
          <div className={styles.img_badge_list}>
            {popupType === 'only_knewnew' && (
              <span className={`${styles.img_badge} ${styles.only}`}>ONLY</span>
            )}
            {(badgeTxt || status === '3_closed') && (
              <span
                className={`${styles.img_badge} ${
                  status === '3_closed' && styles.close_badge
                }`}
                style={{
                  display: status === '2_before_open' ? 'none' : 'block',
                }}>
                {status === '3_closed' ? '판매 종료' : badgeTxt}
              </span>
            )}
          </div>
        )}

        {!noBadge && (
          <div
            className={styles.like_wrapper}
            onClick={($event: React.MouseEvent<HTMLDivElement>) =>
              _doBookmark($event)
            }
            style={{cursor: 'pointer'}}>
            {!isBookmark ? (
              <span
                className={`icon ${styles.heart_empty} active ${styles.heart_icon}`}>
                &nbsp;
              </span>
            ) : (
              <span
                className={`icon ${styles.heart_filled} ${styles.heart_icon}`}>
                &nbsp;
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.txt_wrapper}>
        <div
          className={styles.title_wrapper}
          dangerouslySetInnerHTML={{
            __html: switchNewLineTxt(title || ''),
          }}></div>
        <div className={styles.sub_txt_wrapper}>
          <div className={styles.sub_txt}>{info}</div>
        </div>
        <div className={styles.price_wrapper}>
          {status !== '3_closed' ? (
            <>
              {!!discountNumber && discountNumber > 0 && (
                <span className={styles.rate}>{discountNumber}%</span>
              )}
              <span className={styles.price}>
                {addComma(Number(item.price))}
              </span>
            </>
          ) : (
            <span className={styles.price}>판매 종료</span>
          )}
        </div>
      </div>
    </div>
  );
}
