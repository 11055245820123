import React, {useMemo} from 'react';
import {ChevronRight} from 'react-feather';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';

import styles from '../styles.module.scss';

interface Props {
  status:
    | '구매 완료'
    | '취소 요청'
    | '취소 완료'
    | '사용 완료'
    | '기간 만료'
    | '사용 전'
    | '취소 불가';
  item: OrderListItem;
  type?: 'benefit' | 'order';
}

export default function OrderItem({status, item, type}: Props) {
  const navigator = useNavigate();
  const orderTitle = useMemo(() => {
    if (status === '취소 불가') {
      return '구매 완료';
    }
    if (status === '구매 완료' && type === 'benefit') {
      return '사용 전';
    }
    return status;
  }, [status, type]);
  const statusCode = useMemo(() => {
    let code = '';
    switch (status) {
      case '취소 완료':
        code = 'cancel';
        break;
      case '기간 만료':
      case '사용 완료':
        code = 'used';
        break;
      default:
        code = '';
        break;
    }
    return code;
  }, [status]);
  const optionStr = useMemo(() => {
    let str = '';
    if (item.options) {
      str += item.options[0].value;
      str += ' / ';
      str += item.options[0].child[0].value;
    }
    return str;
  }, [item]);
  const moveTo = () => {
    if (type === 'benefit') {
      return navigator(`/order/benefit/${item.id}`, {
        state: {
          status,
          item,
          fromOrderList: true,
          prev: location.pathname.replace('/', ''),
        },
        replace: true,
      });
    }
    return navigator('/user/order/info', {
      state: {
        status,
        item,
        fromOrderList: true,
        prev: location.pathname.replace('/', ''),
      },
    });
  };

  return (
    item && (
      <div className={`${styles.order_item_wrapper}`}>
        <div className={`${styles.date}`}>
          {dayjs(item.created).format('YYYY-MM-DD')}
        </div>
        <div
          className={`${styles.item_info} ${styles[statusCode]}`}
          style={{cursor: 'pointer'}}
          onClick={() => moveTo()}>
          <div className={styles.item_title}>
            <h2
              className={` ${
                orderTitle !== '구매 완료' ? `${styles.disabled}` : ''
              }`}>
              {orderTitle}
            </h2>
            <span className={styles.go_to_detail}>
              상세보기
              <ChevronRight size={18} strokeWidth={1} color={'#000'} />
            </span>
          </div>
          <div className={`${styles.item_contents}`}>
            <div className={styles.txt_wrapper}>
              <h2>{item.popup.title}</h2>
              <div className={styles.options}>{optionStr}</div>
              <div className={styles.count}>수량 {item.purchaseCount}개</div>
            </div>
            <div
              className={styles.img_wrapper}
              style={{
                background: `url('${item.popup.imageUrl}') no-repeat center / cover`,
              }}></div>
          </div>
        </div>
      </div>
    )
  );
}
