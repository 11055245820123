import React, {useCallback} from 'react';

import styles from './styles.module.scss';

interface Props {
  type: 'grayFilled' | 'blackFilled' | 'whiteFilled';
  text: string;
  onClick?: ($event?: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function JoinButtons({type, text, onClick}: Props) {
  const clsName = useCallback(
    (t: string) => {
      let cls = '';
      switch (t) {
        case 'grayFilled':
          cls = styles.grayFilled;
          break;
        case 'blackFilled':
          cls = styles.blackFilled;
          break;
        case 'whiteFilled':
          cls = styles.whiteFilled;
          break;
      }
      return cls;
    },
    [type],
  );
  return (
    <button
      className={`${clsName(type)} ${styles.button}`}
      onClick={onClick}
      disabled={type === 'grayFilled'}>
      {text}
    </button>
  );
}
