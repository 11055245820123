import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {switchNewLineTxt} from '@/utils';
import {MagazineBannerItem} from '@/store';

import Carousel from '../../common/Carousel';
import styles from './styles.module.scss';

export default function MagazineBanner() {
  const navigator = useNavigate();
  const bannerItems = useRecoilValue(MagazineBannerItem);
  const [activeIdx, setActiveIdx] = useState(0);

  return (
    <div>
      <div className={styles.wrapper}>
        {bannerItems.length > 0 && (
          <Carousel
            autoPlay={false}
            infiniteLoop={false}
            size={325}
            onChange={setActiveIdx}>
            {[...bannerItems].splice(0, 5).map(item => {
              return (
                <div
                  className={styles.item}
                  key={item.id}
                  onClick={() =>
                    navigator(`/${item.category}/${item.id}`, {
                      state: {
                        prev: 'magazine',
                      },
                    })
                  }>
                  <div
                    className={styles.img}
                    style={{
                      background: `url("${item.imageUrl}") no-repeat center / cover`,
                    }}></div>
                  <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                      __html: switchNewLineTxt(item.title || ''),
                    }}></div>
                  <div className={styles.desc}>{item.subtitle}</div>
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className={styles.indicator_wrapper}>
        {[...bannerItems].splice(0, 5).map((item, idx) => {
          return (
            <span
              key={`magazine_banner_item_indicator_${idx}`}
              className={`${styles.indicator} ${
                activeIdx === idx ? styles.active : ''
              }`}>
              &nbsp;
            </span>
          );
        })}
      </div>
    </div>
  );
}
