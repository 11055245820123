// import React from "react";
// import ReactDOM from "react-dom";
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import ko from './locales/ko';
import en from './locales/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: en,
      ko: ko,
    },
    //lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ko',
    ns: ['components', 'layouts', 'views'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
