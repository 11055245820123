import React, {useEffect} from 'react';

import {ReactComponent as DownIcon} from '@/assets/icons/brand_address_selector_down_icon.svg';
import {isMobile} from '@/utils';

import {
  BottomSheetHeader,
  BottomSheetWrapper,
  HeaderIcon,
  ModalWrapper,
} from './bottomsheet.styled';

interface Props {
  children: React.ReactNode;
  closed: boolean;
  close: () => void;
}

export default function BottomSheet({close, closed, children}: Props) {
  useEffect(() => {
    console.log(closed);
  }, [closed]);
  return (
    <ModalWrapper close={closed}>
      <BottomSheetWrapper mobile={isMobile()} close={closed} active={!closed}>
        <BottomSheetHeader>
          <button onClick={() => close()}>
            <HeaderIcon>
              <DownIcon />
            </HeaderIcon>
          </button>
        </BottomSheetHeader>
        {children}
      </BottomSheetWrapper>
    </ModalWrapper>
  );
}
