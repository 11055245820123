import React, {useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';

import ZipCard from '@/components/cardList/ZipCard';

import {getMagazine} from '../../../services/magazine';
import {zipItems} from '../../../store';
import {user} from '../../../store/user';

export default function ZipGroup() {
  const userAccessToken = useRecoilValue(user);
  const [_zipItems, setZipList] = useRecoilState(zipItems);
  const [maxCount, setMaxCount] = useState<number>(20);
  const getMagazines = async (category: 'zip' | 'news', limit?: number) => {
    const res = await getMagazine(
      userAccessToken,
      limit ?? 20,
      category,
      0,
      'All',
    );
    const magazines = res.data.results as MagazineItem[];
    setMaxCount(res.data.count);
    return magazines;
  };

  const getZipList = async (limit: number) => {
    const magazines = await getMagazines('zip', limit);
    // return magazines;
    setZipList(magazines);
  };

  return (
    <div>
      <InfiniteScroll
        pageStart={0}
        loadMore={(page: number) => {
          const limit = page * 20;
          getZipList(limit);
        }}
        hasMore={_zipItems.length < maxCount}>
        {_zipItems.map(item => {
          return (
            <div key={item.id}>
              <ZipCard info={item} />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
