import {Capacitor} from '@capacitor/core';
import React, {useMemo} from 'react';

import Modal from '@/layouts/Modal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  info: {
    link: string;
    tel: string;
  };
}

export default function ContactModal({isOpen, setIsOpen, info}: Props) {
  const height = useMemo(() => {
    if (info.link.length > 0 && info.tel.length > 0) {
      return '415px';
    } else {
      return `${415 - 57}px`;
    }
  }, [info]);
  return (
    <Modal.BottomSheet
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      height={Capacitor.getPlatform() === 'ios' ? 'auto' : height}>
      <div>
        <div className={styles.header}>
          <div>
            <span className={styles.calander_icon}></span>
            <strong>방문 날짜를 선택할 수 있습니다.</strong>
          </div>
        </div>
        <div className={styles.info_list}>
          <div>
            • 날짜 선택 없이도 방문 가능하나, 매장 상황에 따라 현장 대기가 있을
            수 있습니다.
          </div>
          <div>• 온라인 예약 또는 유선으로 원하시는 날짜를 예약해주세요.</div>
          <div>• 구매하신 상품의 유효기간 내 날짜만 예약 가능합니다.</div>
          <div>• 예약 관련 모든 문의는 매장으로 문의해주세요.</div>
          <div style={{fontWeight: 700}}>
            • <span>디저트 및 케이크 픽업</span>은 예약이 필수입니다.
          </div>
          <div style={{fontWeight: 700}}>
            • 네이버 예약 시 요청사항에 <span>뉴뉴 티켓</span>을 기재해 주세요.
          </div>
          <div style={{fontWeight: 700}}>
            • 유효기간 외 날짜 예약 시 취소처리될 수 있습니다.
          </div>
        </div>
        <div className={styles.modal_bottom}>
          {info.link.length > 0 && (
            <div
              className={styles.row}
              style={{borderBottom: '1px solid #E9E7EC'}}
              onClick={() => window.open(info.link)}>
              <div className={styles.label}>
                <span className={styles.naver_icon}></span>
                <span className={styles.txt}>온라인 예약</span>
              </div>
              <span className={styles.arrow_right}></span>
            </div>
          )}
          {info.tel.length > 0 && (
            <div
              className={styles.row}
              onClick={() => (document.location.href = `tel:${info.tel}`)}>
              <div className={styles.label}>
                <span className={styles.call_icon}></span>
                <span className={styles.txt}>매장 전화</span>
              </div>
              <span className={styles.arrow_right}></span>
            </div>
          )}
        </div>
      </div>
    </Modal.BottomSheet>
  );
}
