import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {
  BookMarkCount,
  BookMarkTotalPage,
  BookmarkTab,
  bookmarkItems,
} from '@/store/bookmark';
import {ToastMessage, isOpenToast} from '@/store';

import BookmarkCard from '../cardList/BookmarkCard/BookmarkCard';
import {CntField, ItemField} from './BookMark';

export default function BookMarkList() {
  const bookmarks = useRecoilValue(bookmarkItems);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setTotalCount = useSetRecoilState(BookMarkCount);
  const setTotalPage = useSetRecoilState(BookMarkTotalPage);
  const [tab, setTab] = useRecoilState(BookmarkTab);

  const [bookmarkList, setBookmarkList] = useState<MagazineItem[]>([]);
  const [itemCnts, setItemCnts] = useState<ItemField>({
    total: 0,
    brands: 0,
    zip: 0,
    news: 0,
  });
  const openToastMessage = (isBookmark: boolean) => {
    if (isBookmark) {
      setToastMessage('북마크에서 제외했습니다.');
      setIsOpenToast(true);
    }
  };
  const checkTab = (t: string) => {
    return tab === t;
  };
  const changeTab = (t: 'All' | 'news' | 'zip' | 'brands') => {
    if (history.pushState) {
      const url = `${location.protocol}//${location.host}${location.pathname}`;
      history.pushState({path: url}, '', url);
    }
    if (t === 'news') {
      document.querySelector('.tabs')?.scrollTo(130, 0);
    }
    if (t === 'All') {
      document.querySelector('.tabs')?.scrollTo(0, 0);
    }
    setTab(t === 'All' ? '' : t);
  };
  useEffect(() => {
    if (bookmarks.length > 0) {
      setBookmarkList(
        bookmarks.slice(0, bookmarks.length - 1) as MagazineItem[],
      );
      const cnt = [...bookmarks].splice(
        bookmarks.length - 1,
        1,
      )[0] as CntField[];
      const item: ItemField = {total: 0, brands: 0, zip: 0, news: 0};
      cnt.map(c => (item[c.category] = c.bookmarkCount));
      setItemCnts(item);
      setTotalCount(item.total);
      setTotalPage(Math.ceil(item.total / 8));
    }
  }, [bookmarks]);
  return (
    <div>
      <div
        className={`tabs contents-body`}
        style={{display: itemCnts.total > 0 ? 'flex' : 'none'}}>
        <div
          className={`tab ${checkTab('All') ? 'active' : ''}`}
          onClick={() => changeTab('All')}>
          <span className="txt">All</span>
          <span className="cnt">{itemCnts.total}</span>
        </div>
        <div
          className={`tab ${checkTab('brands') ? 'active' : ''}`}
          onClick={() => changeTab('brands')}>
          <span className="txt">Brands</span>
          <span className="cnt">{itemCnts.brands}</span>
        </div>
        <div
          className={`tab ${checkTab('zip') ? 'active' : ''}`}
          onClick={() => changeTab('zip')}>
          <span className="txt">Pick</span>
          <span className="cnt">{itemCnts.zip}</span>
        </div>
        <div
          className={`tab ${checkTab('news') ? 'active' : ''}`}
          onClick={() => changeTab('news')}>
          <span className="txt">News</span>
          <span className="cnt">{itemCnts.news}</span>
        </div>
      </div>
      <div
        className="no-contents"
        style={{
          display: bookmarkList.length > 0 ? 'none' : 'flex',
        }}>
        <span className="txt">담은 매거진이 없습니다.</span>
      </div>
      <div className="contents">
        {bookmarkList.map((item, idx) => {
          return (
            <BookmarkCard
              info={item}
              key={`other_item_ed_brand_${idx}`}
              type={item.category}
              isBookMarked={item.isBookmark}
              makeToast={(is: boolean) => openToastMessage(is)}
            />
          );
        })}
      </div>
    </div>
  );
}
