import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';

import NPickIntroduce from '@/components/v2/ui/templates/nPick/NPickIntroduce';
import NPickIntroImg from '@/assets/banner/n-pick-intro.jpg';
import NPickDetailImg from '@/assets/banner/n-pick-detail.jpg';
import Image from '@/components/v2/ui/atoms/Image/Image';
import NPickDetail from '@/components/v2/ui/templates/nPick/NPickDetail';

export default function NPick() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setBackgroundColor({
        color: '#EBF3FA',
      });
    }
    () => {
      return (
        Capacitor.getPlatform() !== 'web' &&
        StatusBar.setStyle({
          style: Style.Default,
        })
      );
    };
  }, []);

  if (searchParams.get('detail')) {
    return (
      <NPickDetail>
        <Image src={NPickDetailImg} />
      </NPickDetail>
    );
  }

  return (
    <NPickIntroduce>
      <Image src={NPickIntroImg} />
    </NPickIntroduce>
  );
}
