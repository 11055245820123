import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import JoinButtons from '@/components/join/Button';
import JoinField from '@/components/join/JoinField';
import {useDebouncedEffect} from '@/hooks/useDebounceEffect';
import ContentsHeader from '@/layouts/ContentsHeader';
import {
  recoverEmail,
  recoverEmailCodeCheck,
  recoverEmailPassword,
} from '@/services/user';
import AuthButton from '@/components/join/AuthButton';

import styles from './styles.module.scss';

type Fields = 'email' | 'code' | 'password' | 'rePassword';

export default function PasswordReset() {
  const navigator = useNavigate();
  const {state} = useLocation();
  const {t} = useTranslation(['views'], {keyPrefix: 'join.PasswordReset'});
  const [fields, setFields] = useState<Fields[]>(['email', 'code']);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasCodeError, setHasCodeError] = useState(false);
  const [hasPasswdError, setHasPasswdError] = useState(false);
  const [hasConfirmPwdError, setHasConfirmPwdError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [code, setCode] = useState('');
  const [emailAuthState, setEmailAuthState] =
    useState<VerificationType>('active');
  const [codeAuthState, setCodeAuthState] =
    useState<VerificationType>('code-active');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [hasConfirmedPwd, setHasConfirmedPwd] = useState(true);

  const isAllSet = useMemo(() => {
    let isSet = false;

    const validChecker =
      !hasEmailError && !hasPasswdError && !hasCodeError && !hasConfirmPwdError;
    const lengthChecker =
      email.length > 0 &&
      password.length > 0 &&
      code.length > 0 &&
      confirmPwd.length > 0;

    isSet = validChecker && lengthChecker;
    return isSet;
  }, [
    email,
    hasEmailError,
    hasCodeError,
    hasPasswdError,
    hasConfirmPwdError,
    password,
    confirmPwd,
    hasConfirmedPwd,
    code,
  ]);
  const updateFields = (field: Fields[]) => {
    let f = [...fields];
    f = [...f, ...field];
    setFields(f);
  };
  const isShowField = useCallback(
    (f: Fields) => {
      return fields.indexOf(f) > -1;
    },
    [fields],
  );

  const sendRecoverEmail = async () => {
    if (emailAuthState === 'active' || emailAuthState === 're-send') {
      await recoverEmail(email);
      setEmailAuthState('re-send');
      updateFields(['code']);
      setCode('');
      setHasCodeError(false);
      setCodeAuthState('code-active');
    }
  };

  const checkRecoverEmail = async () => {
    try {
      await recoverEmailCodeCheck(email, code);
      setCodeAuthState('success');
      updateFields(['password', 'rePassword']);
    } catch (e) {
      setHasCodeError(true);
      setCodeAuthState('error');
    }
  };

  const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    $event.currentTarget.value = $event.currentTarget.value.toLocaleLowerCase();
    setEmail($event.currentTarget.value);
  };

  const onCodeChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    setCode($event.currentTarget.value);
  };

  const onPasswordChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const pwd = $event.target.value;
    setPassword(pwd);
    const passwordRegexp =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (pwd.length > 0 && !passwordRegexp.test(pwd)) {
      setHasPasswdError(true);
    } else {
      setHasPasswdError(false);
    }
  };

  const onConfirmPwdChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
    if ($event.target.value.length > 0 && $event.target.value !== password) {
      setHasConfirmPwdError(true);
      setHasConfirmedPwd(false);
    } else {
      setHasConfirmPwdError(false);
      setHasConfirmedPwd(true);
    }
    setConfirmPwd($event.target.value);
  };

  const toggleChangePassword = async () => {
    await recoverEmailPassword(email, code, password);
    navigator('/login/email');
  };

  useEffect(() => {
    if (state.email) {
      setEmail(state.email);
    }
  }, [state]);

  useDebouncedEffect(
    () => {
      if (email.length > 0) {
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
          setEmailErrorMsg(t('errMsg_format'));
          setHasEmailError(true);
        }
      }
    },
    500,
    [email],
  );

  return (
    <div className="main" style={{minHeight: '100vh', position: 'relative'}}>
      <ContentsHeader txt={t('header')} isOption={'none'} />
      <div className={`${styles.joinsField} contents-body`}>
        <JoinField
          type={'email'}
          value={email}
          isShow={isShowField('email')}
          onChange={onChange}
          disabled={true}
          rightInfo={
            <AuthButton
              verification={emailAuthState}
              isShow={true}
              onClick={() => sendRecoverEmail()}
            />
          }
          bottomInfo={
            <div
              className={styles.error}
              style={{display: hasEmailError ? 'block' : 'none'}}>
              {emailErrorMsg}
            </div>
          }
        />
        <JoinField
          value={code}
          type={'verification'}
          isShow={isShowField('code')}
          onChange={onCodeChange}
          rightInfo={
            <AuthButton
              verification={codeAuthState}
              isShow={code.length > 0}
              onClick={() =>
                codeAuthState === 'code-active' ? checkRecoverEmail() : {}
              }
            />
          }
          bottomInfo={
            <div
              className={styles.error}
              style={{display: hasCodeError ? 'block' : 'none'}}>
              <span className="small-pink-x-icon">&nbsp;</span>
              {t('codeErrMsg')}
            </div>
          }
        />
        <JoinField
          type={'newPassword'}
          value={password}
          isShow={isShowField('password')}
          onChange={onPasswordChange}
          bottomInfo={
            <div>
              <div
                className={
                  hasPasswdError ? styles.passwordError : styles.password
                }>
                {t('pwdErrTxt_format')}
              </div>
            </div>
          }
        />
        <JoinField
          type={'passwordConfirm'}
          value={confirmPwd}
          isShow={isShowField('rePassword')}
          onChange={onConfirmPwdChange}
          bottomInfo={
            <div
              className={
                !hasConfirmPwdError ? styles.hide : styles.passwordError
              }>
              {t('pwdErrTxt_inexact')}
            </div>
          }
        />
      </div>
      <div
        className={styles.btn_wrapper}
        style={{width: '100%', padding: '0 20px'}}>
        <JoinButtons
          type={isAllSet ? 'whiteFilled' : 'grayFilled'}
          text={t('doneBtn')}
          onClick={() => toggleChangePassword()}
        />
      </div>
    </div>
  );
}
