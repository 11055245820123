import React from 'react';

interface Props {
  isExpand: boolean;
  setIsExpand: (is: boolean) => void;
}

export default function ExpendContentsBtn({isExpand, setIsExpand}: Props) {
  return (
    <button
      className="expand-contents-btn"
      onClick={() => setIsExpand(!isExpand)}>
      {isExpand ? '접어두기' : '펼처보기'}
      <span className={`arrow-icon ${isExpand ? 'up' : 'down'}`}></span>
    </button>
  );
}
