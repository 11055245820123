import React from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import dayjs from 'dayjs';

import Modal from '../../../layouts/Modal';
import styles from './styles.module.scss';
import Button from '../../Button';
import {UserInfo, user} from '../../../store/user';
import {ToastMessage, isOpenToast} from '../../../store';
import {updateProfile} from '../../../services/user';
interface Props {
  title?: string;
  description?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
}

export default function MarketingModal({
  title,
  description,
  isOpen,
  setIsOpen,
}: Props) {
  const accessToken = useRecoilValue(user);
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const agree = async () => {
    const date = `${dayjs().format('YYYY.MM.DD')}`;
    const _userInfo = {
      ...userInfo,
      isMarketing: true,
      isNotifiable: true,
    };
    await updateProfile(userInfo.id, accessToken, _userInfo);
    setUserInfo(_userInfo);
    setIsOpen(false);
    setToastMessage(`${date}에 마케팅 정보 수신을 동의하였습니다.`);
    setIsOpenToast(true);
  };
  return (
    <Modal.Alert
      width={'315px'}
      height={'255px'}
      isOpen={isOpen}
      style={{
        borderRadius: '10px',
        padding: '40px 16px 10px 16px',
      }}>
      <div className={styles.contents} style={{padding: '0'}}>
        <div className={styles.titleArea}>
          {title ? title : '스페셜 혜택을 놓치지 마세요'}
        </div>
        <div className={styles.txtArea}>
          {description ? (
            description
          ) : (
            <div>
              마케팅 정보 수신에 동의하시면
              <br />
              새로운 혜택을 가장 빠르게 확인하실 수 있습니다.
            </div>
          )}
        </div>
        <div className={styles.buttonArea}>
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'알림 수신에 동의합니다'}
            background={'#000'}
            color={'#fff'}
            onClick={agree}
          />
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'동의하지 않습니다'}
            background={'#fff'}
            color={'#9F9CA3'}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </Modal.Alert>
  );
}
