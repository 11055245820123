import React from 'react';

import {TextWrapper} from './text.styled';

interface Props {
  txt: string;
  fontSize: number;
  color: string;
  style?: React.CSSProperties;
}

export default function Text({txt, fontSize, color, style}: Props) {
  return (
    <TextWrapper color={color} fontSize={fontSize} style={style}>
      {txt}
    </TextWrapper>
  );
}
