import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import styles from './styles.module.scss';
import {HomeKnewNewsCard} from '../../../store';

export default function MagazineKnewNews() {
  const navigator = useNavigate();
  const news = useRecoilValue(HomeKnewNewsCard);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <div className={styles.titles}>
          <strong>News</strong>
          <span>놓치기엔 아쉬운, 트렌디한 F&B 브랜드 이슈</span>
        </div>
        <span
          className={`${styles.icon} arrow-right-for-magazine-icon`}
          onClick={() =>
            navigator('/news', {
              state: {
                prev: 'magazine',
              },
            })
          }>
          &nbsp;
        </span>
      </div>
      {news.length > 0 && (
        <div className={styles.contents}>
          <div
            className={styles.item}
            onClick={() =>
              navigator(`/news/${news[0].id}`, {
                state: {
                  prev: 'magazine',
                },
              })
            }>
            <div
              className={styles.img}
              style={{
                background: `url("${news[0].imageUrl}") no-repeat center / cover`,
              }}></div>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{__html: news[0].title}}></div>
          </div>
          <div
            className={styles.item}
            onClick={() =>
              navigator(`/news/${news[1].id}`, {
                state: {
                  prev: 'magazine',
                },
              })
            }>
            <div
              className={styles.img}
              style={{
                background: `url("${news[1].imageUrl}") no-repeat center / cover`,
              }}></div>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{__html: news[1].title}}></div>
          </div>
        </div>
      )}
    </div>
  );
}
