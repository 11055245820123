/* eslint-disable @typescript-eslint/no-explicit-any */
// import React from 'react';
// import AWS from 'aws-sdk';
import Axios from 'axios';

export default function useS3() {
  const uploadImage = async (
    file: File,
    filename: string,
    presignedUrl: string,
    fields: any,
  ) => {
    try {
      const data = new FormData();
      Object.keys(fields).map(k => {
        data.append(k, fields[k]);
      });
      data.append('file', file);
      const res = await Axios.post(presignedUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (exception) {
      console.log(exception);
      /* handle the exception */
    }
  };

  return {
    uploadImage,
  };
}
