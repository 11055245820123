import React from 'react';

import styles from './styles.module.scss';

interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({visible, children}) => {
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: visible ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.4)',
      }}>
      <div className={styles.modal}>{children}</div>
    </div>
  );
};

export default CustomModal;
