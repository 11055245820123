import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {App, URLOpenListenerEvent} from '@capacitor/app';

export default function useAppScheme() {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('init Scheme App Listener');
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('open App Scheme');
      const url = event.url.split('://')[1];
      const isFromKakao = event.url.indexOf('kakaolink') > -1;
      console.log(event.url);
      if (isFromKakao) {
        const link = event.url.split('main=')[1];
        navigate(`${link}`, {
          state: {
            prev: 'home',
          },
        });
      } else {
        navigate(`/${url}`, {
          state: {
            prev: 'home',
          },
        });
      }
    });
  }, []);

  return null;
}
