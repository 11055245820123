import React from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {logout, signout} from '../../../services/user';
import {isOpenLogoutModal} from '../../../store';
import {user, UserInfo, deviceId, SignupUserInfo} from '../../../store/user';
import useStorage from '../../../hooks/useStorage';

interface Props {
  type: 'logout' | 'signout';
}

export default function PopupModal({type}: Props) {
  const navigator = useNavigate();
  const {setToken} = useStorage();
  const [_isOpenLogoutModal, setIsLogoutModal] =
    useRecoilState(isOpenLogoutModal);
  const userInfo = useRecoilValue(UserInfo);
  const resetUserInfo = useResetRecoilState(UserInfo);
  const resetSignupInfo = useResetRecoilState(SignupUserInfo);
  const accessToken = useRecoilValue(user);
  const resetAccessToken = useResetRecoilState(user);
  const _deviceId = useRecoilValue(deviceId);
  const doLogout = async () => {
    await logout(accessToken, _deviceId);
    setIsLogoutModal(false);
    setToken('');
    resetUserInfo();
    resetSignupInfo();
    resetAccessToken();
    navigator('/login', {
      state: {
        prevPath: `${location.pathname}${location.search}`,
      },
    });
  };
  const doSignOut = async () => {
    await signout(userInfo.id, accessToken);
    setIsLogoutModal(false);
    resetUserInfo();
    resetSignupInfo();
    resetAccessToken();
    navigator('/login', {
      state: {
        prevPath: `${location.pathname}${location.search}`,
      },
    });
  };
  const modalContents = [
    {
      type: 'logout',
      txt: '로그아웃 하시겠습니까?',
      submitTxt: '로그아웃',
      doFn: doLogout,
    },
    {
      type: 'signout',
      txt: '탈퇴 후 재가입시<br />데이터는 복구되지 않습니다.<br />정말 탈퇴하시겠습니까?',
      submitTxt: '탈퇴하기',
      doFn: doSignOut,
    },
  ];
  const cancel = () => {
    setIsLogoutModal(false);
  };
  return (
    <div
      className="modal-wrapper"
      style={{
        display: _isOpenLogoutModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div className={`modal popup alert ${type}`}>
        <div
          className="modal-body"
          dangerouslySetInnerHTML={{
            __html: modalContents.filter(c => c.type === type)[0].txt,
          }}></div>
        <div className="btn-wrapper">
          <button
            className="btn submit"
            onClick={() =>
              modalContents.filter(c => c.type === type)[0].doFn()
            }>
            {modalContents.filter(c => c.type === type)[0].submitTxt}
          </button>
          <button className="btn cancel" onClick={() => cancel()}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
}
