import React from 'react';
import {useRecoilState} from 'recoil';

import {isOpenNickNameAlert} from '../../../store';

export default function NickNameAlert() {
  const [openModal, setOpenModal] = useRecoilState(isOpenNickNameAlert);
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="close-btns"
          style={{display: 'flex', justifyContent: 'flex-start'}}>
          <span
            className="icon close-gray-icon"
            style={{
              width: '14px',
              height: '14px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(false)}>
            &nbsp;
          </span>
        </div>
        <div
          className="login-modal-header"
          style={{
            marginTop: '25px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          닉네임을 변경해주세요
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          닉네임은 15자 이내로 설정 가능합니다
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn"
            style={{
              fontWeight: 600,
              fontSize: '14px',
              background: '#000',
              color: '#fff',
            }}
            onClick={() => setOpenModal(false)}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
