import {atom} from 'recoil';

export const RecommendContents = atom<RecommendData[]>({
  key: 'recommend/contents',
  default: [],
});

export const SelectedRecommendId = atom<number>({
  key: 'recommend/selected-recommend-id',
  default: -1,
});

export const MarketPageProductInfo = atom<RecommendProduct>({
  key: 'recommend/market-page-product-info',
  default: undefined,
});

export const FoodLogFeeds = atom<RecommendContentReview[]>({
  key: 'recommend/food-log-feeds',
  default: [],
});

export const MarketPageFilters = atom<MarketPageFilter[]>({
  key: 'recommend/market-page-filter',
  default: [
    {
      type: 'reaction',
      selection: [],
    },
    {
      type: 'store',
      selection: [],
    },
    {
      type: 'sort',
      selection: [],
    },
  ],
});
