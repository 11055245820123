import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';

import PopupModal from '@/components/common/PopupModal';
import Menus from '@/components/myKnewnew/Menus';
import ContentsHeader from '@/layouts/ContentsHeader';
import {isOpenLogoutModal, UserInfo} from '@/store';
import {isMobile} from '@/utils';
import useAppStore from '@/hooks/useAppStore';

export default function Options() {
  const navigator = useNavigate();
  const [modalType, setModalType] = useState<'logout' | 'signout'>('logout');
  const setIsOpenLoginModal = useSetRecoilState(isOpenLogoutModal);
  const userInfo = useRecoilValue(UserInfo);
  const {getCurrentAppVersion, getAvailableAppVersion} = useAppStore();
  const [versionText, setVersionText] = useState('');

  const openLogout = () => {
    setModalType('logout');
    setIsOpenLoginModal(true);
  };
  const openSignout = () => {
    setModalType('signout');
    setIsOpenLoginModal(true);
  };

  const getVersionText = async () => {
    const currentVersion = await getCurrentAppVersion();
    const availableVersion = await getAvailableAppVersion();
    let text = 'v' + currentVersion;
    if (currentVersion === availableVersion) text = text + ' (최신버전)';
    else text = text + ' (업데이트 필요)';
    setVersionText(text);
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      getVersionText();
    }
  }, []);

  return (
    <div className="main my-knewnew-options">
      <ContentsHeader
        txt="설정"
        isOption="none"
        back={() => navigator('/user')}
      />
      <Menus doLogout={openLogout} />
      <div
        className={`my-knewnew-footer ${
          Capacitor.getPlatform() === 'android' ? 'android' : ''
        }`}>
        <div className="signout">
          {userInfo.id != -1 ? (
            <span style={{cursor: 'pointer'}} onClick={() => openSignout()}>
              회원탈퇴
            </span>
          ) : (
            <span></span>
          )}
          <span style={{display: isMobile() ? 'inherit' : 'none'}}>
            {versionText}
          </span>
        </div>
        <div
          className="bottom-messages"
          style={{display: isMobile() ? 'none' : 'block'}}>
          <div className="logo-box"></div>
          <div className="message">©2023 knewnew. ALL RIGHTS RESERVED.</div>
          <div className="message">
            Knewnew 웹서비스는 각 브라우저의 최신 버전에 최적화되어 있습니다.
          </div>
        </div>
      </div>
      <PopupModal type={modalType} />
    </div>
  );
}
