import {Capacitor, registerPlugin} from '@capacitor/core';
import {useEffect, useState} from 'react';
export interface EchoPlugin {
  echo(options: {value?: string; token?: string}): Promise<{
    value?: string;
    token?: string;
    accessToken?: string;
    refreshToken?: string;
    email?: string;
    nickname?: string;
    gender?: string;
    profileImage?: string;
    result?: string;
  }>;
}

export default function useNative() {
  const [Echo, setEcho] = useState<EchoPlugin>();
  const polyfill = {
    echo: async () => {
      return {
        value: '',
        token: '',
        accessToken: '',
        refreshToken: '',
        email: '',
        nickname: '',
        gender: '',
        profileImage: '',
        result: '',
      };
    },
  };

  const registEcho = () => {
    const _echo =
      Capacitor.getPlatform() === 'web'
        ? polyfill
        : registerPlugin<EchoPlugin>('Echo');
    setEcho(_echo);
  };

  useEffect(() => {
    if (!Echo) {
      registEcho();
    }
  }, []);

  return {
    Echo: Echo || polyfill,
    registEcho,
  };
}
