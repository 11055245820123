import {useEffect, useMemo, useState} from 'react';
import {Geolocation, GeolocationPosition} from '@capacitor/geolocation';
import {PermissionStatus} from '@capacitor/geolocation/dist/esm/definitions';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {
  BrandAreaListPage,
  CurrentLatitude,
  CurrentLongitude,
  IsAreaSelected,
} from '@/store';

interface Coordinates {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
  calcWalkMinutes?: (distance: number) => string;
  getCurrentPosition?: () => void;
  openGeolocationChecker?: () => void;
  checkGeolocation?: () => void;
  permissionState?: PermissionStatus | undefined;
  isPossibleGeolocation?: boolean;
  isGeolocationModalOpen?: boolean;
  setIsGeolocationModalOpen?: (is: boolean) => void;
  setIsAreaSelected?: (is: boolean) => void;
}

const useGeoLocation = (): Coordinates => {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [permissionState, setPermissionState] = useState<PermissionStatus>();
  const [isAreaSelected, setIsAreaSelected] = useRecoilState(IsAreaSelected);
  const setBrandsPage = useSetRecoilState(BrandAreaListPage);
  const [isGeolocationModalOpen, setIsGeolocationModalOpen] = useState(false);

  const setLongitude = useSetRecoilState(CurrentLongitude);
  const setLatitude = useSetRecoilState(CurrentLatitude);

  const calcWalkMinutes = (distance: number) => {
    if (distance < 500) {
      return `도보 5분 이하`;
    } else if (distance >= 500 && distance < 1000) {
      return `도보 15분 이하`;
    } else if (distance >= 1000 && distance < 2000) {
      return `도보 20분 이하`;
    } else if (distance >= 2000 && distance < 3000) {
      return `도보 20분 이상`;
    } else {
      return `검색 결과가 없습니다`;
    }
  };

  const isPossibleGeolocation = useMemo(() => {
    console.log(`isAreaSelected: ${isAreaSelected}`);
    if (isAreaSelected) {
      return false;
    }
    if (permissionState && permissionState.location !== 'granted') {
      return false;
    }
    return true; // 기본값으로 false를 반환
  }, [isAreaSelected, permissionState]);

  const getCurrentPosition = async () => {
    try {
      const position: GeolocationPosition =
        await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          maximumAge: 5000,
        });
      setCoordinates({
        latitude: Math.abs(position.coords.latitude),
        longitude: Math.abs(position.coords.longitude),
        error: null,
      });
    } catch (error: any) {
      setCoordinates({
        latitude: null,
        longitude: null,
        error: error.message,
      });
    }
  };

  const checkGeolocation = async () => {
    try {
      const permission = await Geolocation.checkPermissions();
      setPermissionState(permission);
      return permission.location;
    } catch (e) {
      console.log(e);
      setIsGeolocationModalOpen(true);
      setBrandsPage(0);
      // 지역기반으로 검색할 수 있도록 true로 변경
      setIsAreaSelected(true);
    }
  };

  const openGeolocationChecker = async () => {
    await Geolocation.requestPermissions({
      permissions: ['location'],
    });
  };

  useEffect(() => {
    if (coordinates) {
      if (coordinates.latitude) {
        setLatitude(coordinates.latitude);
      }
      if (coordinates.longitude) {
        setLongitude(coordinates.longitude);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    getCurrentPosition();
    checkGeolocation();
  }, []); // Dependency array is empty because we only want this effect to run once

  return {
    ...coordinates,
    calcWalkMinutes,
    getCurrentPosition,
    checkGeolocation,
    openGeolocationChecker,
    permissionState,
    isPossibleGeolocation,
    isGeolocationModalOpen,
    setIsGeolocationModalOpen,
    setIsAreaSelected,
  };
};

export default useGeoLocation;
