import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Notification} from '@/views/myKnewnew/PushList/LIst';
import {timeForToday} from '@/utils';

import styles from './styles.module.scss';

interface Props {
  item: Notification;
  isRead: boolean;
}

export default function PushListItem({item, isRead}: Props) {
  const navigator = useNavigate();
  return (
    <div
      className={`${styles.push_list_item} ${isRead ? styles.read : ''}`}
      onClick={() => {
        navigator(item?.link);
      }}>
      {item.type !== 'admin_noti' && (
        <div
          className={`${styles.img} ${
            !item.reviewImage && `${styles.img_dummy}`
          }`}
          style={{
            background: `url("${item.reviewImage}") no-repeat center / cover`,
          }}></div>
      )}
      <div className={styles.contents}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.message}>
          <span className={styles.txt}>{item.message}</span>
          <span className={styles.time}>{timeForToday(item.created)}</span>
        </div>
      </div>
    </div>
  );
}
