import React, {useMemo} from 'react';

import {isAndroid, isMobile} from '@/utils';

import {BrandAllImageWrapper, ImgList} from './brandallimage.styled';
import Image from '../../atoms/Image/Image';

interface Props {
  images: string[];
  setSelectedImage: (idx: number) => void;
}
export default function BrandAllImage({images, setSelectedImage}: Props) {
  const innerWidth = useMemo(() => {
    return isMobile() ? Math.floor(window.innerWidth / 2) : Math.floor(375 / 2);
  }, []);
  return (
    <BrandAllImageWrapper mobile={isMobile()} android={isAndroid()}>
      <ImgList>
        {images?.map((o, i) => (
          <div
            className="flex"
            key={`img-item-${i}`}
            onClick={() => setSelectedImage(i)}>
            <Image
              src={o}
              width={innerWidth}
              height={innerWidth}
              style={{
                flex: 1,
                width: `${innerWidth}px`,
                height: `${innerWidth}px`,
              }}
            />
          </div>
        ))}
      </ImgList>
    </BrandAllImageWrapper>
  );
}
