import {SplashScreen} from '@capacitor/splash-screen';
import {Capacitor} from '@capacitor/core';
import {useNavigate} from 'react-router-dom';

import useAppStore from './useAppStore';

export default function useSplash() {
  const navigator = useNavigate();
  const {getCurrentAppVersion, getAvailableAppVersion} = useAppStore();
  // const setAccessToken = useSetRecoilState(user);
  const show = async () => {
    if (Capacitor.getPlatform() !== 'web') {
      await versionCheck();
    }
  };
  const hide = async () => {
    if (Capacitor.getPlatform() !== 'web') {
      await SplashScreen.hide();
    }
  };

  const versionCheck = async () => {
    try {
      if (process.env.REACT_APP_ENV === 'production') {
        const currentVersion = await getCurrentAppVersion();
        const availableVersion = await getAvailableAppVersion();

        const current = currentVersion.split('.');
        const avail = (availableVersion as string).split('.');

        if (Number(current[0]) < Number(avail[0])) {
          navigator('/update', {
            replace: true,
          });
          return false;
        }
        if (Number(current[0]) === Number(avail[0])) {
          if (Number(current[1]) < Number(avail[1])) {
            navigator('/update', {
              replace: true,
            });
            return false;
          }
        }
      }
      return true;
    } catch (e) {
      navigator('/login');
    }
  };

  return {
    show,
    hide,
  };
}
