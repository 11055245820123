import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {lock, unlock} from 'tua-body-scroll-lock';

import {isOpenCommunityLoginModal} from '@/store';
import KakaoLoginBtn from '@/assets/banner/kakao-login-btn.png';
import FreeComment from '@/assets/banner/its-free.svg';

export default function CommunityLoginAlert() {
  const navigator = useNavigate();
  const openModal = useRecoilValue(isOpenCommunityLoginModal);
  const setOpenModal = useSetRecoilState(isOpenCommunityLoginModal);
  const toggle = async () => {
    setOpenModal(false);
    navigator('/login', {
      state: {
        prevPath: `${location.pathname}${location.search}`,
      },
    });
  };
  const cancel = () => {
    setOpenModal(false);
    navigator('/community');
  };
  useEffect(() => {
    if (openModal) {
      lock(document.querySelector('.modal-wrapper.alert') as HTMLElement);
    } else {
      unlock(document.querySelector('.modal-wrapper.alert') as HTMLElement);
    }
  }, [openModal]);
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="login-modal-header"
          style={{
            marginTop: '30px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          로그인 시 이용할 수 있어요!
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          3초만에 가입하고 더 많은 콘텐츠를 둘러보세요.
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn"
            style={{
              fontWeight: 600,
              fontSize: '14px',
              background: `url(${KakaoLoginBtn}) no-repeat center / contain`,
              color: '#fff',
            }}
            onClick={() => toggle()}>
            <span
              className="free-comment"
              style={{
                display: 'inline-block',
                width: '163px',
                height: '28px',
                marginTop: '-50px',
                background: `url(${FreeComment}) no-repeat center / cover`,
              }}></span>
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => cancel()}>
            아니요, 다음에 할래요.
          </button>
        </div>
      </div>
    </div>
  );
}
