import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';

import {isOpenToast, MagazineItem} from '@/store';
import {UserInfo} from '@/store/user';
import {switchNewLineTxt} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';
import ContentsFooter from '@/components/contents/ContentsFooter';
import FreeComment from '@/assets/banner/its-free.svg';

export default function Brand() {
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [id, setId] = useState(0);
  const magazineItem = useRecoilValue(
    MagazineItem({
      id,
      type: 'brand',
    }),
  );
  const [relatedMagazineRows, setRelatedMagazineRows] = useState<
    MagazineItem[][]
  >([]);
  const [_isOpenToast, setIsOpenToast] = useRecoilState(isOpenToast);
  const [hasLocked, setHasLocked] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);

  const close = () => {
    setIsOpenToast(false);
  };

  const _getRelatedMagazines = () => {
    const exceptCurrentContents = magazineItem.relatedItems
      .filter((item: MagazineItem) => item.id !== magazineItem.info.id)
      .splice(0, 4);
    const relatedLength = Math.ceil(exceptCurrentContents.length / 2);
    const rows: MagazineItem[][] = [];
    for (let i = 0; i < relatedLength; i++) {
      rows.push([...exceptCurrentContents].splice(i * 2, 2));
    }
    setRelatedMagazineRows(rows);
  };

  const init = () => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    setId(id);
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  };

  const onScroll = () => {
    if (window.scrollY > 400 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
    }
  };

  useEffect(() => {
    if (_isOpenToast) {
      setTimeout(() => {
        close();
      }, 2000);
    }
  }, [_isOpenToast]);

  useEffect(() => {
    if (magazineItem.relatedItems.length > 0) {
      _getRelatedMagazines();
    }
    if (magazineItem.info?.brands?.categoryList.length > 0) {
      setCategories(magazineItem.info.brands.categoryList.slice().sort());
    }
  }, [magazineItem]);

  const sender = useLogger();

  useEffect(() => {
    init();
    document.addEventListener('scroll', onScroll);
    sender({
      _msg: 'ToBrands',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    return () => document.removeEventListener('scroll', onScroll);
  }, [window.location.pathname]);

  return magazineItem.info ? (
    <div>
      <div
        className="brand-banner"
        style={{
          background: `center / cover no-repeat  url('${magazineItem.info.imageUrl}')`,
        }}>
        <div className="txt-content">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: switchNewLineTxt(magazineItem.info.title || ''),
            }}></div>
          <div className="sub-title">{magazineItem.info.subtitle}</div>
          <div className="category-info">
            {categories.join(' ∙ ').replaceAll(' / ', '/')}
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="contents-info">
          <div className="dates">{magazineItem.info.created.split('T')[0]}</div>
          <div className="see-count">
            <span className="icon see-count-icon">&nbsp;</span>
            <span className="cnt">{magazineItem.info.viewCount}</span>
          </div>
        </div>
        <div
          className={`detail-main-contents magazine-detail-wrapper ${
            hasLocked ? 'show-login' : ''
          }`}>
          <div
            className="logins"
            style={{display: userInfo.id === -1 ? 'flex' : 'none', zIndex: 3}}>
            <div className="login-txt">
              <p>다음 내용이 궁금하다면?</p>
              <h1>
                딱 3초만에 가입하고 <br /> 계속 볼 수 있어요!
              </h1>
              <div
                className="main-cont btn black"
                onClick={() => {
                  sender({
                    _msg: 'joinmodal_click',
                    _screen: window.location.pathname,
                    _action: Action.CLICK,
                  });
                  navigator('/login', {
                    state: {
                      prevPath: `${location.pathname}${location.search}`,
                    },
                  });
                }}
                style={{cursor: 'pointer', background: '#000', color: '#fff'}}>
                <div
                  className="message-box"
                  style={{
                    background: `url("${FreeComment}") no-repeat center / cover`,
                  }}></div>
                <div>로그인하러 가기</div>
              </div>
            </div>
          </div>
          <div
            className="contents"
            dangerouslySetInnerHTML={{__html: magazineItem.file}}></div>
        </div>
      </div>
      <div
        className="about-market-wrapper"
        style={{display: userInfo ? 'block' : 'none'}}>
        <div className="about-market">
          <div className="about-market-header">
            About{' '}
            <span className="title">{magazineItem.info.brands?.title}</span>
          </div>
          {magazineItem.info.brands?.type === 'place' ? (
            <div className="about-offline-market-body market-body">
              <div
                className="market-info"
                style={{alignItems: 'flex-start', textAlign: 'left'}}>
                <span className="title">Place</span>
                <span
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: magazineItem.info.brands?.address
                      ? magazineItem.info.brands?.address.replace(
                          /\\n/g,
                          '<br />',
                        )
                      : '',
                  }}></span>
              </div>
              <div
                className="market-info"
                style={{alignItems: 'flex-start', textAlign: 'left'}}>
                <span className="title">Opening Hours</span>
                <span
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: magazineItem.info.brands?.openingHours
                      ? magazineItem.info.brands?.openingHours.replace(
                          /\\n/g,
                          '<br />',
                        )
                      : '',
                  }}></span>
              </div>
            </div>
          ) : (
            <div className="about-offline-market-body market-body">
              <div
                className="market-info"
                style={{alignItems: 'flex-start', textAlign: 'left'}}>
                <span className="title">Product</span>
                <span
                  className="info"
                  dangerouslySetInnerHTML={{
                    __html: magazineItem.info.brands?.productName
                      ? magazineItem.info.brands?.productName.replace(
                          /\\n/g,
                          '<br />',
                        )
                      : '',
                  }}></span>
              </div>
              <div
                className="market-info"
                style={{alignItems: 'flex-start', textAlign: 'left'}}>
                <span className="title">Online Shop</span>
                <span className="info">
                  {magazineItem.info.brands?.whereToBuy}
                </span>
              </div>
            </div>
          )}
          {magazineItem.info.brands.parentId ? (
            <div
              className="brand-more"
              style={{cursor: 'pointer'}}
              onClick={
                magazineItem.info.brands?.brandLink
                  ? () =>
                      navigator(
                        `/brand/${magazineItem.info.brands?.parentId}/${magazineItem.info.brands?.id}`,
                      )
                  : undefined
              }>
              <span className="brand-more-txt">브랜드 더 알아보기</span>
              <span className="arrow-right-at-brand">&nbsp;</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="curator-info">
        <div
          className="curator-img"
          style={{
            background: `center / cover no-repeat url(${magazineItem.info.author.profileImage})`,
          }}></div>
        <div className="curator-txt-info">
          <div className="nick-name">
            <span>{magazineItem.info.author.nickname}</span>
            <span
              className="curator-profile"
              onClick={() =>
                navigator(`/curators/${magazineItem.info.author.id}`, {
                  state: {
                    history: 'contents',
                    prev: `${location.pathname.slice(1, undefined)}`,
                  },
                })
              }>
              <span className="curator-txt">Curator Profile</span>
              <span className="arrow-right-at-brand">&nbsp;</span>
            </span>
          </div>
          <div className="curator-message">
            {magazineItem.info.author.headline}
          </div>
        </div>
      </div>
      <div className="content-divider"></div>
      <div className="curator-other-contents">
        {relatedMagazineRows.length > 1 ? (
          <h1 className="hangul">함께 보면 좋은 글</h1>
        ) : (
          <></>
        )}
        {relatedMagazineRows.map((c, idx) => {
          return (
            <div className="contents-info" key={`related_magazine_info_${idx}`}>
              {c.map(item => {
                return (
                  <div
                    className="item"
                    key={`related_magazine_item_${item.id}`}
                    onClick={() => navigator(`/knewbrands/${item.id}`)}
                    style={{cursor: 'pointer'}}>
                    <div
                      className="item-img"
                      style={{
                        background: `center / cover no-repeat  url(${item.imageUrl})`,
                      }}></div>
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: switchNewLineTxt(item.title || ''),
                      }}></div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <ContentsFooter
        detailInfo={magazineItem.info as MagazineItem}
        type="brand"
      />
    </div>
  ) : (
    <div></div>
  );
}
