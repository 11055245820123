import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import ContentsHeader from '../../layouts/ContentsHeader';
import styles from './styles.module.scss';
import Layout from '../../layouts';

export default function CancelFailed() {
  const navigator = useNavigate();
  const localStorageKey = 'WEB_PAYMENT_INFO';
  const openBrowser = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
    } else {
      await Browser.open({
        url: 'http://pf.kakao.com/_qmUqG/chat',
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem(localStorageKey);
  }, []);
  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt="주문 취소 실패"
          isOption="none"
          isBottomLine=""
          isPopup
          noBack={true}
        />
        <div className={styles.failed_info} style={{height: '100vh'}}>
          <span className={`icon ${styles.warning_icon}`}>&nbsp;</span>
          <h2>{'주문 취소에 실패했습니다.'}</h2>
          <div className={styles.message}>
            {'일시적인 오류로 인해 주문 취소에 실패했습니다.'} <br />
            {'같은 오류가 반복될 경우'} <br />
            {'마이 > 1:1 문의를 통해 문의바랍니다.'}
          </div>
          <div className={styles.btn_wrapper}>
            <button className={styles.failed_btn} onClick={() => openBrowser()}>
              1:1 문의
            </button>
            <button className={styles.failed_btn} onClick={() => navigator(-1)}>
              이전으로
            </button>
          </div>
        </div>
      </div>
    </Layout.Main>
  );
}
