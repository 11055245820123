import React from 'react';

import Layout from '@/layouts';

import NPickHeader from '../../molecules/NPick/NPickHeader';
import NPickBottom from '../../molecules/NPick/NPickBottom';

interface Props {
  children: React.ReactNode;
}

export default function NPickIntroduce({children}: Props) {
  return (
    <Layout.Main>
      <NPickHeader />
      {children}
      <NPickBottom />
    </Layout.Main>
  );
}
