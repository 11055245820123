import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {useLocation} from 'react-router-dom';

import styles from './styles.module.scss';
import {PathHistories} from '../../store';

interface Props {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

// 경로 비교를 위한 범용 함수
const comparePaths = (
  currentPath: string,
  prevPath: string,
  {
    target = [],
    minLength = 0,
    exactMatchIndex = -1,
  }: {
    target?: string[];
    minLength?: number;
    exactMatchIndex?: number;
  },
): boolean => {
  const currentSegments = currentPath.split('/');
  const prevSegments = prevPath.split('/');

  // 공통 조건: 최소 길이와 경로 비교
  if (currentSegments.length < minLength || prevSegments.length < minLength) {
    return false;
  }

  // 특정 구간 일치 여부 확인
  if (
    exactMatchIndex > -1 &&
    currentSegments[1] === prevSegments[1] &&
    currentSegments[2] === prevSegments[2]
  ) {
    return true;
  }

  // 대상 경로 리스트(target) 내에서 다른 구간으로 이동 여부 확인
  return (
    target.includes(currentSegments[1]) &&
    target.includes(prevSegments[1]) &&
    currentSegments[1] !== prevSegments[1]
  );
};

// 루트 경로인지 확인하는 함수
const isRootPath = (pathname: string, search: string) => {
  const rootPaths = [
    '/brand',
    '/home',
    '/collection/list',
    '/community',
    '/user',
  ];
  return rootPaths.includes(pathname) && search === '';
};

export default function Index({children}: Props) {
  const location = useLocation();
  const [pathHistory, setPathHistory] = useRecoilState(PathHistories);

  useEffect(() => {
    let histories = [...pathHistory]; // 스프레드 연산자로 복사
    const {pathname, search, state} = location;
    const isRoot = isRootPath(pathname, search);
    const lastHistory = histories[histories.length - 1];

    // 로그인 경로 또는 루트 경로일 때 처리
    if (pathname === '/login') return;
    if (isRoot) {
      histories = [];
      return;
    }

    const currentPath = `${pathname.replace('/', '')}${search}`;
    const prevPath = `/${lastHistory?.path || ''}`;

    // 매거진, 브랜드 리스트, 브랜드 지점 변경 시 처리
    const isMagazineListChange = comparePaths(pathname, prevPath, {
      target: ['zip', 'knewbrands', 'news'],
      minLength: 2,
    });

    const isBrandListChange = comparePaths(pathname, prevPath, {
      target: ['brands'],
      minLength: 3,
    });

    const isBrandBranchChange = comparePaths(pathname, prevPath, {
      exactMatchIndex: 3,
    });

    if (
      lastHistory &&
      (isMagazineListChange || isBrandListChange || isBrandBranchChange)
    ) {
      return;
    }

    // 동일한 경로의 중복 여부 확인 후 히스토리 갱신
    if (lastHistory?.path === currentPath && !isRoot) {
      histories[histories.length - 1] = {path: currentPath, state};
    } else {
      histories.push({path: currentPath, state});
    }

    setPathHistory(histories);
  }, [location.pathname, location.search]); // 의존성 배열에 pathname과 search 추가

  return (
    <div className={styles.main} id="main">
      {children}
    </div>
  );
}
