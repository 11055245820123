import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CommunityLoader() {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={380}
        height={293}
        viewBox="0 0 380 293"
        backgroundColor="#F5F5F5"
        foregroundColor="#EFEFEF"
        style={{width: '100%'}}>
        <rect x="20" y="34" rx="0" ry="0" width="103" height="28" />
        <rect x="20" y="72" rx="0" ry="0" width="126" height="147" />
        <rect x="150" y="72" rx="0" ry="0" width="126" height="147" />
        <rect x="280" y="72" rx="0" ry="0" width="126" height="147" />
        <rect x="20" y="229" rx="0" ry="0" width="335" height="32" />
        <rect x="20" y="367" rx="0" ry="0" width="126" height="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={380}
        height={313}
        viewBox="0 0 380 313"
        backgroundColor="#F5F5F5"
        foregroundColor="#EFEFEF"
        style={{width: '100%'}}>
        <rect x="20" y="60" rx="0" ry="0" width="103" height="28" />
        <rect x="20" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="150" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="280" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="20" y="255" rx="0" ry="0" width="335" height="32" />
        <rect x="20" y="293" rx="0" ry="0" width="126" height="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={380}
        height={313}
        viewBox="0 0 380 313"
        backgroundColor="#F5F5F5"
        foregroundColor="#EFEFEF"
        style={{width: '100%'}}>
        <rect x="20" y="60" rx="0" ry="0" width="103" height="28" />
        <rect x="20" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="150" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="280" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="20" y="255" rx="0" ry="0" width="335" height="32" />
        <rect x="20" y="293" rx="0" ry="0" width="126" height="20" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={380}
        height={313}
        viewBox="0 0 380 313"
        backgroundColor="#F5F5F5"
        foregroundColor="#EFEFEF"
        style={{width: '100%'}}>
        <rect x="20" y="60" rx="0" ry="0" width="103" height="28" />
        <rect x="20" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="150" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="280" y="98" rx="0" ry="0" width="126" height="147" />
        <rect x="20" y="255" rx="0" ry="0" width="335" height="32" />
        <rect x="20" y="293" rx="0" ry="0" width="126" height="20" />
      </ContentLoader>
    </div>
  );
}
