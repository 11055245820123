import styled from 'styled-components';

export const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e9e7ec;
  padding-bottom: 8px;
`;

export const TextContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
`;
