import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {App} from '@capacitor/app';
import dayjs from 'dayjs';
import {useLocation} from 'react-router-dom';

import ToggleButton from '@/components/common/ToggleButton';
import ContentsHeader from '@/layouts/ContentsHeader';
import {updateProfile} from '@/services/user';
import {ToastMessage, isOpenToast, user, UserInfo} from '@/store';
import useNative from '@/hooks/useNative';
import usePushNotification from '@/hooks/usePushNotification';
import Layout from '@/layouts';

import styles from './styles.module.scss';

export default function PushConfig() {
  const {Echo} = useNative();
  const {state} = useLocation();
  const [isMarketing, setIsMarketing] = useState(false);
  const [isNotifiable, setIsNotifiable] = useState(false);
  const [isDeviceNotice, setIsDeviceNotice] = useState(false);
  const {checkPermissions} = usePushNotification();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const accessToken = useRecoilValue(user);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const date = `${dayjs().format('YYYY.MM.DD')}`;

  const toggleNotiable = async (isActive: boolean) => {
    if (isDeviceNotice) {
      const updatedUserInfo = {
        ...userInfo,
        isNotifiable: isActive,
      };
      await updateProfile(userInfo.id, accessToken, updatedUserInfo);
      setUserInfo(updatedUserInfo);
      setIsNotifiable(isActive);
    }
  };

  const toggleMarketing = async (isActive: boolean) => {
    if (isDeviceNotice) {
      const updatedUserInfo = {
        ...userInfo,
        isMarketing: isActive,
      };
      const toastMessage = isActive ? '동의하였습니다' : '철회하였습니다.';
      await updateProfile(userInfo.id, accessToken, updatedUserInfo);
      setUserInfo(updatedUserInfo);
      setIsMarketing(isActive);
      setToastMessage(`${date}에 마케팅 정보 수신을 ${toastMessage}`);
      setIsOpenToast(true);
    }
  };

  const toggleSetting = () => {
    Echo.echo({
      value: 'setting',
    });
  };

  const checkPermission = async () => {
    const res = await checkPermissions();
    console.log('checkPermission in Alert :', res);
    console.log('state :', state);
    const isNotifiable = userInfo.isNotifiable as boolean;
    const isMarketing = userInfo.isMarketing as boolean;
    setIsMarketing(isMarketing);
    setIsNotifiable(isNotifiable);
    setIsDeviceNotice(res === 'granted');
  };

  useEffect(() => {
    checkPermission();
    App.addListener('appStateChange', async ({isActive}) => {
      if (isActive) {
        const res = await checkPermissions();
        setIsDeviceNotice(res === 'granted');
      }
    });
    return () => {
      App.removeAllListeners();
    };
  }, []);

  return (
    <Layout.Main>
      <div className={`${styles.push_config}`}>
        <ContentsHeader txt="알림 설정" isOption="none" />
        <div className={`${styles.wrapper} ${styles.large}`}>
          <div className={styles.title}>
            <strong>앱 푸시 수신 설정</strong>
            <span onClick={() => toggleSetting()}>설정하기</span>
          </div>
          <div className={styles.contents}>
            앱에서 보내는 알림을 받기 위해 기기 푸시 알림을 켜주세요. 알림
            설정은 <br />
            {`‘설정 > 뉴뉴 > 알림’에서 변경할 수 있습니다.`}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <strong className={!isDeviceNotice ? styles.disabled : ''}>
              활동 알림
            </strong>
          </div>
          <div className={styles.contents}>
            <div>
              팔로우, 관심 브랜드, 댓글 등에 대한 푸시 알림을 받아볼 수<br />
              있습니다.
            </div>
            <div>
              <ToggleButton
                hasToggled={isNotifiable}
                disabled={!isDeviceNotice}
                callback={() => toggleNotiable(!userInfo.isNotifiable)}
              />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <strong className={!isDeviceNotice ? styles.disabled : ''}>
              혜택 및 마케팅 알림
            </strong>
          </div>
          <div className={styles.contents}>
            <div>
              찜한 쇼룸 오픈, 제휴 혜택 안내, 이벤트 소식 등에 대한 푸시
              <br /> 알림 을 받아볼 수 있습니다.
            </div>
            <div>
              <ToggleButton
                hasToggled={isMarketing}
                disabled={!isDeviceNotice}
                callback={() => toggleMarketing(!userInfo.isMarketing)}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout.Main>
  );
}
