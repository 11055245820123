import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

import queryKeys from '@/hooks/queries/brands/queryKeys';
import {getBrandBenefits, getBrandDetails} from '@/services/apis/brands';

export function useGetBrandDetails(
  payload: {id: number; placeId: number},
  options?: UseQueryOptions<BrandListItemType, Error>,
): UseQueryResult<BrandListItemType, Error> {
  return useQuery<BrandListItemType, Error>({
    ...options,
    queryKey: queryKeys.getBrandDetail(payload),
    queryFn: async () => {
      const res = await getBrandDetails(payload.id);
      const parentId = res.id;
      const placeInfo = res.brands.find(b => b.id === payload.placeId);
      return {
        ...placeInfo,
        parentId,
        subtitle: res.subtitle,
        bookmarkCount: res.bookmarkCount,
        isBookmark: res.isBookmark,
        childPlaces: res.brands,
      } as BrandListItemType;
    },
  });
}

export function useGetBenefits(
  payload: BrandBenefitParams,
  options?: UseQueryOptions<CollectionListItem[], Error>,
): UseQueryResult<CollectionListItem[], Error> {
  return useQuery<CollectionListItem[], Error>({
    ...options,
    queryKey: queryKeys.getBenefits(payload),
    queryFn: async () => {
      const res = await getBrandBenefits(payload);
      return res.results as CollectionListItem[];
    },
  });
}
