import React from 'react';
import {useNavigate} from 'react-router-dom';

import Badge from '../common/Badge';
import PickItem from './items/PickItem';

interface Props {
  content: RecommendData;
}

export default function PickContent({content}: Props) {
  const navigate = useNavigate();
  const moveToContents = () => {
    navigate(`/recommend/${content.id}`);
  };
  return (
    <div className="pick-content" onClick={() => moveToContents()}>
      <div className="recommend-text text-white">추천 콘텐츠</div>
      <Badge type="pick" isShow={true} />
      <div className="title">{content.title}</div>
      <div className="description">{content.subtitle}</div>
      {content.contents.length > 0 && (
        <div className="pick-items slide-wrapper">
          {content.contents.map(c => {
            return <PickItem key={c.id} item={c} />;
          })}
        </div>
      )}
    </div>
  );
}
