import styled, {css} from 'styled-components';

const androidStyle = css`
  padding-top: var(--status-bar-height);
  height: calc(375px + var(--status-bar-height));

  @media screen and (max-width: 600px) {
    height: calc(100vw + var(--status-bar-height));
  }
`;

const mobileStyle = css`
  position: fixed !important;
`;

export const BrandImageWrapper = styled.div<BrandImageWrapperI>`
  position: ${({mobile}) => (mobile ? 'fixed' : 'sticky')};
  height: 375px;
  background: #fff;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  ${({android}) => android && androidStyle};
  ${({mobile}) => mobile && mobileStyle};
`;
