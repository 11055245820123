import React from 'react';
import {useRecoilState} from 'recoil';

import {MarketPageFilters} from '../../../store/recommend';

interface Props {
  title: string;
  isSelected: boolean;
  filterType: string;
  click: () => void;
}

export default function ShopFilter({
  title,
  isSelected,
  filterType,
  click,
}: Props) {
  const [filterInfo, setFilterInfo] = useRecoilState(MarketPageFilters);
  const reset = (type: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    _filterInfo.forEach(f => {
      if (f.type === type) {
        f.selection = [];
      }
    });
    setFilterInfo(_filterInfo);
  };
  return (
    <div
      className={`shop-filter ${isSelected ? 'setup' : 'pre'}`}
      onClick={() => click()}
      style={{cursor: 'pointer'}}>
      <span>{title}</span>
      {!isSelected && (
        <span className="icon arrow-down-black-icon">&nbsp;</span>
      )}
      {isSelected && (
        <span
          className="icon white-close-icon"
          onClick={() => reset(filterType)}>
          &nbsp;
        </span>
      )}
    </div>
  );
}
