import React from 'react';

import ListItem from './items/ListItem';

interface Props {
  isBest: boolean;
  content: RecommendData;
}

export default function ListContent({content}: Props) {
  return (
    <div className="list-content">
      {/* <Badge type="best" isShow={isBest} /> */}
      <div className="title">{content.title.split('\\n').join(' \n ')}</div>
      <div className="description">{content.explain}</div>
      <div className="list-content-item-wrapper">
        <div className="list-items slide-wrapper">
          {content.contents.map(c => {
            return <ListItem key={c.id} item={c} foodlogID={c.review} />;
          })}
        </div>
      </div>
      <div className="list-item-divider"></div>
    </div>
  );
}
