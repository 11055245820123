import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';

export default function AboutBanner() {
  const {t} = useTranslation(['components'], {keyPrefix: 'about.Banner'});
  return (
    <div className={styles.aboutBannerWrapper}>
      <div className={styles.aboutBannerImg}></div>
      <div className="banner-contents">
        <div className={styles.txtContent}>
          {t('txtContent1')}
          <br />
          {t('txtContent2')}
          <br />
          <strong>{t('txtContent3')}</strong>
        </div>
      </div>
    </div>
  );
}
