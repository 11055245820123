import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import styles from './styles.module.scss';
import {MagazineBrandCard} from '../../../store';

export default function MagazineKnewBrand() {
  const navigator = useNavigate();
  const brand = useRecoilValue(MagazineBrandCard);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <div className={styles.titles}>
          <strong>Brand</strong>
          <span>맛과 멋이 있는 F&B 브랜드 이야기</span>
        </div>
        <span
          className={`${styles.icon} arrow-right-for-magazine-icon`}
          onClick={() =>
            navigator('/knewbrands', {
              state: {
                prev: 'magazine',
              },
            })
          }>
          &nbsp;
        </span>
      </div>
      {!!brand && (
        <div
          style={{cursor: 'pointer'}}
          onClick={() =>
            navigator(`/knewbrands/${brand.id}`, {
              state: {
                prev: 'magazine',
              },
            })
          }>
          <div
            className={styles.brand_image}
            style={{
              background: `url("${brand.imageUrl}") no-repeat center / cover`,
            }}></div>
          <div className={styles.title}>{brand.title}</div>
          <div className={styles.info}>
            <span className={styles.brand}>{brand.subtitle}</span>{' '}
          </div>
        </div>
      )}
    </div>
  );
}
