import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import cc from 'classcat';

import {UserInfo, user} from '@/store/user';
import {doBookMark} from '@/services/magazine';

import styles from '../styles.module.scss';

interface Props {
  info: MagazineItem;
  toggleLike: (idx: number, isLike: boolean) => void;
  idx: number;
}

export default function MyMagazineItem({info, toggleLike, idx}: Props) {
  const navigator = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const _doBookmark = async ($event: React.MouseEvent<HTMLDivElement>) => {
    $event.preventDefault();
    $event.stopPropagation();
    try {
      if (userInfo.id == -1) {
        // setOpenLoginModal(true);
        navigator('/login', {
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        });
      } else {
        const id = info.id;
        const next = !info.isBookmark;
        await doBookMark(id, next, accessToken);
        toggleLike(idx, next);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const moveTo = () => {
    const prevPath = `${location.pathname}`;
    navigator(
      `/${info.category === 'brands' ? 'knewbrands' : info.category}/${
        info.id
      }`,
      {
        state: {
          prev: `${prevPath}`,
          searchParam: location.search,
        },
      },
    );
  };
  return (
    <div
      className={styles.scrapMagazineItem}
      onClick={() => moveTo()}
      style={{
        background: `url('${info.imageUrl}') no-repeat center / cover`,
      }}>
      <div className={styles.top}>
        <span
          className={cc([styles.bookmark, info.isBookmark && styles.active])}
          onClick={_doBookmark}></span>
      </div>
      <div className={styles.bottom}>
        <p className={styles.title}>{info.title}</p>
      </div>
    </div>
  );
}
