import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {Preferences} from '@capacitor/preferences';

import {user} from '@/store/user';

export default function FirstPage() {
  const navigator = useNavigate();
  const setAccessToken = useSetRecoilState(user);
  const move = async () => {
    const {value} = await Preferences.get({key: 'auth'});
    if (value && value.length > 0) {
      setAccessToken(value);
      navigator('/home');
    } else {
      navigator('/login');
    }
  };
  useEffect(() => {
    if (Capacitor.getPlatform() === 'web') {
      navigator('/home');
    } else {
      StatusBar.setStyle({
        style: Style.Dark,
      });
      // showSplash();
      move();
    }
  }, []);
  return (
    <div
      className="main"
      style={{minHeight: '100vh', background: '#000'}}></div>
  );
}
