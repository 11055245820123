import {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import dayjs from 'dayjs';
import {Capacitor} from '@capacitor/core';

import {user} from '../store/user';
import {getCouponList} from '../services/coupon';
import useAuth from './useAuth';
import {isOpenInstallModal} from '../store';

export default function useCoupon() {
  const {openAuth} = useAuth();
  const accessToken = useRecoilValue(user);
  const ADMIN_COUPON_LIST_STORAGE_KEY = 'checked_coupon_list';
  const [hasNewCoupon, setHasNewCoupon] = useState(false);
  const setHasRead = useSetRecoilState(isOpenInstallModal);
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const getCouponData = async () => {
    const res = await getCouponList(accessToken, 0);
    const _coupons = res.data.filter(c => {
      return dayjs().diff(c.expireDate) < 0;
    });
    const existedAdminCoupons = JSON.parse(
      localStorage.getItem(ADMIN_COUPON_LIST_STORAGE_KEY) || '[]',
    );
    const adminCoupons = _coupons.filter(c => c.target !== 'code');
    adminCoupons.forEach(c => {
      if (existedAdminCoupons.indexOf(c.id) === -1) {
        setHasNewCoupon(true);
      }
    });
    setCoupons(_coupons);
  };

  const authModalClick = (path: string) => {
    const isWeb = Capacitor.getPlatform() === 'web';
    console.log('in auth modal');
    console.log(isWeb);
    if (isWeb) {
      // 설치모달
      setHasRead(true);
    } else {
      openAuth(path);
    }
  };

  useEffect(() => {
    getCouponData();
  }, []);

  return {
    coupons,
    hasNewCoupon,
    authModalClick,
  };
}
