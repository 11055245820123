import Axios from 'axios';

import {errorInterceptor} from '../utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

interface CommunitiesParam {
  address1?: string | undefined;
  address2?: string | undefined;
  offset: number;
  limit?: number;
  remove_signout?: boolean;
  brand_group?: number;
}

export const getCommunities = (token?: string, param?: CommunitiesParam) => {
  return token
    ? axios.get(`/review/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: param,
      })
    : axios.get('/review/', {
        params: param,
      });
};

export const getCommunityContents = (id: number, token?: string) => {
  return token
    ? axios.get(`/review/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.get(`/review/${id}/`);
};

export const makeLike = (id: number, isLike: boolean, token: string) => {
  return axios.post(
    `/review/${id}/like/`,
    {
      isLike,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const fetchBrands = (str: string) => {
  return axios.get(`/magazine/brands`, {
    params: {
      name: str,
    },
  });
};

export const fetchAddress = () => {
  return axios.get('/review/address?type=area2&child=true');
};

export const saveReview = (contents: CommunityContentReq, token: string) => {
  const imgs = contents.images.map(pic => {
    const _pic = {...pic};
    const img = _pic.image?.split('/')[_pic.image.split('/').length - 1];
    _pic.image = _pic.image.indexOf('http') > -1 ? `review/${img}` : _pic.image;
    return _pic;
  });
  const param = {...contents};
  param.images = imgs;
  return axios.post(
    '/review/',
    {
      ...param,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const patchReview = (
  contents: CommunityContentReq,
  token: string,
  id: number,
) => {
  const imgs = contents.images.map(pic => {
    const _pic = {...pic};
    const img = _pic.image?.split('/')[_pic.image.split('/').length - 1];
    _pic.image =
      _pic.image.indexOf('http') > -1 ? `/review/${img}` : _pic.image;
    return _pic;
  });
  const param = {...contents};
  param.images = imgs;
  return axios.patch(
    `/review/${id}/`,
    {
      ...param,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const deleteReview = (id: string, token: string) => {
  return axios.delete(`/review/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const saveReviewTemp = (
  contents: CommunityContentReq,
  token: string,
) => {
  return axios.post(
    '/review/temp/',
    {
      contents,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getReviewTemp = (token: string) => {
  return axios.get('/review/temp/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteReviewTemp = (token: string) => {
  return axios.delete('/review/temp/delete', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reportContents = (
  reviewObj: {
    review: number;
  },
  detail: string,
  token: string,
  qnaType: string,
) => {
  return axios.post(
    '/qna/',
    {
      ...reviewObj,
      objectType: 'review',
      content: detail,
      qnaType,
      status: 'unresolved',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const requestProposal = (qnaParam: QnaParams, token: string) => {
  return token
    ? axios.post('/qna/proposal/', qnaParam, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.post('/qna/', qnaParam);
};

export const requestReport = (qnaParam: QnaParams, token: string) => {
  return token
    ? axios.post('/qna/report/', qnaParam, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.post('/qna/', qnaParam);
};
