import styled from 'styled-components';

export const FooterWrapper = styled.div<{mobile?: boolean}>`
  z-index: 8;
  width: ${({mobile}) => (mobile ? '100%' : '375px')};
  position: fixed;
  display: flex;
  bottom: 0;
  left: auto;
  right: auto;
  padding: 18px 30px 28px 30px;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
`;
