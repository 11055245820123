import React, {Suspense} from 'react';
import {useNavigate} from 'react-router-dom';

import ContentsHeader from '@/layouts/ContentsHeader';
import Loader from '@/components/common/Loader';
import Layout from '@/layouts';

import styles from './styles.module.scss';
import OrderItems from './components/OrderItems';

export default function OrderList() {
  const navigator = useNavigate();
  return (
    <Layout.Main>
      <div className={`${styles.order_list}`} style={{position: 'relative'}}>
        <ContentsHeader
          txt={'구매내역'}
          isOption="none"
          isBottomLine=""
          back={() => navigator('/user')}
        />
        <div
          className={styles.order_list_wrapper}
          style={{minHeight: 'calc(100vh - 56px - 78px)'}}>
          <h2 className={styles.order_list_title}>최근 구매내역</h2>
          <Suspense fallback={<Loader />}>
            <OrderItems />
          </Suspense>
        </div>
      </div>
    </Layout.Main>
  );
}
