import React from 'react';
import {useNavigate} from 'react-router';
import {useRecoilState, useResetRecoilState} from 'recoil';

import {
  EditableCommunity,
  isOpenCommunityEditCancelAlert,
} from '../../../store';

export default function CommunityEditCancelAlert() {
  const navigator = useNavigate();
  const resetEditableCommunity = useResetRecoilState(EditableCommunity);

  const [openModal, setOpenModal] = useRecoilState(
    isOpenCommunityEditCancelAlert,
  );
  const apply = async () => {
    await resetEditableCommunity();
    await setOpenModal(false);
    navigator(-1);
  };
  return (
    <div
      className="modal-wrapper alert"
      style={{
        display: openModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        className="modal-contents  community-alert"
        style={{
          width: '335px',
          background: '#fff',
          borderRadius: '10px',
          padding: '20px',
        }}>
        <div
          className="close-btns"
          style={{display: 'flex', justifyContent: 'flex-start'}}>
          <span
            className="icon close-gray-icon"
            style={{
              width: '14px',
              height: '14px',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(false)}>
            &nbsp;
          </span>
        </div>
        <div
          className="login-modal-header"
          style={{
            marginTop: '25px',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25.2px',
            letterSpacing: '-2%',
            textAlign: 'center',
          }}>
          게시글 수정을 취소하시겠습니까?
        </div>
        <div
          className="login-modal-info"
          style={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '140%',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}>
          작성 취소 선택 시 수정된 글은 저장되지 않습니다.
        </div>
        <div className="login-btn-wrapper" style={{marginTop: '40px'}}>
          <button
            className="modal btn"
            style={{
              fontWeight: 600,
              fontSize: '14px',
              background: '#000',
              color: '#fff',
            }}
            onClick={() => apply()}>
            수정 취소
          </button>
          <button
            className="modal btn cancel"
            style={{fontWeight: 600, color: '#D2D0D5', fontSize: '14px'}}
            onClick={() => setOpenModal(false)}>
            계속 작성
          </button>
        </div>
      </div>
    </div>
  );
}
