import React from 'react';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactElement;
  width: string;
  height: string;
  isOpen: boolean;
  style?: React.CSSProperties;
}

export default function Alert({children, width, height, isOpen, style}: Props) {
  return (
    <div
      className={styles.modalWrapper}
      style={{display: isOpen ? 'flex' : 'none'}}>
      <div
        className={styles.modalContents}
        style={{width: width, height: height, ...style}}>
        {children}
      </div>
    </div>
  );
}
