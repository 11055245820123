import {useMutation} from '@tanstack/react-query';

import {doBookmark} from '@/services/apis/brands';

export function useDoBookmark() {
  return useMutation({
    mutationFn: (payload: {id: number; bookmark: boolean}) =>
      doBookmark({
        id: payload.id,
        bookmark: payload.bookmark,
      }),
  });
}
