import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import ContentsHeader from '../../layouts/ContentsHeader';
import styles from './styles.module.scss';
import Layout from '../../layouts';

interface Props {
  type: 'failed' | 'soldout';
}

export default function Failed({type}: Props) {
  const navigator = useNavigate();
  const location = useLocation();
  const localStorageKey = 'WEB_PAYMENT_INFO';
  const openBrowser = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
    } else {
      await Browser.open({
        url: 'http://pf.kakao.com/_qmUqG/chat',
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem(localStorageKey);
  }, []);
  return (
    <Layout.Main>
      <div style={{position: 'relative'}}>
        <ContentsHeader
          txt="주문 결제"
          isOption="none"
          isBottomLine=""
          isPopup
          back={() =>
            navigator(
              `/popup/${(location.state?.magazine as MagazineItem).id}`,
              {
                state: {
                  prev: 'home',
                },
              },
            )
          }
        />
        <div className={styles.failed_info} style={{height: '100vh'}}>
          <span className={`icon ${styles.warning_icon}`}>&nbsp;</span>
          <h2>
            {type === 'failed'
              ? '결제에 실패했습니다.'
              : '티켓이 매진되었습니다.'}
          </h2>
          {type === 'failed' && (
            <div className={styles.message}>
              결제가 실패 혹은 취소되었습니다.
              <br />
              문제가 반복될 경우 카카오톡 채널로 문의해 주세요.
            </div>
          )}
          {type === 'soldout' && (
            <div className={styles.message}>
              티켓이 매진되어 결제가 취소될 예정입니다.
              <br />
              {'마이 > 내 티켓 > 주문상세를 통해 내역을 확인해 주세요.'}
            </div>
          )}
          <div className={styles.btn_wrapper}>
            <button className={styles.failed_btn} onClick={() => openBrowser()}>
              문의하기
            </button>
            <button
              className={styles.failed_btn}
              onClick={() => navigator('/home')}>
              홈으로
            </button>
          </div>
        </div>
      </div>
    </Layout.Main>
  );
}
