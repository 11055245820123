import React from 'react';

import styles from './styles.module.scss';

interface Props {
  disabled?: boolean;
  hasToggled: boolean;
  callback: () => void;
}

export default function ToggleButton({hasToggled, disabled, callback}: Props) {
  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={hasToggled} onChange={() => callback()} />
      <span
        className={`${styles.slider} ${styles.round} ${
          disabled ? styles.disabled : ''
        }`}></span>
    </label>
  );
}
