import React, {useMemo} from 'react';
import {useRecoilValue} from 'recoil';

import {JoinFieldInfo} from '@/store';

import styles from './styles.module.scss';

interface Props {
  type: JoinFieldCategory;
  rightInfo?: React.ReactElement;
  bottomInfo?: React.ReactElement;
  isShow?: boolean;
  disabled?: boolean;
  value: string;
  onChange?: ($event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function EmailJoinField({
  type,
  rightInfo,
  bottomInfo,
  isShow,
  disabled,
  value,
  onChange,
}: Props) {
  const joinFields = useRecoilValue(JoinFieldInfo);
  const fields = useMemo(() => {
    return joinFields[type];
  }, [type]);
  return (
    <div
      className={styles.joinField}
      style={{display: isShow ? 'block' : 'none'}}>
      <div className={styles.label}>
        <strong>{fields.label}</strong>
        <span>{fields.helper}</span>
      </div>
      <div className={styles.inputWrapper}>
        <input
          type={
            type === 'password' ||
            type === 'passwordConfirm' ||
            type === 'newPassword'
              ? 'password'
              : 'text'
          }
          value={value || ''}
          placeholder={fields.placeholder}
          disabled={disabled}
          onChange={onChange}
        />
        <span style={{marginRight: '20px'}}>{rightInfo}</span>
      </div>
      <div>{bottomInfo}</div>
    </div>
  );
}
