import React from 'react';

interface Props {
  type: 'pick' | 'best';
  isShow: boolean;
}

export default function Badge({type, isShow}: Props) {
  return (
    <div
      className={`badge-wrapper flex ${type}`}
      style={{display: isShow ? 'flex' : 'none'}}>
      <div className={`badge-icon ${type}`}></div>
      <div className="badge-text">{type.toUpperCase()}</div>
    </div>
  );
}
