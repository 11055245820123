import {atom} from 'recoil';

export const isOpenMenu = atom<boolean>({
  key: 'common/is-open-menu',
  default: false,
});

export const HomeItemImages = atom<HTMLImageElement[]>({
  key: 'home-item/images',
  default: [],
});

export const IsLoadHomeImage = atom<boolean>({
  key: 'home-item/loading',
  default: false,
});

export const CommonHistories = atom<string[]>({
  key: 'common/histories',
  default: [],
});

export const AuthRootPath = atom<string>({
  key: 'common/auth-root-path',
  default: '',
});

export const ScrollPositions = atom<{[key: string]: {x: number; y: number}}>({
  key: 'common/scroll-pos',
  default: {
    '/': {
      x: 0,
      y: 0,
    },
  },
});

export const LoginHistoryPath = atom<string>({
  key: 'common/history-path',
  default: 'home',
});

export const LoginPrevPath = atom<string>({
  key: 'common/login-prev-path',
  default: '',
});

export const PathHistories = atom<
  {
    path: string;
    state?: any;
  }[]
>({
  key: 'common/path-histories',
  default: [],
});
