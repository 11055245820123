import {initializeApp} from 'firebase/app';
import {FirebaseApp} from '@firebase/app-types';
import {FirebaseAnalytics} from '@firebase/analytics-types';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {useState} from 'react';
import {Auth, getAuth} from 'firebase/auth';
import {Capacitor} from '@capacitor/core';

export default function useFirebase() {
  const platform = Capacitor.getPlatform();
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APP_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId:
      platform == 'web'
        ? process.env.REACT_APP_FIREBASE_APP_ID
        : platform == 'ios'
        ? process.env.REACT_APP_FIREBASE_APP_IOS_ID
        : process.env.REACT_APP_FIREBASE_APP_ANDROID_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>();
  const [analytics, setAnalytics] = useState<FirebaseAnalytics>();
  const [auth, setAuth] = useState<Auth>();
  const initAnalytics = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    setFirebaseApp(app as FirebaseApp);
    setAnalytics(analytics as FirebaseAnalytics);
    setAuth(getAuth(app as FirebaseApp));
    logEvent(analytics, 'initlize analytics knewnew');
  };
  return {
    initAnalytics,
    firebaseApp,
    analytics,
    auth,
  };
}
