import React from 'react';
import {useRecoilState} from 'recoil';

import {MarketPageFilters} from '../../../../store/recommend';
import FilterIconBtn from '../../buttons/FilterIconBtn';

export default function StoreModalBody() {
  const [filterInfo, setFilterInfo] = useRecoilState(MarketPageFilters);
  const clickIcon = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'store')[0];
    const fIdx = f.selection.indexOf(selection);
    if (fIdx > -1) {
      f.selection.splice(fIdx, 1);
    } else {
      f.selection.push(selection);
    }
    _filterInfo.forEach(filter => {
      if (filter.type === f.type) {
        filter.selection = f.selection;
      }
    });
    setFilterInfo(_filterInfo);
  };
  const isActive = (selection: string) => {
    const _filterInfo = JSON.parse(
      JSON.stringify(filterInfo),
    ) as MarketPageFilter[];
    const f = _filterInfo.filter(f => f.type === 'store')[0];
    return f.selection.indexOf(selection) > -1;
  };
  return (
    <div className="filter-modal-body">
      <FilterIconBtn
        type={'kurly'}
        isActive={isActive('kurly')}
        _click={clickIcon}
      />
      <FilterIconBtn
        type={'coupang'}
        isActive={isActive('coupang')}
        _click={clickIcon}
      />
      <FilterIconBtn
        type={'bmart'}
        isActive={isActive('bmart')}
        _click={clickIcon}
      />
      <FilterIconBtn
        type={'ssg'}
        isActive={isActive('ssg')}
        _click={clickIcon}
      />
      <FilterIconBtn
        type={'naver'}
        isActive={isActive('naver')}
        _click={clickIcon}
      />
      <FilterIconBtn
        type={'etc'}
        isActive={isActive('etc')}
        _click={clickIcon}
      />
    </div>
  );
}
