import React, {useEffect, useRef} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import Button from './Button';
import {
  isOpenModal,
  ModalCommentID,
  ModalCommentStatus,
  OpeningModalStatus,
  CommunityCommentCount,
} from '../../../store';
import {deleteComment} from '../../../services/comment';
import {user} from '../../../store/user';

interface Props {
  type: 'edit' | 'report' | 'contents-report' | 'contents-edit';
  fetch: () => void;
  refetchContents?: () => void;
}
export default function CommentModal({type, fetch, refetchContents}: Props) {
  const navigator = useNavigate();
  const [openModal, setOpenModal] = useRecoilState(isOpenModal);
  const commentId = useRecoilValue(ModalCommentID);
  const accessToken = useRecoilValue(user);
  const openingStatus = useRecoilValue(OpeningModalStatus);
  const setCommentStatus = useSetRecoilState(ModalCommentStatus);
  const [commentCnt, setCommunityCommentCount] = useRecoilState(
    CommunityCommentCount,
  );
  const remove = async () => {
    await deleteComment(commentId, accessToken);
    setCommunityCommentCount(commentCnt - 1);
    fetch();
    if (refetchContents) {
      refetchContents();
    }
  };
  const edit = () => {
    setCommentStatus('edit');
    setOpenModal(false);
  };
  const moveToReport = () => {
    const paths = window.location.pathname.split('/');
    const id = paths[paths.length - 1];
    navigator(`/comments/report/${id}/${commentId}`);
  };
  const moveToContentsReport = () => {
    const paths = window.location.pathname.split('/');
    const id = paths[paths.length - 1];
    navigator(`/contents/report/${id}`);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event: any) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      setOpenModal(false);
    }
  };
  return (
    <div
      className="modal-wrapper"
      style={{display: openModal && openingStatus === type ? 'flex' : 'none'}}>
      <div className="modal-contents comment-m" ref={wrapperRef}>
        {type === 'edit' && (
          <div className={`modal-${type}`}>
            <Button
              type={'edit'}
              click={() => setOpenModal(false)}
              remove={remove}
              edit={edit}
            />
            <Button type={'cancel'} click={() => setOpenModal(false)} />
          </div>
        )}
        {type === 'report' && (
          <div className={`modal-${type}`}>
            <Button
              type={'report'}
              click={() => setOpenModal(false)}
              report={moveToReport}
            />
            <Button type={'cancel'} click={() => setOpenModal(false)} />
          </div>
        )}
        {type === 'contents-report' && (
          <div className={`modal-${type}`}>
            <Button
              type={'contents-report'}
              click={() => setOpenModal(false)}
              report={moveToContentsReport}
              style={{marginBottom: '10px'}}
            />
            <Button type={'cancel'} click={() => setOpenModal(false)} />
          </div>
        )}
        {type === 'contents-edit' && (
          <div className={`modal-${type}`}>
            <Button
              type={'contents-edit'}
              click={() => setOpenModal(false)}
              remove={remove}
              edit={edit}
              style={{marginBottom: '10px'}}
            />
            <Button type={'cancel'} click={() => setOpenModal(false)} />
          </div>
        )}
      </div>
    </div>
  );
}
