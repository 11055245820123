import React, {useEffect, useState} from 'react';

import {recommendList} from '../../../services/recommend';
import OtherItems from './OtherItems';

export default function OtherRecommendContents() {
  const [recommends, setRecommends] = useState<RecommendData[]>([]);
  const init = async () => {
    const paths = window.location.pathname.split('/');
    const id = paths[paths.length - 1];
    const res = await recommendList({
      limit: 20,
      offset: 0,
    });
    let data = res.data as RecommendData[];
    data = data.filter(o => o.id !== Number(id)).splice(0, 3);
    setRecommends(data);
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div className="other-recommend-contents-wrapper main-content">
      <div className="title">다른 추천 콘텐츠</div>
      <div className="divider"></div>
      {recommends.map(recommend => {
        return <OtherItems key={recommend.id} recommendData={recommend} />;
      })}
    </div>
  );
}
