import styled from 'styled-components';

export const ImageCounterWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.4);
  color: var(--color-white, #fff);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  border-radius: 12px;
  height: 24px;
`;
