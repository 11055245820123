import Axios from 'axios';

import {Notification} from '../views/myKnewnew/PushList/LIst';
import {errorInterceptor} from '../utils';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use(undefined, errorInterceptor.bind(this));

export const userProfile = (id: string) => {
  return axios.get(`/user/${id}/profile/`);
};

export const userDetail = (token: string) => {
  return axios.get('/user/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const login = (
  token: string,
  providerType: 'kakao' | 'email' | 'naver' | 'apple' | 'google',
  email?: string,
  passwd?: string,
) => {
  return axios.post(
    '/auth/login/',
    {
      providerType,
      providerKey: token,
      email,
      password: passwd,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
};

export const signup = (
  info: SignupInfo,
  userInfo: Omit<UserInfoObj, 'id'> & Partial<{id: number}>,
) => {
  const params: Omit<UserInfoObj, 'id'> & Partial<{id: number}> & SignupInfo = {
    ...userInfo,
    tags: info.tags,
    profileImage:
      info.profileImage && info.profileImage.length > 0
        ? info.profileImage
        : userInfo.profileImage,
    birth: info.birth,
    gender: info.gender,
    nickname: info.nickname,
    isMarketing: false,
  };
  return axios.post('/auth/signup/', params);
};

export const logout = (token: string, _deviceId = '') => {
  if (_deviceId.length == 0) {
    return axios.get('/auth/logout/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return axios.get('/auth/logout/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        deviceId: _deviceId,
      },
    });
  }
};

export const signout = (id: number, token: string) => {
  return axios.delete(`/user/${id}/profile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProfile = (
  id: number,
  token: string,
  userInfo: UserInfoObj,
) => {
  const _userInfo = JSON.parse(JSON.stringify(userInfo)) as UserInfoObj;
  if (_userInfo.profileImage) {
    const pic = _userInfo.profileImage;
    const img = pic?.split('/')[pic.split('/').length - 1];
    _userInfo.profileImage =
      _userInfo.profileImage.indexOf('http') > -1
        ? `/user/${img}`
        : _userInfo.profileImage;
  } else {
    //Insert empty string because json object which has undefined value doesn't send
    _userInfo.profileImage = '';
  }
  return axios.patch(`/user/${id}/profile/`, _userInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadImage = (img: string, route = 'user') => {
  return axios.post('/file/presigned/', {
    fileName: [`${img}`],
    route,
  });
};

export const getUsersReview = (
  id: number,
  token: string,
  param: {offset: number},
) => {
  return token
    ? axios.get(`/user/${id}/reviews/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: param,
      })
    : axios.get(`/user/${id}/reviews/`, {
        params: param,
      });
};

export const getUserProfile = (id: number, token: string) => {
  return token
    ? axios.get(`/user/${id}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    : axios.get(`/user/${id}/profile`);
};

export const verifyEmail = (email: string) => {
  return axios.post('/auth/email/verify/', {
    email,
  });
};

export const verifyEmailCode = (email: string, code: string) => {
  return axios.post('/auth/email/verify/code/', {
    email,
    code,
  });
};

export const checkValidEmail = (email: string) => {
  return axios.post('/auth/email/check/', {
    email,
  });
};

export const recoverEmailPassword = (
  email: string,
  code: string,
  password: string,
) => {
  return axios.post('/auth/recover/verify/', {
    email,
    code,
    password,
  });
};

export const recoverEmailCodeCheck = (email: string, code: string) => {
  return axios.post('/auth/recover/check/', {
    email,
    code,
  });
};

export const recoverEmail = (email: string) => {
  return axios.post('/auth/recover/', {
    email,
  });
};

export const registDevice = (param: DeviceParam, token: string) => {
  return axios.post('/notification/device/', param, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getNoticeList = (token: string) => {
  return axios.get('/notification/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateNotice = (
  id: number,
  notice: Notification,
  token: string,
) => {
  return axios.patch(`/notification/${id}`, notice, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ReadAllNotice = (token: string) => {
  return axios.patch(`/notification/`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBanner = (token: string) => {
  return axios.get('/banner/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyBookmarks = (
  id: number,
  params: {
    offset: number;
    limit: number;
    remove_count?: string;
  },
  type: 'brand' | 'magazine' | 'product',
  token: string,
) => {
  const api = `/user/${id}/bookmark/${type}/`;
  return axios.get(api, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getMyActivityCounts = (token: string, id: number) => {
  const api = `/user/${id}/count/`;
  return axios.get(api, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
