import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';

import Modal from '../../layouts/Modal';
import styles from './styles.module.scss';
import {BrandSortInfo} from '../../store/brand';

interface Props {
  isOpen: boolean;
  currentSort: 'newest' | 'famous';
  toggle: (sort: 'newest' | 'famous') => void;
  setIsOpen: (is: boolean) => void;
}

export default function BrandSortBottomSheet({
  isOpen,
  setIsOpen,
  currentSort,
  toggle,
}: Props) {
  const [sortItems, setSortItems] = useRecoilState(BrandSortInfo);
  const toggleItem = (idx: number) => {
    let _sortItems = JSON.parse(JSON.stringify(sortItems));
    _sortItems = _sortItems.map((o: BrandSortItems) => {
      return {
        ...o,
        isSelect: false,
      };
    });
    _sortItems[idx].isSelect = true;
    setSortItems(_sortItems);
    toggle(_sortItems[idx].value);
  };

  useEffect(() => {
    let _sortItems = JSON.parse(JSON.stringify(sortItems));
    let _idx = -1;
    _sortItems = _sortItems.map((o: BrandSortItems) => {
      return {
        ...o,
        isSelect: false,
      };
    });
    _sortItems.forEach((item: BrandSortItems, idx: number) => {
      if (item.value === currentSort) {
        _idx = idx;
      }
    });
    _sortItems[_idx].isSelect = true;
    setSortItems(_sortItems);
  }, [currentSort]);

  return (
    <Modal.BottomSheet height="174px" isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={styles.shareSheet}>
        <div
          className={`${styles.item} ${
            sortItems[0].isSelect ? styles.active : ''
          }`}
          onClick={() => toggleItem(0)}>
          {sortItems[0].txt}
        </div>
        <div className={styles.divider}></div>
        <div
          className={`${styles.item} ${
            sortItems[1].isSelect ? styles.active : ''
          }`}
          onClick={() => toggleItem(1)}>
          {sortItems[1].txt}
        </div>
      </div>
    </Modal.BottomSheet>
  );
}
