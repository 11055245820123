import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import ContentsHeader from '@/layouts/ContentsHeader';
import ButtonFooter from '@/layouts/ButtonFooter';
import {IsOpenDuplicatedNotice, isOpenToast, ToastMessage} from '@/store';
import {
  applyEmptyOptionsNotice,
  getMagazineCollectionItems,
} from '@/services/magazine';
import {user} from '@/store/user';
import AuthModal from '@/components/common/AuthModal';
import Layout from '@/layouts';
import useHistory from '@/hooks/useHistory';
import CollectionLoader from '@/components/common/Skeleton/CollectionLoader';

import styles from './styles.module.scss';
import CollectionItem from './components/Item';

export default function GiftOptionAlert() {
  const {state} = useLocation();
  const navigator = useNavigate();
  const {historyBack} = useHistory();
  const [isOpenDuplicatedNotice, setIsOpenDuplicatedNotice] = useRecoilState(
    IsOpenDuplicatedNotice,
  );
  const [phoneNumber, setPhoneNumber] = useState('010');
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [itemList, setItemList] = useState<CollectionListItem[]>();
  const [completed, setCompleted] = useState(false);
  const setIsOpenToastMessage = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const accessToken = useRecoilValue(user);
  const enterPhoneNumber = ($event: React.ChangeEvent<HTMLInputElement>) => {
    const v = $event.currentTarget.value;
    if (v.length <= 3) {
      setPhoneNumber('010');
    } else {
      setPhoneNumber(v.replace(/[^0-9]/g, ''));
    }

    const isValidPhoneNumber =
      v.startsWith('01') && /(\d{3})(\d{4})(\d{4})/.test(v);
    setIsPhoneNumberError(!isValidPhoneNumber);
  };
  const apply = async () => {
    const id = state?.detailInfo?.id;
    try {
      if (selectedOptionId) {
        await applyEmptyOptionsNotice(
          accessToken,
          {
            phone: phoneNumber,
          },
          selectedOptionId,
        );
        getData();
        // setCompleted(true);
        setToastMessage('재오픈 알림 신청이 완료되었습니다.');
        setIsOpenToastMessage(true);
        navigator(`/brand/giftcard/${id}`, {
          replace: true,
        });
      }
    } catch (e: any) {
      // TODO: move to back, open modal
      if (e.response && e.response.status === 558) {
        setIsOpenDuplicatedNotice(true);
      }
    }
  };

  // TODO: recoil state에 추가하기
  const getData = async () => {
    try {
      const {data} = await getMagazineCollectionItems(
        accessToken,
        'select_shop_famous',
      );
      const list = data?.[0]?.magazines ?? [];
      setItemList(list.slice(0, 8));
    } catch (e) {
      console.log(e);
    }
  };

  const isDisabledApplyBtn = useMemo(() => {
    return !(
      !isPhoneNumberError &&
      phoneNumber.length >= 11 &&
      !!selectedOptionId
    );
  }, [isPhoneNumberError, phoneNumber, selectedOptionId]);

  useEffect(() => {
    if (phoneNumber.length === 11) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    } else if (phoneNumber.length === 13) {
      const number = phoneNumber
        .replace(/-/g, '')
        .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      setPhoneNumber(number);
    } else if (phoneNumber.length < 7) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2'));
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (state && state.optionId) {
      setSelectedOptionId(state.optionId);
    }
  }, [state]);

  useEffect(() => {
    if (completed) {
      getData();
    }
  }, []);

  return (
    <Layout.Main>
      <div className={`${styles.emptyOptionAlertArea}`}>
        <ContentsHeader
          txt={'오픈 알림 신청'}
          isOption={'none'}
          isPopup={true}
          back={() => {
            setCompleted(false);
            const id = state?.detailInfo?.id;
            navigator(`/brand/giftcard/${id}`, {
              replace: true,
            });
          }}
        />
        {!completed ? (
          <>
            <div className={styles.contents}>
              <div className={styles.title}>
                {state?.detailInfo.title || ''}
              </div>
              <div className={styles.phoneNumberInput}>
                <div className={styles.phoneNumberInputTitle}>
                  휴대폰 번호 *
                </div>
                <div className={styles.phoneNumberInputForm}>
                  <input
                    type="tel"
                    className={`${
                      isPhoneNumberError ? styles.error_border : ''
                    }`}
                    placeholder="알림을 받으실 휴대폰 번호를 입력해주세요"
                    maxLength={13}
                    value={phoneNumber}
                    onChange={enterPhoneNumber}
                  />
                  <div>
                    <div
                      className={styles.error}
                      style={{display: isPhoneNumberError ? 'block' : 'none'}}>
                      {'휴대폰 번호가 올바르지 않습니다.'}
                    </div>
                  </div>
                  <ul>
                    <li>
                      • 할인권 오픈 시 문자/카카오톡 메시지로 알려드립니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ButtonFooter>
              <div
                className={styles.btnWrapper}
                style={{
                  marginTop: '13px',
                  marginBottom: '13px',
                }}>
                <button
                  className={styles.cancelBtn}
                  onClick={() => historyBack()}>
                  취소
                </button>
                <button
                  className={styles.applyBtn}
                  onClick={() => apply()}
                  disabled={isDisabledApplyBtn}>
                  신청
                </button>
              </div>
            </ButtonFooter>
          </>
        ) : (
          <div className={styles.waitingArea}>
            <div className={styles.txtWrapper}>
              <strong className={styles.title}>
                대기 신청이 완료되었습니다
              </strong>
              <p className={styles.desc}>
                {
                  '추가 수량 발생 시 입력하신 연락처로\n 안내 문자를 발송해 드립니다.'
                }
              </p>
            </div>
            {!!itemList && itemList.length > 0 && (
              <>
                <div className={styles.list_title}>함께보면 좋은 상품</div>
                <div
                  className={styles.listArea}
                  style={{padding: '15px 20px 0'}}>
                  <div className={styles.list}>
                    {itemList.map((item, idx) => {
                      return (
                        <div key={`collection_item_${idx}`}>
                          {item?.id === -1 ? (
                            <CollectionLoader />
                          ) : (
                            <CollectionItem
                              idx={idx}
                              item={item}
                              toggleLike={() => console.log('')}
                              setOpenInstallAlert={() => console.log('')}
                              noBadge
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div style={{padding: '40px 20px'}}>
                  <button
                    className={styles.collectionViewMoreBtn}
                    onClick={() =>
                      navigator(`/collection/items/select_shop_famous`, {
                        state: {
                          backPath: `/collection/list`,
                        },
                      })
                    }>
                    <span className={styles.txt}>상품 전체 보기</span>
                    <span className={styles.collectionViewMoreIcon}></span>
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        <AuthModal
          txt={<>이미 알림이 신청된 상품입니다.</>}
          isOpen={isOpenDuplicatedNotice}
          setIsOpen={(is: boolean) => setIsOpenDuplicatedNotice(is)}
          action={() => setIsOpenDuplicatedNotice(false)}
          btnType={'error'}
        />
      </div>
    </Layout.Main>
  );
}
