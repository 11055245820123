import React from 'react';

import Layout from '@/layouts';

import DefaultHeader from '../layouts/headers/DefaultHeader';

interface Props {
  children: React.ReactNode;
}

export default function NPickDetail({children}: Props) {
  return (
    <Layout.Main>
      <DefaultHeader />
      {children}
    </Layout.Main>
  );
}
