import {useCallback, useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {useLocation} from 'react-router-dom';

import {ScrollPositions} from '../store';

export const useScroll = () => {
  const location = useLocation();
  const [scrollPosList, setScrollPosList] = useRecoilState(ScrollPositions);
  const onScroll = () => {
    requestAnimationFrame(() => {
      const {pageXOffset, pageYOffset, location} = window;
      const existedSP = {...scrollPosList};
      existedSP[`${location.pathname}${location.search}`] = {
        x: pageXOffset,
        y: pageYOffset,
      };
      setScrollPosList(() => {
        return existedSP;
      });
    });
  };
  const syncScroll = () => {
    const sp = scrollPosList[`${location.pathname}${location.search}`];
    if (sp && sp.y > 0) {
      setTimeout(() => {
        window.scrollTo(sp.x, sp.y);
      }, 100);
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  };
  const hasScrollPosition = () => {
    const keys = Object.keys(scrollPosList);
    return (
      keys.indexOf(`${location.pathname}${location.search}`) > -1 &&
      location.state?.scrollEnabled
    );
  };
  const checkScrolled = useCallback(
    (path: string) => {
      console.log(scrollPosList[path]);
      return scrollPosList[path] && scrollPosList[path].y > 0;
    },
    [scrollPosList],
  );
  const resetScroll = async (path?: string) => {
    const _sp = JSON.parse(JSON.stringify(scrollPosList));
    const sp = _sp[path ? path : `${`${location.pathname}${location.search}`}`];
    if (sp) {
      _sp[path ? path : `${location.pathname}${location.search}`] = {
        x: 0,
        y: 0,
      };
      setScrollPosList(_sp);
    }
    return;
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    scrollPosList,
    syncScroll,
    hasScrollPosition,
    checkScrolled,
    resetScroll,
  };
};
