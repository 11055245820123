import styled, {css, keyframes} from 'styled-components';

export const fadeIn = keyframes`
0% {
  opacity: 0;
  display: none;
}
100% {
  opacity: 1;
  display: flex;
}
`;

export const androidStyle = css`
  padding-top: var(--status-bar-height);
  height: calc(56px + var(--status-bar-height));
`;

export const scrolledStyle = css`
  animation: ${fadeIn} 0.5s;
  position: sticky;
  width: inherit;
  top: 0;
  background: #fff;
  border-bottom: 1px solid rgb(233, 231, 236);
`;

export const mobileStyle = css`
  position: fixed !important;
`;

export const withKakaoStyle = css`
  min-width: 76px;
  margin-right: 8px;
`;
export const withScrolledStyle = css`
  padding: 0;
  background: transparent !important;
  margin-right: 0 !important;
`;

export const ContentHeader = styled.header<ContentsHeaderPropsI>`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding-top: env(safe-area-inset-top);
  padding-left: 20px;
  padding-right: 20px;
  height: calc(56px + env(safe-area-inset-top));
  background: transparent;

  ${({android}) => android && androidStyle}
  ${({scrolled}) => scrolled && scrolledStyle}
  ${({mobile, scrolled}) => mobile && scrolled && mobileStyle}
`;

export const HeaderTitle = styled.h3`
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const BackButton = styled.div<ContentsHeaderBackBtnPropsI>`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;

  ${({kakao}) => kakao && withKakaoStyle}
  ${({scrolled}) => scrolled && withScrolledStyle}

  .backIcon {
    width: 20px;
    height: 20px;
    background: url('/assets/icons/overlay_header_back_icon.svg') no-repeat
      center / cover;
  }

  .kakaoWrap {
    display: inline-flex;
    align-items: center;
    border-right: 2px solid #e9e7ec;
    padding: 0 10px;
    cursor: pointer;

    .kakaoIcon {
      min-width: 22px;
      height: 20px;
      background: url('/assets/icons/overlay_header_kakao_black.svg') no-repeat
        center / cover;
      margin-right: 4px;
    }

    .kakaoTxt {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.32px;
      color: var(--color-black, #000);
    }
  }

  .homeIcon {
    width: 20px;
    height: 20px;
    background: url('/assets/icons/overlay_home_icon.svg') no-repeat center /
      cover;
  }
`;

export const ShareButton = styled.div<ShareButtonI>`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: ${({scrolled}) => (scrolled ? '#fff' : 'rgba(0, 0, 0, 0.3)')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
