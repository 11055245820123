import React from 'react';

import BottomSheet from './BottomSheet';
import Alert from './Alert';

function Modal() {
  return <div>index</div>;
}

Modal.BottomSheet = BottomSheet;
Modal.Alert = Alert;

export default Modal;
