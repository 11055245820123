import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import {MagazineDetailInfo} from '@/store';
import {switchNewLineTxt} from '@/utils';

import BrandItemLoader from '../../common/Skeleton/BrandItemLoader';
import styles from './styles.module.scss';

interface Props {
  info: MagazineItem;
  type: 'news' | 'zip' | 'brands' | 'showroom' | undefined;
  isBookMarked?: boolean;
  makeToast?: (is: boolean) => void;
}

export default function BrandCard({info, type}: Props) {
  const navigator = useNavigate();
  const setMagazineDetailInfo = useSetRecoilState(MagazineDetailInfo);
  const [isLoadImage, setIsLoadImage] = useState(false);
  const loadImag = (imgPath: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        setIsLoadImage(true);
        resolve(img);
      };
      img.onerror = () => {
        reject(null);
      };
      img.src = imgPath;
    });
  };
  const moveTo = async () => {
    const isFromBookmark = location.pathname.indexOf('bookmark') > -1;
    await setMagazineDetailInfo(info);
    navigator(`/${type === 'brands' ? 'knewbrands' : type}/${info.id}`, {
      state: {
        bookmark: isFromBookmark,
        prev: location.pathname.replace('/', ''),
      },
    });
  };

  useEffect(() => {
    loadImag(info.imageUrl);
  }, [info]);

  return isLoadImage ? (
    <div className={`${styles.brandCard}`} onClick={() => moveTo()}>
      <div
        className="picture-wrapper"
        style={{
          background: `center / cover no-repeat url('${info.imageUrl}')`,
          paddingTop: '112.24%',
          borderRadius: '5px',
        }}>
        &nbsp;
      </div>
      {/**
      <div className={styles.brandType}>
        <div className={styles.type}>
          {info.target?.replace(/^[a-z]/, char => char.toUpperCase())}
        </div>
        <div
          className={styles.writer}
          style={{display: type === 'news' ? 'none' : 'block'}}>
          {info.author.nickname}
        </div>
      </div>
      */}
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: switchNewLineTxt(info.title || ''),
        }}></div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: switchNewLineTxt(info.subtitle || ''),
        }}></div>
    </div>
  ) : (
    <BrandItemLoader />
  );
}
