import React from 'react';

interface Props {
  fontSize?: number;
  fill?: string;
}

export default function OverlayShareIcon({fontSize, fill}: Props) {
  return (
    <svg
      width={`${fontSize || 20}`}
      height={`${fontSize || 20}`}
      viewBox={`0 0 ${fontSize || 20} ${fontSize || 20}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5070_11189)">
        <path
          d="M13.2796 7.64047C14.8396 7.64047 16.1096 6.37047 16.1096 4.81047C16.1096 3.25047 14.8396 1.98047 13.2796 1.98047C11.7196 1.98047 10.4496 3.25047 10.4496 4.81047C10.4496 5.08047 10.4996 5.33047 10.5696 5.58047L6.65965 7.95047C6.14965 7.47047 5.46965 7.17047 4.71965 7.17047C3.15965 7.17047 1.88965 8.44047 1.88965 10.0005C1.88965 11.5605 3.15965 12.8305 4.71965 12.8305C5.40965 12.8305 6.03965 12.5705 6.52965 12.1605L9.60965 14.2705C9.50965 14.5605 9.43965 14.8605 9.43965 15.1905C9.43965 16.7505 10.7096 18.0205 12.2696 18.0205C13.8296 18.0205 15.0996 16.7505 15.0996 15.1905C15.0996 13.6305 13.8296 12.3605 12.2696 12.3605C11.5796 12.3605 10.9496 12.6205 10.4596 13.0305L7.37965 10.9205C7.47965 10.6305 7.54965 10.3305 7.54965 10.0005C7.54965 9.73047 7.49965 9.48047 7.42965 9.23047L11.3396 6.86047C11.8496 7.34047 12.5296 7.64047 13.2796 7.64047ZM13.2796 3.48047C14.0096 3.48047 14.6096 4.08047 14.6096 4.81047C14.6096 5.54047 14.0096 6.14047 13.2796 6.14047C12.5496 6.14047 11.9496 5.54047 11.9496 4.81047C11.9496 4.08047 12.5496 3.48047 13.2796 3.48047ZM4.71965 11.3205C3.98965 11.3205 3.38965 10.7205 3.38965 9.99047C3.38965 9.26047 3.98965 8.66047 4.71965 8.66047C5.44965 8.66047 6.04965 9.26047 6.04965 9.99047C6.04965 10.7205 5.44965 11.3205 4.71965 11.3205ZM12.2796 13.8505C13.0096 13.8505 13.6096 14.4505 13.6096 15.1805C13.6096 15.9105 13.0096 16.5105 12.2796 16.5105C11.5496 16.5105 10.9496 15.9105 10.9496 15.1805C10.9496 14.4505 11.5496 13.8505 12.2796 13.8505Z"
          fill={fill || 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_5070_11189">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
